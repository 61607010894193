import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LocationCreate,
    LocationDto,
    LocationRead,
    LocationSearchParams,
} from '../../entities/location.dto';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { LocationMetadataMap } from './location.mapping-profile';

@Injectable()
export class LocationMapper extends AbstractEntityMapper<
    LocationRead,
    LocationCreate,
    LocationDto,
    LocationSearchParams
> {
    constructor() {
        super(LocationDto, PlatformCoreMapper, LocationMetadataMap.symbols);
    }
}
