import { Injectable } from '@angular/core';
import { AbstractEntityMapper } from '@pf/shared-services';
import { PlatformCommunicationsMapper } from '../platform-communications.mapper';
import { OneTimeLinkMetadataMap } from './one-time-link.mapping-profile';
import {
    OneTimeLinkCreate,
    OneTimeLinkDto,
    OneTimeLinkRead,
    OneTimeLinkSearchParams,
} from '@pf/shared/util-platform';

@Injectable()
export class OneTimeLinkMapper extends AbstractEntityMapper<
    OneTimeLinkRead,
    OneTimeLinkCreate,
    OneTimeLinkDto,
    OneTimeLinkSearchParams
> {
    constructor() {
        super(
            OneTimeLinkDto,
            PlatformCommunicationsMapper,
            OneTimeLinkMetadataMap.symbols
        );
    }
}
