<nz-form-item class="pf-form-item">
    <nz-form-label
        *ngIf="!!label"
        class="pf-form-label"
        [nzRequired]="pfRequired"
        [nzNoColon]="true"
        [title]="label"
    >{{ label }}</nz-form-label
    >
    <nz-form-control class="pf-form-control">
        <nz-switch
            class="pf-input"
            [nzLoading]="loading"
            [formControl]="formControl"
        ></nz-switch>
    </nz-form-control>
</nz-form-item>
