<nz-form-item class="pf-form-item">
    <nz-form-label
        *ngIf="!!label"
        class="pf-form-label"
        [nzRequired]="pfRequired"
        [nzNoColon]="true"
        [title]="label"
    >{{ label }}</nz-form-label
    >
    <nz-form-control class="pf-form-control">
        <ng-container [ngSwitch]="typeProps?.type">
            <ng-container *ngSwitchCase="'dateRange'">
                <nz-range-picker
                    class="pf-input"
                    [formControl]="formControl"
                    [nzRanges]="presetRanges"
                    [nzDisabled]="pfDisabled || loading"
                    [nzFormat]="dateFormat"
                    [nzPlaceHolder]="placeholder"
                    [nzShowTime]="showTime"
                >
                </nz-range-picker>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
                <nz-date-picker
                    class="pf-input"
                    [nzDisabled]="pfDisabled || loading"
                    [nzFormat]="dateFormat"
                    [nzPlaceHolder]="placeholder"
                    [formControl]="formControl"
                    [nzShowTime]="showTime"
                >
                </nz-date-picker>
            </ng-container>
        </ng-container>
    </nz-form-control>
</nz-form-item>
