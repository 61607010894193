import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFItemCatalogEntities,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    CategoryCreate,
    CategoryDto,
    CategoryRead,
    CategorySearchParams,
} from '../../entities/category.dto';
import {
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFItemCatalogEntities.Category);

withDefaultEntityMetadata<CategoryDto>(symbols.dto, {
    name: String,
    code: String,
    parentCategoryId: String,
    topLevelCategoryID: String,
    categoryBreadCrumbs: String,
    isTopLevelCategory: Boolean,
    children: [symbols.dto],
    readOnly: Boolean,
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.dto],
});

withDefaultEntityMetadata<CategoryRead & { children: CategoryDto[] }>(
    symbols.entity,
    {
        name: String,
        code: String,
        parentCategoryId: String,
        topLevelCategoryID: String,
        categoryBreadCrumbs: String,
        isTopLevelCategory: Boolean,
        children: [symbols.dto],
        resourceAuth: ResourceAuthSymbol,
        customFields: [CustomFieldMetadataMap.symbols.entity],
    }
);

withMetadata<CategoryCreate>(symbols.createBody, {
    name: String,
    code: String,
    parentCategoryId: String,
    topLevelCategoryID: String,
    categoryBreadCrumbs: String,
    isTopLevelCategory: Boolean,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<CategorySearchParams>(symbols.searchParams, {
    search: String,
});

export const CategoryMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<CategoryRead, CategoryDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<CategoryDto, CategoryRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<CategoryDto, CategoryCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<CategoryDto>, CategorySearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('isTopLevelCategory'),
            withSearchFilter('parentCategoryId'),
            forMember(
                d => d.search,
                mapFrom(s => {
                    return s.searchValue;
                })
            ),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
