import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { StopItemRead } from '../../entities/stop-item.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class StopItemStore extends AbstractEntityStore<StopItemRead> {
    constructor() {
        super(PFLoadEntities.StopItem);
    }
}
