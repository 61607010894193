import { Injectable } from '@angular/core';
import { AbstractSearchFacade } from '@pf/shared-services';
import {
    TimezoneCreate,
    TimezoneDto,
    TimezoneRead,
    TimezoneSearchParams,
} from '../entities/timezone.dto';
import { TimezoneDataService } from '../infrastructure/timezone/timezone.data.service';
import { TimezoneMapper } from '../infrastructure/timezone/timezone.mapper';
import { TimezoneStore } from '../infrastructure/timezone/timezone.store';

@Injectable()
export class TimezoneFacade extends AbstractSearchFacade<
    TimezoneDto,
    TimezoneRead,
    TimezoneCreate,
    TimezoneSearchParams
> {
    nameField: keyof TimezoneDto = 'name';

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof TimezoneDto, string>> {
        return { abbreviation: searchText };
    }

    constructor(
        timezoneDataService: TimezoneDataService,
        private timezoneStore: TimezoneStore,
        private timezoneMapper: TimezoneMapper
    ) {
        super(timezoneDataService, timezoneStore, timezoneMapper);
    }
}
