import {
    VendorCreate,
    VendorDto,
    VendorRead,
    VendorSearchParams,
} from '../../entities/vendor.dto';

import { AbstractEntityMapper } from '@pf/shared-services';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { VendorMetadataMap } from './vendor.mapping-profile';

@Injectable()
export class VendorMapper extends AbstractEntityMapper<
    VendorRead,
    VendorCreate,
    VendorDto,
    VendorSearchParams
> {
    constructor() {
        super(VendorDto, PlatformCoreMapper, VendorMetadataMap.symbols);
    }
}
