import {
    FragmentMappingConfig,
    FragmentMetadataMap,
} from '../fragment-mapping-config';
import {
    IAddressDto,
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
    ITypeEntityFragment,
} from '@pf/shared-common';
import {
    ITypeEntityFragmentSymbol,
    withTypeEntityMetadata,
} from '../../type-entity.mapping-profile';
import { AddressMetadataMap } from '../../address.mapping-profile';
import { PlatformCoreFragments } from './platform-core-fragments.enum';
import { SafeMerge } from '@pf/shared-utility';

export interface ILocationFragment extends IEntityFragment {
    locationName?: string | null;
    locationNumber?: string | null;
    locationType: ITypeEntityFragment;
    address: IAddressDto;
}

export interface ILocationFragmentDto
    extends ILocationFragment,
        IEntityFragmentDto {}

export function LocationFragmentMetadataMap<
    TLocationFragmentDto extends ILocationFragmentDto,
    TLocationFragment extends ILocationFragment
>({
    entityName,
    fragmentDtoMetadataExtras,
    fragmentMetadataExtras,
    toFragmentDtoMappingFns,
    toFragmentMappingFns,
    profileExtras,
}: FragmentMappingConfig<
    TLocationFragmentDto,
    TLocationFragment
>): IMappingProfile {
    return FragmentMetadataMap<TLocationFragmentDto, TLocationFragment>(
        PlatformCoreFragments.Location,
        {
            entityName,
            fragmentDtoMetadataExtras: SafeMerge(
                {
                    locationName: String,
                    locationNumber: String,
                    locationType: ITypeEntityFragmentSymbol,
                    address: AddressMetadataMap.symbols.dto,
                } as FragmentMappingConfig<
                    TLocationFragmentDto,
                    TLocationFragment
                >['fragmentDtoMetadataExtras'],
                fragmentDtoMetadataExtras
            ),
            fragmentMetadataExtras: SafeMerge(
                {
                    locationName: String,
                    locationNumber: String,
                    locationType: ITypeEntityFragmentSymbol,
                    address: AddressMetadataMap.symbols.entity,
                } as FragmentMappingConfig<
                    TLocationFragmentDto,
                    TLocationFragment
                >['fragmentMetadataExtras'],
                fragmentMetadataExtras
            ),
            toFragmentDtoMappingFns,
            toFragmentMappingFns,
            profileExtras: (mapper, symbols) => {
                withTypeEntityMetadata();
                AddressMetadataMap.profile(mapper);
                profileExtras?.(mapper, symbols);
            },
        }
    );
}

export const DefaultLocationFragmentMetadataMap = LocationFragmentMetadataMap<
    ILocationFragmentDto,
    ILocationFragment
>({});
