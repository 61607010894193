import {
    IconModule,
    LayoutModule,
    SharedUiModule,
    TableModule,
} from '@pf/shared-ui';

import { ChangeDataViewComponent } from './change-data-view.component';
import { ChangeTreeComponent } from './change-tree.component';
import { ChangeViewComponent } from './change-view.component';
import { CommonModule } from '@angular/common';
import { DetailedHistoryViewComponent } from './detailed-history-view.component';
import { EntityHistoryComponent } from './entity-history.component';
import { EntityHistoryDrawerComponent } from './entity-history-drawer.component';
import { EntityHistoryService } from './entity-history.service';
import { NgModule } from '@angular/core';
import { NgxDiff2htmlModule } from '@mcarey1590/ngx-diff2html';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        LayoutModule,
        IconModule,
        TableModule,
        NzTimelineModule,
        NzTreeViewModule,
        NzDrawerModule,
        NzModalModule,
        NgxDiff2htmlModule,
    ],
    declarations: [
        EntityHistoryComponent,
        ChangeViewComponent,
        DetailedHistoryViewComponent,
        ChangeDataViewComponent,
        ChangeTreeComponent,
        EntityHistoryDrawerComponent,
    ],
    exports: [
        EntityHistoryComponent,
        DetailedHistoryViewComponent,
        ChangeDataViewComponent,
        ChangeTreeComponent,
        EntityHistoryDrawerComponent,
    ],
    providers: [EntityHistoryService],
})
export class EntityHistoryModule {}
