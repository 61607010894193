import { Injectable } from '@angular/core';
import { PermissionTypes, PFCoreEntities } from '@pf/shared-common';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildCorePermissionPath } from '@pf/shared/util-platform';

@Injectable()
export class RoutesForUserRole extends AbstractRouteBuilder {
    permissionType = buildCorePermissionPath(
        PermissionTypes.Modify,
        PFCoreEntities.User
    );
}
