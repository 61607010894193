import {
    Equipment,
    EquipmentTypeFragment,
    EquipmentViewModel,
} from '@control-tower/platform-loads';
import { IEntity, IMappingProfile } from '@pf/shared-common';
import { ITypeEntityFragmentSymbol } from '@pf/shared/util-platform';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { uniqueId } from 'lodash-es';

export interface IEquipmentDto extends IEntity, Equipment {}

export class EquipmentDto implements IEquipmentDto {
    constructor(data: Partial<IEquipmentDto>) {
        Object.assign(this, data);
        this.id = uniqueId();
    }
    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    equipmentType!: EquipmentTypeFragment;
    quantity!: number;
}

export type EquipmentRead = Equipment;
export type EquipmentCreate = EquipmentViewModel;

const symbols = entitySymbols('Equipment');

withDefaultEntityMetadata<EquipmentDto>(symbols.dto, {
    equipmentType: ITypeEntityFragmentSymbol,
    quantity: Number,
});

withMetadata<EquipmentRead>(symbols.entity, {
    equipmentType: ITypeEntityFragmentSymbol,
    quantity: Number,
});

withMetadata<EquipmentCreate>(symbols.createBody, {
    equipmentTypeId: String,
    quantity: Number,
});

export const EquipmentMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<EquipmentRead, EquipmentDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<EquipmentDto, EquipmentRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<EquipmentDto, EquipmentCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (x) => x.equipmentTypeId,
                mapFrom((source) => source.equipmentType?.id || null)
            )
        );
    },
};
