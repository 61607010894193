import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneTimeLinkDataService } from './infrastructure/one-time-link/one-time-link.data.service';
import { OneTimeLinkFacade } from './application/one-time-link.facade';
import { OneTimeLinkStore } from './infrastructure/one-time-link/one-time-link.store';
import { OneTimeLinkMapper } from './infrastructure/one-time-link/one-time-link.mapper';
import {
    ApiModule as CommunicationsApiModule,
    Configuration as CommunicationsApiConfig,
    ConfigurationParameters,
} from '@control-tower/platform-communications';
import { sharedEnv } from '@pf/shared-common';

const communicationsApiConfigFactory = (basePath: string) => {
    return (): CommunicationsApiConfig => {
        const params: ConfigurationParameters = {
            basePath,
        };
        return new CommunicationsApiConfig(params);
    };
};

@NgModule({
    imports: [CommonModule],
    providers: [
        OneTimeLinkDataService,
        OneTimeLinkFacade,
        OneTimeLinkStore,
        OneTimeLinkMapper,
    ],
})
export class PlatformCommunicationsDomainModule {
    static ApiModule() {
        return CommunicationsApiModule.forRoot(
            communicationsApiConfigFactory(
                sharedEnv.baseApiUrl + '/communications'
            )
        );
    }
}
