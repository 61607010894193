import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpResponseBase,
} from '@angular/common/http';
import { catchError, Observable, retry, tap, throwError } from 'rxjs';
import { LoggerService } from '@pf/shared-services';
import { NotificationsService, ResultMap, ResultType } from '@pf/shared-ui';
import {
    NotificationType,
    SafeAny,
    ToastMessage,
    ToastType,
} from '@pf/shared-common';

@Injectable()
export class ApiLoggingInterceptor implements HttpInterceptor {
    constructor(
        private loggerService: LoggerService,
        private notificationsService: NotificationsService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const startTime = +new Date();

        function createLogMessage(message: string, response: HttpResponseBase) {
            return `${request.method} ${request.url}\n${message} with status: ${
                response.status
            } - ${response.statusText}.\nRequest completed in ${
                +new Date() - startTime
            }ms.`;
        }

        return next.handle(request).pipe(
            retry(2),
            catchError(err => {
                let message = '';
                if (err.error instanceof ErrorEvent) {
                    message = (err.error as ErrorEvent).error;
                } else {
                    message = this.processServerError(err);
                }
                this.toastFailure(message);
                return throwError(err);
            }),
            tap({
                next: event => {
                    if (event instanceof HttpResponse) {
                        this.loggerService.trace(
                            createLogMessage(`Succeeded`, event)
                        );
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.loggerService.trace(
                        createLogMessage(error.error, error)
                    );
                },
            })
        );
    }

    processServerError(err: SafeAny): string {
        const errorMessage = err?.error;
        if (errorMessage?.title) {
            if (
                errorMessage.title.indexOf(
                    'One or more validation errors occurred'
                ) > -1
            ) {
                let message = '';
                for (const key in errorMessage.errors) {
                    message += errorMessage.errors[key] + '\n';
                }
                return message;
            }
            return errorMessage.title;
        }
        const resultTypes = [...ResultMap.values()];
        const status = err.status?.toString();
        const resultType = resultTypes.find(r => r.statusCode === status);
        return (
            resultType?.subTitle ||
            ResultMap.get(ResultType.Error)?.subTitle ||
            ''
        );
    }

    private toastFailure(message: string) {
        this.notificationsService.create({
            type: NotificationType.ToastMessage,
            toastType: ToastType.error,
            message: 'Request failed! ' + message,
        } as ToastMessage);
    }
}
