import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFTransportationEntities } from '@pf/shared-common';
import { EquipmentRead } from '../../entities/equipment.dto';

@Injectable()
export class EquipmentStore extends AbstractEntityStore<EquipmentRead> {
    constructor() {
        super(PFTransportationEntities.Equipment);
    }
}
