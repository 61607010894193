import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormControl } from '@angular/forms';
import { FrameworkColumn } from '@pf/shared-common';
import { distinctUntilChanged } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'pf-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent implements AfterViewInit {
    private _column!: FrameworkColumn;
    @Input() set column(column: FrameworkColumn) {
        this.checkboxControl.setValue(!!column.checked);
        this._column = column;
    }
    get column() {
        return this._column;
    }
    @HostBinding('style') get minWidth() {
        return this.column.width ? `min-width: ${this.column.width}px;` : null;
    }
    @Output() checkChanged = new EventEmitter<boolean>();
    checkboxControl = new FormControl<boolean>(false);

    ngAfterViewInit(): void {
        this.checkboxControl.valueChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe((checked) => {
                this.column.checked = checked;
                this.checkChanged.emit(!!checked);
            });
    }
}
