import { Injectable } from '@angular/core';
import { AbstractEntityMapper } from '@pf/shared-services';
import {
    UserPermissionCreate,
    UserPermissionDto,
    UserPermissionRead,
    UserPermissionSearchParams,
} from '../../entities/user-permission.dto';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { UserPermissionMetadataMap } from './user-permission.mapping-profile';

@Injectable()
export class UserPermissionMapper extends AbstractEntityMapper<
    UserPermissionRead,
    UserPermissionCreate,
    UserPermissionDto,
    UserPermissionSearchParams
> {
    constructor() {
        super(
            UserPermissionDto,
            PlatformCoreMapper,
            UserPermissionMetadataMap.symbols
        );
    }
}
