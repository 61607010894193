<form [formGroup]='form.formGroup' nz-form>
  <section pfSection pfTitle='Contact Information' [disableAnchor]='true'>
    <div class='pf-form-group'>
      <pf-input
        label='First Name'
        [controlName]='form.formControlNames.firstName'
        placeholder='First Name'
      ></pf-input>
      <pf-select
        label='Contact Type'
        [searchFacade]='contactTypeFacade'
        [controlName]='form.formControlNames.contactTypeId'
        (selected)='this.setContactTypeName($event)'
      ></pf-select>
      <pf-input
        label='Last Name'
        [controlName]='form.formControlNames.lastName'
        placeholder='Last Name'
      ></pf-input>
      <pf-input
        label='Email'
        [controlName]='form.formControlNames.email'
        inputType='email'
        placeholder='Email'
      ></pf-input>
      <pf-input
        label='Phone'
        [controlName]='form.formControlNames.phone'
        inputType='phone'
        placeholder='Phone'
      ></pf-input>
      <pf-input
        label='Description'
        [controlName]='form.formControlNames.description'
        inputType='textarea'
        placeholder='Title'
        [max]='250'
        pfErrorTip='Description cannot exceed 250 characters.'
      ></pf-input>
    </div>
  </section>
</form>
