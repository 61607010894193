import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    EquipmentCreate,
    EquipmentDto,
    EquipmentRead,
    EquipmentSearchParams,
} from '../../entities/equipment.dto';
import { PFTransportationEntities } from '@pf/shared-common';
import {
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFTransportationEntities.Equipment);

withDefaultEntityMetadata<EquipmentDto>(symbols.dto, {
    number: String,
    carrierId: String,
    description: String,
    equipmentTypeId: String,
    locationId: String,
    dimensions: Object,
    maximumLoadWeight: Object,
    weight: Object,
});

withDefaultEntityMetadata<EquipmentRead>(symbols.entity, {
    equipmentTypeId: String,
    carrierId: String,
    description: String,
    dimensions: Object,
    locationId: String,
    maximumLoadWeight: Object,
    weight: Object,
    number: String,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
});

withMetadata<EquipmentCreate>(symbols.createBody, {
    carrierId: String,
    description: String,
    dimensions: Object,
    equipmentTypeId: String,
    locationId: String,
    maximumLoadWeight: Object,
    weight: Object,
    number: String,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<EquipmentSearchParams>(symbols.searchParams, {
    number: String,
});

export const EquipmentMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<EquipmentRead, EquipmentDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<EquipmentDto, EquipmentRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<EquipmentDto, EquipmentCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<EquipmentDto>, EquipmentSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('number'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
        CustomFieldMetadataMap.profile(mapper);
    },
};
