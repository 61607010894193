import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
    TableQueryParams,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    FiscalPeriodCreate,
    FiscalPeriodDto,
    FiscalPeriodRead,
    FiscalPeriodSearchParams,
} from '../../entities/fiscal-period.dto';
import { ResourceAuthSymbol } from '@pf/shared/util-platform';
import { FiscalPeriodStatus } from '@control-tower/platform-core';

const symbols = entitySymbols(PFCoreEntities.FiscalPeriod);

withDefaultEntityMetadata<FiscalPeriodDto>(symbols.dto, {
    startDate: String,
    endDate: String,
    calendarType: String,
    name: String,
    description: String,
    year: Number,
    quarter: Number,
    month: Number,
    week: Number,
    status: String,
});

withDefaultEntityMetadata<FiscalPeriodRead>(symbols.entity, {
    startDate: String,
    endDate: String,
    calendarType: String,
    name: String,
    description: String,
    year: Number,
    quarter: Number,
    month: Number,
    week: Number,
    status: String,
    resourceAuth: ResourceAuthSymbol,
});

withMetadata<FiscalPeriodCreate>(symbols.createBody, {
    startDate: String,
    endDate: String,
    calendarType: String,
    name: String,
    description: String,
    year: Number,
    quarter: Number,
    month: Number,
    week: Number,
    status: String,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<FiscalPeriodSearchParams>(
    symbols.searchParams,
    {
        dateRangeStart: String,
        dateRangeEnd: String,
        calendarType: String,
        name: String,
        description: String,
        yearStart: Number,
        yearEnd: Number,
        quarterStart: Number,
        quarterEnd: Number,
        monthStart: Number,
        monthEnd: Number,
        weekStart: Number,
        weekEnd: Number,
        status: String,
    }
);

export const FiscalPeriodMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<FiscalPeriodRead, FiscalPeriodDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<FiscalPeriodDto, FiscalPeriodRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<FiscalPeriodDto, FiscalPeriodCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<FiscalPeriodDto>, FiscalPeriodSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('name'),
            withSearchFilter('startDate'),
            withSearchFilter('endDate'),
            forMember(
                d => d.status,
                mapFrom(s => {
                    return (s as TableQueryParams<FiscalPeriodDto>).checkboxes
                        ?.status
                        ? null
                        : FiscalPeriodStatus.Open;
                })
            ),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
