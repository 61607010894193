<ng-template #contentRef>
  <ng-content></ng-content>
</ng-template>
<ng-container [ngSwitch]='type'>
  <ng-container *ngSwitchCase="'menu'">
        <span nz-typography [nzEllipsis]='true'
        ><ng-container *ngTemplateOutlet='contentRef'></ng-container
        ></span>
  </ng-container>
  <ng-container *ngSwitchCase="'button'">
        <span nz-typography
        ><strong
        ><ng-container
          *ngTemplateOutlet='contentRef'
        ></ng-container></strong
        ></span>
  </ng-container>
  <ng-container *ngSwitchCase="'header'">
    <h2 nz-typography>
      <ng-container *ngTemplateOutlet='contentRef'></ng-container>
    </h2>
  </ng-container>
  <ng-container *ngSwitchCase="'title'">
    <h4 nz-typography>
      <ng-container *ngTemplateOutlet='contentRef'></ng-container>
    </h4>
  </ng-container>
  <ng-container *ngSwitchCase="'subtitle'">
    <h5 nz-typography>
      <ng-container *ngTemplateOutlet='contentRef'></ng-container>
    </h5>
  </ng-container>
  <ng-container *ngSwitchDefault>
            <span
              nz-typography
              style='margin-bottom: auto'
              [ngClass]="{'pf-text-ellipses': ellipsis}"
              [nzType]='nzType'
            >
                <ng-container *ngTemplateOutlet='contentRef'></ng-container>
            </span>
  </ng-container>
</ng-container>
