import {
    AfterContentInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    ViewContainerRef,
} from '@angular/core';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { InjectionService } from '../../../services/injection.service';
import { ContentService } from '../services/content.service';
import { AbstractPageAnchor } from './AbstractPageAnchor';

@Directive({
    selector: '[pfArticle]',
})
export class ArticleDirective
    extends AbstractPageAnchor
    implements AfterContentInit
{
    constructor(
        private contentService: ContentService,
        elementRef: ElementRef,
        viewContainerRef: ViewContainerRef,
        injectionService: InjectionService,
        cdr: ChangeDetectorRef
    ) {
        super(elementRef, viewContainerRef, injectionService, cdr);
    }

    ngAfterContentInit(): void {
        this.setDivider();
    }

    protected registerPageAnchor() {
        let sectionRef: HTMLElement | null = this.elementRef.nativeElement
            .parentElement as HTMLElement;
        while (sectionRef && !sectionRef.hasAttribute('pfSection')) {
            sectionRef = sectionRef.parentElement;
        }
        if (!sectionRef) {
            return;
        }
        const sectionNodes = Array.from(sectionRef.children);
        const index = sectionNodes.findIndex(
            (node) =>
                node === this.elementRef.nativeElement ||
                node.contains(this.elementRef.nativeElement)
        );
        this.contentService.registerArticle(sectionRef.id, {
            title: this.title,
            id: this.id,
            index,
        });
    }

    protected override configureDivider(divider: NzDividerComponent): void {
        super.configureDivider(divider);
        divider.nzPlain = true;
    }
}
