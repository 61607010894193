import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { SharedUiModule } from '../../shared-ui.module';
import { TransferComponent } from './transfer.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUiModule,
        NzTransferModule,
        NzSkeletonModule,
    ],
    declarations: [TransferComponent],
    exports: [TransferComponent],
})
export class TransferModule {}
