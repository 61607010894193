import { camelCase, startCase } from 'lodash-es';

import { ChangeOperation } from '@pf/shared-common';

export function computeChangedFields(changes: ChangeOperation[]) {
    return changes.map((change) => startCase(change.path?.slice(1))).join(', ');
}

export function computeChangeText(changes: ChangeOperation[]) {
    const aggregatedChanges = changes.reduce(
        (pv, cv) => {
            const op = cv.op?.toLocaleLowerCase().trim();
            if (op === 'add') {
                pv.additions.count++;
            } else if (op === 'replace') {
                pv.updates.count++;
            } else if (op === 'remove') {
                pv.removals.count++;
            }
            return pv;
        },
        {
            additions: { display: 'Addition(s)', count: 0 },
            updates: { display: 'Update(s)', count: 0 },
            removals: { display: 'Removal(s)', count: 0 },
        }
    );
    const changeText = Object.values(aggregatedChanges)
        .filter((x) => x.count > 0)
        .map((x) => `${x.count} ${x.display}`)
        .join(', ');
    return changeText;
}

export function normalizeChanges(changes?: ChangeOperation[] | null) {
    return changes?.length
        ? changes.map((c) => ({
              op: c.op as string,
              path: (c.path as string)
                  .split('/')
                  .map((p) => camelCase(p))
                  .join('/'),
              value: c.value,
          }))
        : [];
}
