<nz-badge
    nz-dropdown
    nzTrigger="click"
    [(nzVisible)]="menuVisible"
    nzOverlayClassName="app-notification-menu"
    nzPlacement="bottomCenter"
    [nzDropdownMenu]="notificationMenu"
    [nzCount]="notifications.length"
    [nzOverflowCount]="10"
    [nzOffset]="[7, -5]"
>
    <pf-icon iconType="notification"></pf-icon>
</nz-badge>
<nz-dropdown-menu #notificationMenu>
    <ul nz-menu>
        <li id="clear-all" *ngIf="notifications.length > 1">
            <pf-button
                buttonType="action"
                id="clear-all-btn"
                (click)="appNotificationService.clearPersistant()"
                >Clear All</pf-button
            >
        </li>
        <pf-app-notification
            *ngFor="let notification of notifications"
            [notification]="notification"
        ></pf-app-notification>
    </ul>
</nz-dropdown-menu>
