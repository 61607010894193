import { AbstractEntityMapper } from '@pf/shared-services';
import {
    TrailerCreate,
    TrailerDto,
    TrailerRead,
    TrailerSearchParams,
} from '../../entities/trailer.dto';
import { Injectable } from '@angular/core';
import { PlatformTransportationMapper } from '../platform-transportation.mapper';
import { TrailerMetadataMap } from './trailer.mapping-profile';

@Injectable()
export class TrailerMapper extends AbstractEntityMapper<
    TrailerRead,
    TrailerCreate,
    TrailerDto,
    TrailerSearchParams
> {
    constructor() {
        super(
            TrailerDto,
            PlatformTransportationMapper,
            TrailerMetadataMap.symbols
        );
    }
}
