import { SafeAny } from '@pf/shared-common';

export const normalizeString = (str: string) => {
    return str?.trim().toLowerCase() || '';
};

export const isValue = (value: SafeAny) => {
    return value !== undefined && value !== null && value !== '';
};

export const wait = (ms: number) => {
    return new Promise(resolve => {
        try {
            setTimeout(resolve, ms);
        } catch (error) {
            console.warn(error);
        }
    });
};

export const makeUnique = (value: string) => {
    return value + new Date().getTime().toString();
};
