import {
    Currency,
    ItemFragment,
    LoadUnifiedStopItemViewModel,
    ResourceAuth,
    StopItemSearchRequestParams,
    StopItemViewModelRead,
} from '@control-tower/platform-loads';
import { IEntity } from '@pf/shared-common';
import { ICustomFieldDto } from '@pf/shared/util-platform';
import { IChargeDto } from './charge.dto';

export type StopItemRead = StopItemViewModelRead & IEntity;
export type StopItemCreate = LoadUnifiedStopItemViewModel;
export type StopItemSearchParams = StopItemSearchRequestParams;

export class StopItemDto implements IEntity {
    constructor(data: Partial<StopItemDto>) {
        Object.assign(this, data);
    }

    id!: string;
    stopId!: string;
    quantity?: number;
    value?: Currency;
    valueUnitOfMeasurement?: string;
    valueMeasurement?: number;
    valueDescription?: string;
    resourceAuth?: ResourceAuth;
    item?: ItemFragment;
    isDeleted?: boolean;
    customFields?: ICustomFieldDto[];
    charges?: IChargeDto[];
}
