<button
    *ngIf="typeProps"
    nz-button
    [nzType]="typeProps.type"
    [nzSize]="buttonSize"
    [nzShape]="typeProps.shape"
    [nzLoading]="loading"
    [disabled]="disabled"
    [attr.slim]="typeProps.slim"
    [nzDanger]="buttonType === 'delete' || buttonType === 'action-delete'"
    [nzBlock]="typeProps.width === 'full'">
    <ng-container *ngIf="!!typeProps.icon">
        <pf-icon
            [size]="buttonSize === 'small' ? 'xs' : 'lg'"
            [iconType]="typeProps.icon"
            (click)="iconClickHandler($event)"></pf-icon>
    </ng-container>
    <ng-content></ng-content>
</button>
