import { IEntity } from '@pf/shared-common';
import { differenceInMinutes } from 'date-fns';

export function isEntityStale(entity: IEntity): boolean {
    if (!entity.modifiedDate) {
        return true;
    }
    // check if modified date is greater than 5 minutes
    return (
        differenceInMinutes(
            new Date(),
            new Date(entity.modifiedDate as string)
        ) > 5
    );
}
