import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';
import { FormService } from '../services/form.service';
import {
    NzDrawerComponent,
    NzDrawerRef,
    NzDrawerService,
} from 'ng-zorro-antd/drawer';
import { first, race, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    CreateDrawerOptions,
    DrawerComponent,
} from '../../../interfaces/DrawerComponent';
import { IDynamicComponent } from '../../../interfaces/IDynamicComponent';
import { SafeAny } from '@pf/shared-common';
import { calcDrawerHeight } from '../../../drawer-util';
import { PreviousRouteService } from '../../layout/services/previous-route.service';

@UntilDestroy()
@Component({
    selector: 'pf-drawer-form',
    template: `
        <ng-template #drawerExtrasTpl>
            <pf-form-buttons
                *ngIf="opened"
                [entityType]="entityType"
                [submitText]="submitLabel"
                [cancelText]="cancelLabel"
                [hideCancel]="!showCancel"
                [hideSubmit]="!showSubmit"></pf-form-buttons>
        </ng-template>
        <ng-container *ngIf="nested">
            <nz-drawer
                #nestedDrawer
                [nzTitle]="pfTitle"
                [nzExtra]="drawerExtrasTpl"
                [nzHeight]="nestedDrawerHeight"
                [nzClosable]="false"
                nzPlacement="bottom">
                <div *nzDrawerContent>
                    <ng-content></ng-content>
                </div>
            </nz-drawer>
        </ng-container>
    `,
    styleUrls: ['./drawer-form.component.scss'],
})
export class DrawerFormComponent implements DrawerComponent, IDynamicComponent {
    @ViewChild('drawerExtrasTpl', { static: true })
    drawerExtras!: TemplateRef<SafeAny>;
    @ViewChild('nestedDrawer', { static: false })
    nestedDrawer!: NzDrawerComponent;
    private _drawerRef!: NzDrawerRef<Type<SafeAny>, string>;
    private hash: string | undefined;
    opened = false;

    constructor(
        private drawerService: NzDrawerService,
        private formService: FormService,
        private cdr: ChangeDetectorRef,
        private previousRouteService: PreviousRouteService
    ) {}

    destroy$ = new Subject<void>();
    @Input() nested = false;
    @Input() pfTitle = '';
    @Input() entityType!: string;
    nestedDrawerHeight?: string;

    @Output() drawerOpened = new EventEmitter<void>();
    @Output() drawerClosed = new EventEmitter<void>();
    @Input() submitLabel = 'Submit';
    @Input() cancelLabel = 'Cancel';
    @Input() showSubmit = true;
    @Input() showCancel = true;
    @Input() navigateBackOnClose = true;

    createDrawer(opts: CreateDrawerOptions): void {
        this.navigateBackOnClose = opts.navigateBackOnClose;
        this.entityType = opts.entityType;
        this.hash = opts.hash;
        this._drawerRef = this.drawerService.create<
            Type<SafeAny>,
            void,
            string
        >({
            nzTitle: opts.title,
            nzExtra: this.drawerExtras,
            nzContent: opts.component,
            nzContentParams: opts.componentProps,
            nzClosable: false,
            nzCloseOnNavigation: false,
            nzPlacement: 'bottom',
            nzHeight: calcDrawerHeight(opts.level ?? 0),
            nzOnCancel: async () => {
                this.formService.cancel(this.entityType);
                return false;
            },
            nzWrapClassName: opts.entityType,
        });
    }

    open(): void {
        this.opened = true;
        location.hash = '';
        this.formService.resetForm(this.entityType);
        race(
            this.formService.cancelled$(this.entityType),
            this.formService.submitted$(this.entityType)
        )
            .pipe(untilDestroyed(this))
            .subscribe(() => this.close());
        if (this.nested) {
            this.nestedDrawerHeight = calcDrawerHeight(1);
            this.nestedDrawer.open();
        } else {
            this._drawerRef.open();
        }
        this.checkHash();
        this.drawerOpened.emit();
    }

    close(): void {
        this.opened = false;
        if (this.nested) {
            this.nestedDrawer.close();
        } else {
            this._drawerRef.close();
            this.destroy$.next();
            if (this.navigateBackOnClose) {
                this.previousRouteService.navigateBack();
            }
        }
        this.drawerClosed.emit();
        this.formService.deRegisterForm(this.entityType);
    }

    private checkHash(): void {
        if (!this.hash) {
            return;
        }

        const afterOpen$ = this.nested
            ? this.nestedDrawer.afterOpen
            : this._drawerRef.afterOpen;
        afterOpen$.pipe(first()).subscribe(() => {
            setTimeout(() => {
                location.hash = this.hash as string;
                const el = document.getElementById(this.hash as string);
                if (el) {
                    el.classList.add('alert');
                    setTimeout(() => {
                        el.classList.remove('alert');
                    }, 1200);
                }
            }, 100);
        });
    }

    configureButtons(options: {
        showCancel?: boolean;
        showSubmit?: boolean;
        cancelLabel?: string;
        submitLabel?: string;
    }): void {
        this.showCancel = options.showCancel ?? true;
        this.showSubmit = options.showSubmit ?? true;
        this.cancelLabel = options.cancelLabel ?? 'Cancel';
        this.submitLabel = options.submitLabel ?? 'Submit';
        this.cdr.markForCheck();
    }

    setTitle(title: string): void {
        if (this._drawerRef) {
            this._drawerRef.nzTitle = title;
        } else {
            this.pfTitle = title;
        }
    }
}
