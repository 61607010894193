import { Message } from 'roarr';
import { ILogger } from './ILogger';
import { LogLevel } from './LogLevel.enum';
import { datadogLogs } from '@datadog/browser-logs'

export class DataDogLogger implements ILogger {
    logLevel: LogLevel = LogLevel.All;

    constructor(logLevel: LogLevel) {
        this.logLevel = logLevel;
    }

    trace(message: Message): void {
        datadogLogs.logger.debug(message.message, message.context);
    }
    debug(message: Message): void {
        datadogLogs.logger.debug(message.message, message.context);
    }
    info(message: Message): void {
        datadogLogs.logger.info(message.message, message.context);
    }
    warn(message: Message): void {
        datadogLogs.logger.warn(message.message, message.context);
    }
    error(message: Message): void {
        datadogLogs.logger.error(message.message, message.context);
    }
    fatal(message: Message): void {
        datadogLogs.logger.error(message.message, message.context);
    }
    shouldLog(message: Message<{ logLevel: number }>): boolean {
        return message.context.logLevel >= this.logLevel && !!datadogLogs.logger
    }
}
