import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { IAddressDto } from '@pf/shared-common';
import { launchMap } from '@pf/shared-utility';

@Directive({
    selector: '[pfMapAction]',
})
export class MapActionDirective {
    @Input('pfMapAction') address?: IAddressDto;
    @Input() action = 'map';
    @HostBinding('style.cursor')
    cursor = 'pointer';

    @HostBinding('attr.disabled')
    get disabled() {
        return this.address ? null : '';
    }

    @HostListener('click') onClick() {
        if (!this.address?.city || !this.address?.addressLine1) {
            return;
        }
        switch (this.action) {
            case 'map':
                launchMap(this.address);
                break;
            default:
                break;
        }
    }
}
