import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFTransportationEntities } from '@pf/shared-common';
import { VehicleRead } from '../../entities/vehicle.dto';

@Injectable()
export class VehicleStore extends AbstractEntityStore<VehicleRead> {
    constructor() {
        super(PFTransportationEntities.Vehicle);
    }
}
