import { Injectable } from '@angular/core';
import {
    ActivationEnd,
    NavigationEnd,
    NavigationStart,
    Router,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SafeAny } from '@pf/shared-common';
import { Location } from '@angular/common';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PreviousRouteService {
    private previousUrl?: string;
    private previousRouteData?: SafeAny;
    private currentUrl: string;
    private currentRouteData: SafeAny;

    constructor(private router: Router, private location: Location) {
        this.currentUrl = this.router.url;
        router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof ActivationEnd) {
                this.previousRouteData = this.currentRouteData;
                this.currentRouteData = event.snapshot.data;
            }
            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationStart
            ) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }

    public getPreviousRouteData() {
        return this.previousRouteData;
    }

    public getCurrentUrl() {
        return this.currentUrl;
    }

    public getCurrentRouteData() {
        return this.currentRouteData;
    }

    async navigateBack() {
        if (this.previousUrl) {
            this.currentUrl = this.previousUrl;
            this.location.replaceState(this.previousUrl);
            await this.router.navigateByUrl(this.previousUrl);
        }
    }
}
