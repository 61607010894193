import { IGlobalAppConfig } from '@pf/shared-ui';

export default {
    forms: {
        errorTips: {
            invalidExternalSourceId:
                'External Source Id must be a 6 digit number',
        },
    },
} as IGlobalAppConfig;
