import { IEntity } from '@pf/shared-common';
import {
    CategoryViewModelRead,
    ItemBrandViewModel,
    ItemEntitySearchRequestParams,
    ItemViewModelBody,
    ItemViewModelRead,
    ResourceAuth,
} from '@control-tower/platform-item-catalog';
import { asArraySafe } from '@pf/shared-utility';
import { ICustomFieldDto } from '@pf/shared/util-platform';

export type ItemRead = ItemViewModelRead & IEntity;
export type ItemCreate = ItemViewModelBody;
export type ItemSearchParams = ItemEntitySearchRequestParams;
export type CategoryViewModelDto = Omit<
    CategoryViewModelRead,
    'resourceAuth' | 'parentCategory'
> &
    IEntity;

export class ItemDto implements IEntity {
    constructor(data: Partial<ItemDto>) {
        Object.assign(this, data);
        if (data.assignedCategories) {
            this.assignedCategories = asArraySafe(data.assignedCategories);
        }
    }

    id!: string;
    name!: string;
    code?: string;
    description?: string;
    brandId?: string;
    brand?: ItemBrandViewModel;
    parentItemId?: string;
    sku?: string;
    modelNumber?: string;
    partNumber?: string;
    isBreakPack?: boolean;
    hasInstances?: boolean;
    packSize?: number;
    maxGroupSize?: number;
    hazardous?: boolean;
    weight: ItemRead['weight'];
    dimensions: ItemRead['dimensions'];
    assignedCategories?: CategoryViewModelDto[];
    resourceAuth?: ResourceAuth;
    isDeleted?: boolean;
    customFields?: ICustomFieldDto[];
}
