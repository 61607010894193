import { Injectable } from '@angular/core';
import {
    NzMessageDataOptions,
    NzMessageRef,
    NzMessageService,
} from 'ng-zorro-antd/message';
import {
    INotificationService,
    NotificationType,
    ToastMessage,
    ToastMessageCreateRef,
    ToastType,
} from '@pf/shared-common';

@Injectable({
    providedIn: 'root',
})
export class ToastMessageService implements INotificationService {
    private _toastTypeMethods: Record<
        ToastType,
        (content: string, options: NzMessageDataOptions) => NzMessageRef
    >;
    constructor(private messageService: NzMessageService) {
        this._toastTypeMethods = {
            [ToastType.error]: messageService.error.bind(messageService),
            [ToastType.info]: messageService.info.bind(messageService),
            [ToastType.loading]: messageService.loading.bind(messageService),
            [ToastType.success]: messageService.success.bind(messageService),
            [ToastType.warning]: messageService.warning.bind(messageService),
        };
    }

    create(message: ToastMessage): ToastMessageCreateRef {
        const method = this._toastTypeMethods[message.toastType];
        const ref = method(message.message, {
            nzDuration: message.persist === true ? 0 : message.persist || 5000,
            nzPauseOnHover: true,
        });
        return {
            type: NotificationType.ToastMessage,
            id: ref.messageId,
            onClose: ref.onClose,
        };
    }
    remove(id: string): void {
        this.messageService.remove(id);
    }
}
