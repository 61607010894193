import { Injectable } from '@angular/core';
import { combineLatest, first, map } from 'rxjs';
import { LoggerService } from '../logging/Logger.service';
import { ProfileProvider } from '@pf/shared-common';

@Injectable()
export class UserPermissionCheckService {
    constructor(
        private profileProvider: ProfileProvider,
        private logger: LoggerService
    ) {}

    hasOneOfPermissions(permissions: string[]) {
        return combineLatest(
            permissions.map(permission => this.checkPermission(permission))
        ).pipe(map(results => results.some(result => result)));
    }

    hasAllPermissions(permissions: string[]) {
        return combineLatest(
            permissions.map(permission => this.checkPermission(permission))
        ).pipe(map(results => results.every(result => result)));
    }

    checkPermission(permission: string) {
        return combineLatest([
            this.profileProvider.userGroupType$,
            this.profileProvider.hasPermission$(permission),
        ]).pipe(
            first(),
            map(([userGroupType, hasPermission]) => {
                if (userGroupType !== 'administrator' && !hasPermission) {
                    this.logger.warn(
                        `User does not have permission: ${permission}`
                    );
                    return false;
                }
                return true;
            })
        );
    }
}
