import {
    GetLocationString,
    LocationCreate,
    LocationDto,
    LocationRead,
    LocationSearchParams,
} from '../entities/location.dto';

import {
    AbstractManageEntityFacade,
    EntitySaveOptions,
} from '@pf/shared-services';
import { Injectable, Optional, signal, WritableSignal } from '@angular/core';
import { LocationDataService } from '../infrastructure/locations/location.data.service';
import { LocationMapper } from '../infrastructure/locations/location.mapper';
import { LocationStore } from '../infrastructure/locations/location.store';
import { RoutesForLocation } from './location.routes';
import { map, Observable, of, switchMap } from 'rxjs';
import { TimezoneDto } from '../entities/timezone.dto';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import {
    ColumnSearchType,
    IColumnSearchFacade,
    IPagedResult,
    IQueryParams,
    SafeAny,
    SearchStrategy,
    SelectOption,
} from '@pf/shared-common';
import { asArraySafe, mapToSelectOptions } from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-core';
import { TypedFormGroup } from 'ngx-sub-form';
import {
    LocationFacadeConfig,
    ManageLocationView,
} from './location-facade.config';

@Injectable()
export class LocationFacade
    extends AbstractManageEntityFacade<
        LocationDto,
        LocationRead,
        LocationCreate,
        LocationSearchParams
    >
    implements IColumnSearchFacade<LocationDto>
{
    nameField: keyof LocationDto = 'locationName';
    locationManageView!: WritableSignal<ManageLocationView>;
    customFieldsConfig$ = this.config?.customFields$ ?? of([]);

    constructor(
        dataService: LocationDataService,
        routes: RoutesForLocation,
        store: LocationStore,
        mapper: LocationMapper,
        @Optional() public config?: LocationFacadeConfig
    ) {
        super(dataService, routes, store, mapper);
        this.setViewSignal();
    }

    notifyFormChange(
        location: LocationDto,
        formGroup: TypedFormGroup<SafeAny>
    ) {
        if (this.config?.onFormChange) {
            this.config.onFormChange(location, formGroup);
        }
    }

    override applyResourceAuth(body: LocationCreate, dto: LocationDto) {
        body.resourceAuth = {
            locations: dto.id ? [dto.id] : [],
            customers: asArraySafe(body.customers),
            vendors: asArraySafe(body.vendors),
            carriers: asArraySafe(body.carriers),
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        'locationName'
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LocationDto, string>> {
        return { locationName: searchText };
    }

    get columnListFilterName(): string {
        return 'location';
    }

    getColumnListFilters$(): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageNumber: 1,
            pageSize: 50,
        };

        return this.dataService.searchAll$(queryParams).pipe(
            map(results =>
                results.sort((a, b) =>
                    a.locationName.localeCompare(b.locationName)
                )
            ),
            map(result =>
                result.map(location => ({
                    value: location.id,
                    text: GetLocationString(location),
                }))
            )
        );
    }

    override save$(
        dto: LocationDto,
        options?: EntitySaveOptions<LocationDto>
    ): Observable<LocationDto> {
        const timezone = { ...dto.timeZone };
        delete timezone.id;

        return super
            .save$({ ...dto, timeZone: timezone as TimezoneDto }, options)
            .pipe(
                switchMap(result => {
                    if (
                        !result.resourceAuth?.locations?.find(
                            x => x === result.id
                        )
                    ) {
                        // save again to update resourceAuth with new location id
                        return super.save$(result, {
                            emitEvent: false,
                            saveCustomFields: false,
                        });
                    }
                    return of(result);
                })
            );
    }

    override searchByText$(
        text: string
    ): Observable<IPagedResult<LocationDto>> {
        const params: IQueryParams<LocationDto> = {
            pageIndex: 1,
            pageSize: 20,
            sortOrder: 'ascend',
            sortField: this.nameField,
            searchValue: text,
            searchStrategy: SearchStrategy.Contains,
            searchFields: ['locationName', 'locationNumber'],
        };
        return this.search$(params);
    }

    override mapToSelectOptions(
        source: Observable<IPagedResult<LocationDto>>
    ): Observable<SelectOption[]> {
        return mapToSelectOptions<LocationDto>(
            this.nameField,
            'id',
            loc => loc.locationTypeName
        )(source);
    }

    private setViewSignal() {
        const defaultView: ManageLocationView = {
            maxAssociatedCustomers: 0,
        };
        if (!this.config?.view) {
            this.locationManageView = signal<ManageLocationView>(defaultView);
        } else {
            this.config.view.update(view => {
                return {
                    ...defaultView,
                    ...view,
                };
            });
            this.locationManageView = this.config.view;
        }
    }
}
