import { AbstractEntityMapper } from '@pf/shared-services';
import {
    DriverCreate,
    DriverDto,
    DriverRead,
    DriverSearchParams,
} from '../../entities/driver.dto';
import { Injectable } from '@angular/core';
import { PlatformTransportationMapper } from '../platform-transportation.mapper';
import { DriverMetadataMap } from './driver.mapping-profile';

@Injectable()
export class DriverMapper extends AbstractEntityMapper<
    DriverRead,
    DriverCreate,
    DriverDto,
    DriverSearchParams
> {
    constructor() {
        super(
            DriverDto,
            PlatformTransportationMapper,
            DriverMetadataMap.symbols
        );
    }
}
