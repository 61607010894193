import { Injectable } from '@angular/core';
import { LoggerService } from '@pf/shared-services';
import {
    AbstractTypeEntityFacade,
    TypeEntityStore,
} from '@pf/shared/util-platform';
import { ServicesService } from '@control-tower/platform-transportation';
import { TypeEntityTypes } from '@pf/shared-common';

@Injectable()
export class ServiceFacade extends AbstractTypeEntityFacade<ServicesService> {
    constructor(
        typeService: ServicesService,
        store: TypeEntityStore,
        logger: LoggerService
    ) {
        super(
            store,
            logger,
            typeService,
            'serviceGetAll',
            TypeEntityTypes.Service
        );
    }
}
