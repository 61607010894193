import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'safeString',
})
export class SafeStringPipe implements PipeTransform {
    transform(value: string | null | undefined, defaultValue = ''): string {
        return value || defaultValue;
    }
}
