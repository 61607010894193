import { IAddressDto, ValueFormatterFn } from '@pf/shared-common';

export const AddressFormatter: ValueFormatterFn = (value: IAddressDto) => {
    return value?.addressLine1
        ? value.addressLine1 +
              ' ' +
              value.addressLine2 +
              ' ' +
              value.city +
              ', ' +
              value.geoZone +
              ' ' +
              value.postalCode
        : '';
};
