import { getMobileOS } from './device-util';
import { phone } from 'phone';
import { IAddressDto } from '@pf/shared-common';

function formatNumber(phoneNumber: string) {
    return phone(phoneNumber, { country: 'US' }).phoneNumber;
}

export function launchLink(action: string, link: string) {
    if (action === 'https') {
        const aRef = document.createElement('a');
        aRef.href = action + ':' + link;
        aRef.setAttribute('target', '_blank');
        aRef.click();
    } else {
        window.location.href = action + ':' + encodeURIComponent(link);
    }
}

export function launchCall(phoneNumber: string) {
    const formattedNumber = formatNumber(phoneNumber);
    if (!formattedNumber) {
        console.warn(
            `Call link not launched due to invalid phone number ${phoneNumber}`
        );
        return;
    }
    launchLink('tel', formattedNumber);
}

export function launchText(phoneNumber: string, message?: string) {
    let formattedNumber = formatNumber(phoneNumber);
    if (!formattedNumber) {
        console.warn(
            `Text link not launched due to invalid phone number ${phoneNumber}`
        );
        return;
    }
    if (message) {
        formattedNumber += `${
            getMobileOS() === 'iOS' ? '&' : '?'
        }body=${message}`;
    }
    launchLink('sms', formattedNumber);
}

export function launchEmail(
    emailAddress: string,
    subject?: string,
    body?: string
) {
    let link = emailAddress;
    const params = [];
    if (subject) {
        params.push('subject=' + subject);
    }
    if (body) {
        params.push('body=' + body);
    }
    if (params.length > 0) {
        link += `${getMobileOS() === 'iOS' ? '&' : '?'}` + params.join('&');
    }
    launchLink('mailto', link);
}

export function launchMap(address: IAddressDto) {
    launchLink(
        'https',
        `//maps.${getMobileOS() === 'iOS' ? 'apple' : 'google'}.com/?q=${
            address.addressLine1
        }${address.addressLine2 || ''}, ${address.city} ${
            address.geoZone ? ', ' + address.geoZone : ''
        } ${address.country ? ', ' + address.country : ''}`
    );
}
