import {
    FragmentMappingConfig,
    InteroperableMetadata,
    InteroperableMetadataMap,
} from '../fragment-mapping-config';
import {
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
} from '@pf/shared-common';

import { PlatformCoreFragments } from './platform-core-fragments.enum';

export interface ICarrierFragment extends IEntityFragment {
    carrierName?: string | null;
    scac?: string | null;
}

export interface ICarrierFragmentDto
    extends ICarrierFragment,
        IEntityFragmentDto {}

export function CarrierFragmentMetadataMap<
    TFragmentDto extends ICarrierFragmentDto,
    TFragment extends ICarrierFragment
>(config: FragmentMappingConfig<TFragmentDto, TFragment>): IMappingProfile {
    const interoperableMetadata = {
        carrierName: String,
        scac: String,
    } as InteroperableMetadata<TFragmentDto, TFragment>;
    return InteroperableMetadataMap(
        PlatformCoreFragments.Carrier,
        interoperableMetadata,
        config
    );
}

export const DefaultCarrierFragmentMetadataMap = CarrierFragmentMetadataMap<
    ICarrierFragmentDto,
    ICarrierFragment
>({});
