import {
    CdkDragDrop,
    DragDrop,
    DragRef,
    DropListRef,
} from '@angular/cdk/drag-drop';
import {
    AfterContentInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Self,
} from '@angular/core';
import { SafeAny } from '@pf/shared-common';
import { NzTimelineComponent } from 'ng-zorro-antd/timeline';
import { race, Subscription } from 'rxjs';
import { wait } from '@pf/shared-utility';

@Directive({
    selector: '[pfTimelineDropList]',
})
export class TimelineDropListDirective implements AfterContentInit, OnDestroy {
    @Input() dragPreviewClass = 'cdk-drag-preview';
    @Input() enableDragAndDrop = false;
    @Output()
    dropListDroppedEvent = new EventEmitter<CdkDragDrop<SafeAny>>();
    dropList?: DropListRef<SafeAny>;
    private dragRefs: DragRef[] = [];
    private subscriptions = new Subscription();

    ngAfterContentInit(): void {
        if (!this.enableDragAndDrop) return;

        this.setupDropList();
    }

    ngOnDestroy(): void {
        this.dispose();
    }

    constructor(
        private elRef: ElementRef,
        private dragDropService: DragDrop,
        @Self() private timeline: NzTimelineComponent
    ) {}

    private dispose() {
        try {
            this.dragRefs.forEach((dragRef) => dragRef.dispose());
            this.dragRefs = [];
            this.dropList?.dispose();
            this.dropList = undefined;
            this.subscriptions.unsubscribe();
            this.subscriptions = new Subscription();
        } catch (e) {
            console.warn('Error disposing of timeline drop list', e);
        }
    }

    private async setupDropList() {
        this.dispose();
        const ulEl = this.elRef.nativeElement.querySelector('ul');
        ulEl.classList.add('cdk-drop-list');
        this.dropList = this.dragDropService.createDropList(ulEl);
        this.dropList.lockAxis = 'y';

        await this.makeListDraggable();

        const listenForContentChangesSub =
            this.timeline.listOfItems.changes.subscribe((queryList) => {
                if (queryList.length === 0) {
                    return;
                }
                setTimeout(async () => {
                    await this.setupDropList();
                }, 500);
            });
        this.subscriptions.add(listenForContentChangesSub);
    }

    private async makeListDraggable(retries = 0) {
        if (retries > 5) {
            return;
        }

        try {
            if (!this.dropList) {
                return;
            }

            this.getDragRefs();

            if (this.dragRefs.length === 0) {
                await wait(100);
                await this.makeListDraggable(retries + 1);
                return;
            }

            this.addDragRefsToDropListAndObserve();
        } catch (e) {
            console.warn('Error setting up drag list', e);
        }
    }

    private getDragRefs() {
        this.dragRefs = [
            ...this.elRef.nativeElement.querySelectorAll(
                'li.ant-timeline-item'
            ),
        ].map((liEl: HTMLElement) => {
            const dragEl = this.dragDropService.createDrag(liEl);
            dragEl.previewClass = this.dragPreviewClass;
            return dragEl;
        });
    }

    private addDragRefsToDropListAndObserve() {
        if (!this.dropList) {
            return;
        }
        const dropListElement = this.dropList.element as HTMLElement;
        this.dropList.withItems(this.dragRefs);
        const dragSubscription = race(
            this.dragRefs.map((dragRef) => dragRef.started)
        ).subscribe(() => {
            dropListElement.classList.add('cdk-drop-list-dragging');
        });
        this.subscriptions.add(dragSubscription);
        const dropSubscription = race(
            this.dragRefs.map((dragRef) => dragRef.dropped)
        ).subscribe((dropEvent) => {
            dropListElement.classList.remove('cdk-drop-list-dragging');
            this.dropListDroppedEvent.emit(dropEvent as SafeAny);
        });
        this.subscriptions.add(dropSubscription);
    }
}
