import { IEntity, IMappingProfile } from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import { Notes, UserFragment } from '@control-tower/platform-loads';
import {
    UserFragmentSymbol,
    withUserFragmentMetadata,
} from '../fragments/user-fragment';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { uniqueId } from 'lodash-es';

export interface INoteDto extends IEntity, Notes {}

export class NoteDto implements INoteDto {
    constructor(data: Partial<INoteDto>) {
        Object.assign(this, data);
        this.id = data.timestamp || uniqueId();
    }

    withPlaceholder() {
        return new NoteDto({
            ...this,
            note: this.note && this.note !== '--' ? this.note : '--',
        });
    }

    withoutPlaceholder() {
        return new NoteDto({
            ...this,
            note: this.note === '--' ? '' : this.note,
        });
    }

    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    note!: string;
    user?: UserFragment;
    timestamp?: string;
}

export type NoteRead = Notes;

const symbols = entitySymbols('Note');

withDefaultEntityMetadata<
    Omit<NoteDto, 'withPlaceholder' | 'withoutPlaceholder'>
>(symbols.dto, {
    note: String,
    timestamp: String,
    user: UserFragmentSymbol,
});

withMetadata<NoteRead>(symbols.entity, {
    note: String,
    timestamp: String,
    user: UserFragmentSymbol,
});

export const NoteMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<NoteRead, NoteDto>(mapper, symbols.entity, symbols.dto);
        createMap<NoteDto, NoteRead>(mapper, symbols.dto, symbols.entity);

        withUserFragmentMetadata();
    },
};
