import {
    addProfile,
    CamelCaseNamingConvention,
    createMapper,
} from '@automapper/core';
import { IQueryParamsMetadata, IQueryParamsSymbol } from '@pf/shared-common';
import {
    withResourceAuthMetadata,
    withTypeEntityMetadata,
} from '@pf/shared/util-platform';

import { LoadMetadataMap } from './loads/load.mapping-profile';
import { pojos } from '@automapper/pojos';
import { withMetadata } from '@pf/shared-utility';
import { StopMetadataMap } from './stops/stop.mapping-profile';
import { StopItemMetadataMap } from './stop-items/stop-item.mapping-profile';

/** Global Metadata */
withMetadata(IQueryParamsSymbol, IQueryParamsMetadata);
withTypeEntityMetadata();
withResourceAuthMetadata();

/** Create mapper */
export const PlatformLoadsMapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

/** Add mapping profiles */
addProfile(PlatformLoadsMapper, LoadMetadataMap.profile);
addProfile(PlatformLoadsMapper, StopMetadataMap.profile);
addProfile(PlatformLoadsMapper, StopItemMetadataMap.profile);
