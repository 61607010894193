import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FrameworkColumn,
    getTableRowValue,
    SafeAny,
    TableRow,
} from '@pf/shared-common';

import { InjectionHostDirective } from '../../../../directives/injection-host.directive';
import { InjectionService } from '../../../../services/injection.service';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'pf-table-cell',
    template: `
        <ng-container *ngIf="column.type !== 'checkbox'; else checkboxTemplate">
            <span *ngIf="!column.component; else templateHost"
                ><pf-text type="primary" [ellipsis]="!expandToContent">{{
                    value
                }}</pf-text></span
            >
        </ng-container>
        <ng-template #templateHost pfHost="local"></ng-template>
        <ng-template #checkboxTemplate>
            <pf-checkbox [control]="checkboxControl" no-margin></pf-checkbox>
        </ng-template>
    `,
    styles: [
        `
            :host {
                display: block;
                overflow: hidden;
            }
        `,
    ],
})
export class TableCellComponent implements OnInit {
    @ViewChild(InjectionHostDirective, { static: true })
    injectionHost!: InjectionHostDirective;
    checkboxControl = new FormControl();
    private _row!: TableRow;
    private checkboxSubscription?: Subscription;
    value?: string | number | boolean | Date | SafeAny;

    @Input() set row(row: TableRow) {
        this._row = row;
        this.setupCellValue();
    }

    get row() {
        return this._row;
    }

    @Input() expandToContent = false;
    private _column!: FrameworkColumn;
    componentRef?: ComponentRef<SafeAny>;

    @Input() set column(column: FrameworkColumn) {
        this._column = column;
        this.setupCellValue();
    }

    get column() {
        return this._column;
    }

    constructor(
        private injectionService: InjectionService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.loadComponent();
    }

    private setupCellValue() {
        if (!this.column || !this.row) {
            return;
        }
        this.setValue();
        if (this.column.component) {
            this.loadComponent();
        }
        if (this.column.type === 'checkbox') {
            this.setupCheckbox();
        }
    }

    private setValue() {
        this.value = this.column.valueFormatter(
            getTableRowValue(this.column, this.row.data),
            this.row.data
        );
    }

    private loadComponent() {
        if (this.componentRef?.componentType !== this.column.component) {
            this.componentRef = this.injectionService.appendComponentHost({
                component: this.column.component as SafeAny,
                hostLocation: this.injectionHost.viewContainerRef,
            });
        }
        this.updateComponentRef();
    }

    private updateComponentRef() {
        if (!this.componentRef) {
            return;
        }
        const componentProps = {
            row: this.row.data,
            value: this.value,
            column: this.column,
            ...(this.column.componentProps || {}),
        };
        this.injectionService.projectComponentInputs(
            this.componentRef,
            componentProps
        );
    }

    private setupCheckbox() {
        const value = Boolean(this.value);
        if (this.checkboxSubscription && value === this.checkboxControl.value) {
            return;
        }
        this.checkboxControl.setValue(value);

        if (this.checkboxSubscription) {
            return;
        }
        this.checkboxSubscription = this.checkboxControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.column.checkedChange?.(this.row, value);
                this.row.data[this.column.field] = value;
            });
    }
}
