import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import {
    FrameworkColumn,
    getTableRowValue,
    ITableCellValueRenderer,
    SafeAny,
    TableColumn,
} from '@pf/shared-common';
import { ValueFormatterService } from '@pf/shared-services';

export interface MultiValueCellRendererProps<T> {
    fields: Array<Pick<TableColumn<T>, 'field' | 'type' | 'headerName'>>;
}

@Component({
    selector: 'pf-multi-value-cell-renderer',
    template: `
        <pf-text type="primary" *ngFor="let field of fieldsWithValue">
            <span class="value-container">
                <span
                    ><strong>{{ field.headerName }}</strong></span
                >
                <span>{{ field.value }}</span>
            </span>
        </pf-text>
    `,
    styles: [
        `
            :host {
                pf-text {
                    padding: 0;
                }
                .value-container {
                    display: flex;
                    flex-direction: column;
                    font-size: smaller;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiValueCellRendererComponent
    implements ITableCellValueRenderer, MultiValueCellRendererProps<SafeAny>
{
    private valueFormatterService = inject(ValueFormatterService);
    fieldsWithValue: { headerName: string; value: string }[] = [];

    @Input() set value(_: SafeAny) {
        this.fieldsWithValue = this.fields.map(field => ({
            headerName: field.headerName,
            value: this.valueFormatterService.format(
                field.type,
                getTableRowValue(field, this.row),
                this.row
            ),
        }));
    }
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;
    @Input() fields: MultiValueCellRendererProps<SafeAny>['fields'] = [];
}
