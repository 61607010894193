import * as hash from 'object-hash';

import {
    IDocument,
    IDocumentCreate,
    IDocumentSearchParams,
    IPagedResult,
} from '@pf/shared-common';
import { Observable, of, switchMap, tap } from 'rxjs';

import { LoggerService } from '../logging/Logger.service';
import { DocumentsStore } from './DocumentsStore';

export interface DocumentsCrudMethods {
    get$(entityId: string, documentId: string): Observable<IDocument>;

    search$(
        entityId: string,
        params: IDocumentSearchParams
    ): Observable<IPagedResult<IDocument>>;

    create$(entityId: string, document: IDocumentCreate): Observable<IDocument>;

    delete$(entityId: string, documentId: string): Observable<IDocument>;

    download$(entityId: string, documentId: string): Observable<Blob>;
}

export class DocumentsDataService {
    constructor(
        private entityName: string,
        private crudMethods: DocumentsCrudMethods,
        private store: DocumentsStore,
        private _logger: LoggerService
    ) {}

    get$(entityId: string, documentId: string): Observable<IDocument> {
        if (this.store.exists(documentId)) {
            return of(this.store.get(documentId));
        }
        this._logger.debug(`Fetching ${this.entityName}`);
        return this.crudMethods
            .get$(entityId, documentId)
            .pipe(tap(entity => this.store.add(entity, false)));
    }

    search$(
        entityId: string,
        queryParams: IDocumentSearchParams
    ): Observable<IPagedResult<IDocument>> {
        this._logger.debug(`Searching ${this.entityName}`);
        const pageHash = hash(queryParams);
        return this.crudMethods.search$(entityId, queryParams).pipe(
            tap(result => this.store.setPage(result, pageHash)),
            this.store.skipWhilePageExists(
                queryParams.pageNumber as number,
                pageHash
            )
        );
    }

    create$(entityId: string, body: IDocumentCreate): Observable<IDocument> {
        this._logger.debug(`Creating ${this.entityName}`);
        return this.crudMethods
            .create$(entityId, body)
            .pipe(tap(created => this.store.add(created, true)));
    }

    update$(
        entityId: string,
        documentId: string,
        update: IDocumentCreate
    ): Observable<IDocument> {
        this._logger.debug(
            `Updating document ${documentId} for ${this.entityName}: ${entityId}`
        );
        return this.crudMethods
            .delete$(entityId, documentId)
            .pipe(switchMap(() => this.crudMethods.create$(entityId, update)));
    }

    delete$(entityId: string, documentId: string): Observable<IDocument> {
        this._logger.debug(
            `Deleting document ${documentId} for ${this.entityName}: ${entityId}`
        );
        return this.crudMethods
            .delete$(entityId, documentId)
            .pipe(tap(this.store.delete.bind(this.store)));
    }

    download$(entityId: string, documentId: string): Observable<Blob> {
        this._logger.debug(
            `Downloading document ${documentId} for ${this.entityName}: ${entityId}`
        );
        return this.crudMethods.download$(entityId, documentId);
    }
}
