import { OptionalMapOperator } from '@pf/shared-utility';
import { IDocumentTemplate } from '@pf/shared-common';
import { Observable } from 'rxjs';
import { TypeMatch } from '@pf/shared/util-platform';

export abstract class EntityFacadeConfig<TDto> {
    preSaveMap: OptionalMapOperator<TDto>;
    postSaveMap: OptionalMapOperator<TDto>;
}

export type DocumentTypeConfig = Omit<
    IDocumentTemplate & TypeMatch,
    'typeFragment'
>;

export abstract class EntityDocumentsFacadeConfig {
    abstract templates$: Observable<DocumentTypeConfig[]>;
}
