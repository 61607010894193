import { DriverDataService } from '../infrastructure/drivers/driver.data.service';
import {
    DriverCreate,
    DriverDto,
    DriverRead,
    DriverSearchParams,
} from '../entities/driver.dto';
import { DriverStore } from '../infrastructure/drivers/driver.store';
import { Injectable } from '@angular/core';
import { RoutesForDriver } from './driver.routes';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { DriverMapper } from '../infrastructure/drivers/driver.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';

@Injectable()
export class DriverFacade extends AbstractManageEntityFacade<
    DriverDto,
    DriverRead,
    DriverCreate,
    DriverSearchParams
> {
    nameField: keyof DriverDto = 'driverNumber';

    constructor(
        dataService: DriverDataService,
        routes: RoutesForDriver,
        store: DriverStore,
        mapper: DriverMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof DriverDto, string>> {
        return { driverNumber: searchText };
    }
}
