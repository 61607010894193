import {
    CamelCaseNamingConvention,
    addProfile,
    createMapper,
} from '@automapper/core';
import { pojos } from '@automapper/pojos';
import { IQueryParamsMetadata, IQueryParamsSymbol } from '@pf/shared-common';
import { withMetadata } from '@pf/shared-utility';
import {
    withResourceAuthMetadata,
    withTypeEntityMetadata,
} from '@pf/shared/util-platform';
import { ItemMetadataMap } from './items/item.mapping-profile';
import { CategoryMetadataMap } from './categories/category.mapping-profile';

/** Global Metadata */
withMetadata(IQueryParamsSymbol, IQueryParamsMetadata);
withTypeEntityMetadata();
withResourceAuthMetadata();

/** Create mapper */
export const PlatformItemCatalogMapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

/** Add mapping profiles */
addProfile(PlatformItemCatalogMapper, ItemMetadataMap.profile);
addProfile(PlatformItemCatalogMapper, CategoryMetadataMap.profile);
