import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { appRouteProviders, appRoutes } from './app.routes';
import {
    AppModuleConfiguration,
    playerFactory,
    SharedUiAppSharedModule,
} from '@pf/shared/ui-app-shared';
import loadProviderConfig from './config/load-provider-config';
import globalConfig from './config/global-config';
import appBranding from './config/app-branding';
import appConstants from './config/app-constants';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    IconModule,
    LayoutModule,
    NotificationsModule,
    SharedUiModule,
} from '@pf/shared-ui';
import { AuthModule } from '@pf/shared-services';
import { PlatformCoreDomainModule } from '@pf/platform-core/domain';
import { PlatformItemCatalogDomainModule } from '@pf/platform-item-catalog/domain';
import { PlatformLoadsDomainModule } from '@pf/platform-loads/domain';
import { PlatformTransportationDomainModule } from '@pf/platform-transportation/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

const appModuleConfiguration = new AppModuleConfiguration(environment)
    .withRoutes(appRoutes, appRouteProviders)
    .withAppBranding(appBranding)
    .withGlobalConfig(globalConfig)
    .withAppConstants(appConstants)
    .withLoadProviderConfig(loadProviderConfig);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IconModule,
        SharedUiModule,
        LayoutModule.forRoot(appModuleConfiguration.environment.mainUrl),
        AuthModule.forRoot(appModuleConfiguration.environment.auth0),
        PlatformCoreDomainModule.ApiModule(),
        PlatformItemCatalogDomainModule.ApiModule(),
        PlatformLoadsDomainModule.ApiModule(),
        PlatformTransportationDomainModule.ApiModule(),
        NotificationsModule.forRoot(),
        FontAwesomeModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(),
        SharedUiAppSharedModule,
        RouterModule.forRoot(appModuleConfiguration.appRoutes, {
            initialNavigation: 'enabledBlocking',
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    providers: [...appModuleConfiguration.providers],
    bootstrap: [AppComponent],
})
export class AppModule {}
