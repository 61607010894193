import {
    FragmentMappingConfig,
    InteroperableMetadata,
    InteroperableMetadataMap,
} from '../fragment-mapping-config';
import {
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
} from '@pf/shared-common';

import { PlatformCoreFragments } from './platform-core-fragments.enum';

export interface ICustomerFragment extends IEntityFragment {
    customerName?: string | null;
    customerNumber?: string | null;
}

export interface ICustomerFragmentDto
    extends ICustomerFragment,
        IEntityFragmentDto {}

export function CustomerFragmentMetadataMap<
    TFragmentDto extends ICustomerFragmentDto,
    TFragment extends ICustomerFragment
>(config: FragmentMappingConfig<TFragmentDto, TFragment>): IMappingProfile {
    const interoperableMetadata = {
        customerName: String,
        customerNumber: String,
    } as InteroperableMetadata<TFragmentDto, TFragment>;
    return InteroperableMetadataMap(
        PlatformCoreFragments.Customer,
        interoperableMetadata,
        config
    );
}

export const DefaultCustomerFragmentMetadataMap = CustomerFragmentMetadataMap<
    ICustomerFragmentDto,
    ICustomerFragment
>({});
