import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
    format,
    formatDuration,
    formatISO,
    intervalToDuration,
} from 'date-fns';
import { IDateConfig, IDateService } from '@pf/shared-common';

export const DATE_CONFIG = new InjectionToken<IDateConfig>('config');

export const DEFAULT_DATE_CONFIG: IDateConfig = {
    dateFormat: 'MM/dd/yyyy',
    timeFormat: 'hh:mm:ss aaa',
    fileTimestampFormat: 'MM_dd_yyyy_hh_mm_ss_aaa',
};

@Injectable({
    providedIn: 'root',
})
export class DateService implements IDateService {
    constructor(@Inject(DATE_CONFIG) private dateConfig: IDateConfig) {}

    getYear(date?: string | Date) {
        return !date ? 0 : new Date(date).getFullYear();
    }

    formatDate(date: string | Date | number | undefined) {
        try {
            return !date
                ? ''
                : format(new Date(date), this.dateConfig.dateFormat);
        } catch (e) {
            return '';
        }
    }

    formatDateToISO(date: string | Date | number | undefined) {
        return !date
            ? ''
            : formatISO(new Date(date), {
                  representation: 'date',
              });
    }

    formatDateTime(date: string | Date | undefined) {
        return !date
            ? ''
            : format(
                  new Date(date),
                  `${this.dateConfig.dateFormat} ${this.dateConfig.timeFormat}`
              );
    }

    formatTime(date: string | Date | undefined) {
        return !date ? '' : format(new Date(date), this.dateConfig.timeFormat);
    }

    formatFileTimestamp(date?: string | Date) {
        return format(
            date ? new Date(date) : new Date(),
            this.dateConfig.fileTimestampFormat
        );
    }

    timeElapsed(
        start: string | Date | number,
        end?: string | Date | number | undefined
    ) {
        return formatDuration(
            intervalToDuration({
                start: new Date(start),
                end: end ? new Date(end) : new Date(),
            }),
            {
                format: ['days', 'hours', 'minutes'],
            }
        );
    }

    getFutureDate(days: number) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return this.formatDateToISO(date);
    }
}
