import {
    Event,
    EventViewModel,
    UserFragment,
} from '@control-tower/platform-loads';
import {
    IEntity,
    IMappingProfile,
    ITypeEntityFragment,
} from '@pf/shared-common';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { ITypeEntityFragmentSymbol } from '@pf/shared/util-platform';
import { UserFragmentSymbol } from '../fragments/user-fragment';
import { uniqueId } from 'lodash-es';

export interface IEventDto extends IEntity, Event {}

export class EventDto implements IEventDto {
    constructor(data: Partial<IEventDto>) {
        Object.assign(this, data);
        this.id = uniqueId();
    }
    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    eventType!: ITypeEntityFragment;
    userFragment?: UserFragment;
    eventTimestamp?: string;
    eventDescription?: string;
}

export type EventRead = Event;
export type EventCreate = EventViewModel;

const symbols = entitySymbols('Event');

withDefaultEntityMetadata<EventDto>(symbols.dto, {
    eventType: ITypeEntityFragmentSymbol,
    eventTimestamp: String,
    eventDescription: String,
    userFragment: UserFragmentSymbol,
});

withMetadata<EventRead>(symbols.entity, {
    eventType: ITypeEntityFragmentSymbol,
    eventTimestamp: String,
    eventDescription: String,
    userFragment: UserFragmentSymbol,
});

withMetadata<EventCreate>(symbols.createBody, {
    eventTypeId: String,
    eventTimestamp: String,
    eventDescription: String,
    userFragment: UserFragmentSymbol,
});

export const EventMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<EventRead, EventDto>(mapper, symbols.entity, symbols.dto);
        createMap<EventDto, EventRead>(mapper, symbols.dto, symbols.entity);
        createMap<EventDto, EventCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (x) => x.eventTypeId,
                mapFrom((source) => source.eventType?.id || null)
            )
        );
    },
};
