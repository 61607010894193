import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFTransportationEntities } from '@pf/shared-common';
import { TrailerRead } from '../../entities/trailer.dto';

@Injectable()
export class TrailerStore extends AbstractEntityStore<TrailerRead> {
    constructor() {
        super(PFTransportationEntities.Trailer);
    }
}
