import {
    CarrierFragmentMetadataMap,
    CustomerFragmentMetadataMap,
    IdIsDefaultSymbol,
    LocationFragmentMetadataMap,
    VendorFragmentMetadataMap,
} from '@pf/shared/util-platform';
import {
    IAddressDto,
    IEntityFragment,
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    ITypeEntityFragment,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    IRoleFragmentDto,
    IUserCarrierDto,
    IUserCustomerDto,
    IUserLocationDto,
    IUserVendorDto,
    UserCreate,
    UserDto,
    UserOmniSearchFieldTypes,
    UserRead,
    UserSearchParams,
} from '../../entities/user.dto';
import {
    IdIsDefault,
    RoleFragment,
    UserCarrier,
    UserCustomer,
    UserLocation,
    UserVendor,
} from '@control-tower/platform-core';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultOmniSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';

const symbols = entitySymbols(PFCoreEntities.User);

const userRolesSymbols = entitySymbols(
    `${PFCoreEntities.User}-${PFCoreEntities.UserRole}`
);
export const UserRolesMetadataMap: IMappingProfile = {
    symbols: userRolesSymbols,
    profile: (mapper: Mapper) => {
        withDefaultEntityMetadata<IRoleFragmentDto>(userRolesSymbols.dto, {
            roleName: String,
        });
        withMetadata<RoleFragment>(userRolesSymbols.entity, {
            id: String,
            roleName: String,
            isPublic: Boolean,
        });
        createMap<RoleFragment, IRoleFragmentDto>(
            mapper,
            userRolesSymbols.entity,
            userRolesSymbols.dto
        );
        createMap<IRoleFragmentDto, RoleFragment>(
            mapper,
            userRolesSymbols.dto,
            userRolesSymbols.entity
        );
    },
};

const UserCarriersMetadataMap = CarrierFragmentMetadataMap<
    IUserCarrierDto,
    UserCarrier & IEntityFragment
>({
    entityName: PFCoreEntities.User,
    fragmentDtoMetadataExtras: { isDefault: Boolean },
    fragmentMetadataExtras: { isDefault: Boolean },
    profileExtras: (mapper, symbols) => {
        createMap<IUserCarrierDto, IdIsDefault>(
            mapper,
            symbols.dto,
            IdIsDefaultSymbol
        );
    },
});

const UserVendorsMetadataMap = VendorFragmentMetadataMap<
    IUserVendorDto,
    UserVendor & IEntityFragment
>({
    entityName: PFCoreEntities.User,
    fragmentDtoMetadataExtras: { isDefault: Boolean },
    fragmentMetadataExtras: { isDefault: Boolean },
    profileExtras: (mapper, symbols) => {
        createMap<IUserVendorDto, IdIsDefault>(
            mapper,
            symbols.dto,
            IdIsDefaultSymbol
        );
    },
});

const UserLocationsMetadataMap = LocationFragmentMetadataMap<
    IUserLocationDto,
    UserLocation & {
        locationType: ITypeEntityFragment;
        address: IAddressDto;
    } & IEntityFragment
>({
    entityName: PFCoreEntities.User,
    fragmentDtoMetadataExtras: { isDefault: Boolean },
    fragmentMetadataExtras: { isDefault: Boolean },
    profileExtras: (mapper, symbols) => {
        createMap<IUserLocationDto, IdIsDefault>(
            mapper,
            symbols.dto,
            IdIsDefaultSymbol
        );
    },
});

const UserCustomersMetadataMap = CustomerFragmentMetadataMap<
    IUserCustomerDto,
    UserCustomer & IEntityFragment
>({
    entityName: PFCoreEntities.User,
    fragmentDtoMetadataExtras: { isDefault: Boolean },
    fragmentMetadataExtras: { isDefault: Boolean },
    profileExtras: (mapper, symbols) => {
        createMap<IUserCustomerDto, IdIsDefault>(
            mapper,
            symbols.dto,
            IdIsDefaultSymbol
        );
    },
});

withDefaultEntityMetadata<UserDto>(symbols.dto, {
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    userGroupType: String,
    lastLogin: String,
    roles: [userRolesSymbols.dto],
    carriers: [UserCarriersMetadataMap.symbols.dto],
    vendors: [UserVendorsMetadataMap.symbols.dto],
    locations: [UserLocationsMetadataMap.symbols.dto],
    customers: [UserCustomersMetadataMap.symbols.dto],
    allCarriers: Boolean,
    allVendors: Boolean,
    allLocations: Boolean,
    allCustomers: Boolean,
});

withDefaultEntityMetadata<UserRead>(symbols.entity, {
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    userGroupType: String,
    lastLogin: String,
    roles: [userRolesSymbols.entity],
    carriers: [UserCarriersMetadataMap.symbols.entity],
    vendors: [UserVendorsMetadataMap.symbols.entity],
    locations: [UserLocationsMetadataMap.symbols.entity],
    customers: [UserCustomersMetadataMap.symbols.entity],
    localization: Object,
    allCarriers: Boolean,
    allVendors: Boolean,
    allLocations: Boolean,
    allCustomers: Boolean,
});

withMetadata<UserCreate>(symbols.createBody, {
    firstName: String,
    lastName: String,
    email: String,
    phoneNumber: String,
    userGroupType: String,
    roles: [String],
    isHidden: Boolean,
    carriers: [IdIsDefaultSymbol],
    vendors: [IdIsDefaultSymbol],
    locations: [IdIsDefaultSymbol],
    customers: [IdIsDefaultSymbol],
    localization: Object,
    allCarriers: Boolean,
    allVendors: Boolean,
    allLocations: Boolean,
    allCustomers: Boolean,
});

withDefaultSearchParamsMetadata<UserSearchParams>(symbols.searchParams, {
    email: String,
});

export const UserMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<UserRead, UserDto>(mapper, symbols.entity, symbols.dto);
        createMap<UserDto, UserRead>(mapper, symbols.dto, symbols.entity);
        createMap<UserDto, UserCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                d => d.roles,
                mapFrom(s => (s.roles || []).map(r => r.id))
            )
        );
        createMap<IQueryParams<UserDto>, UserSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('email'),
            ...withDefaultOmniSearchParamsMappingConfiguration(
                UserSearchFieldsMapper
            )
        );
        UserRolesMetadataMap.profile(mapper);
        UserCarriersMetadataMap.profile(mapper);
        UserVendorsMetadataMap.profile(mapper);
        UserLocationsMetadataMap.profile(mapper);
        UserCustomersMetadataMap.profile(mapper);
    },
};

export const UserSearchFieldsMapper: Partial<
    Record<keyof UserDto, UserOmniSearchFieldTypes[]>
> = {
    firstName: ['FirstName'],
    lastName: ['LastName'],
    email: ['Email'],
};
