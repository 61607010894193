export enum ItemAppConstants {
    Item = 'Item',
    Items = 'Items',
    ManageItemsTitle = 'ManageItemsTitle',
    ManageItemsSubTitle = 'ManageItemsSubTitle',
}
export const ItemAppConstantDefaults = {
    [ItemAppConstants.Item]: 'Item',
    [ItemAppConstants.Items]: 'Items',
    [ItemAppConstants.ManageItemsTitle]: 'Manage Items',
    [ItemAppConstants.ManageItemsSubTitle]:
        'Management screen to add, edit, or deactivate items in the system.',
};

// Category Constants
export enum CategoryAppConstants {
    Category = 'Category',
    Categories = 'Categories',
    ManageCategoriesTitle = 'ManageCategoriesTitle',
    ManageCategoriesSubTitle = 'ManageCategoriesSubTitle',
}
export const CategoryAppConstantDefaults = {
    [CategoryAppConstants.Category]: 'Category',
    [CategoryAppConstants.Categories]: 'Categories',
    [CategoryAppConstants.ManageCategoriesTitle]: 'Manage Categories',
    [CategoryAppConstants.ManageCategoriesSubTitle]:
        'Management screen to add, edit, or deactivate categories in the system.',
};
