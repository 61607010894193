import { Injectable } from '@angular/core';
import { LoggerService } from '@pf/shared-services';
import {
    AbstractTypeEntityFacade,
    TypeEntityStore,
} from '@pf/shared/util-platform';
import { EquipmentTypesService } from '@control-tower/platform-transportation';
import { TypeEntityTypes } from '@pf/shared-common';

@Injectable()
export class EquipmentTypeFacade extends AbstractTypeEntityFacade<EquipmentTypesService> {
    constructor(
        typeService: EquipmentTypesService,
        store: TypeEntityStore,
        logger: LoggerService
    ) {
        super(
            store,
            logger,
            typeService,
            'equipmentTypeGetAll',
            TypeEntityTypes.EquipmentType
        );
    }
}
