import { Injectable } from '@angular/core';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    StopItemCreate,
    StopItemRead,
    StopItemSearchParams,
} from '../../entities/stop-item.dto';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    StopItemViewModelCreateBody,
    StopItemsService,
} from '@control-tower/platform-loads';
import { StopItemStore } from './stop-item.store';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';

@Injectable()
export class StopItemDataService extends AbstractDataService<
    StopItemRead,
    StopItemCreate
> {
    protected EntityName = PFLoadEntities.StopItem;

    constructor(
        private service: StopItemsService,
        store: StopItemStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(
        body: StopItemViewModelCreateBody
    ): Observable<StopItemRead> {
        return this.service.stopItemCreate({
            stopItemViewModelCreateBody: body,
        }) as Observable<StopItemRead>;
    }
    protected deleteObs$(id: string): Observable<StopItemRead> {
        return this.service.stopItemDelete({
            id,
        }) as Observable<StopItemRead>;
    }
    protected undoDeleteObs$(id: string): Observable<StopItemRead> {
        // return this.service.stopItemUndoDelete({
        //     id,
        // }) as Observable<StopItemRead>;
        throw new Error('Method not implemented.');
    }
    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<StopItemRead> {
        return this.service.stopItemUpdatePatch({
            id,
            operation: operations,
        }) as Observable<StopItemRead>;
    }
    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<StopItemRead>>> {
        return this.service.stopItemGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<StopItemRead>>
        >;
    }
    protected getObs$(id: string): Observable<StopItemRead> {
        return this.service.stopItemReadById({
            id,
        }) as Observable<StopItemRead>;
    }
    protected searchObs$(
        searchParams: StopItemSearchParams
    ): Observable<IPagedResult<StopItemRead>> {
        return this.service.stopItemSearch(searchParams) as Observable<
            IPagedResult<StopItemRead>
        >;
    }
}
