import { IEntity } from '@pf/shared-common';
import {
    DriverViewModelRead,
    DriverViewModelCreateBody,
    DriverSearchRequestParams,
} from '@control-tower/platform-transportation';
import {
    ICarrierFragmentDto,
    ILocationFragmentDto,
} from '@pf/shared/util-platform';
import { asArraySafe } from '@pf/shared-utility';

export type DriverRead = DriverViewModelRead & IEntity;
export type DriverCreate = DriverViewModelCreateBody;
export type DriverSearchParams = DriverSearchRequestParams;

export class DriverDto implements IEntity {
    constructor(data: Partial<DriverDto>) {
        Object.assign(this, data);
        this.licenses = asArraySafe(data.licenses);
    }
    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    driverNumber!: string;
    lastName?: string;
    firstName?: string;
    location?: ILocationFragmentDto;
    carrier?: ICarrierFragmentDto;
    licenses?: object[]; // TODO: add fragment type
}
