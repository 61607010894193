import { AbstractEntityMapper } from '@pf/shared-services';
import {
    CategoryCreate,
    CategoryDto,
    CategoryRead,
    CategorySearchParams,
} from '../../entities/category.dto';
import { inject, Injectable } from '@angular/core';
import { PlatformItemCatalogMapper } from '../platform-item-catalog.mapper';
import { CategoryMetadataMap } from './category.mapping-profile';
import { asArraySafe } from '@pf/shared-utility';
import { READONLY_CATEGORY_NAMES } from '../../application/category.facade';

@Injectable()
export class CategoryMapper extends AbstractEntityMapper<
    CategoryRead,
    CategoryCreate,
    CategoryDto,
    CategorySearchParams
> {
    private readonly readonlyCategoryNames = asArraySafe(
        inject(READONLY_CATEGORY_NAMES, { optional: true })
    );

    constructor() {
        super(
            CategoryDto,
            PlatformItemCatalogMapper,
            CategoryMetadataMap.symbols
        );
    }

    override toDTO(entity: CategoryRead): CategoryDto {
        return this.setReadOnlyOnCategory(super.toDTO(entity));
    }

    private setReadOnlyOnCategory(category: CategoryDto) {
        category.readOnly = !!this.readonlyCategoryNames.find(
            x => x === category.name
        );
        return category;
    }
}
