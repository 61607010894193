import { AddressCreate, AddressDto, AddressRead } from './address.dto';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { GeoPoint } from '@control-tower/platform-core';
import { GeoPointSymbol } from './associated-address.mapping-profile';
import { IMappingProfile } from '@pf/shared-common';
import { PlatformSharedEntities } from './shared-entities.enum';

const symbols = entitySymbols(PlatformSharedEntities.Address);

withDefaultEntityMetadata<AddressDto>(symbols.dto, {
    name: String,
    addressLine1: String,
    addressLine2: String,
    city: String,
    geoZone: String,
    postalCode: String,
    country: String,
    coordinatesLatitude: Number,
    coordinatesLongitude: Number,
});
withMetadata<AddressRead>(symbols.entity, {
    name: String,
    addressLine1: String,
    addressLine2: String,
    city: String,
    geoZone: String,
    postalCode: String,
    country: String,
    coordinates: GeoPointSymbol,
});
withMetadata<AddressCreate>(symbols.createBody, {
    name: String,
    addressLine1: String,
    addressLine2: String,
    city: String,
    geoZone: String,
    postalCode: String,
    country: String,
    coordinates: GeoPointSymbol,
});

export const AddressMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<AddressRead, AddressDto>(mapper, symbols.entity, symbols.dto);
        createMap<AddressDto, AddressRead>(
            mapper,
            symbols.dto,
            symbols.entity,
            forMember(
                (d) => d.coordinates,
                mapFrom((s) => {
                    if (!s.coordinatesLatitude || !s.coordinatesLongitude) {
                        return null;
                    }
                    return {
                        latitude: s.coordinatesLatitude,
                        longitude: s.coordinatesLongitude,
                    } as GeoPoint;
                })
            )
        );
        createMap<AddressDto, AddressCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
    },
};
