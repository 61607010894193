import {
    EntityMetadataExtras,
    SafeMerge,
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultFragmentMetadata,
} from '@pf/shared-utility';
import {
    EntitySymbols,
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
    SafeAny,
} from '@pf/shared-common';
import { Mapper, MappingConfiguration, createMap } from '@automapper/core';

export type FragmentMappingConfig<
    TFragmentDto extends IEntityFragmentDto,
    TFragment extends IEntityFragment
> = {
    entityName?: string;
    fragmentDtoMetadataExtras?: Partial<
        EntityMetadataExtras<
            Omit<
                TFragmentDto,
                | 'id'
                | 'isDeleted'
                | 'createdDate'
                | 'modifiedDate'
                | 'lastModifiedDate'
            >
        >
    >;
    fragmentMetadataExtras?: Partial<EntityMetadataExtras<TFragment>>;
    toFragmentDtoMappingFns?: MappingConfiguration<TFragment, TFragmentDto>[];
    toFragmentMappingFns?: MappingConfiguration<TFragmentDto, TFragment>[];
    profileExtras?: (mapper: Mapper, symbols: EntitySymbols) => void;
};

export type InteroperableMetadata<
    TFragmentDto extends IEntityFragmentDto,
    TFragment extends IEntityFragment
> =
    | FragmentMappingConfig<
          TFragmentDto,
          TFragment
      >['fragmentDtoMetadataExtras']
    | FragmentMappingConfig<TFragmentDto, TFragment>['fragmentMetadataExtras'];

export function FragmentMetadataMap<
    TFragmentDto extends IEntityFragmentDto,
    TFragment extends IEntityFragment
>(
    fragmentName: string,
    config: FragmentMappingConfig<TFragmentDto, TFragment>
): IMappingProfile {
    const fragmentSymbols = entitySymbols(
        config.entityName
            ? `${config.entityName}-${fragmentName}'`
            : fragmentName
    );

    return {
        symbols: fragmentSymbols,
        profile: (mapper: Mapper) => {
            withDefaultEntityMetadata<TFragmentDto>(
                fragmentSymbols.dto,
                config.fragmentDtoMetadataExtras || ({} as SafeAny)
            );
            withDefaultFragmentMetadata<TFragment>(
                fragmentSymbols.entity,
                config.fragmentMetadataExtras || ({} as SafeAny)
            );
            createMap<TFragment, TFragmentDto>(
                mapper,
                fragmentSymbols.entity,
                fragmentSymbols.dto,
                ...(config.toFragmentDtoMappingFns || [])
            );
            createMap<TFragmentDto, TFragment>(
                mapper,
                fragmentSymbols.dto,
                fragmentSymbols.entity,
                ...(config.toFragmentMappingFns || [])
            );
            config.profileExtras?.(mapper, fragmentSymbols);
        },
    };
}

export function InteroperableMetadataMap<
    TFragmentDto extends IEntityFragmentDto,
    TFragment extends IEntityFragment
>(
    fragmentName: string,
    interoperableMetadata: InteroperableMetadata<TFragmentDto, TFragment>,
    config: FragmentMappingConfig<TFragmentDto, TFragment>
): IMappingProfile {
    return FragmentMetadataMap(fragmentName, {
        entityName: config.entityName,
        fragmentDtoMetadataExtras: SafeMerge(
            interoperableMetadata as FragmentMappingConfig<
                TFragmentDto,
                TFragment
            >['fragmentDtoMetadataExtras'],
            config.fragmentDtoMetadataExtras
        ),
        fragmentMetadataExtras: SafeMerge(
            interoperableMetadata as FragmentMappingConfig<
                TFragmentDto,
                TFragment
            >['fragmentMetadataExtras'],
            config.fragmentMetadataExtras
        ),
        toFragmentDtoMappingFns: config.toFragmentDtoMappingFns,
        toFragmentMappingFns: config.toFragmentMappingFns,
        profileExtras: config.profileExtras,
    });
}
