import {
    CamelCaseNamingConvention,
    addProfile,
    createMapper,
} from '@automapper/core';
import { pojos } from '@automapper/pojos';
import { OneTimeLinkMetadataMap } from './one-time-link/one-time-link.mapping-profile';
import { IQueryParamsSymbol, IQueryParamsMetadata } from '@pf/shared-common';
import { withMetadata } from '@pf/shared-utility';
import {
    withTypeEntityMetadata,
    withResourceAuthMetadata,
} from '@pf/shared/util-platform';

/** Global Metadata */
withMetadata(IQueryParamsSymbol, IQueryParamsMetadata);
withTypeEntityMetadata();
withResourceAuthMetadata();

/** Create mapper */
export const PlatformCommunicationsMapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

/** Add mapping profiles */
addProfile(PlatformCommunicationsMapper, OneTimeLinkMetadataMap.profile);
