export enum CarrierAppConstants {
    Carrier = 'Carrier',
    Carriers = 'Carriers',
    ManageCarriersTitle = 'ManageCarriersTitle',
    ManageCarriersSubTitle = 'ManageCarriersSubTitle',
}
export const CarrierAppConstantDefaults = {
    [CarrierAppConstants.Carrier]: 'Carrier',
    [CarrierAppConstants.Carriers]: 'Carriers',
    [CarrierAppConstants.ManageCarriersTitle]: 'Manage Carriers',
    [CarrierAppConstants.ManageCarriersSubTitle]:
        'Management screen to add, edit, or deactivate carriers in the system.',
};
