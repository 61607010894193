<pf-icon [iconType]="notification.iconType" notification-icon></pf-icon>
<pf-text type="primary" bold notification-title>{{
    notification.title
}}</pf-text>
<pf-text type="description" notification-message>{{
    notification.message
}}</pf-text>
<pf-button buttonType="action" notification-close (click)="dismiss()"
    >DISMISS</pf-button
>
<pf-text type="primary" notification-time>{{ timeElapsed }} ago</pf-text>
<ng-container *ngIf="notification.action">
    <pf-button
        [buttonType]="notification.action.buttonType"
        notification-action
        (click)="actionClickHandler()"
        >{{ notification.action.text }}</pf-button
    >
</ng-container>
