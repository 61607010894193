import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    LocationCreate,
    LocationRead,
    LocationSearchParams,
} from '../../entities/location.dto';

import { Injectable } from '@angular/core';
import {
    LocationCustomFieldsService,
    LocationsService,
} from '@control-tower/platform-core';
import { LocationStore } from './location.store';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';

@Injectable()
export class LocationDataService extends AbstractDataService<
    LocationRead,
    LocationCreate
> {
    protected EntityName = PFCoreEntities.Location;

    constructor(
        private service: LocationsService,
        private customFieldsService: LocationCustomFieldsService,
        store: LocationStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: LocationCreate): Observable<LocationRead> {
        return this.service.locationCreate({
            locationViewModelCreateBody: body,
        }) as Observable<LocationRead>;
    }

    protected deleteObs$(id: string): Observable<LocationRead> {
        return this.service.locationDelete({
            id,
        }) as Observable<LocationRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LocationRead> {
        return this.service.locationUndoDelete({
            id,
        }) as Observable<LocationRead>;
    }

    protected getObs$(id: string): Observable<LocationRead> {
        return this.service.locationReadById({
            id,
        }) as Observable<LocationRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<LocationRead> {
        return this.service.locationUpdatePatch({
            id,
            operation: operations,
        }) as Observable<LocationRead>;
    }

    protected searchObs$(
        searchParams: LocationSearchParams
    ): Observable<IPagedResult<LocationRead>> {
        return this.service.locationSearch(searchParams) as Observable<
            IPagedResult<LocationRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<LocationRead>>> {
        return this.service.locationGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<LocationRead>>
        >;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.locationCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.locationCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.locationDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }
}
