<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]='false'
  [nzHeight]='450'
  [nzVisible]='visible'
  nzPlacement='bottom'
  nzTitle='Upload'
  [nzExtra]='extraTpl'
  (nzOnClose)='onDrawerClose()'>
  <div *nzDrawerContent>
    <form nz-form [formGroup]='formGroup' *ngIf='document && formGroup'>
      <nz-upload
        class='pf-form-item'
        nzType='drag'
        [nzMultiple]='false'
        [nzBeforeUpload]='beforeUploadFn'
        [nzFileList]='fileList'
        [nzAccept]='fileInputAccept'
        [nzSize]='maxFileSize'>
        <p class='ant-upload-drag-icon'>
          <span nz-icon nzType='inbox'></span>
        </p>
        <p class='ant-upload-text'>Click or drag file to this area to upload</p>
        <p class='ant-upload-hint'>
          {{ uploadHint }}
        </p>
      </nz-upload>
      <pf-input
        label='File Name'
        inputType='text'
        controlName='documentName'></pf-input>
      <pf-date-picker
        *ngIf='hasExpiration'
        label='Expires'
        controlName='documentExpirationDate'></pf-date-picker>
      <pf-input
        label='Notes'
        inputType='textarea'
        controlName='documentNotes'></pf-input>
    </form>
  </div>

  <ng-template #extraTpl>
    <div drawer-buttons>
      <pf-button
        buttonType='cancel'
        (click)='onCancelClick()'
        [disabled]='saving'
        style='margin-right: var(--pf-gap)'
      >Cancel
      </pf-button>
      <pf-button buttonType='submit'
                 [disabled]='!valid || saving'
                 [loading]='saving'
                 (click)='onSubmitClick()'
      >Submit
      </pf-button>
    </div>
  </ng-template>
</nz-drawer>
