import {
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    ViewContainerRef,
} from '@angular/core';

import { InjectionService } from '../../../services/injection.service';
import { NzDividerComponent } from 'ng-zorro-antd/divider';

@Directive()
export abstract class AbstractPageAnchor {
    private _dividerComponentRef?: ComponentRef<NzDividerComponent>;
    private _title = '';
    @Input() pfOrientation: 'left' | 'right' | 'center' = 'left';

    @Input() set pfTitle(title: string) {
        this._title = title;
        this.setTitle();
    }

    @Input() disableAnchor = false;

    get title() {
        return this._title;
    }

    id = '';

    protected constructor(
        protected elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private injectionsService: InjectionService,
        private cdr: ChangeDetectorRef
    ) {}

    private setId() {
        const htmlElement = this.elementRef.nativeElement as HTMLElement;
        let id = htmlElement.id;
        if (!id) {
            htmlElement.id = id = this.title.toLowerCase().replace(/ /g, '_');
        }
        this.id = id;
    }

    protected abstract registerPageAnchor(): void;

    private setTitle() {
        const htmlElement = this.elementRef.nativeElement as HTMLElement;
        htmlElement.setAttribute('title', this.title);

        this.setId();
    }

    protected setDivider() {
        if (!this._dividerComponentRef) {
            this._dividerComponentRef =
                this.injectionsService.insertIntoComponentHost('start', {
                    hostLocation: this.viewContainerRef,
                    component: NzDividerComponent,
                    index: 0,
                });
            this.configureDivider(this._dividerComponentRef.instance);
            if (!this.disableAnchor) {
                this.registerPageAnchor();
            }
            this.cdr.detectChanges();
        }
    }

    protected configureDivider(divider: NzDividerComponent): void {
        divider.nzText = this.title;
        divider.nzOrientation = this.pfOrientation;
    }
}
