import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    DriverCreate,
    DriverDto,
    DriverRead,
    DriverSearchParams,
} from '../../entities/driver.dto';
import { PFTransportationEntities } from '@pf/shared-common';
import {
    DefaultCarrierFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFTransportationEntities.Driver);

withDefaultEntityMetadata<DriverDto>(symbols.dto, {
    driverNumber: String,
    lastName: String,
    firstName: String,
    location: DefaultLocationFragmentMetadataMap.symbols.dto,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.dto,
    licenses: [Object], // TODO use license fragment metadata
});

withDefaultEntityMetadata<DriverRead>(symbols.entity, {
    driverNumber: String,
    lastName: String,
    firstName: String,
    location: DefaultLocationFragmentMetadataMap.symbols.entity,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.entity,
    resourceAuth: ResourceAuthSymbol,
    licenses: [Object], // TODO use license fragment metadata
});

withMetadata<DriverCreate>(symbols.createBody, {
    driverNumber: String,
    lastName: String,
    firstName: String,
    locationId: String,
    carrierId: String,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<DriverSearchParams>(symbols.searchParams, {
    driverNumbers: [String],
});

export const DriverMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<DriverRead, DriverDto>(mapper, symbols.entity, symbols.dto);
        createMap<DriverDto, DriverRead>(mapper, symbols.dto, symbols.entity);
        createMap<DriverDto, DriverCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (d) => d.carrierId,
                mapFrom((d) => d.carrier?.id)
            ),
            forMember(
                (d) => d.locationId,
                mapFrom((d) => d.location?.id)
            )
        );
        createMap<IQueryParams<DriverDto>, DriverSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('driverNumber'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
        DefaultCarrierFragmentMetadataMap.profile(mapper);
        DefaultLocationFragmentMetadataMap.profile(mapper);
    },
};
