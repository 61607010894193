import { IEntity } from '@pf/shared-common';
import {
    CategorySearchRequestParams,
    CategoryViewModelBase,
    CategoryViewModelRead,
} from '@control-tower/platform-item-catalog';
import { ResourceAuth } from '@control-tower/platform-item-catalog/model/resourceAuth';
import { CustomFieldViewModel } from '@control-tower/platform-item-catalog/model/customFieldViewModel';

export type CategoryRead = Omit<CategoryViewModelRead, 'parentCategory'> &
    IEntity;
export type CategoryCreate = CategoryViewModelBase;
export type CategorySearchParams = CategorySearchRequestParams;

export class CategoryDto implements IEntity {
    constructor(data: Partial<CategoryDto>) {
        Object.assign(this, data);
    }

    id!: string;
    name!: string;
    code!: string;
    parentCategoryId?: string | null;
    topLevelCategoryID?: string | null;
    categoryBreadCrumbs?: string | null;
    isTopLevelCategory?: boolean;
    resourceAuth?: ResourceAuth;
    createdDate?: string;
    modifiedDate?: string;
    isDeleted?: boolean;
    customFields?: Array<CustomFieldViewModel> | null;
    children?: CategoryDto[];
    readOnly?: boolean;
}
