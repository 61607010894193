export enum VendorAppConstants {
    Vendor = 'Vendor',
    Vendors = 'Vendors',
    ManageVendorsTitle = 'ManageVendorsTitle',
    ManageVendorsSubTitle = 'ManageVendorsSubTitle',
}
export const VendorAppConstantDefaults = {
    [VendorAppConstants.Vendor]: 'Vendor',
    [VendorAppConstants.Vendors]: 'Vendors',
    [VendorAppConstants.ManageVendorsTitle]: 'Manage Vendors',
    [VendorAppConstants.ManageVendorsSubTitle]:
        'Management screen to add, edit, or deactivate vendors in the system.',
};
