import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    Input,
    ViewChild,
} from '@angular/core';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'pf-checkbox',
    templateUrl: './checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends BaseControlComponent {
    @ViewChild('checkbox', { static: true })
    checkboxComponentRef!: NzCheckboxComponent;

    @Input() labelPosition: 'left' | 'right' = 'left';

    constructor(injector: Injector, elementRef: ElementRef) {
        super(injector, elementRef);
    }
}
