<platform-associated-entities-form
    title="Contacts"
    [loading]="loading"
    [formControl]="control"
    [facade]="$any(contactFacade)"
    [columns]="contactColumns"
    [fragmentFn]="contactFragmentFn"
>
    <pf-button
        [buttonType]="'add-item'"
        data-pf-handle="add-contact"
        (click)="addContact()"
    >Add Contact
    </pf-button
    >
</platform-associated-entities-form>

<pf-drawer-form
    pfTitle="Add Contact"
    [nested]="true"
    [entityType]="entityType"
>
    <platform-core-contact-form
        *ngIf="newContact !== null"
        [entity]="newContact"
        (entityUpdate)="saveContact($event)"
    >
    </platform-core-contact-form>
</pf-drawer-form>
