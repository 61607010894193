import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'platform-shared-public-app',
    templateUrl: './public-app.component.html',
    styleUrls: ['./public-app.component.scss'],
})
export class PublicAppComponent implements OnInit {
    ngOnInit(): void {
        document.body.classList.add('loaded');
    }
}
