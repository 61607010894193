import { Observable } from 'rxjs';
import { Tenant, TenantBranding, TenantLocalization } from '@control-tower/platform-core';

export abstract class TenantProvider {
    abstract readonly tenant: Tenant | null;
    abstract readonly id$: Observable<string>;
    abstract readonly name$: Observable<string>;
    abstract readonly branding$: Observable<TenantBranding>;
    abstract readonly localization$: Observable<TenantLocalization>;
    abstract readonly tenants$: Observable<Record<string, Tenant>>;
    abstract load(): void;
    abstract setDefaultTenant(tenantId: string): void;
    abstract selectTenant(tenantId: string): void;
}
