import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultComponent } from './result.component';
import { NzResultModule } from 'ng-zorro-antd/result';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import { SharedUiModule } from '../../shared-ui.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        NzResultModule,
        NzLayoutModule,
        LottieModule,
        LottieCacheModule,
    ],
    declarations: [ResultComponent],
    exports: [ResultComponent],
})
export class ResultModule {}
