import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import {
    APP_CONSTANTS_CONFIG,
    ResolveValue,
} from '../../../../pipes/app-constants.pipe';

@Component({
    selector: 'pf-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
    @Input() pageTitle!: string;
    @Input() pageSubtitle!: string;
    @Input() hideBreadcrumbs = false;

    private _appConstants = inject(APP_CONSTANTS_CONFIG);
    routeLabelFn = (routeLabel: string) =>
        ResolveValue(routeLabel, this._appConstants);
}
