export type PFEntities =
    | PFCoreEntities
    | PFLoadEntities
    | PFTransportationEntities
    | PFItemCatalogEntities
    | PFCommunicationsEntities;

export enum PFCoreEntities {
    Carrier = 'Carriers',
    Contact = 'Contacts',
    Vendor = 'Vendors',
    Customer = 'Customers',
    Location = 'Locations',
    User = 'Users',
    UserRole = 'UserRoles',
    Timezone = 'Timezones',
    UserPermission = 'UserPermissions',
    FiscalPeriod = 'FiscalPeriods',
}

export enum PFLoadEntities {
    Load = 'Loads',
    LoadUnified = 'LoadsUnified',
    Stop = 'Stops',
    Trips = 'Trips',
    Lanes = 'Lanes',
    LaneGroups = 'LaneGroups',
    Rates = 'Rates',
    RateCharges = 'RateCharges',
    Quotes = 'Quotes',
    StopItem = 'StopItems',
    StopCharge = 'StopCharges',
    ItemCharges = 'ItemCharges',
    LaneCarriers = 'LaneCarriers',
}

export enum PFTransportationEntities {
    Driver = 'Driver',
    Vehicle = 'Vehicle',
    Trailer = 'Trailer',
    Equipment = 'Equipment',
    Mode = 'Modes',
    Service = 'Service',
}

export enum PFWmsEntities {
    InventoryBundle = 'InventoryBundle',
    Shipment = 'Shipment',
    Asns = 'Asns',
    Container = 'Container',
    PackingList = 'PackingList',
    InventoryClaim = 'InventoryClaim',
    PickList = 'PickList',
    Pick = 'Pick',
    PutAwayList = 'PutAwayList',
    InventoryReceipt = 'InventoryReceipt',
    Slot = 'Slot',
    Zone = 'Zone',
    CycleCount = 'CycleCount',
}

export enum PFOrdersEntities {
    AccountsPayable = 'AccountsPayable',
    AccountsReceivable = 'AccountsReceivable',
    PurchaseOrder = 'PurchaseOrder',
    SalesOrder = 'SalesOrder',
}

export enum PFYmsEntities {
    DockArea = 'DockArea',
    Dock = 'Dock',
    ExceptionResolution = 'ExceptionResolution',
    Exception = 'Exception',
    Gate = 'Gate',
    Schedule = 'Schedule',
    Spot = 'Spot',
    VisitOperationItem = 'VisitOperationItem',
    VisitOperation = 'VisitOperation',
    Visit = 'Visit',
    YardAllowance = 'YardAllowance',
    YardArea = 'YardArea',
    Yard = 'Yard',
}

export enum PFItemCatalogEntities {
    Item = 'Items',
    Category = 'Categories',
}

export enum PFCommunicationsEntities {
    OneTimeLink = 'OneTimeLinks',
}

export enum PlatformModules {
    PlatformCommunications = 'Communications',
    PlatformCore = 'Core',
    PlatformItemCatalog = 'Item-Catalog',
    PlatformLoads = 'Loads',
    PlatformOrders = 'Orders',
    PlatformTransportation = 'Transportation',
    PlatformWms = 'WMS',
    PlatformYms = 'YMS',
}

export enum PermissionTypes {
    Read = 'Read',
    Modify = 'Modify',
    Admin = 'Admin',
}
