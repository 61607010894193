import {
    UserRoleCreate,
    UserRoleDto,
    UserRoleRead,
    UserRoleSearchParams,
} from '../entities/user-role.dto';

import { AbstractManageEntityFacade } from '@pf/shared-services';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { Injectable } from '@angular/core';
import { RoutesForUserRole } from './user-role.routes';
import { UserRoleDataService } from '../infrastructure/user-roles/user-role.data.service';
import { UserRoleMapper } from '../infrastructure/user-roles/user-role.mapper';
import { UserRoleStore } from '../infrastructure/user-roles/user-role.store';
import { map, Observable } from 'rxjs';
import { UserDataService } from '../infrastructure/users/user.data.service';
import { UserSearchParams } from '../entities/user.dto';
import { IPagedResult, IQueryParams } from '@pf/shared-common';
import { UserPermissionDto } from '../entities/user-permission.dto';
import { Permission } from '@control-tower/platform-core';
import { asArraySafe } from '@pf/shared-utility';

@Injectable()
export class UserRoleFacade extends AbstractManageEntityFacade<
    UserRoleDto,
    UserRoleRead,
    UserRoleCreate,
    UserRoleSearchParams
> {
    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    nameField: keyof UserRoleDto = 'roleName';

    constructor(
        userRoleDataService: UserRoleDataService,
        private userDataService: UserDataService,
        routes: RoutesForUserRole,
        store: UserRoleStore,
        mapper: UserRoleMapper
    ) {
        super(userRoleDataService, routes, store, mapper);
    }

    getUsersAssignedToRole(roleId: string) {
        return this.userDataService.search$({
            pageNumber: 1,
            pageSize: 100,
            userRoleId: roleId,
        } as UserSearchParams);
    }

    getPermissionChanges(updatedUserRole: UserRoleDto) {
        const existingUserRole = this.store.get(updatedUserRole.id);
        const assignedPermissions = this.comparePermissionArrays(
            existingUserRole.permissions,
            updatedUserRole.permissions
        );
        const removedPermissions = asArraySafe(
            updatedUserRole.permissions
        ).filter(p => p.isDeleted === true);
        return { assignedPermissions, removedPermissions };
    }

    override search$(
        params: IQueryParams<UserRoleDto>
    ): Observable<IPagedResult<UserRoleDto>> {
        return super.search$({
            ...params,
            filters: {
                ...params.filters,
                readOnly: params.filters?.readOnly ?? 'false',
            },
        });
    }

    get readOnlyRoles() {
        return this.search$({
            pageIndex: 1,
            pageSize: 100,
            filters: { readOnly: 'true' },
        }).pipe(map(x => this.mapper.toDTOList(x.data)));
    }

    private comparePermissionArrays(
        left: UserPermissionDto[] | Permission[] | undefined | null,
        right: UserPermissionDto[] | Permission[] | undefined | null
    ) {
        return right?.filter(
            ({ permissionName }) =>
                !left?.some(
                    ({ permissionName: oldPermissionName }) =>
                        oldPermissionName === permissionName
                )
        );
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof UserRoleDto, string>> {
        return { roleName: searchText };
    }
}
