import {
    ICarrierFragment,
    ICustomerFragmentDto,
    ILocationFragmentDto,
    IVendorFragmentDto,
} from '@pf/shared/util-platform';
import { IEntity, IOmniFieldSearchParams } from '@pf/shared-common';
import {
    SearchingUserEnum,
    UserSearchRequestParams,
    UserViewModelCreateBody,
    UserViewModelRead,
} from '@control-tower/platform-core';

import { asArraySafe } from '@pf/shared-utility';

export type UserRead = UserViewModelRead & IEntity;
export type UserCreate = UserViewModelCreateBody;
export type UserSearchParams = UserSearchRequestParams & IOmniFieldSearchParams;
export type UserOmniSearchFieldTypes = SearchingUserEnum;

export interface IRoleFragmentDto extends IEntity {
    roleName?: string | null;
}

export interface IUserCarrierDto extends ICarrierFragment {
    isDefault?: boolean;
}

export interface IUserCustomerDto extends ICustomerFragmentDto {
    isDefault?: boolean;
}

export interface IUserVendorDto extends IVendorFragmentDto {
    isDefault?: boolean;
}

export interface IUserLocationDto extends ILocationFragmentDto {
    isDefault?: boolean;
}

export interface IUserDto extends IEntity {
    /**
     * The User\'s first name.
     */
    firstName?: string | null;
    /**
     * The User\'s Last Name.
     */
    lastName?: string | null;
    /**
     * The User\'s email address
     */
    email: string;
    /**
     * The User\'s phone number.
     */
    phoneNumber?: string | null;
    /**
     * The User\'s group type.
     */
    userGroupType?: string | null;
    /**
     * The date and time the User last logged in.
     */
    lastLogin?: string | null;
    /**
     * The list of Roles the User has access to for this tenant.
     */
    roles?: IRoleFragmentDto[];
    /**
     * A list of Carriers associated to the User.
     */
    carriers?: IUserCarrierDto[];
    /**
     * A list of Vendors associated to the User.
     */
    vendors?: IUserVendorDto[];
    /**
     * A list of Locations associated to the User.
     */
    locations?: IUserLocationDto[];
    /**
     * A list of Customers associated to the User.
     */
    customers?: IUserCustomerDto[];
}

export class UserDto implements IUserDto {
    constructor(data: Partial<IUserDto>) {
        Object.assign(this, data);
        this.roles = asArraySafe(data.roles);
        this.carriers = asArraySafe(data.carriers);
        this.vendors = asArraySafe(data.vendors);
        this.locations = asArraySafe(data.locations);
        this.customers = asArraySafe(data.customers);
    }

    id!: string;
    email!: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    userGroupType?: string;
    lastLogin?: string;
    roles?: IRoleFragmentDto[];
    carriers?: IUserCarrierDto[];
    vendors?: IUserVendorDto[];
    locations?: IUserLocationDto[];
    customers?: IUserCustomerDto[];
    allCarriers?: boolean;
    allVendors?: boolean;
    allLocations?: boolean;
    allCustomers?: boolean;
    isDeleted = false;
    createdDate?: string;
    modifiedDate?: string;
}
