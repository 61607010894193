import {
    UserPermissionEntitySearchRequestParams,
    UserPermissionViewModelCreateBody,
    UserPermissionViewModelRead,
} from '@control-tower/platform-core';
import { IEntity } from '@pf/shared-common';

export type UserPermissionRead = UserPermissionViewModelRead & IEntity;
export type UserPermissionCreate = UserPermissionViewModelCreateBody;
export type UserPermissionSearchParams =
    UserPermissionEntitySearchRequestParams;

export interface IUserPermissionDto extends IEntity {
    permissionName: string;
    description?: string;
}

export class UserPermissionDto implements IUserPermissionDto {
    constructor(data: Partial<IUserPermissionDto>) {
        const { id, ...permission } = data;
        Object.assign(this, permission);
    }
    get id() {
        return this.permissionName;
    }

    permissionName!: string;
    description!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
}
