import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { DriversService } from '@control-tower/platform-transportation';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
} from '@pf/shared-common';
import { DriverStore } from './driver.store';
import {
    DriverCreate,
    DriverRead,
    DriverSearchParams,
} from '../../entities/driver.dto';
import { Operation } from 'fast-json-patch';
import { PFTransportationEntities } from '@pf/shared-common';

@Injectable()
export class DriverDataService extends AbstractDataService<
    DriverRead,
    DriverCreate
> {
    protected EntityName = PFTransportationEntities.Driver;
    constructor(
        private service: DriversService,
        store: DriverStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }
    protected createObs$(body: DriverCreate): Observable<DriverRead> {
        return this.service.driverCreate({
            driverViewModelCreateBody: body,
        }) as Observable<DriverRead>;
    }
    protected deleteObs$(id: string): Observable<DriverRead> {
        return this.service.driverDelete({
            id,
        }) as Observable<DriverRead>;
    }
    protected undoDeleteObs$(id: string): Observable<DriverRead> {
        // return this.service.driverUndoDelete({
        //     id,
        // }) as Observable<DriverRead>;
        throw new Error('Method not implemented.');
    }
    protected getObs$(id: string): Observable<DriverRead> {
        return this.service.driverReadById({
            id,
        }) as Observable<DriverRead>;
    }
    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<DriverRead> {
        return this.service.driverUpdatePatch({
            id,
            operation: operations,
        }) as Observable<DriverRead>;
    }
    protected searchObs$(
        searchParams: DriverSearchParams
    ): Observable<IPagedResult<DriverRead>> {
        return this.service.driverSearch(searchParams) as Observable<
            IPagedResult<DriverRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<DriverRead>>> {
        return this.service.driverGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<DriverRead>>
        >;
    }
}
