import {
    LoadCreate,
    LoadDto,
    LoadRead,
    LoadSearchParams,
} from '../../entities/load.dto';
import {
    AbstractManageEntityFacade,
    EntitySaveOptions,
} from '@pf/shared-services';
import {
    BuildChangeTrackingFacadeFactory,
    CustomFieldConfig,
    TypeConfig,
} from '@pf/shared/util-platform';
import { inject, Injectable } from '@angular/core';
import { LoadDataService } from '../../infrastructure/loads/load.data.service';
import { LoadMapper } from '../../infrastructure/loads/load.mapper';
import { LoadStore } from '../../infrastructure/loads/load.store';
import { RoutesForLoads } from './load.routes';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';
import { LoadStatusTypeFacade } from '../type-entities/LoadStatusTypeFacade';
import {
    IPagedResult,
    IQueryParams,
    PFLoadEntities,
    SelectOption,
} from '@pf/shared-common';
import { ReferenceTypeFacade } from '../type-entities/ReferenceTypeFacade';
import { LoadSystemStatusType } from '@control-tower/platform-loads';
import {
    LoadFacadeConfig,
    LoadStatusTypesConfig,
    LoadsView,
} from './load-facade.config';
import { LoadDocumentsFacade } from './load-documents.facade';
import {
    asArraySafe,
    mapToDtoPageResult,
    optionalMap,
} from '@pf/shared-utility';

@Injectable()
export class LoadFacade extends AbstractManageEntityFacade<
    LoadDto,
    LoadRead,
    LoadCreate,
    LoadSearchParams
> {
    nameField: keyof LoadDto = 'loadNumber';
    private readonly _referencesFacade = inject(ReferenceTypeFacade);
    private readonly _loadReferences$ = (
        this.config.references$ || of([])
    ).pipe(
        switchMap(references => this._referencesFacade.mapToCodes$(references))
    );

    private readonly _loadStatusTypeFacade = inject(LoadStatusTypeFacade);
    private readonly _loadRecentStatusTypesConfig$: Observable<LoadStatusTypesConfig> =
        this.config.recentStatusTypes$.pipe(
            switchMap((config: LoadStatusTypesConfig) =>
                this._loadStatusTypeFacade.mapToCodes$(config)
            )
        );
    private readonly _loadHistoryStatusTypesConfig$: Observable<LoadStatusTypesConfig> =
        this.config.historyStatusTypes$.pipe(
            switchMap((config: LoadStatusTypesConfig) =>
                this._loadStatusTypeFacade.mapToCodes$(config)
            )
        );

    private readonly _loadsViewSubject = new BehaviorSubject<LoadsView | null>(
        null
    );

    get loaded() {
        return this._loadsViewSubject.value !== null;
    }

    get canEditLoadNumber() {
        return !this.store.active?.id;
    }

    referencesConfig$: Observable<TypeConfig[]> = this._loadReferences$.pipe(
        map((references: TypeConfig[]) => {
            return references.map(config => {
                return {
                    ...config,
                    label: config.typeFragment?.name,
                } as TypeConfig;
            });
        })
    );

    loadStatusTypes$: Observable<LoadStatusTypesConfig> = this._loadsViewSubject
        .asObservable()
        .pipe(
            switchMap(view =>
                view === 'recent'
                    ? this._loadRecentStatusTypesConfig$
                    : this._loadHistoryStatusTypesConfig$
            ),
            map((typeConfigs: LoadStatusTypesConfig) =>
                typeConfigs.filter(type => !!type.typeFragment)
            ),
            tap(types => {
                this.validateLoadStatusTypes(types);
            }),
            shareReplay(1)
        );

    loadStatusTypeOptions$: Observable<SelectOption[]> = combineLatest([
        this.loadStatusTypes$,
        this.active$,
    ]).pipe(
        map(([types, activeLoad]) => {
            if (!types) {
                return [];
            }
            const availableTypes = activeLoad?.id
                ? types
                : types.filter(
                      type =>
                          type.typeFragment?.loadSystemStatus ===
                          LoadSystemStatusType.New
                  );
            return availableTypes.map(type => {
                return {
                    label: type.typeFragment?.name,
                    value: type.typeFragment?.id,
                    disabled:
                        (activeLoad?.id &&
                            type.typeFragment?.loadSystemStatus ===
                                LoadSystemStatusType.New) ||
                        (!activeLoad?.id &&
                            type.typeFragment?.loadSystemStatus !==
                                LoadSystemStatusType.New),
                } as SelectOption;
            });
        })
    );

    get customFieldsConfig$(): Observable<CustomFieldConfig[]> {
        return this.config.customFields$ || of([]);
    }

    constructor(
        dataService: LoadDataService,
        routes: RoutesForLoads,
        store: LoadStore,
        mapper: LoadMapper,
        private readonly config: LoadFacadeConfig,
        private readonly documentsFacade: LoadDocumentsFacade
    ) {
        super(dataService, routes, store, mapper);
        this.listenForRealTimeEvents(PFLoadEntities.Load, {
            customEntityMapping: (entity: LoadRead) => {
                return dataService.get$(entity.id, true);
            },
        });
    }

    get loadDataService(): LoadDataService {
        return this.dataService as LoadDataService;
    }

    set loadsView(view: LoadsView | null) {
        this._loadsViewSubject.next(view);
    }

    get loadsView() {
        return this._loadsViewSubject.value;
    }

    get newStatusTypeId$(): Observable<string> {
        return this._loadStatusTypeFacade
            .getByCode$(this.config.newStatusType.code)
            .pipe(
                tap(type => {
                    if (!type) {
                        console.warn(
                            `New status type with code ${this.config.newStatusType.code} not found`
                        );
                    }
                }),
                map(type => type?.id as string)
            );
    }

    get cancelledTypeId$(): Observable<string> {
        return this._loadStatusTypeFacade
            .getByCode$(this.config.cancelledStatusType.code)
            .pipe(
                tap(type => {
                    if (!type) {
                        console.warn(
                            `Cancelled status type with code ${this.config.cancelledStatusType.code} not found`
                        );
                    }
                }),
                map(type => type?.id as string)
            );
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        'loadNumber'
    );

    override search$(
        params: IQueryParams<LoadDto>
    ): Observable<IPagedResult<LoadDto>> {
        this.lastQueryParams = params;
        let searchParams = this.mapper.toSearchParams(params);
        if (this.config.additionalSearchParams) {
            searchParams = {
                ...searchParams,
                ...this.config.additionalSearchParams(),
            };
        }
        return this.dataService
            .search$(searchParams)
            .pipe(mapToDtoPageResult(this.mapper));
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LoadDto, string>> {
        return { loadNumber: searchText };
    }

    loadPaginationTotal$ = this.store
        .getPage$()
        .pipe(map(page => page.totalRecords));

    getLoadStatisticsByLoadStatusType$(
        statusTypeId: string,
        startDate: string,
        endDate: string
    ) {
        return this.loadDataService
            .loadStatusCountObs$(statusTypeId, startDate, endDate)
            .pipe(
                catchError(err => {
                    console.error(err);
                    return of(0);
                }),
                map(result => result || 0)
            );
    }

    override applyResourceAuth(body: LoadCreate, dto: LoadDto) {
        const stops = asArraySafe(dto.stops);
        body.resourceAuth = {
            locations: stops
                .filter(stop => !!stop)
                .map(stop => stop.location?.id)
                .filter(id => !!id) as string[],
            customers: stops
                .filter(stop => !!stop)
                .map(stop => stop.customer?.id)
                .filter(id => !!id) as string[],
            vendors: stops
                .filter(stop => !!stop)
                .map(stop => stop.vendor?.id)
                .filter(id => !!id) as string[],
            carriers: dto.carrier?.id ? [dto.carrier.id] : [],
        };

        if (body.stops?.length) {
            body.stops.forEach(stop => {
                stop.resourceAuth = body.resourceAuth;
                if (stop.stopItems?.length) {
                    stop.stopItems.forEach(item => {
                        item.resourceAuth = { allAuthorized: true };
                    });
                }
            });
        }
    }

    override save$(
        dto: LoadDto,
        options?: EntitySaveOptions<LoadDto>
    ): Observable<LoadDto> {
        const resolvedOptions = {
            saveCustomFields: true,
            emitEvent: true,
            ...(options || {}),
        };
        const update = !!dto.id;

        return this.generateLoadNumber$(dto).pipe(
            optionalMap(this.config?.preSaveMap),
            map(updatedDto => {
                const updatingEntityCreate =
                    this.mapper.toCreateBody(updatedDto);
                this.applyResourceAuth(updatingEntityCreate, updatedDto);
                return updatingEntityCreate;
            }),
            switchMap(load => this.dataService.create$(load)),
            map(entity => this.mapper.toDTO(entity)),
            switchMap(load => {
                if (!update && dto.documents?.length) {
                    return combineLatest(
                        asArraySafe(dto.documents).map(doc => {
                            return this.documentsFacade.create$(load.id, doc);
                        })
                    ).pipe(map(_ => load));
                }
                return of(load);
            }),
            optionalMap(this.config?.postSaveMap),
            tap(entity => {
                if (resolvedOptions.emitEvent) {
                    if (update) {
                        this.updated$.next({
                            entity,
                            actionText: resolvedOptions.actionText,
                        });
                    } else {
                        this.added$.next({
                            entity,
                            actionText: resolvedOptions.actionText,
                        });
                    }
                }
            })
        );
    }

    cancelLoad$(loadId: string) {
        return combineLatest([
            this.getById$(loadId),
            this.cancelledTypeId$,
        ]).pipe(
            map(([load, cancelledTypeId]) => {
                return new LoadDto({
                    ...load,
                    loadStatus: { loadStatusType: { id: cancelledTypeId } },
                });
            }),
            switchMap(load =>
                this.save$(load, {
                    actionText: 'cancelled',
                })
            )
        );
    }

    private validateLoadStatusTypes(types: LoadStatusTypesConfig) {
        if (!types.length) {
            return;
        }
        const newStatuses = types.filter(
            t => t.typeFragment?.loadSystemStatus === LoadSystemStatusType.New
        );
        const inProgressStatuses = types.filter(
            t =>
                t.typeFragment?.loadSystemStatus ===
                LoadSystemStatusType.InProgress
        );

        if (newStatuses.length !== 1) {
            console.debug('New statuses', newStatuses);
        }
        if (inProgressStatuses.length === 0) {
            console.debug('In progress statuses', inProgressStatuses);
        }
    }

    private generateLoadNumber$<TLoad extends { loadNumber: string }>(
        load: TLoad
    ) {
        if (load.loadNumber) {
            return of(load);
        }

        return this.loadDataService.generateLoadNumber$().pipe(
            map(loadNumber => {
                load.loadNumber = loadNumber || '';
                return load;
            })
        );
    }
}
