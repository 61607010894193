import { Component, Input } from '@angular/core';
import {
    FrameworkColumn,
    IAddressDto,
    ITableCellValueRenderer,
    SafeAny,
} from '@pf/shared-common';

@Component({
    selector: 'pf-address-renderer',
    template: `
        <pf-text type="primary" bold *ngIf="addressName">{{
            addressName
        }}</pf-text>
        <pf-text *ngIf="value" [pfMapAction]="value">
            <div *ngIf="value?.addressLine1">
                <pf-icon iconType="location" inline></pf-icon>
                <span>{{ value.addressLine1 }}</span
                ><span *ngIf="value.addressLine2"
                    >, {{ value.addressLine2 }}</span
                >
            </div>
            <div>
                <span *ngIf="value.city">{{ value.city }}</span
                ><span *ngIf="value.geoZone">, {{ value.geoZone }}</span
                ><span *ngIf="value.postalCode"> {{ value.postalCode }}</span>
                <span *ngIf="value.country"> {{ value.country }}</span>
            </div>
        </pf-text>
    `,
    styles: [
        `
            :host {
                pf-text {
                    padding: 0;
                }

                pf-icon {
                    color: var(--ant-primary-color);
                }
            }
        `,
    ],
})
export class AddressRendererComponent implements ITableCellValueRenderer {
    @Input() value?: IAddressDto;
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;
    @Input() addressName?: string | null;

    get fullAddressString() {
        let addressString = '';
        if (this.addressName) {
            addressString += this.addressName + ' - ';
        }
        if (this.value?.addressLine1) {
            addressString += this.value.addressLine1;
        }
        if (this.value?.addressLine2) {
            addressString += `, ${this.value.addressLine2}`;
        }
        if (this.value?.city) {
            addressString += `, ${this.value.city}`;
        }
        if (this.value?.geoZone) {
            addressString += `, ${this.value.geoZone}`;
        }
        if (this.value?.postalCode) {
            addressString += `, ${this.value.postalCode}`;
        }
        return addressString;
    }
}
