import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { NgModule } from '@angular/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { SharedUiModule } from '../../shared-ui.module';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { TableComponent } from './components/table.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableHeaderSearchComponent } from './components/table-header-search/table-header-search.component';
import { TableToolbarComponent } from './components/table-toolbar/table-toolbar.component';
import { DateFilterComponent } from './components/date-filter.component';
import { TableRowsComponent } from './components/table-rows.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUiModule,
        NzFormModule,
        NzTableModule,
        NzDropDownModule,
        NzTagModule,
        NzDropDownModule,
        NzDividerModule,
        NzPopoverModule,
        NzSegmentedModule,
    ],
    declarations: [
        TableComponent,
        TableToolbarComponent,
        TableCellComponent,
        TableHeaderComponent,
        TableHeaderSearchComponent,
        TableActionsComponent,
        FilterGroupComponent,
        DateFilterComponent,
        TableRowsComponent,
    ],
    exports: [
        TableComponent,
        FilterGroupComponent,
        DateFilterComponent,
        TableRowsComponent,
    ],
})
export class TableModule {}
