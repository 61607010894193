import { AbstractEntityMapper } from '@pf/shared-services';
import {
    VehicleCreate,
    VehicleDto,
    VehicleRead,
    VehicleSearchParams,
} from '../../entities/vehicle.dto';
import { Injectable } from '@angular/core';
import { PlatformTransportationMapper } from '../platform-transportation.mapper';
import { VehicleMetadataMap } from './vehicle.mapping-profile';

@Injectable()
export class VehicleMapper extends AbstractEntityMapper<
    VehicleRead,
    VehicleCreate,
    VehicleDto,
    VehicleSearchParams
> {
    constructor() {
        super(
            VehicleDto,
            PlatformTransportationMapper,
            VehicleMetadataMap.symbols
        );
    }
}
