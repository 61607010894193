import {
    CustomFieldMetadataMap,
    DefaultCustomerFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    DefaultVendorFragmentMetadataMap,
    ICustomerFragmentDto,
    ICustomFieldDto,
    ILocationFragmentDto,
    ITypeEntityFragmentSymbol,
    IVendorFragmentDto,
} from '@pf/shared/util-platform';
import {
    IEntityFragment,
    IEntityFragmentDto,
    ITypeEntityFragment,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    LoadStopSequenceViewModel,
    StopFragment,
    StopStatus,
} from '@control-tower/platform-loads';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultFragmentMetadata,
    withMetadata,
} from '@pf/shared-utility';
import { StopItemDto } from '../entities/stop-item.dto';
import {
    StopChargeDto,
    StopChargeMetadataMap,
} from '../entities/stop-charge.dto';
import { StopItemMetadataMap } from '../infrastructure/stop-items/stop-item.mapping-profile';

export interface IStopFragmentDto extends IEntityFragmentDto {
    stopType: ITypeEntityFragment;
    location: ILocationFragmentDto;
    customer?: ICustomerFragmentDto;
    vendor?: IVendorFragmentDto;
    arrival: string;
    departure: string;
    stopStatus: StopStatus;
    expectedDeparture: string;
    expectedArrival: string;
    sequence: number;
    uniqueItemCount: number;
    totalItemCount: number;
    totalItemValue: number;
    customFields: ICustomFieldDto[];
    stopItems: StopItemDto[];
    charges: StopChargeDto[];
}

export type StopFragmentRead = StopFragment & IEntityFragment;
export type StopFragmentCreate = LoadStopSequenceViewModel;

const stopFragmentSymbols = entitySymbols(
    `${PFLoadEntities.Load}-${PFLoadEntities.Stop}`
);
const stopStatusSymbol = 'StopStatus';

export const StopFragmentMetadataMap = {
    symbols: stopFragmentSymbols,
    profile: (mapper: Mapper) => {
        withDefaultEntityMetadata<IStopFragmentDto>(stopFragmentSymbols.dto, {
            stopType: ITypeEntityFragmentSymbol,
            location: DefaultLocationFragmentMetadataMap.symbols.dto,
            customer: DefaultCustomerFragmentMetadataMap.symbols.dto,
            vendor: DefaultVendorFragmentMetadataMap.symbols.dto,
            arrival: String,
            departure: String,
            stopStatus: stopStatusSymbol,
            expectedDeparture: String,
            expectedArrival: String,
            sequence: Number,
            uniqueItemCount: Number,
            totalItemCount: Number,
            totalItemValue: Number,
            customFields: [CustomFieldMetadataMap.symbols.dto],
            stopItems: [StopItemMetadataMap.symbols.dto],
            charges: [StopChargeMetadataMap.symbols.dto],
        });
        withDefaultFragmentMetadata<StopFragmentRead>(
            stopFragmentSymbols.entity,
            {
                stopType: ITypeEntityFragmentSymbol,
                location: DefaultLocationFragmentMetadataMap.symbols.entity,
                vendor: DefaultVendorFragmentMetadataMap.symbols.entity,
                customer: DefaultCustomerFragmentMetadataMap.symbols.entity,
                arrival: String,
                departure: String,
                stopStatus: stopStatusSymbol,
                expectedDeparture: String,
                expectedArrival: String,
                sequence: Number,
            }
        );
        withMetadata<StopFragmentCreate>(stopFragmentSymbols.createBody, {
            stopId: String,
            sequence: Number,
        });

        createMap<StopFragmentRead, IStopFragmentDto>(
            mapper,
            stopFragmentSymbols.entity,
            stopFragmentSymbols.dto
        );
        createMap<IStopFragmentDto, StopFragmentRead>(
            mapper,
            stopFragmentSymbols.dto,
            stopFragmentSymbols.entity
        );
        createMap<IStopFragmentDto, StopFragmentCreate>(
            mapper,
            stopFragmentSymbols.dto,
            stopFragmentSymbols.createBody,
            forMember(
                x => x.stopId,
                mapFrom(source => source.id || null)
            )
        );

        DefaultLocationFragmentMetadataMap.profile(mapper);
        DefaultCustomerFragmentMetadataMap.profile(mapper);
        DefaultVendorFragmentMetadataMap.profile(mapper);
    },
};
