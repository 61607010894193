import { IEntity, SafeAny } from '@pf/shared-common';
import {
    addEntities,
    deleteAllEntities,
    deleteEntities,
    EntitiesRef,
    EntitiesState,
    getAllEntities,
    getEntity,
    selectAllEntities,
    selectEntities,
    selectEntity,
    UIEntitiesRef,
    unionEntities,
    updateEntities,
    withUIEntities,
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { PropsFactory } from '@ngneat/elf/src/lib/state';
import {
    excludeKeys,
    persistState,
    StateStorage,
} from '@ngneat/elf-persist-state';
import { Store } from '@ngneat/elf';
import { AbstractEntityStore } from './AbstractEntityStore';

export abstract class AbstractEntityStoreWithUiEntities<
    TEntity extends IEntity,
    TUi extends { id: string }
> extends AbstractEntityStore<TEntity> {
    get uiStateStore(): Store<{
        name: string;
        state: EntitiesState<EntitiesRef<'UIEntities', 'UIIds', 'idKeyUI'>>;
        config: never;
    }> {
        return this._store as SafeAny;
    }

    combinedState$: Observable<Array<TEntity & TUi>> = this._store
        .combine({
            UIEntities: this.uiStateStore.pipe(
                selectEntities({ ref: UIEntitiesRef })
            ),
            entities: this._store.pipe(selectAllEntities()),
        })
        .pipe(unionEntities());

    protected constructor(
        storeName: string,
        additionalProps: PropsFactory<SafeAny, SafeAny>[] = []
    ) {
        super(storeName, [
            withUIEntities<TUi>({ initialValue: [] }),
            ...additionalProps,
        ]);
    }

    protected persistUIState(key: string, storage: StateStorage) {
        persistState(this._store, {
            key,
            storage,
            source: () =>
                this._store.pipe(
                    excludeKeys(['ids', 'entities', 'pagination'])
                ),
        });
    }

    addUIEntity(uiEntity: TUi): void {
        this.uiStateStore.update(addEntities(uiEntity, { ref: UIEntitiesRef }));
    }

    updateUIEntity(id: string, update: Partial<TUi>): void {
        this.uiStateStore.update(
            updateEntities(id, (entity) => ({ ...entity, ...update }), {
                ref: UIEntitiesRef,
            })
        );
    }

    removeUIEntity(id: string) {
        this.uiStateStore.update(deleteEntities(id, { ref: UIEntitiesRef }));
    }

    getUIEntity$(id: string): Observable<TUi> {
        return this.uiStateStore.pipe(selectEntity(id, { ref: UIEntitiesRef }));
    }

    getUIEntity(id: string): TUi {
        return this.uiStateStore.query(getEntity(id, { ref: UIEntitiesRef }));
    }

    getUIEntities$(): Observable<TUi[]> {
        return this.uiStateStore.pipe(
            selectAllEntities({ ref: UIEntitiesRef })
        );
    }

    getUIEntities(): TUi[] {
        return this.uiStateStore.query(getAllEntities({ ref: UIEntitiesRef }));
    }

    clearUIEntities() {
        return this.uiStateStore.update(
            deleteAllEntities({ ref: UIEntitiesRef })
        );
    }
}
