import {
    FragmentMappingConfig,
    InteroperableMetadata,
    InteroperableMetadataMap,
} from '../fragment-mapping-config';
import {
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
} from '@pf/shared-common';

import { PlatformTransportationFragments } from './platform-transportation-fragments.enum';

export interface ITrailerFragment extends IEntityFragment {
    description?: string | null;
    trailerNumber?: string | null;
}

export interface ITrailerFragmentDto
    extends ITrailerFragment,
        IEntityFragmentDto {}

export function TrailerFragmentMetadataMap<
    TFragmentDto extends ITrailerFragmentDto,
    TFragment extends ITrailerFragment
>(config: FragmentMappingConfig<TFragmentDto, TFragment>): IMappingProfile {
    const interoperableMetadata = {
        description: String,
        trailerNumber: String,
    } as InteroperableMetadata<TFragmentDto, TFragment>;
    return InteroperableMetadataMap(
        PlatformTransportationFragments.Trailer,
        interoperableMetadata,
        config
    );
}

export const DefaultTrailerFragmentMetadataMap = TrailerFragmentMetadataMap<
    ITrailerFragmentDto,
    ITrailerFragment
>({});
