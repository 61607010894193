<div class="logo">
    <img
        [src]="brandingService.branding.logoLocation"
        [attr.alt]="brandingService.branding.name"
    />
</div>
<div id="app-header-actions">
    <ng-content select="[header-actions]"></ng-content>
    <pf-button
        id="app-header-menu-btn"
        buttonType="actions-more"
        icon-right
        nz-dropdown
        [nzDropdownMenu]="menu"
    >
        <p>
            <pf-icon iconType="user"></pf-icon>
            {{ profileProvider.fullName$ | async }}
        </p>
    </pf-button>
    <nz-dropdown-menu #menu nzPlacement="bottomLeft">
        <ul nz-menu>
            <li class="user-menu-email">
                {{ profileProvider.email$ | async }}
            </li>
            <!--            <li nz-menu-item>-->
            <!--                <a>Edit Profile</a>-->
            <!--            </li>-->
            <li nz-menu-item (click)="resetPassword()">
                <a>Reset Password</a>
            </li>
            <li nz-menu-item (click)="logout()">
                <a>Logout</a>
            </li>
        </ul>
    </nz-dropdown-menu>
</div>
