<nz-form-item class="pf-form-item">
    <nz-form-label
        *ngIf="!!label && labelPosition === 'left'"
        class="pf-form-label"
        [nzRequired]="pfRequired"
        [nzNoColon]="true"
        [title]="label"
        >{{ label }}</nz-form-label
    >
    <nz-form-control class="pf-form-control">
        <label
            #checkbox
            nz-checkbox
            [formControl]="formControl"
            class="pf-input"
            ><span *ngIf="labelPosition === 'right'">{{ label }}</span></label
        >
    </nz-form-control>
</nz-form-item>
