import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFCoreEntities } from '@pf/shared-common';
import { LocationRead } from '../../entities/location.dto';

@Injectable()
export class LocationStore extends AbstractEntityStore<LocationRead> {
    constructor() {
        super(PFCoreEntities.Location);
    }
}
