<nz-drawer
    [nzHeight]="drawerHeight"
    [nzVisible]="showDrawer"
    [nzTitle]="entityName + ' History'"
    [nzClosable]="true"
    nzPlacement="bottom"
    (nzOnClose)="close()"
>
    <div *nzDrawerContent>
        <platform-entity-history
            [facade]="facade"
            [entityName]="entityName"
        ></platform-entity-history>
    </div>
</nz-drawer>
