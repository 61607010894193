<pf-table-toolbar
    [title]="title"
    [showSearch]="!!searchField && !!searchField.length"
    [showClearFilters]="showClearFilters"
    (resetTable)="reset()"
    [disableClearFilters]="loading"
    (search)="onSearchChanged($event)">
    <ng-content
        select="[toolbar-title]"
        ngProjectAs="[toolbar-title]"></ng-content>
    <ng-content
        select="[toolbar-left]"
        ngProjectAs="[toolbar-left]"></ng-content>
    <ng-content
        select="[toolbar-right]"
        ngProjectAs="[toolbar-right]"></ng-content>
</pf-table-toolbar>
<nz-table
    #table
    [nzLoading]="loading"
    [nzData]="tableState.rows"
    [nzFrontPagination]="!serverRendered"
    [nzPageIndex]="tableState.pageIndex"
    [nzPageSize]="pageSize"
    [nzTotal]="tableState.total"
    [nzShowPagination]="showPagination"
    [nzItemRender]="renderItemTemplate"
    nzTableLayout="fixed"
    [nzScroll]="scrollSettings"
    [nzSize]="size"
    (nzQueryParams)="onQueryParamsChange($event)">
    <ng-template #renderItemTemplate let-type let-page="page">
        <ng-container [ngSwitch]="type">
            <a *ngSwitchCase="'page'">{{ page }}</a>
            <a *ngSwitchCase="'prev'">Previous</a>
            <a *ngSwitchCase="'next'">Next</a>
            <a *ngSwitchCase="'prev_5'"><<</a>
            <a *ngSwitchCase="'next_5'">>></a>
        </ng-container>
    </ng-template>
    <thead>
    <tr>
        <th nzWidth="40px" *ngIf="expandable"></th>
        <th
            *ngFor="
                    let column of tableState.columns;
                    trackBy: headerTrackBy
                "
            [nzColumnKey]="column.field"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortDirections]="column.sortDirections"
            [nzSortFn]="column.sortFn"
            [nzWidth]="column.width"
            [nzFilterMultiple]="column.listFilterFacade !== undefined"
            [nzFilters]="
                    listFilters()[
                        column.listFilterFacade?.columnListFilterName ?? ''
                    ]
                "
            [nzFilterFn]="true"
            (nzFilterChange)="filterChanged(column, $event)"
            [nzCustomFilter]="!column.listFilterFacade">
            <pf-table-header
                [column]="column"
                (checkChanged)="
                        onTableHeaderChecked(column, $event)
                    "></pf-table-header>
            <pf-table-header-search
                *ngIf="column.search || column.serverSearch"
                nz-th-extra
                [column]="column"
                (searchChanged)="
                        onColumnSearchChanged(column)
                    "></pf-table-header-search>
        </th>
        <th
            *ngIf="!!actions && actions.length"
            style="text-align: center; font-size: smaller"
            nzRight
            [nzWidth]="
                    ((actions[0].name || '').length || 4) * 12 + 30 + 'px'
                ">
            Actions
        </th>
    </tr>
    </thead>
    <tbody>
    <pf-table-rows
        [expandToContent]="expandToContent"
        [columns]="tableState.columns"
        [actions]="actions"
        [expandRowRef]="expandRowRef"
        [options]="options"
        [expandable]="expandable"
        [expand]="isFiltered"
        [singleExpand]="singleExpand"
        [data]="table.data"></pf-table-rows>
    </tbody>
</nz-table>

<div footer-left>
    <pf-button
        *ngIf="serverRendered && showExport"
        buttonType="export"
        [loading]="exporting"
        (click)="exportHandler()"
    >Export
    </pf-button>
    <ng-content select="[footer-left]"></ng-content>
</div>
