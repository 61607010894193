import { Injectable } from '@angular/core';
import {
    INotificationCreateRef,
    INotificationService,
} from '@pf/shared-common';

// eslint-disable @typescript-eslint/no-unused-vars
@Injectable({
    providedIn: 'root',
})
export class EmailService implements INotificationService {
    remove(): void {
        throw new Error('Method not implemented.');
    }
    create(): INotificationCreateRef {
        throw new Error('Method not implemented.');
    }
}
