import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    ApiLoggingInterceptor,
    TenantHeaderInterceptor,
} from '@pf/shared/ui-platform';
import {
    APP_TENANT_ID,
    ConsoleLogger,
    DataDogLogger,
    DATE_CONFIG,
    DEFAULT_DATE_CONFIG,
    LogLevel,
    LogManagerService,
} from '@pf/shared-services';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {
    APP_CONSTANTS_CONFIG,
    AppBranding,
    AppConstantsConfig,
    GlobalAppConfigProvider,
    IGlobalAppConfig,
} from '@pf/shared-ui';
import { Route } from '@angular/router';
import {
    LoadProviderConfig,
    PlatformLoadsDomainModule,
} from '@pf/platform-loads/domain';
import {
    BrandingVm,
    IAppEnvironment,
    SafeAny,
    sharedEnv,
} from '@pf/shared-common';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
    ItemCatalogProviderConfig,
    PlatformItemCatalogDomainModule,
} from '@pf/platform-item-catalog/domain';
import {
    CoreProviderConfig,
    PlatformCoreDomainModule,
} from '@pf/platform-core/domain';

registerLocaleData(en);

const logManagerFactory = () => {
    const logManager = new LogManagerService();
    logManager.addLogger(new ConsoleLogger(LogLevel.Info));
    return logManager;
};

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

export class AppModuleConfiguration {
    public appRoutes: Route[] = [];
    private _providers: NonNullable<NgModule['providers']> = [];

    get providers(): NonNullable<NgModule['providers']> {
        return [
            { provide: NZ_I18N, useValue: en_US },
            {
                provide: DATE_CONFIG,
                useValue: DEFAULT_DATE_CONFIG,
            },
            {
                provide: HTTP_INTERCEPTORS,
                multi: true,
                useClass: ApiLoggingInterceptor,
            },
            {
                provide: HTTP_INTERCEPTORS,
                multi: true,
                useClass: TenantHeaderInterceptor,
            },
            {
                provide: LogManagerService,
                useFactory: logManagerFactory,
            },
            {
                provide: APP_INITIALIZER,
                multi: true,
                deps: [LogManagerService],
                useFactory: this.dataDogProviderFactory.bind(this),
            },
            ...this._providers,
        ];
    }

    get dataDogRumConfig(): SafeAny {
        if (!this.environment.dataDog) {
            return null;
        }
        const { applicationId, clientToken, service } =
            this.environment.dataDog;
        return {
            applicationId,
            clientToken,
            service,
            site: 'datadoghq.com',
            env: this.environment.environment,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackFrustrations: true,
            defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
            allowedTracingUrls: [
                (url: string) => url.startsWith(sharedEnv.baseApiUrl),
            ],
        };
    }

    get dataDogLogsConfig(): SafeAny {
        if (!this.environment.dataDog) {
            return null;
        }
        const { clientToken, service } = this.environment.dataDog;
        return {
            clientToken,
            site: 'datadoghq.com',
            service,
            env: this.environment.environment,
            forwardErrorsToLogs: false,
        };
    }

    constructor(public environment: IAppEnvironment) {}

    withRoutes(routes: Route[], providers: Provider[]) {
        this.appRoutes = routes;
        this._providers.push(...providers);
        return this;
    }

    withAppConstants(appConstants: AppConstantsConfig) {
        this._providers.push({
            provide: APP_CONSTANTS_CONFIG,
            useValue: appConstants,
        });
        return this;
    }

    withAppBranding(appBranding: BrandingVm) {
        this._providers.push({ provide: AppBranding, useValue: appBranding });
        return this;
    }

    withAppTenantId(tenantId: string) {
        this._providers.push({ provide: APP_TENANT_ID, useValue: tenantId });
        return this;
    }

    withGlobalConfig(config: IGlobalAppConfig) {
        this._providers.push(GlobalAppConfigProvider(config));
        return this;
    }

    withCoreProviderConfig(coreProviderConfig: CoreProviderConfig) {
        this._providers.push(
            ...PlatformCoreDomainModule.ConfigProviders(coreProviderConfig)
        );
        return this;
    }

    withLoadProviderConfig(loadProviderConfig: LoadProviderConfig) {
        this._providers.push(
            ...PlatformLoadsDomainModule.ConfigProviders(loadProviderConfig)
        );
        return this;
    }

    private dataDogProviderFactory(logManager: LogManagerService) {
        return () => {
            const config = this.dataDogRumConfig;
            if (!config) {
                return;
            }
            datadogRum.init(this.dataDogRumConfig);
            datadogLogs.init(this.dataDogLogsConfig);

            datadogRum.startSessionReplayRecording();
            logManager.addLogger(new DataDogLogger(LogLevel.Warn));
            return;
        };
    }

    withItemCatalogProviderConfig(
        itemCatalogProviderConfig: ItemCatalogProviderConfig
    ) {
        this._providers.push(
            ...PlatformItemCatalogDomainModule.ConfigProviders(
                itemCatalogProviderConfig
            )
        );
        return this;
    }
}
