<ng-container *ngIf="left && right">
    <div class="code-area">
        <header>
            <pf-text type="primary">Before</pf-text>
            <pf-button buttonType="copy" (click)="copy(left)">Copy</pf-button>
        </header>
        <code>
            {{ left }}
        </code>
    </div>
    <div class="code-area">
        <header>
            <pf-text type="primary">After</pf-text>
            <pf-button buttonType="copy" (click)="copy(right)">Copy</pf-button>
        </header>
        <code>
            {{ right }}
        </code>
    </div>

    <ngx-diff2html
        [left]="left"
        [right]="right"
        format="side-by-side"
    ></ngx-diff2html>
</ng-container>
