import { Injectable, Injector } from '@angular/core';
import { PFItemCatalogEntities, PermissionTypes, IAppRouter } from '@pf/shared-common';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildItemCatalogPermissionPath } from '@pf/shared/util-platform';
import { RoutePathBuilder } from '@ngspot/route-path-builder';

@Injectable()
export class RoutesForCategory extends AbstractRouteBuilder {
    permissionType: string = buildItemCatalogPermissionPath(
        PermissionTypes.Read,
        PFItemCatalogEntities.Item
    );
}

@Injectable()
export class RoutesForItem extends AbstractRouteBuilder {
    permissionType: string = buildItemCatalogPermissionPath(
        PermissionTypes.Read,
        PFItemCatalogEntities.Item
    );
}

@Injectable()
export class RoutesForItemCatalog extends RoutePathBuilder implements IAppRouter {
    items = this.childRoutes('items', RoutesForItem);
    categories = this.childRoutes('categories', RoutesForCategory);
    permissionType: string = buildItemCatalogPermissionPath(
        PermissionTypes.Modify,
        PFItemCatalogEntities.Item
    );

    constructor(injector: Injector) {
        super(injector);
    }

    main() {
        return this.url('');
    }
}
