import { IEntity, SafeAny } from '@pf/shared-common';

import { ChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { EntityHistoryService } from '../entity-history/entity-history.service';
import { TableActionsBuilder } from '@pf/shared-ui';

export class PlatformCommonTableActions {
    static withEntityHistoryAction<TEntity extends IEntity>(
        builder: TableActionsBuilder<TEntity>,
        entityHistoryService: EntityHistoryService,
        changeTrackingFacadeFactory: ChangeTrackingFacadeFactory<SafeAny>
    ) {
        return builder.withAction({
            name: 'View History',
            onClick: row => {
                const entityId = (row as unknown as IEntity).id;
                const facade = changeTrackingFacadeFactory(entityId);
                const entityName = (row as SafeAny)[facade.nameField] as string;
                entityHistoryService.create(entityName, facade).open();
            },
            textType: 'primary',
        });
    }
}
