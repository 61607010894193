import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { ContactsService } from '@control-tower/platform-core';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import { ContactStore } from './contact.store';
import { ContactCreate, ContactRead, ContactSearchParams } from './contact.dto';
import { Operation } from 'fast-json-patch';

@Injectable()
export class ContactDataService extends AbstractDataService<
    ContactRead,
    ContactCreate
> {
    protected EntityName = PFCoreEntities.Contact;

    constructor(
        private service: ContactsService,
        store: ContactStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: ContactCreate): Observable<ContactRead> {
        return this.service.contactCreate({
            contactViewModelCreateBody: body,
        }) as Observable<ContactRead>;
    }

    protected deleteObs$(id: string): Observable<ContactRead> {
        return this.service.contactDelete({
            id,
        }) as Observable<ContactRead>;
    }

    protected undoDeleteObs$(id: string): Observable<ContactRead> {
        return this.service.contactUndoDelete({
            id,
        }) as Observable<ContactRead>;
    }

    protected getObs$(id: string): Observable<ContactRead> {
        return this.service.contactReadById({
            id,
        }) as Observable<ContactRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<ContactRead> {
        return this.service.contactUpdatePatch({
            id,
            operation: operations,
        }) as Observable<ContactRead>;
    }

    protected searchObs$(
        searchParams: ContactSearchParams
    ): Observable<IPagedResult<ContactRead>> {
        return this.service.contactSearch(searchParams) as Observable<
            IPagedResult<ContactRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<ContactRead>>> {
        return this.service.contactGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<ContactRead>>
        >;
    }
}
