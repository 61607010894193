import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    StopCreate,
    StopDto,
    StopRead,
    StopSearchParams,
} from '../../entities/stop.dto';
import {
    ContactMetadataMap,
    CustomFieldMetadataMap,
    DefaultCustomerFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    DefaultVendorFragmentMetadataMap,
    ITypeEntityFragmentSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import { StopStatus } from '@control-tower/platform-loads';
import { EventMetadataMap } from '../../entities/event.dto';
import { ReferenceMetadataMap } from '../../entities/reference.dto';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import { StopChargeMetadataMap } from '../../entities/stop-charge.dto';
import { StopItemMetadataMap } from '../stop-items/stop-item.mapping-profile';
import { StopStatusViewModel } from '@control-tower/platform-loads/model/stopStatusViewModel';
import { StopUnifiedFragmentRead } from '../../entities/stop.unified.dto';

const symbols = entitySymbols(PFLoadEntities.Stop);

const stopStatusSymbols = entitySymbols(PFLoadEntities.Stop + 'Status');
withMetadata<StopStatus>(stopStatusSymbols.entity, {
    stopStatusType: ITypeEntityFragmentSymbol,
    lastUpdated: String,
});
withMetadata<StopStatusViewModel>(stopStatusSymbols.createBody, {
    stopStatusTypeId: String,
    lastUpdated: String,
});

withDefaultEntityMetadata<StopDto>(symbols.dto, {
    specialInstructions: String,
    expectedArrival: String,
    expectedDeparture: String,
    arrival: String,
    departure: String,
    location: DefaultLocationFragmentMetadataMap.symbols.dto,
    customer: DefaultCustomerFragmentMetadataMap.symbols.dto,
    vendor: DefaultVendorFragmentMetadataMap.symbols.dto,
    stopType: ITypeEntityFragmentSymbol,
    stopStatus: stopStatusSymbols.entity,
    charges: [StopChargeMetadataMap.symbols.dto],
    events: [EventMetadataMap.symbols.dto],
    contacts: [ContactMetadataMap.symbols.dto],
    references: [ReferenceMetadataMap.symbols.dto],
    customFields: [CustomFieldMetadataMap.symbols.dto],
    stopItems: [StopItemMetadataMap.symbols.dto],
    resourceAuth: ResourceAuthSymbol,
});

withDefaultEntityMetadata<StopRead & StopUnifiedFragmentRead>(symbols.entity, {
    specialInstructions: String,
    expectedArrival: String,
    expectedDeparture: String,
    arrival: String,
    departure: String,
    location: DefaultLocationFragmentMetadataMap.symbols.entity,
    customer: DefaultCustomerFragmentMetadataMap.symbols.entity,
    vendor: DefaultVendorFragmentMetadataMap.symbols.entity,
    stopType: ITypeEntityFragmentSymbol,
    stopStatus: stopStatusSymbols.entity,
    charges: [StopChargeMetadataMap.symbols.entity],
    events: [EventMetadataMap.symbols.entity],
    contacts: [ContactMetadataMap.symbols.entity],
    references: [ReferenceMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    sequence: Number,
    stopItems: [StopItemMetadataMap.symbols.entity],
});

withMetadata<StopCreate>(symbols.createBody, {
    specialInstructions: String,
    expectedArrival: String,
    expectedDeparture: String,
    arrival: String,
    departure: String,
    locationId: String,
    customerId: String,
    vendorId: String,
    stopTypeId: String,
    stopStatus: stopStatusSymbols.createBody,
    charges: [StopChargeMetadataMap.symbols.createBody],
    events: [EventMetadataMap.symbols.createBody],
    contacts: [ContactMetadataMap.symbols.createBody],
    references: [ReferenceMetadataMap.symbols.createBody],
    notes: [String],
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<StopSearchParams>(symbols.searchParams, {});

export const StopMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<StopStatus, StopStatusViewModel>(
            mapper,
            stopStatusSymbols.entity,
            stopStatusSymbols.createBody,
            forMember(
                e => e.stopStatusTypeId,
                mapFrom(s => s.stopStatusType?.id)
            )
        );
        createMap<StopRead, StopDto>(mapper, symbols.entity, symbols.dto);
        createMap<StopDto, StopRead>(mapper, symbols.dto, symbols.entity);
        createMap<StopDto, StopCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                e => e.stopTypeId,
                mapFrom(s => s.stopType?.id)
            ),
            forMember(
                e => e.locationId,
                mapFrom(s => s.location?.id)
            ),
            forMember(
                e => e.customerId,
                mapFrom(s => s.customer?.id)
            ),
            forMember(
                e => e.vendorId,
                mapFrom(s => s.vendor?.id)
            )
        );
        createMap<IQueryParams<StopDto>, StopSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            ...withDefaultSearchParamsMappingConfiguration()
        );

        StopItemMetadataMap.profile(mapper);
        ContactMetadataMap.profile(mapper);
        StopChargeMetadataMap.profile(mapper);
        DefaultLocationFragmentMetadataMap.profile(mapper);
        EventMetadataMap.profile(mapper);
        ReferenceMetadataMap.profile(mapper);
        CustomFieldMetadataMap.profile(mapper);
    },
};
