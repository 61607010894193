import { catchError, map, Observable, of } from 'rxjs';
import { DocumentDto, IDocumentDto, IDocumentsFacade } from '@pf/shared-common';
import { inject, Injectable } from '@angular/core';
import {
    DocumentsCrudMethods,
    DocumentsDataService,
} from './DocumentsDataService';
import { DocumentsStore } from './DocumentsStore';
import { LoggerService } from '../logging/Logger.service';
import * as saveAs from 'file-saver';
import { documentsMapper } from './DocumentsMapper';
import { mapToDtoPageResult, RemoveNulls } from '@pf/shared-utility';

@Injectable()
export abstract class AbstractDocumentsFacade implements IDocumentsFacade {
    private readonly _logger = inject(LoggerService);
    private readonly _store: DocumentsStore;
    private readonly _dataService: DocumentsDataService;

    protected constructor(
        entityName: string,
        crudMethods: DocumentsCrudMethods
    ) {
        this._store = new DocumentsStore(entityName);
        this._dataService = new DocumentsDataService(
            entityName,
            crudMethods,
            this._store,
            this._logger
        );
    }

    all$(entityId: string): Observable<DocumentDto[]> {
        return this._dataService
            .search$(entityId, {
                pageNumber: 1,
                pageSize: 100,
                isDeleted: false,
            })
            .pipe(
                mapToDtoPageResult(documentsMapper),
                map(page => page.data)
            );
    }

    create$(entityId: string, document: IDocumentDto): Observable<DocumentDto> {
        const createBody = RemoveNulls(documentsMapper.toCreateBody(document));
        return this._dataService
            .create$(entityId, createBody)
            .pipe(map(entity => documentsMapper.toDTO(entity)));
    }

    update$(
        entityId: string,
        documentId: string,
        modified: IDocumentDto
    ): Observable<DocumentDto> {
        const createBody = documentsMapper.toCreateBody(modified);
        return this._dataService
            .update$(entityId, documentId, createBody)
            .pipe(map(entity => documentsMapper.toDTO(entity)));
    }

    delete$(entityId: string, documentId: string): Observable<DocumentDto> {
        return this._dataService
            .delete$(entityId, documentId)
            .pipe(map(entity => documentsMapper.toDTO(entity)));
    }

    download$(
        entityId: string,
        documentId: string,
        filename: string
    ): Observable<boolean> {
        return this._dataService.download$(entityId, documentId).pipe(
            map(blob => {
                saveAs(blob, filename, { autoBom: false });
                return true;
            }),
            catchError(err => {
                this._logger.error(
                    'Failed to download. ' + JSON.stringify(err)
                );
                return of(false);
            })
        );
    }
}
