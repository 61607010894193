import { AddressRendererComponent, TableColumnBuilder } from '@pf/shared-ui';
import {
    ICarrierFragmentDto,
    ICustomerFragmentDto,
    ILocationFragmentDto,
    IVendorFragmentDto,
} from '@pf/shared/util-platform';

import { TableColumn } from '@pf/shared-common';
import { ICarrierDto } from '../entities/carrier.dto';
import { IVendorDto } from '../entities/vendor.dto';
import { ICustomerDto } from '../entities/customer.dto';
import { ILocationDto } from '../entities/location.dto';

export interface AssociatedEntityConfig<TDto, TSearch> {
    columns: TableColumn<TDto>[];
    fragmentFn: (entity: TSearch) => TDto;
}

export const AssociatedCarrierConfig: AssociatedEntityConfig<
    ICarrierFragmentDto,
    ICarrierDto
> = {
    columns: new TableColumnBuilder<ICarrierFragmentDto>()
        .withNameColumn('carrierName')
        .withColumn({
            headerName: 'SCAC',
            field: 'scac',
        }).columns,
    fragmentFn: (carrier: ICarrierDto) => {
        return {
            id: carrier.id,
            carrierName: carrier.carrierName,
            scac: carrier.scac,
        } as ICarrierFragmentDto;
    },
};

export const AssociatedVendorConfig: AssociatedEntityConfig<
    IVendorFragmentDto,
    IVendorDto
> = {
    columns: new TableColumnBuilder<IVendorFragmentDto>()
        .withNameColumn('vendorName')
        .withColumn({
            headerName: 'Vendor Number',
            field: 'vendorNumber',
        }).columns,
    fragmentFn: (vendor: IVendorDto) => {
        return {
            id: vendor.id,
            vendorName: vendor.vendorName,
        } as IVendorFragmentDto;
    },
};

export const AssociatedCustomerConfig: AssociatedEntityConfig<
    ICustomerFragmentDto,
    ICustomerDto
> = {
    columns: new TableColumnBuilder<ICustomerFragmentDto>()
        .withNameColumn('customerName')
        .withColumn({
            headerName: 'Customer Number',
            field: 'customerNumber',
        }).columns,
    fragmentFn: (customer: ICustomerDto) => {
        return {
            id: customer.id,
            customerName: customer.customerName,
        } as ICustomerFragmentDto;
    },
};

export const AssociatedLocationConfig: AssociatedEntityConfig<
    ILocationFragmentDto,
    ILocationDto
> = {
    columns: new TableColumnBuilder<ILocationFragmentDto>()
        .withNameColumn('locationName')
        .withColumn({
            headerName: 'Type',
            field: 'locationType',
            valueFormatter: (_, record) => record.locationType?.name,
        })
        .withColumn({
            headerName: 'Address',
            field: 'address',
            component: AddressRendererComponent,
            width: 300,
        }).columns,
    fragmentFn: (location: ILocationDto) => {
        return {
            id: location.id,
            locationName: location.locationName,
            locationType: {
                id: location.locationTypeId,
                name: location.locationTypeName,
            },
            address: {
                addressLine1: location.address?.addressLine1,
                addressLine2: location.address?.addressLine2,
                city: location.address?.city,
                country: location.address?.country,
                postalCode: location.address?.postalCode,
            },
        } as ILocationFragmentDto;
    },
};
