import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
} from '@angular/core';
import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'pf-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent extends BaseControlComponent {
    constructor(injector: Injector, elementRef: ElementRef) {
        super(injector, elementRef);
    }
}
