import { SafeAny, TableAction, TableColumn } from '@pf/shared-common';
import { OptionalMapOperator } from '@pf/shared-utility';
import { Type, WritableSignal } from '@angular/core';
import { TypedFormGroup } from 'ngx-sub-form';
import { IFormComponent } from '@pf/shared-ui';

export interface IFormFacadeConfig<TDto> {
    onFormChange?(currentValue: TDto, formGroup: TypedFormGroup<SafeAny>): void;
}

export interface ManagePageView {
    title: string;
    subtitle: string;
    formTitle?: string;
    createButtonLabel?: string;
    customCreatePermission?: string;
    showFormSubmit?: boolean;
    formComponent?: Type<IFormComponent<SafeAny, SafeAny>> | null;
}

export abstract class ManageFacadeConfig<TDto, TView extends ManagePageView> {
    abstract view?: WritableSignal<TView>;
    abstract tableColumnsMap: OptionalMapOperator<TableColumn<TDto>[]>;
    abstract tableActionsMap: OptionalMapOperator<TableAction<TDto>[]>;
}
