import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
} from '@angular/core';
import { SafeAny, TableAction } from '@pf/shared-common';

@Component({
    selector: 'pf-table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionsComponent<T> {
    private readonly cdr = inject(ChangeDetectorRef);
    private _actions: TableAction<T>[] = [];
    private _row: T | null = null;
    firstAction: TableAction<T> | null = null;
    moreActions: TableAction<T>[] | null = null;

    @Input() set actions(actions: TableAction<T>[]) {
        this._actions = actions.map(action => ({ ...action }));
        this.setupActions();
    }

    get actions() {
        return this._actions;
    }

    @Input() index!: number;

    @Input() set row(row: SafeAny) {
        this._row = row;
        this.setupActions();
    }

    get row() {
        return this._row;
    }

    actionClicked(action: TableAction<T>) {
        action.onClick(this.row, this.index);
    }

    private setupActions() {
        this.actions.forEach(action => {
            if (action.onRowChange && this.row) {
                action.onRowChange(this.row, action);
            }
        });
        const displayedActions = this.actions.filter(x => !x.hide);
        if (displayedActions.length > 0) {
            this.firstAction = displayedActions[0];
        } else {
            this.firstAction = null;
        }
        if (displayedActions.length > 1) {
            this.moreActions = displayedActions.slice(1);
        } else {
            this.moreActions = null;
        }
        this.cdr.markForCheck();
    }
}
