export enum TypeEntityTypes {
    LocationType = 'LocationTypes',
    ContactType = 'ContactTypes',
    LoadStatusType = 'LoadStatusTypes',
    VehicleType = 'VehicleTypes',
    EquipmentType = 'EquipmentTypes',
    StopType = 'StopTypes',
    ChargeType = 'ChargeTypes',
    ReferenceType = 'ReferenceTypes',
    Service = 'Services',
    DocumentType = 'DocumentTypes',
}
