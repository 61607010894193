import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EntityBaseRootFormComponent } from '@pf/shared-ui';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import {
    ITypeEntityFragment,
    PFCoreEntities,
    SelectOption,
} from '@pf/shared-common';
import { ContactTypeFacade, IContact } from '@pf/shared/util-platform';

interface ContactForm {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    contactTypeId: string | null;
    description: string;
}

@UntilDestroy()
@Component({
    selector: 'platform-core-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent extends EntityBaseRootFormComponent<
    IContact,
    ContactForm
> {
    private _contactTypeName = '';

    entityType = PFCoreEntities.Contact;

    constructor(public contactTypeFacade: ContactTypeFacade) {
        super();
    }

    setContactTypeName(option: SelectOption) {
        this._contactTypeName = option.label as string;
    }

    formControls() {
        return {
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            email: new FormControl(null),
            phone: new FormControl(null),
            contactTypeId: new FormControl(null),
            description: new FormControl(null, Validators.maxLength(250)),
        };
    }

    fromFormGroup(formValue: ContactForm) {
        return {
            ...this.initialValue,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email || '',
            phone: formValue.phone || '',
            contactType: {
                id: formValue.contactTypeId,
                name: this._contactTypeName,
            } as ITypeEntityFragment,
            description: formValue.description || '',
        } as IContact;
    }

    toFormGroup(contact: IContact) {
        return {
            firstName: contact.firstName || '',
            lastName: contact.lastName || '',
            email: contact.email || '',
            phone: contact.phone || '',
            contactTypeId: contact.contactType?.id || null,
            description: contact.description || '',
        };
    }
}
