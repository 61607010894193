import { AuthService as Auth0 } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';
import { PlatformAuthService } from '@pf/shared-common';
import { filter, first, Observable, of, switchMap } from 'rxjs';
import { datadogRum } from '@datadog/browser-rum';
import { OneTimeLinkService } from './one-time-link.service';
import jwtDecode from 'jwt-decode';

@Injectable()
export class Auth0AuthService extends PlatformAuthService {
    constructor(
        private authService: Auth0,
        private oneTimeLinkService: OneTimeLinkService
    ) {
        super();
    }
    isLoading$ = this.oneTimeLinkService.isAuthenticated
        ? of(false)
        : this.authService.isLoading$;
    isAuthenticated$ = this.oneTimeLinkService.isAuthenticated
        ? of(true)
        : this.authService.isAuthenticated$;
    user$ = this.oneTimeLinkService.isAuthenticated
        ? of(jwtDecode(this.oneTimeLinkService.token as string))
        : this.authService.user$;
    error$ = this.authService.error$;

    login() {
        if (!this.oneTimeLinkService.isAuthenticated) {
            this.authService.isAuthenticated$
                .pipe(
                    first(),
                    filter(authenticated => !authenticated),
                    switchMap(() => this.authService.loginWithRedirect())
                )
                .subscribe();
        }
    }
    logout(returnUrl?: string) {
        datadogRum.stopSession();
        this.authService.logout({ returnTo: returnUrl });
    }

    override getAccessToken(): Observable<string> {
        const oneTimeLinkToken = this.oneTimeLinkService.token;
        return oneTimeLinkToken
            ? of(oneTimeLinkToken)
            : this.authService.getAccessTokenSilently();
    }
}
