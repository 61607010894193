import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconProps, IconType } from '@pf/shared-common';

import { IconMap, IIconPopover } from './icon-definitions';
import { RotateProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'pf-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
    @Input() popover?: IIconPopover;
    @Input() rotate?: RotateProp;
    @Input() size?: SizeProp;

    get iconClass() {
        return this.size ? `fa-${this.size}` : '';
    }

    iconProps: IconProps | null = null;

    @Input() set iconType(iconType: keyof typeof IconType) {
        this.setIconProps(IconType[iconType]);
    }

    setIconProps(iconType: IconType): void {
        if (!IconMap.has(iconType)) {
            throw new Error(`Icon not found! ${IconType[iconType]}`);
        }
        this.iconProps = IconMap.get(iconType) as IconProps;
    }
}
