import { Injectable } from '@angular/core';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { PermissionTypes, PFCoreEntities } from '@pf/shared-common';
import { buildCorePermissionPath } from '../utility/permission-path-builder.util';

@Injectable()
export class RoutesForContact extends AbstractRouteBuilder {
    permissionType = buildCorePermissionPath(
        PermissionTypes.Modify,
        PFCoreEntities.Contact
    );
}
