import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
    AuthGuard as Auth0Guard,
    AuthService as Auth0Service,
} from '@auth0/auth0-angular';
import { SafeAny } from '@pf/shared-common';
import { OneTimeLinkService } from '@pf/shared-services';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends Auth0Guard {
    override canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        if (this.oneTimeLinkService.isAuthenticated) {
            return of(true);
        }
        return super.canActivate(next, state);
    }

    override canLoad(route: SafeAny, segments: SafeAny): Observable<boolean> {
        if (this.oneTimeLinkService.isAuthenticated) {
            return of(true);
        }
        return super.canLoad(route, segments);
    }

    constructor(
        private oneTimeLinkService: OneTimeLinkService,
        auth0Service: Auth0Service
    ) {
        super(auth0Service);
    }
}
