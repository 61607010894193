import { Message, ROARR, Roarr, getLogLevelName } from 'roarr';

import { ILogger } from './ILogger';
import { Injectable } from '@angular/core';
import { SafeAny } from '@pf/shared-common';

@Injectable({
    providedIn: 'root',
})
export class LogManagerService {
    private readonly _loggers: ILogger[] = [];
    log = Roarr;

    addLogger(logger: ILogger) {
        const isExist = this._loggers.some((l) => {
            return l.constructor === logger.constructor;
        });
        if (!isExist) {
            this._loggers.push(logger);
        }
    }

    constructor() {
        this.listen();
    }

    private listen() {
        ROARR.write = (message) => {
            const msg = JSON.parse(message) as Message<{ logLevel: number }>;
            this._loggers.forEach((l) => {
                if (l.shouldLog(msg)) {
                    const logLevelName = getLogLevelName(
                        msg.context.logLevel
                    ) as SafeAny;
                    (l as SafeAny)[logLevelName](msg);
                }
            });
        };
    }
}
