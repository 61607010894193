import { Injectable } from '@angular/core';
import { PFCommunicationsEntities } from '@pf/shared-common';
import { AbstractEntityStore } from '@pf/shared-services';
import { OneTimeLinkRead } from '@pf/shared/util-platform';

@Injectable()
export class OneTimeLinkStore extends AbstractEntityStore<OneTimeLinkRead> {
    constructor() {
        super(PFCommunicationsEntities.OneTimeLink);
    }
}
