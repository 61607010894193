import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DrawerRenderedRouteData } from '../interfaces/DrawerRenderedRouteData';
import { FormService } from '../../forms/services/form.service';
import { DrawerService } from '../services/drawer.service';
import { PreviousRouteService } from '../services/previous-route.service';

@Injectable({
    providedIn: 'root',
})
export class DrawerRenderedGuard {
    constructor(
        private router: Router,
        private drawerService: DrawerService,
        private formService: FormService,
        private previousRouteService: PreviousRouteService,
        private location: Location
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const previousUrl = this.previousRouteService.getPreviousUrl();
        const currentUrl = this.previousRouteService.getCurrentUrl();
        const parentData = this.previousRouteService.getPreviousRouteData();
        const data = route.data as DrawerRenderedRouteData;

        // Do not open drawer if the route is not a child of the previous route or if the entity type of the previous route is different
        if (
            !previousUrl ||
            (!currentUrl?.startsWith(previousUrl) &&
                parentData?.entityType !== data.entityType)
        ) {
            this.formService.registerForm(data.entityType);
            return true;
        }

        if (!data.type || !route.component) {
            console.error(
                'Route with DrawerRenderedGuard must contain a component and DrawerRenderedRouteData'
            );
            return true;
        }

        const state = this.router.getCurrentNavigation()?.extras.state;
        this.drawerService.createFormDrawer({
            title: data.title,
            entityType: data.entityType,
            component: route.component,
            hash: state?.['hash'],
        });
        setTimeout(() => this.location.replaceState(currentUrl));
        return false;
    }
}
