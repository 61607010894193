import {
    CamelCaseNamingConvention,
    addProfile,
    createMapper,
} from '@automapper/core';
import { IQueryParamsMetadata, IQueryParamsSymbol } from '@pf/shared-common';
import {
    withResourceAuthMetadata,
    withTypeEntityMetadata,
} from '@pf/shared/util-platform';

import { CarrierMetadataMap } from './carriers/carrier.mapping-profile';
import { CustomerMetadataMap } from './customers/customer.mapping-profile';
import { LocationMetadataMap } from './locations/location.mapping-profile';
import { UserMetadataMap } from './users/user.mapping-profile';
import { UserPermissionMetadataMap } from './user-permissions/user-permission.mapping-profile';
import { UserRoleMetadataMap } from './user-roles/user-role.mapping-profile';
import { VendorMetadataMap } from './vendors/vendor.mapping-profile';
import { pojos } from '@automapper/pojos';
import { withMetadata } from '@pf/shared-utility';
import { FiscalPeriodMetadataMap } from './fiscal-periods/fiscal-period.mapping-profile';

/** Global Metadata */
withMetadata(IQueryParamsSymbol, IQueryParamsMetadata);
withTypeEntityMetadata();
withResourceAuthMetadata();

/** Create mapper */
export const PlatformCoreMapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

/** Add mapping profiles */
addProfile(PlatformCoreMapper, CarrierMetadataMap.profile);
addProfile(PlatformCoreMapper, VendorMetadataMap.profile);
addProfile(PlatformCoreMapper, CustomerMetadataMap.profile);
addProfile(PlatformCoreMapper, UserMetadataMap.profile);
addProfile(PlatformCoreMapper, LocationMetadataMap.profile);
addProfile(PlatformCoreMapper, UserRoleMetadataMap.profile);
addProfile(PlatformCoreMapper, UserPermissionMetadataMap.profile);
addProfile(PlatformCoreMapper, FiscalPeriodMetadataMap.profile);
