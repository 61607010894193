import {
    AccountType,
    Charge,
    ChargeViewModel,
    ConversionRate,
} from '@control-tower/platform-loads';
import {
    IEntity,
    IMappingProfile,
    ITypeEntityFragment,
} from '@pf/shared-common';
import {
    ConversionRateSymbol,
    ITypeEntityFragmentSymbol,
} from '@pf/shared/util-platform';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { uniqueId } from 'lodash-es';

export type ChargeRead = Charge;
export type ChargeCreate = ChargeViewModel;

export interface IChargeDto extends IEntity, Charge {}

export class ChargeDto implements IChargeDto {
    constructor(data: Partial<IChargeDto>) {
        Object.assign(this, data);
        this.id = uniqueId();
    }

    currencyTypeCode?: string;
    amount!: number;
    date?: string;
    conversions?: ConversionRate[];
    accountType?: AccountType;
    chargeType?: ITypeEntityFragment;
    quantity!: number;
    description?: string;
    equipmentType?: ITypeEntityFragment;
    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
}

const symbols = entitySymbols('Charge');

withDefaultEntityMetadata<ChargeDto>(symbols.dto, {
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
    accountType: String,
    chargeType: ITypeEntityFragmentSymbol,
    quantity: Number,
    description: String,
    equipmentType: ITypeEntityFragmentSymbol,
});

withMetadata<ChargeRead>(symbols.entity, {
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
    accountType: String,
    chargeType: ITypeEntityFragmentSymbol,
    quantity: Number,
    description: String,
    equipmentType: ITypeEntityFragmentSymbol,
});

withMetadata<ChargeCreate>(symbols.createBody, {
    chargeTypeId: String,
    equipmentTypeId: String,
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
    accountType: String,
    quantity: Number,
    description: String,
});

export const ChargeMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<ChargeRead, ChargeDto>(mapper, symbols.entity, symbols.dto);
        createMap<ChargeDto, ChargeRead>(mapper, symbols.dto, symbols.entity);
        createMap<ChargeDto, ChargeCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (x) => x.chargeTypeId,
                mapFrom((source) => source.chargeType?.id || null)
            ),
            forMember(
                (x) => x.equipmentTypeId,
                mapFrom((source) => source.equipmentType?.id || null)
            )
        );
    },
};
