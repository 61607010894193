import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { IconModule } from '../icon';

@NgModule({
    declarations: [CardComponent],
    exports: [CardComponent],
    imports: [CommonModule, NzCardModule, IconModule],
})
export class CardModule {}
