import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validate as email } from 'email-validator';

export function EmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }
        const validationResult = email(value);
        return !validationResult ? { email: true } : null;
    };
}
