import { TrailerDataService } from '../infrastructure/trailers/trailer.data.service';
import {
    TrailerCreate,
    TrailerDto,
    TrailerRead,
    TrailerSearchParams,
} from '../entities/trailer.dto';
import { TrailerStore } from '../infrastructure/trailers/trailer.store';
import { Injectable } from '@angular/core';
import { RoutesForTrailer } from './trailer.routes';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { TrailerMapper } from '../infrastructure/trailers/trailer.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';

@Injectable()
export class TrailerFacade extends AbstractManageEntityFacade<
    TrailerDto,
    TrailerRead,
    TrailerCreate,
    TrailerSearchParams
> {
    nameField: keyof TrailerDto = 'trailerNumber';

    constructor(
        dataService: TrailerDataService,
        routes: RoutesForTrailer,
        store: TrailerStore,
        mapper: TrailerMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof TrailerDto, string>> {
        return { trailerNumber: searchText };
    }
}
