import { Injectable } from '@angular/core';
import { PFLoadEntities, PermissionTypes } from '@pf/shared-common';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildLoadPermissionPath } from '@pf/shared/util-platform';

@Injectable()
export class RoutesForStops extends AbstractRouteBuilder {
    permissionType = buildLoadPermissionPath(
        PermissionTypes.Read,
        PFLoadEntities.Stop
    );
}
