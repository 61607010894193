import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    inject,
    OnInit,
    Output,
} from '@angular/core';
import { Controls, createForm, FormType } from 'ngx-sub-form';
import { EntitySubForm } from './form.types';
import { debounceTime, filter, firstValueFrom, map, Subject, tap } from 'rxjs';
import { FormService } from '../services/form.service';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { IEntity, SafeAny } from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { markDirtyOnSubmitIfInvalid, validateAndMarkDirty } from './form-operators';

@UntilDestroy()
@Component({
    selector: 'pf-entity-base-sub-form',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class EntityBaseSubFormComponent<
    TEntity extends object & IEntity,
    TFormInterface extends object = TEntity
> implements EntitySubForm<TEntity, TFormInterface>, OnInit
{
    private markForCheck$ = new Subject<void>();
    protected cdr = inject(ChangeDetectorRef);
    protected formService = inject(FormService);

    @Output() valueUpdated = new EventEmitter<TEntity>();

    registerDirtyCheck = true;
    listenForSubmit = true;
    listenForCancel = true;
    initialValue?: TEntity;

    form = createForm<TEntity, TFormInterface>(this, {
        formType: FormType.SUB,
        formControls: this.formControls(),
        toFormGroup: (entity: TEntity) => {
            this.initialValue = entity;
            return this.toFormGroup(entity);
        },
        fromFormGroup: (formValue: TFormInterface) => {
            return {
                ...this.initialValue,
                ...this.fromFormGroup(formValue),
            };
        },
    });

    @HostBinding('attr.pf-data-form')
    abstract entityType: string;

    abstract rootEntityType: string;

    // Arguments are optional to make Angular happy; however, they are required by derived classes
    protected constructor() {
        this.markForCheck$
            .pipe(untilDestroyed(this), debounceTime(300))
            .subscribe(() => {
                this.cdr.markForCheck();
            });
        this.form.controlValue$
            .pipe(untilDestroyed(this), debounceTime(100))
            .subscribe(value => {
                this.valueUpdated.emit(value as TEntity);
            });
    }

    ngOnInit(): void {
        this.setupDirtyCheck();
        this.setupCancelListener();
        this.setupSubmitListener();
        this.setupRootSubmitListener();
    }

    abstract formControls(): Controls<TFormInterface>;

    abstract toFormGroup(item: TEntity): TFormInterface;

    abstract fromFormGroup(formValue: TFormInterface): TEntity;

    markForCheck() {
        this.markForCheck$.next();
    }

    private setupDirtyCheck() {
        if (!this.registerDirtyCheck) {
            return;
        }
        // Setup isDirty$ observable
        const isDirty$ = dirtyCheck(
            this.form.formGroup,
            this.form.controlValue$.pipe(
                filter(i => !!i),
                map(initialValue => this.toFormGroup(initialValue as TEntity))
            ),
            {
                useBeforeunloadEvent: true,
            } as SafeAny
        );
        isDirty$
            .pipe(
                untilDestroyed(this),
                filter(() =>
                    // Only notify dirty state when form is in init or error state
                    ['init', 'error'].includes(
                        this.formService.activeState(this.entityType)?.action ||
                            ''
                    )
                ),
                tap(isDirty => {
                    this.formService.notifyState(this.entityType, {
                        isDirty: isDirty && this.form.formGroup.dirty,
                    });
                })
            )
            .subscribe();

        markDirtyOnSubmitIfInvalid(
            this.formService,
            this.entityType,
            this.form.formGroup,
            untilDestroyed(this)
        );
    }

    private setupCancelListener() {
        if (!this.listenForCancel) {
            return;
        }
        this.formService
            .cancel$(this.entityType)
            .pipe(
                untilDestroyed(this),
                tap(() => {
                    this.form.formGroup.reset(this.initialValue);
                    this.form.formGroup.markAsPristine();
                    this.formService.cancelled(this.entityType);
                })
            )
            .subscribe();
    }

    private setupSubmitListener() {
        if (!this.listenForSubmit) {
            return;
        }
        this.formService
            .submit$(this.entityType)
            .pipe(untilDestroyed(this))
            .subscribe(async () => {
                const controlValue = await firstValueFrom(
                    this.form.controlValue$
                );
                if (!this.form.formGroup.invalid) {
                    this.formService.submitted(
                        this.entityType,
                        controlValue as TEntity
                    );
                }
            });
    }

    // added this to validate the subform when the root form is submitted -terry
    private setupRootSubmitListener() {
        this.formService
            .submit$(this.rootEntityType)
            .pipe(untilDestroyed(this))
            .subscribe(async () => {
                validateAndMarkDirty(this.form.formGroup);
            });
    }
}
