import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
} from '@angular/core';
import { createForm, FormType } from 'ngx-sub-form';
import { FormArray, FormControl, UntypedFormArray } from '@angular/forms';
import { SafeAny } from '@pf/shared-common';
import { EntityForm } from './form.types';

interface EntityListForm<TEntity extends { id: string; isDeleted?: boolean }> {
    entities: TEntity[];
}

@Component({
    selector: 'pf-entity-list-base-form',
    template: '',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class EntityListBaseFormComponent<
    TEntity extends { id: string; isDeleted?: boolean }
> implements EntityForm<TEntity[], EntityListForm<TEntity>>, AfterViewInit
{
    @Input() loading = false;
    abstract entityType: string;
    protected cdr = inject(ChangeDetectorRef);

    public form = createForm<TEntity[], EntityListForm<TEntity>>(this, {
        formType: FormType.SUB,
        formControls: {
            entities: new UntypedFormArray([]),
        },
        toFormGroup: (values: TEntity[]) => {
            return {
                entities: values,
            } as EntityListForm<TEntity>;
        },
        fromFormGroup: (formValue: EntityListForm<TEntity>) => {
            return formValue.entities;
        },
        createFormArrayControl: (key, value) => new FormControl(value),
    });

    get valuesControl(): FormArray<FormControl<TEntity>> {
        return this.form.formGroup.controls.entities as SafeAny;
    }

    trackByFn(index: number, item: FormControl<TEntity>) {
        return item.value?.id || index;
    }

    protected abstract createItem(): TEntity;

    addNewItem() {
        this.valuesControl.controls.push(
            new FormControl({
                ...this.createItem(),
                initial: true,
            }) as FormControl<TEntity>
        );
        this.cdr.markForCheck();
    }

    valueUpdated(entity: TEntity, index: number): void {
        if ((this.valuesControl.value[index] as SafeAny)?.initial) {
            const list = this.valuesControl.value;
            delete (entity as SafeAny).initial;
            list[index] = entity;
            this.valuesControl.setValue(list);
            this.form.formGroup.markAsDirty();
            this.form.formGroup.markAsTouched();
        } else {
            this.form.formGroup.controls.entities.updateValueAndValidity({
                emitEvent: true,
            });
        }
    }

    deleteItem(index: number) {
        const itemValue = this.valuesControl.value[index];
        if (itemValue.id) {
            this.valuesControl.controls[index].setValue({
                ...itemValue,
                isDeleted: true,
            });
        } else {
            this.valuesControl.removeAt(index);
        }
        this.cdr.markForCheck();
    }

    undoDelete(controlIndex: number) {
        this.valuesControl.controls[controlIndex].setValue({
            ...this.valuesControl.controls[controlIndex].value,
            isDeleted: false,
        });
        this.cdr.markForCheck();
    }

    ngAfterViewInit(): void {
        this.loading = false;
        this.cdr.markForCheck();
    }
}
