import { AbstractTypeEntityFacade } from '../abstractions/AbstractTypeEntityFacade';
import { DocumentTypesService } from '@control-tower/platform-core';
import { Injectable } from '@angular/core';
import { LoggerService } from '@pf/shared-services';
import { TypeEntityStore } from '../stores/TypeEntityStore';
import { TypeEntityTypes } from '@pf/shared-common';

@Injectable()
export class DocumentTypeFacade extends AbstractTypeEntityFacade<DocumentTypesService> {
    constructor(
        typeService: DocumentTypesService,
        store: TypeEntityStore,
        logger: LoggerService
    ) {
        super(
            store,
            logger,
            typeService,
            'documentTypeGetAll',
            TypeEntityTypes.DocumentType
        );
    }
}
