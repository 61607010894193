<pf-content>
    <section pfSection pfTitle="Most Recent Changes">
        <nz-timeline>
            <nz-timeline-item
                *ngFor="
                    let change of recentChanges$ | async;
                    trackBy: trackByFn;
                    let first = first
                "
                [nzLabel]="changeLabelTmpl"
            >
                <ng-template #changeLabelTmpl>
                    <div class="timeline-label">
                        <pf-text>{{ change.userEmail }}</pf-text>
                        <pf-text type="description">{{
                            change.createdDate | PfDate
                        }}</pf-text>
                    </div>
                </ng-template>
                <platform-change-view
                    [autoExpand]="first"
                    [entityName]="entityName"
                    [entityChange]="change"
                ></platform-change-view>
            </nz-timeline-item>
        </nz-timeline>
        <pf-button buttonType="submit" block (click)="openDetailedHistory()"
            >View Detailed History</pf-button
        >
    </section>
</pf-content>
<nz-drawer
    [nzHeight]="drawerHeight"
    [nzVisible]="showDetailedHistory"
    [nzTitle]="detailedHistoryTitle"
    [nzZIndex]="1001"
    [nzClosable]="true"
    nzPlacement="bottom"
    (nzOnClose)="closeDetailedHistory()"
    (nzVisibleChange)="drawerOnViewInit()"
>
    <div *nzDrawerContent>
        <platform-detailed-history-view
            *ngIf="facade"
            [changeTrackingFacade]="facade"
            [entityName]="entityName"
        ></platform-detailed-history-view>
    </div>
</nz-drawer>
