import { Injectable } from '@angular/core';
import { LocationTypesService } from '@control-tower/platform-core';
import { LoggerService } from '@pf/shared-services';
import {
    AbstractTypeEntityFacade,
    TypeEntityStore,
} from '@pf/shared/util-platform';
import { TypeEntityTypes } from '@pf/shared-common';

@Injectable()
export class LocationTypeFacade extends AbstractTypeEntityFacade<LocationTypesService> {
    constructor(
        locationTypeService: LocationTypesService,
        store: TypeEntityStore,
        logger: LoggerService
    ) {
        super(
            store,
            logger,
            locationTypeService,
            'locationTypeGetAll',
            TypeEntityTypes.LocationType
        );
    }
}
