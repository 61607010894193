import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFCoreEntities } from '@pf/shared-common';
import { UserPermissionRead } from '../../entities/user-permission.dto';

@Injectable()
export class UserPermissionStore extends AbstractEntityStore<UserPermissionRead> {
    constructor() {
        super(PFCoreEntities.UserPermission);
    }
}
