import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { IEntity, IManageEntityFacade, SafeAny } from '@pf/shared-common';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from '@pf/shared-ui';

@UntilDestroy()
@Component({
    selector: 'platform-entity-status-toggle',
    template: `
        <pf-switch
            [label]="label"
            [loading]="loading"
            [pfDisabled]="disabled"
            [control]="switchControl"></pf-switch>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusToggleComponent implements OnInit {
    private _formService? = inject(FormService, { optional: true });
    private _cdr = inject(ChangeDetectorRef);
    private _entity?: IEntity;

    switchControl = new FormControl<boolean>(false);
    loading = false;
    disabled = true;

    @Input() label = 'Status';

    @Input({ required: true }) set entity(entity: IEntity) {
        this._entity = entity;
        this.switchControl.setValue(!entity.isDeleted);
        this.disabled = !entity.id;
    }

    @Input() entityType?: string;

    @Input({ required: true }) facade!: IManageEntityFacade<SafeAny>;

    ngOnInit() {
        this.switchControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.activate();
                } else {
                    this.deactivate();
                }
            });
    }

    activate() {
        if (!this._entity?.id || !this._entity.isDeleted) {
            return;
        }
        this._entity.isDeleted = false;
        if (this._entity.id) {
            this.loading = true;
            this.facade
                .undoDelete$(this._entity.id)
                .subscribe(() => this.afterRequest());
        }
    }

    deactivate() {
        if (!this._entity?.id || this._entity.isDeleted) {
            return;
        }
        this._entity.isDeleted = true;
        if (this._entity.id) {
            this.loading = true;
            this.facade
                .delete$(this._entity.id)
                .subscribe(() => this.afterRequest());
        }
    }

    private afterRequest() {
        this.loading = false;
        this._cdr.markForCheck();
        if (this.entityType && this._formService) {
            this._formService.markAsDirty(this.entityType, true);
        }
    }
}
