import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef,
} from '@angular/core';
import { IIconPopover } from '../icon';
import { SafeAny } from '@pf/shared-common';

@Component({
    selector: 'pf-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
    @Input() title!: string;
    @Input() description?: string;
    @Input() extraInformation?: IIconPopover;

    @Input() headerActionTemplate?: TemplateRef<SafeAny> | string;
}
