import { AbstractEntityStore } from '@pf/shared-services';
import { CarrierDto } from '../../entities/carrier.dto';
import { Injectable } from '@angular/core';
import { PFCoreEntities } from '@pf/shared-common';

@Injectable()
export class CarrierStore extends AbstractEntityStore<CarrierDto> {
    constructor() {
        super(PFCoreEntities.Carrier);
    }
}
