import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzSegmentedOption } from 'ng-zorro-antd/segmented';
import {
    FilterOption,
    ISegmentedOption,
    SafeAny,
    SelectOption,
} from '@pf/shared-common';

import { TableService } from '../../services/table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';

@UntilDestroy()
@Component({
    selector: 'pf-filter-group',
    templateUrl: './filter-group.component.html',
    styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent {
    private _filterOptions: FilterOption[] = [];
    selectControl = new FormControl<string | number | null>(null);
    selectedIndex: number | null = null;
    @Input() filterField!: string;
    selectOptions: SelectOption[] = [];

    @Input() set filterOptions(options: FilterOption[]) {
        this._filterOptions = options;
        this.setFilterOptions();
    }

    @Output() selectedChange = new EventEmitter<FilterOption>();
    segmentedOptions: ISegmentedOption[] = [] as ISegmentedOption[];

    constructor(private tableService: TableService) {
        this.listenForTableReset();
    }

    handleIndexChange(index: number) {
        if (index === this.selectedIndex) {
            return;
        }
        if (index !== this.selectControl.value) {
            this.selectControl.setValue(index);
        }
        this.selectedIndex = index;
        const option = this._filterOptions[index];

        if (!option) {
            return;
        }

        if (!this.filterField && !option.filterFn) {
            console.error(
                'filterField or option.filterFn was not supplied to filter-group.'
            );
            return;
        }
        const filtersUpdate = option.filterFn
            ? option.filterFn()
            : {
                  [this.filterField as SafeAny]: option.value,
              };
        this.tableService.updateFilters(filtersUpdate);
        this.selectedChange.emit(option);
    }

    private listenForTableReset() {
        this.tableService.reset$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDefaultOption());
    }

    private setFilterOptions() {
        this.segmentedOptions = this._filterOptions.map(
            o =>
                ({
                    value: o.value,
                    label: o.label,
                } as NzSegmentedOption)
        );
        this.selectOptions = this._filterOptions.map(
            (o, index) =>
                ({
                    label: o.label,
                    value: index,
                } as SelectOption)
        );

        this.setDefaultOption(true);
    }

    private setDefaultOption(checkInitial?: boolean) {
        const defaultSelected = this._filterOptions.findIndex(x =>
            checkInitial ? x.initial : x.default
        );
        const selectedIndex = defaultSelected > -1 ? defaultSelected : 0;
        this.handleIndexChange(selectedIndex);
    }
}
