import { Injectable } from '@angular/core';
import {
    AbstractManageEntityFacade,
    OneTimeLinkService,
} from '@pf/shared-services';
import { QueryFilters } from '@pf/shared-common';
import { OneTimeLinkDataService } from '../infrastructure/one-time-link/one-time-link.data.service';
import { OneTimeLinkStore } from '../infrastructure/one-time-link/one-time-link.store';
import { OneTimeLinkMapper } from '../infrastructure/one-time-link/one-time-link.mapper';
import { RoutesForOneTimeLink } from './one-time-link.routes';
import {
    OneTimeLinkDto,
    OneTimeLinkRead,
    OneTimeLinkCreate,
    OneTimeLinkSearchParams,
} from '@pf/shared/util-platform';
import { Observable, map } from 'rxjs';

@Injectable()
export class OneTimeLinkFacade extends AbstractManageEntityFacade<
    OneTimeLinkDto,
    OneTimeLinkRead,
    OneTimeLinkCreate,
    OneTimeLinkSearchParams
> {
    nameField: keyof OneTimeLinkDto = 'subject';

    constructor(
        protected override dataService: OneTimeLinkDataService,
        routes: RoutesForOneTimeLink,
        store: OneTimeLinkStore,
        mapper: OneTimeLinkMapper,
        private oneTimeLinkService: OneTimeLinkService
    ) {
        super(dataService, routes, store, mapper);
    }

    override add(): void {
        this.resetActive();
    }

    override manage(): void {
        this.resetActive();
    }

    override edit(entityId: string): void {
        this.setActive(entityId);
    }

    override textSearchFilter(
        searchText: string
    ): QueryFilters<OneTimeLinkDto> {
        return { subject: searchText };
    }

    getOneTimeToken(id: string): Observable<{
        token: string;
        expiresAt: string;
    }> {
        const authenticate$ = (
            this.dataService as OneTimeLinkDataService
        ).authenticate(id);

        return authenticate$.pipe(
            map(authenticateResponse => {
                const token = authenticateResponse.token;
                const expiresAt = authenticateResponse.expiresAt;

                this.oneTimeLinkService.token = token;
                this.oneTimeLinkService.oneTimeLinkId = id;

                return {
                    token,
                    expiresAt,
                };
            })
        );
    }
}
