import { ValidatorFn, Validators } from '@angular/forms';
import { IconType } from '@pf/shared-common';

import { EmailValidator } from '../../validators/EmailValidator';
import { PhoneValidator } from '../../validators/PhoneValidator';

/**
 * The `InputType` type is used to define different `pf-input` component behaviors
 */
export enum InputType {
    text,
    email,
    number,
    phone,
    zip,
    search,
    textarea,
}

export interface InputTypeProps {
    type: string;
    componentType: ComponentType;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    suffixIcon?: keyof typeof IconType;
    inputMode?: string;
    validators?: ValidatorFn[];
}

/**
 * The `ComponentType` type is used to switch generated component
 * between `input-number` and `input` nz components
 */
export type ComponentType = 'input' | 'inputNumber' | 'textarea';

export const InputTypePropMap: Record<
    InputType,
    (min: number, max: number) => InputTypeProps
> = {
    [InputType.text]: (minLength, maxLength) => ({
        type: 'text',
        componentType: 'input',
        minLength,
        maxLength,
        validators: [
            ...(minLength ? [Validators.minLength(minLength)] : []),
            ...(maxLength ? [Validators.maxLength(maxLength)] : []),
        ],
    }),
    [InputType.search]: (minLength, maxLength) => ({
        type: 'text',
        inputMode: 'search',
        componentType: 'input',
        suffixIcon: 'search',
        minLength,
        maxLength,
        validators: [
            ...(minLength ? [Validators.minLength(minLength)] : []),
            ...(maxLength ? [Validators.maxLength(maxLength)] : []),
        ],
    }),
    [InputType.email]: () => ({
        type: 'email',
        inputMode: 'email',
        componentType: 'input',
        maxLength: 320,
        validators: [EmailValidator()],
    }),
    [InputType.phone]: () => ({
        type: 'phone',
        inputMode: 'tel',
        componentType: 'input',
        maxLength: 16,
        validators: [PhoneValidator()],
    }),
    [InputType.zip]: () => ({
        type: 'text',
        inputMode: 'numeric',
        componentType: 'input',
        minLength: 5,
        maxLength: 10,
        validators: [
            Validators.minLength(5),
            Validators.maxLength(10),
            Validators.pattern(/\d{5}-?(\d{4})?/),
        ],
    }),
    [InputType.number]: (min, max) => ({
        type: 'number',
        inputMode: 'numeric',
        componentType: 'inputNumber',
        min,
        max,
        validators: [
            ...(min ? [Validators.minLength(min)] : []),
            ...(max ? [Validators.maxLength(max)] : []),
        ],
    }),
    [InputType.textarea]: (minLength, maxLength) => ({
        type: 'textarea',
        componentType: 'textarea',
        minLength,
        maxLength,
        validators: [
            ...(minLength ? [Validators.minLength(minLength)] : []),
            ...(maxLength ? [Validators.maxLength(maxLength)] : []),
        ],
    }),
};
