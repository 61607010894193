import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BrandingDto, BrandingVm } from '@pf/shared-common';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { BehaviorSubject } from 'rxjs';
import { TinyColor } from '@ctrl/tinycolor';
import { generate } from 'ng-zorro-antd/core/color';
import { updateCSS } from 'ng-zorro-antd/core/util';

export const DefaultBranding: BrandingVm = {
    name: 'Control Tower',
    primaryColor: '#0E3C5A',
    secondaryColor: '#F04C32',
    logoLocation: 'assets/ControlTower-white.svg',
};

export const AppBranding = new InjectionToken<BrandingVm>('AppBranding');

@Injectable({
    providedIn: 'root',
})
export class BrandingService {
    private _brandingSubject = new BehaviorSubject<BrandingVm>(DefaultBranding);

    readonly branding$ = this._brandingSubject.asObservable();

    get branding() {
        return this._brandingSubject.value;
    }

    constructor(
        private nzConfigService: NzConfigService,
        @Optional() @Inject(AppBranding) private appBranding: BrandingVm
    ) {}

    updateBranding(branding: BrandingDto) {
        const primaryColor =
            branding.primaryColor ||
            this.appBranding?.primaryColor ||
            DefaultBranding.primaryColor;
        const secondaryColor =
            branding.secondaryColor ||
            this.appBranding?.secondaryColor ||
            DefaultBranding.secondaryColor;
        const logoLocation =
            branding.logoLocation ||
            this.appBranding?.logoLocation ||
            DefaultBranding.logoLocation;
        this.updateNgZorroTheme(primaryColor);
        this.updateSecondaryColorVariables(secondaryColor);
        this._brandingSubject.next({
            name:
                branding.name || this.appBranding.name || DefaultBranding.name,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            logoLocation: logoLocation,
        });
    }

    private updateNgZorroTheme(primaryColor: string) {
        if (!primaryColor || this.branding.primaryColor === primaryColor) {
            return;
        }
        this.nzConfigService.set('theme', {
            primaryColor: primaryColor,
        });
    }

    private updateSecondaryColorVariables(secondaryColor: string) {
        if (
            !secondaryColor ||
            this.branding.secondaryColor === secondaryColor
        ) {
            return;
        }
        const baseColor = new TinyColor(secondaryColor);
        const colorPalettes = generate(baseColor.toRgbString());
        const cssList = [
            `--pf-secondary-color: ${baseColor.clone().toRgbString()};`,
            `--pf-secondary-color-hover: ${colorPalettes[4]};`,
            `--pf-secondary-color-active: ${colorPalettes[7]};`,
            `--pf-secondary-color-light: #${baseColor
                .clone()
                .lighten(15)
                .toHex()};`,
            `--pf-secondary-color-outline: ${baseColor
                .clone()
                .setAlpha(0.2)
                .toRgbString()};`,
        ];
        const style = `
        :root {
          ${cssList.join('\n')}
        }
        `.trim();
        const dynamicStyleMark = `-pf-${Date.now()}-${Math.random()}`;
        updateCSS(style, `${dynamicStyleMark}-dynamic-theme`);
    }
}
