import { AbstractEntityStore } from '@pf/shared-services';
import { StopRead } from '../../entities/stop.dto';
import { PFLoadEntities } from '@pf/shared-common';
import { Injectable } from '@angular/core';

@Injectable()
export class StopStore extends AbstractEntityStore<StopRead> {
    constructor() {
        super(PFLoadEntities.Stop);
    }
}
