import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    map,
    Observable,
    startWith,
} from 'rxjs';
import { ICustomFieldDto } from '@pf/shared/util-platform';
import { FormControl } from '@angular/forms';
import { asArraySafe } from '@pf/shared-utility';
import { InputComponent } from '@pf/shared-ui';
import { v4 as uuidV4 } from 'uuid';

type CustomFieldListItem = ICustomFieldDto & {
    formControl: FormControl<string | null>;
    listId: string;
};

@Component({
    selector: 'platform-custom-field-list',
    templateUrl: './custom-field-list.component.html',
    styleUrls: ['./custom-field-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldListComponent {
    private readonly customFieldsSubject = new BehaviorSubject<
        CustomFieldListItem[]
    >([]);
    searchControl = new FormControl<string>('');

    @ViewChildren('inputItem') inputComponents!: QueryList<InputComponent>;
    @Input({ required: true }) customFieldKey!: string;
    @Input() listLabel = '';
    @Input() showSearch = true;
    @Input() searchPlaceholder = 'Search';

    @Input() set customFields(customFields: ICustomFieldDto[] | undefined) {
        const value = asArraySafe(customFields);
        if (value.length === 0) {
            this.customFieldsSubject.next([
                {
                    key: this.customFieldKey,
                    value: '',
                    listId: uuidV4(),
                    formControl: new FormControl(''),
                },
            ]);
            return;
        }
        this.customFieldsSubject.next(
            value.map((customField: ICustomFieldDto) => ({
                ...customField,
                listId: uuidV4(),
                formControl: new FormControl(customField.value || null),
            }))
        );
    }

    get customFields(): ICustomFieldDto[] | undefined {
        return this.customFieldsSubject.value
            .map(customField => ({
                ...customField,
                value: customField.formControl.value,
            }))
            .filter(customField => !!customField.value);
    }

    @Output() itemsChanged = new EventEmitter<ICustomFieldDto[]>();

    listControls$: Observable<CustomFieldListItem[]> = combineLatest([
        this.searchControl.valueChanges.pipe(startWith('')),
        this.customFieldsSubject.asObservable(),
    ]).pipe(
        map(([search, customFields]) => {
            const filteredByKey = customFields.filter(
                customField => customField.key === this.customFieldKey
            );
            const searchValue = search?.toLowerCase()?.trim();
            return searchValue
                ? filteredByKey.filter(customField =>
                      customField.value?.toLowerCase()?.includes(searchValue)
                  )
                : filteredByKey;
        })
    );

    addNewItem(): void {
        this.customFieldsSubject.next([
            ...this.customFieldsSubject.value,
            {
                key: this.customFieldKey,
                value: '',
                listId: uuidV4(),
                formControl: new FormControl(''),
            },
        ]);
        setTimeout(() => {
            this.inputComponents.last.focus();
        });
    }

    focusNextOrAddNewItem(index: number): void {
        if (index === this.inputComponents.length - 1) {
            this.addNewItem();
        } else {
            this.inputComponents.toArray()[index + 1].focus();
        }
    }

    removeItem(listId: string): void {
        const customFields = this.customFieldsSubject.value.filter(
            item => item.listId !== listId
        );
        this.customFieldsSubject.next(customFields);
    }

    onInput() {
        this.itemsChanged.emit(this.customFields);
    }
}
