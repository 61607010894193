import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TenantProvider } from '@pf/shared-common';
import { take } from 'rxjs';

const DEFAULT_BARCODE_FORMAT = 'CODE128';

@Component({
    selector: 'platform-barcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeComponent {
    @Input() barcodeValue = '';
    @Input() displayValue = false;
    barcodeFormat = DEFAULT_BARCODE_FORMAT;

    constructor(tenantProvider: TenantProvider, cdr: ChangeDetectorRef) {
        tenantProvider.localization$.pipe(
            take(1)
        ).subscribe((localization) => {
            this.barcodeFormat = localization.barcodeFormat || DEFAULT_BARCODE_FORMAT;
            cdr.markForCheck();
        });
    }
}
