import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppNotificationService } from '../services/app-notification.service';
import { AppNotificationVm } from '@pf/shared-common';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'pf-app-notifications',
    templateUrl: './app-notifications.component.html',
    styleUrls: ['./app-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNotificationsComponent implements OnInit {
    notifications: AppNotificationVm[] = [];
    menuVisible = false;
    constructor(
        public appNotificationService: AppNotificationService,
        private cdr: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.appNotificationService.persistantNotifications$
            .pipe(
                untilDestroyed(this),
                tap((notifications) => {
                    if (notifications.length === 0) {
                        this.menuVisible = false;
                    }
                })
            )
            .subscribe((notifications) => {
                this.notifications = notifications || [];
                this.cdr.markForCheck();
            });
    }
}
