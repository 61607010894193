import { inject, Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import {
    persistState,
    sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import {
    ITypeEntity,
    TenantProvider,
    TypeEntityTypes,
} from '@pf/shared-common';
import { combineLatest, distinctUntilChanged, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface TypeEntityState {
    tenantId: string;
    types: Record<
        string,
        { entities: ITypeEntity[]; currentEntityIds: string[] }
    >;
}

@UntilDestroy()
@Injectable()
export class TypeEntityStore {
    _store = createStore(
        { name: 'typeEntities' },
        withProps<TypeEntityState>({
            tenantId: '',
            types: {},
        })
    );

    constructor() {
        const persist = persistState(this._store, {
            storage: sessionStorageStrategy,
        });
        combineLatest([inject(TenantProvider).id$, persist.initialized$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(([tenantId]) => {
                if (this._store.state.tenantId === tenantId) return;
                this._store.update(() => ({
                    tenantId: tenantId,
                    types: {},
                }));
            });
    }

    public setTypeEntities(
        typeEntities: ITypeEntity[],
        entityType: TypeEntityTypes
    ): void {
        this._store.update(state => ({
            ...state,
            types: {
                ...state.types,
                [`${entityType}s`]: {
                    entities: typeEntities,
                    currentEntityIds: [],
                },
            },
        }));
    }

    get(
        entityId: string,
        entityType: TypeEntityTypes
    ): ITypeEntity | undefined {
        const entities =
            this._store.state.types[`${entityType}s`]?.entities || [];
        return entities.find(entity => entity.id === entityId);
    }

    getByCode(
        code: string,
        entityType: TypeEntityTypes
    ): ITypeEntity | undefined {
        const entities =
            this._store.state.types[`${entityType}s`]?.entities || [];
        return entities.find(entity => entity.code === code);
    }

    public setCurrentEntities(
        currentEntityIds: string[],
        entityType: TypeEntityTypes
    ): void {
        this._store.update(state => ({
            ...state,
            types: {
                ...state.types,
                [`${entityType}s`]: {
                    ...state.types[`${entityType}s`],
                    currentEntityIds: currentEntityIds,
                },
            },
        }));
    }

    public getTypeEntities(
        entityType: TypeEntityTypes
    ): Observable<ITypeEntity[]> {
        return this.getEntityTypeState(entityType).pipe(
            map(state => state.entities)
        );
    }

    public getCurrentEntities(
        entityType: TypeEntityTypes
    ): Observable<ITypeEntity[]> {
        return this.getEntityTypeState(entityType).pipe(
            map(state => {
                const currentEntities = state.currentEntityIds;
                return state.entities.filter(entity =>
                    currentEntities.includes(entity.id)
                );
            })
        );
    }

    private getEntityTypeState(entityType: TypeEntityTypes) {
        return this._store.pipe(
            map(
                state =>
                    state.types[`${entityType}s`] || {
                        entities: [],
                        currentEntityIds: [],
                    }
            )
        );
    }
}
