import { Injectable } from '@angular/core';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildTransportationPermissionPath } from '@pf/shared/util-platform';
import { PermissionTypes, PFTransportationEntities } from '@pf/shared-common';

@Injectable()
export class RoutesForDriver extends AbstractRouteBuilder {
    permissionType = buildTransportationPermissionPath(
        PermissionTypes.Read,
        PFTransportationEntities.Driver
    );
}
