import { Directive, HostBinding, HostListener, Input } from '@angular/core';

import { launchEmail } from '@pf/shared-utility';

@Directive({
    selector: '[pfEmailAction]',
})
export class EmailActionDirective {
    @Input('pfEmailAction') email?: string;
    @Input() action = 'email';
    @HostBinding('style.cursor')
    cursor = 'pointer';
    @HostBinding('attr.disabled')
    get disabled() {
        return this.email ? null : '';
    }

    @HostListener('click') onClick() {
        if (!this.email) {
            return;
        }
        switch (this.action) {
            case 'email':
                launchEmail(this.email);
                break;
            default:
                break;
        }
    }
}
