<pf-table
    [columns]="columns"
    [title]="entityName + ' Changes'"
    [searchFacade]="changeTrackingFacade"
    [expandable]="true"
    [expandRowRef]="expandRowRef"
    [searchField]="['entity']"
    (dataChanged)="onDataChanged()"
>
    <ng-template #expandRowRef let-row>
        <platform-change-tree
            [autoExpand]="true"
            [entityName]="entityName"
            [entityChange]="row.data"
        ></platform-change-tree>
    </ng-template>
</pf-table>
