import { BehaviorSubject, Subject, filter, debounceTime } from 'rxjs';
import { QueryFilters, TableQueryParams } from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Injectable } from '@angular/core';

@UntilDestroy()
@Injectable()
export class TableService {
    private readonly _queryParams = new BehaviorSubject<
        Readonly<TableQueryParams>
    >({
        serverRendered: false,
        pageIndex: 0,
        pageSize: 0,
        filters: {},
        checkboxes: [],
    });
    private _resetSubject = new Subject<void>();

    queryParamsChanged$ = this._queryParams.asObservable().pipe(
        untilDestroyed(this),
        filter(
            queryParams =>
                queryParams !== null &&
                queryParams.pageIndex > 0 &&
                !!queryParams.serverRendered
        ),
        debounceTime(300)
    );

    reset$ = this._resetSubject.asObservable();

    get queryParams() {
        return this._queryParams.value;
    }

    reset(update: Partial<TableQueryParams>) {
        this.updateTableQueryParams({
            pageIndex: 1,
            sortField: null,
            sortOrder: null,
            filters: {},
            checkboxes: {},
            ...update,
        });
        this._resetSubject.next();
    }

    updateTableQueryParams(update: Partial<TableQueryParams>) {
        const newQueryParams: Readonly<TableQueryParams> = {
            ...(this._queryParams.value || {
                pageIndex: 0,
                pageSize: 0,
            }),
            ...update,
        };
        this._queryParams.next(newQueryParams);
    }

    updateFilters<T>(filtersUpdate: QueryFilters<T>) {
        this.updateTableQueryParams({
            pageIndex: 1,
            filters: {
                ...(this.queryParams.filters || {}),
                ...filtersUpdate,
            },
        });
    }
}
