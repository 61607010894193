<ng-content select="header"></ng-content>
<ng-content select="pf-content-header"></ng-content>
<nz-layout #mainContent>
    <nz-sider
        nzCollapsible
        nzBreakpoint="lg"
        [nzCollapsedWidth]="0"
        [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="null"
        [style]="siderScrollStyle"
        [ngClass]="{ expanded: !isCollapsed }"
    >
        <span sider-content>
            <ng-content select="aside"></ng-content>
            <ng-content select="pf-content-aside"></ng-content>
        </span>
    </nz-sider>
    <div
        #siderTrigger
        sider-trigger
        (click)="isCollapsed = !isCollapsed"
        [style]="siderScrollStyle"
    >
        <pf-icon iconType="list"></pf-icon>
    </div>
    <nz-content>
        <ng-content></ng-content>
    </nz-content>
</nz-layout>
