import { Injectable, Injector } from '@angular/core';
import { RoutePathBuilder } from '@ngspot/route-path-builder';
import { IManageEntityRoutes } from '@pf/shared-common';

@Injectable()
export abstract class AbstractRouteBuilder
    extends RoutePathBuilder
    implements IManageEntityRoutes
{
    constructor(injector: Injector) {
        super(injector);
    }
    main() {
        return this.url('');
    }

    edit(id: string) {
        return this.urlFromCommands([id, 'edit']);
    }
    add() {
        return this.url('add');
    }
    view(id: string) {
        return this.urlFromCommands([id]);
    }

    abstract permissionType: string;
}
