import { IEntity, SafeAny } from '@pf/shared-common';
import { Operation } from 'fast-json-patch';

export function fixEntityOperations(dto: SafeAny, ops: Operation[]) {
    let updatedOps = ops.filter(o => o.op !== 'add' || o.value !== null);

    function fixDeleteOps() {
        Object.keys(dto).forEach(key => {
            if (!Array.isArray(dto[key])) {
                return;
            }
            (dto[key] as IEntity[]).forEach((subEntity, subEntityIndex) => {
                if (subEntity.isDeleted) {
                    const path = `/${key}/${subEntityIndex}`;
                    updatedOps = updatedOps.filter(
                        o => !o.path.startsWith(path)
                    );
                    updatedOps.unshift({
                        op: 'remove',
                        path,
                    } as Operation);
                }
            });
        });
    }

    function fixAddOps() {
        updatedOps.forEach(o => {
            if (o.op === 'add' && typeof o.value === 'object') {
                const path = o.path.split('/');
                const key = path[1];
                if (Array.isArray(dto[key]) && dto[key].length <= 1) {
                    (o as Operation).op = 'replace';
                    o.path = `/${key}`;
                    o.value = [o.value];
                }
            }
        });
    }

    fixDeleteOps();
    fixAddOps();
    // sort operations so array delete operations are done in reverse order and after all other operations
    return updatedOps.sort((a, b) => {
        if (a.op === 'remove') {
            return 1;
        }
        if (b.op === 'remove') {
            return -1;
        }
        return 0;
    });
}
