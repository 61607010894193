import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormService } from '../services/form.service';
import {
    IEntity,
    SafeAny,
    TableAction,
    TableColumn,
    TableOptions,
} from '@pf/shared-common';
import { UntilDestroy } from '@ngneat/until-destroy';

import { DrawerFormComponent } from './drawer-form.component';
import { FormControl } from '@angular/forms';
import { TypedFormArray } from 'ngx-sub-form';
import { cloneDeep } from 'lodash-es';
import { compare } from 'fast-json-patch';
import { v4 as uuidv4 } from 'uuid';
import { first } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'pf-drawer-subform',
    templateUrl: './drawer-subform.component.html',
    styleUrls: ['./drawer-subform.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerSubformComponent<TSubEntity extends IEntity = SafeAny> {
    private _cdr = inject(ChangeDetectorRef);
    private _added = new Set<string>();
    private _edited = new Set<string>();
    private _originalValue: TSubEntity[] = [];
    private _formArray!: TypedFormArray<TSubEntity[]>;

    @ViewChild(DrawerFormComponent, { static: false })
    drawerForm!: DrawerFormComponent;

    @Input() formRef!: TemplateRef<{ $implicit: FormControl }>;
    @Input({ required: true }) entityType!: string;
    @Input() tableTitle = '';

    @Input() set formArray(formArray: TypedFormArray<TSubEntity[]>) {
        this._originalValue = cloneDeep(formArray.value);
        this._formArray = formArray;
    }

    get formArray() {
        return this._formArray;
    }

    @Input() columns: TableColumn<TSubEntity>[] = [];
    @Input() loading = true;

    tableOptions: TableOptions<TSubEntity> = {
        rowClassRules: {
            modified: row => this._edited.has(row.data?.id),
            added: row => this._added.has(row.data?.id),
            deleted: row => !!row.data?.isDeleted,
            invalid: row => this.formArray.controls[row.index].invalid,
        },
    };

    actions: TableAction<TSubEntity>[] = [
        {
            name: 'Edit',
            onClick: row => {
                this.edit(row.id);
            },
            textType: 'primary',
        },
        {
            name: 'Remove',
            onRowChange: (row, action) => {
                action.hide = row.isDeleted;
            },
            onClick: row => {
                this.delete(row.id);
            },
            textType: 'danger',
        },
        {
            name: 'Undo',
            onRowChange: (row, action) => {
                action.hide = !(row.isDeleted || this._edited.has(row.id));
            },
            onClick: row => {
                this.undo(row.id);
            },
            textType: 'primary',
        },
    ];
    private _currentControl?: FormControl<TSubEntity | null> | null = null;
    formTitle = '';

    get currentControl() {
        return this._currentControl;
    }

    set currentControl(
        control: FormControl<TSubEntity | null> | undefined | null
    ) {
        this._currentControl = new FormControl<TSubEntity>(
            control?.value as TSubEntity
        );
    }

    constructor(private formService: FormService) {}

    edit(entityId: string) {
        const control = this.formArray.controls.find(
            c => c.value.id === entityId
        );
        this.openControlForm(control as FormControl<TSubEntity>);
    }

    add() {
        const newControl = new FormControl<TSubEntity>({
            id: uuidv4(),
        } as TSubEntity) as FormControl<TSubEntity>;
        this.openControlForm(newControl, true);
    }

    undo(entityId: string) {
        const control = this.formArray.controls.find(
            c => c.value.id === entityId
        );
        const originalValue = this._originalValue.find(c => c.id === entityId);
        control?.reset(originalValue);
        this._edited.delete(entityId);
    }

    delete(entityId: string) {
        const controlIndex = this.formArray.controls.findIndex(
            c => c.value.id === entityId
        );
        if (this._added.has(entityId)) {
            this.formArray.removeAt(controlIndex);
            this._added.delete(entityId);
        } else {
            this.formArray.controls[controlIndex]?.setValue({
                ...this.formArray.controls[controlIndex].value,
                isDeleted: true,
            });
        }
    }

    checkIfValueChanged() {
        if (!this.currentControl?.value) {
            return;
        }
        const entityId = this.currentControl.value.id;
        const originalValue = this._originalValue.find(
            c => c.id === entityId
        ) as TSubEntity;

        if (!originalValue) {
            this.formArray.push(this.currentControl);
        } else {
            const changed =
                compare(originalValue, this.currentControl.value).length > 0;
            if (changed) {
                this._edited.add(entityId);
            } else if (this._edited.has(entityId)) {
                this._edited.delete(entityId);
            }
            this.updateControlValue(entityId, this.currentControl.value);
        }
        this.currentControl = null;
    }

    cancel() {
        this.currentControl = null;
        this._cdr.markForCheck();
    }

    private updateControlValue(entityId: string, value: TSubEntity) {
        const control = this.formArray.controls.find(
            c => c.value.id === entityId
        );
        if (!control) {
            return;
        }
        control.setValue({ ...value });
    }

    private openControlForm(control: FormControl<TSubEntity>, add = false) {
        this.registerForm();
        this.currentControl = control;
        this.formTitle = `${add ? 'Add' : 'Edit'} ${this.entityType}`;
        this.drawerForm.open();
    }

    private registerForm() {
        this.formService.registerForm(this.entityType);
        this.formService
            .submitted$(this.entityType)
            .pipe(first())
            .subscribe(() => {
                if (!this.currentControl) {
                    return;
                }
                this.checkIfValueChanged();
                this._cdr.markForCheck();
            });
        this.formService
            .cancelled$(this.entityType)
            .pipe(first())
            .subscribe(() => {
                this.cancel();
            });
    }
}
