import {
    CustomFieldMetadataMap,
    DefaultCarrierFragmentMetadataMap,
    DefaultTrailerFragmentMetadataMap,
    DefaultVehicleFragmentMetadataMap,
    DefaultVendorFragmentMetadataMap,
    ITypeEntityFragmentSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import {
    LoadCreate,
    LoadDto,
    LoadRead,
    LoadSearchParams,
} from '../../entities/load.dto';
import {
    LoadStatus,
    LoadStatusViewModel,
    WorkflowStatus,
    WorkflowStatusViewModel,
} from '@control-tower/platform-loads';
import {
    createMap,
    forMember,
    mapFrom,
    Mapper,
    mapWith,
} from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { ChargeMetadataMap } from '../../entities/charge.dto';
import { EquipmentMetadataMap } from '../../entities/equipment.dto';
import { EventMetadataMap } from '../../entities/event.dto';
import { LoadDriverMetadataMap } from '../../entities/load-driver.dto';
import { MileMetadataMap } from '../../entities/mile.dto';
import { NoteMetadataMap } from '../../entities/notes.dto';
import { ReferenceMetadataMap } from '../../entities/reference.dto';
import { StopUnifiedMetadataMap } from '../../entities/stop.unified.dto';
import { FiscalPeriodMetadataMap } from '@pf/platform-core/domain';
import { DocumentsMappingProfile } from '@pf/shared-services';

const symbols = entitySymbols(PFLoadEntities.Load);
export const loadStatusSymbols = entitySymbols('LoadStatus');
export const workflowStatusSymbols = entitySymbols('WorkflowStatus');

withMetadata<LoadStatus>(loadStatusSymbols.entity, {
    loadStatusType: ITypeEntityFragmentSymbol,
    lastUpdated: String,
});
withMetadata<LoadStatusViewModel>(loadStatusSymbols.createBody, {
    loadStatusTypeId: String,
    lastUpdated: String,
});
withMetadata<WorkflowStatus>(workflowStatusSymbols.entity, {
    workflowStatusType: ITypeEntityFragmentSymbol,
    lastUpdated: String,
});
withMetadata<WorkflowStatusViewModel>(workflowStatusSymbols.createBody, {
    workflowStatusTypeId: String,
    lastUpdated: String,
});

withDefaultEntityMetadata<LoadDto>(symbols.dto, {
    loadNumber: String,
    loadType: ITypeEntityFragmentSymbol,
    mode: ITypeEntityFragmentSymbol,
    references: [ReferenceMetadataMap.symbols.dto],
    loadStatus: loadStatusSymbols.entity,
    workflowStatus: workflowStatusSymbols.entity,
    stops: [StopUnifiedMetadataMap.symbols.dto],
    vendors: [DefaultVendorFragmentMetadataMap.symbols.dto],
    charges: [ChargeMetadataMap.symbols.dto],
    equipment: [EquipmentMetadataMap.symbols.dto],
    notes: [NoteMetadataMap.symbols.dto],
    distances: [MileMetadataMap.symbols.dto],
    events: [EventMetadataMap.symbols.dto],
    carrier: DefaultCarrierFragmentMetadataMap.symbols.dto,
    drivers: [LoadDriverMetadataMap.symbols.dto],
    trailers: [DefaultTrailerFragmentMetadataMap.symbols.dto],
    vehicle: DefaultVehicleFragmentMetadataMap.symbols.dto,
    wmsShipmentId: String,
    wmsAsnHeaderId: String,
    customFields: [CustomFieldMetadataMap.symbols.dto],
    resourceAuth: ResourceAuthSymbol,
    documents: [DocumentsMappingProfile.symbols.dto],
    financialDate: String,
    fiscalPeriod: FiscalPeriodMetadataMap.symbols.dto,
});

withDefaultEntityMetadata<LoadRead>(symbols.entity, {
    loadNumber: String,
    loadType: ITypeEntityFragmentSymbol,
    mode: ITypeEntityFragmentSymbol,
    notes: [NoteMetadataMap.symbols.entity],
    distances: [MileMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
    wmsShipmentId: String,
    wmsAsnHeaderId: String,
    stops: [StopUnifiedMetadataMap.symbols.entity],
    vendors: [DefaultVendorFragmentMetadataMap.symbols.entity],
    charges: [ChargeMetadataMap.symbols.entity],
    references: [ReferenceMetadataMap.symbols.entity],
    equipment: [EquipmentMetadataMap.symbols.entity],
    events: [EventMetadataMap.symbols.entity],
    drivers: [LoadDriverMetadataMap.symbols.entity],
    trailers: [DefaultTrailerFragmentMetadataMap.symbols.entity],
    vehicle: DefaultVehicleFragmentMetadataMap.symbols.entity,
    loadStatus: loadStatusSymbols.entity,
    workflowStatus: workflowStatusSymbols.entity,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.entity,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    financialDate: String,
    fiscalPeriod: FiscalPeriodMetadataMap.symbols.entity,
    files: [DocumentsMappingProfile.symbols.entity],
});

withMetadata<LoadCreate>(symbols.createBody, {
    id: String,
    loadNumber: String,
    loadTypeId: String,
    modeId: String,
    notes: [NoteMetadataMap.symbols.entity],
    distances: [MileMetadataMap.symbols.createBody],
    stops: [StopUnifiedMetadataMap.symbols.createBody],
    vendors: [String],
    wmsShipmentId: String,
    wmsAsnHeaderId: String,
    charges: [ChargeMetadataMap.symbols.createBody],
    references: [ReferenceMetadataMap.symbols.createBody],
    equipment: [EquipmentMetadataMap.symbols.createBody],
    events: [EventMetadataMap.symbols.createBody],
    carrierId: String,
    drivers: [LoadDriverMetadataMap.symbols.createBody],
    trailers: [String],
    vehicleId: String,
    loadStatus: loadStatusSymbols.createBody,
    workflowStatus: workflowStatusSymbols.createBody,
    resourceAuth: ResourceAuthSymbol,
    financialDate: String,
    customFields: [CustomFieldMetadataMap.symbols.createBody],
});

withDefaultSearchParamsMetadata<LoadSearchParams>(symbols.searchParams, {
    loadNumbers: [String],
    loadStatuses: [String],
});

export const LoadMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LoadStatus, LoadStatusViewModel>(
            mapper,
            loadStatusSymbols.entity,
            loadStatusSymbols.createBody,
            forMember(
                e => e.loadStatusTypeId,
                mapFrom(s => s.loadStatusType?.id)
            )
        );
        createMap<WorkflowStatus, WorkflowStatusViewModel>(
            mapper,
            workflowStatusSymbols.entity,
            workflowStatusSymbols.createBody,
            forMember(
                e => e.workflowStatusTypeId,
                mapFrom(s => s.workflowStatusType?.id)
            )
        );

        createMap<LoadRead, LoadDto>(
            mapper,
            symbols.entity,
            symbols.dto,
            forMember(
                e => e.documents,
                mapWith(
                    DocumentsMappingProfile.symbols.dto,
                    DocumentsMappingProfile.symbols.entity,
                    source => source.files
                )
            )
        );
        createMap<LoadDto, LoadRead>(mapper, symbols.dto, symbols.entity);
        createMap<LoadDto, LoadCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                e => e.trailers,
                mapFrom(s => s.trailers?.map(t => t.id))
            ),
            forMember(
                e => e.vendors,
                mapFrom(s => s.vendors?.map(v => v.id))
            ),
            forMember(
                e => e.carrierId,
                mapFrom(s => s.carrier?.id)
            ),
            forMember(
                e => e.vehicleId,
                mapFrom(s => s.vehicle?.id)
            )
        );
        createMap<IQueryParams<LoadDto>, LoadSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            ...withDefaultSearchParamsMappingConfiguration(),
            forMember(
                d => d.stopCustomerIds,
                mapFrom(s => (s.filters as SafeAny)?.stopCustomerIds || [])
            ),
            forMember(
                d => d.stopVendorIds,
                mapFrom(s => (s.filters as SafeAny)?.stopVendorIds || [])
            ),
            forMember(
                d => d.carrierIds,
                mapFrom(s => (s.filters as SafeAny)?.carrierIds || [])
            ),
            forMember(
                d => d.loadStatuses,
                mapFrom(s =>
                    s.filters?.loadStatus
                        ? Array.isArray(s.filters.loadStatus)
                            ? s.filters.loadStatus
                            : [s.filters.loadStatus]
                        : null
                )
            ),
            forMember(
                d => d.loadNumbers,
                mapFrom(s => {
                    return s.filters?.loadNumber ? [s.filters?.loadNumber] : [];
                })
            ),
            forMember(
                d => d.search,
                mapFrom(s => {
                    return s.filters?.search;
                })
            )
        );

        ChargeMetadataMap.profile(mapper);
        CustomFieldMetadataMap.profile(mapper);
        DefaultCarrierFragmentMetadataMap.profile(mapper);
        DefaultVendorFragmentMetadataMap.profile(mapper);
        DefaultTrailerFragmentMetadataMap.profile(mapper);
        DefaultVehicleFragmentMetadataMap.profile(mapper);
        EquipmentMetadataMap.profile(mapper);
        EventMetadataMap.profile(mapper);
        LoadDriverMetadataMap.profile(mapper);
        MileMetadataMap.profile(mapper);
        NoteMetadataMap.profile(mapper);
        ReferenceMetadataMap.profile(mapper);
        StopUnifiedMetadataMap.profile(mapper);
        DocumentsMappingProfile.profile(mapper);
    },
};
