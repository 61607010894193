import { Component, Input } from '@angular/core';
import {
    FrameworkColumn,
    ITableCellValueRenderer,
    SafeAny,
} from '@pf/shared-common';

export interface ActionRendererProps {
    actionFn: (value: SafeAny, row: SafeAny) => void;
}

@Component({
    selector: 'pf-action-renderer',
    template: ` <a (click)="actionFn(value, row)">{{ value }}</a>`,
    styles: [],
})
export class ActionRendererComponent
    implements ITableCellValueRenderer, ActionRendererProps
{
    @Input() value!: SafeAny;
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;
    @Input() actionFn!: (value: SafeAny, row: SafeAny) => void;
}
