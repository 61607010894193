import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IContact } from '@pf/shared/util-platform';
import { ContactSummaryComponent } from '../templates/contact.component';

@Component({
    selector: 'platform-contacts-preview',
    template: ` <pf-list-preview-renderer
        [value]="contacts"
        [displayFn]="contactDisplayFn"
        [popoverDisplayComponent]="contactSummaryComponent">
    </pf-list-preview-renderer>`,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsPreviewComponent {
    contactSummaryComponent = ContactSummaryComponent;
    contactDisplayFn = (contact: IContact) =>
        contact.firstName + ' ' + contact.lastName;

    @Input() contacts!: IContact[];
}
