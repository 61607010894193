import { inject, TemplateRef, Type } from '@angular/core';
import { SafeAny } from '@pf/shared-common';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

export function calcDrawerHeight(level = 0): string {
    let percentage = 90;
    percentage -= level * 10;
    return percentage + '%';
}

export interface DrawerOptions<T, TResult> {
    title: string;
    component: Type<T> | TemplateRef<SafeAny>;
    componentInputs: Partial<
        T & TResult extends undefined ? SafeAny : T & TResult
    >;
    level?: number;
    extra?: string | TemplateRef<SafeAny>;
    onCancel?: () => Promise<SafeAny>;
    afterOpen?: () => void;
    afterClose?: (result: TResult) => void;
    className?: string;
}

// TODO move it to the drawer service
export const componentDrawer = <
    T extends NonNullable<unknown>,
    TResult extends SafeAny = SafeAny
>() => {
    const drawerService = inject(NzDrawerService);

    function createDrawer(opts: DrawerOptions<T, TResult>) {
        const drawerRef = drawerService.create({
            nzTitle: opts.title,
            nzExtra: opts.extra,
            nzContent: opts.component,
            nzContentParams: opts.componentInputs,
            nzClosable: true,
            nzCloseOnNavigation: false,
            nzPlacement: 'bottom',
            nzHeight: calcDrawerHeight(opts.level || 0),
            nzOnCancel: opts.onCancel,
            nzWrapClassName: opts.className,
        });

        if (opts.afterOpen) drawerRef.afterOpen.subscribe(opts.afterOpen);
        if (opts.afterClose) drawerRef.afterClose.subscribe(opts.afterClose);

        return drawerRef;
    }

    let drawerRef: NzDrawerRef<T, TResult>;
    return {
        open: (opts: DrawerOptions<T, TResult>) => {
            drawerRef = createDrawer(opts);
        },
        close: () => {
            drawerRef?.close();
        },
    };
};
