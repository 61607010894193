import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, UrlTree } from '@angular/router';
import { UserPermissionCheckService } from '@pf/shared-services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserPermissionGuard {
    canActivate(
        route: ActivatedRouteSnapshot
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.checkPermission(route);
    }

    canLoad(
        route: Route
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.checkPermission(route);
    }

    constructor(private permissionCheckService: UserPermissionCheckService) {}

    private checkPermission(route: ActivatedRouteSnapshot | Route) {
        if (!route.data || !route.data['expectedPermission']) {
            return true;
        }
        const expectedPermission = route.data['expectedPermission'];
        return this.permissionCheckService.hasOneOfPermissions(
            typeof expectedPermission === 'string'
                ? [expectedPermission]
                : expectedPermission
        );
    }
}
