<ul nz-menu nzMode="inline" [nzInlineCollapsed]="layoutService.isCollapsed">
    <ng-container
        *ngFor="let appMenu of navigation; trackBy: navigationTrackByFn">
        <ng-container *ngIf="appMenu.subMenu">
            <li
                nz-submenu
                [nzOpen]="showMenuOnOpen"
                [nzDisabled]="appMenu.disable"
                [attr.hidden]="shouldHideFn(appMenu)"
                [nzPaddingLeft]="0"
                [nzTitle]="appMenu.displayName | AppConstant"
                [nzIcon]="'fa:' + appMenu.icon">
                <ul *ngIf="appMenu.subMenu">
                    <li
                        nz-menu-item
                        [nzDisabled]="appMenu.disable"
                        [attr.hidden]="appMenu.hide || subMenu.hide || null"
                        *ngFor="
                            let subMenu of appMenu.subMenu;
                            trackBy: navigationTrackByFn
                        ">
                        <a [routerLink]="[subMenu.route]" nzMatchRouter>
                            {{ subMenu.displayName | AppConstant }}
                        </a>
                    </li>
                </ul>
            </li>
        </ng-container>
        <ng-container *ngIf="!appMenu.subMenu && appMenu.route">
            <li
                nz-menu-item
                class="pf-menu-item"
                [nzDisabled]="appMenu.disable"
                [attr.hidden]="appMenu.hide || null">
                <span pf-title>
                    <a [routerLink]="[appMenu.route]" nzMatchRouter>
                        <pf-icon
                            *ngIf="appMenu.icon"
                            [iconType]="appMenu.icon"></pf-icon
                        ><span>{{ appMenu.displayName }}</span></a
                    >
                </span>
            </li>
        </ng-container>
    </ng-container>
</ul>
