import { Injectable, WritableSignal } from '@angular/core';
import { EntityFacadeConfig, IFormFacadeConfig } from '@pf/shared/ui-platform';
import { LocationDto } from '../entities/location.dto';
import { Observable } from 'rxjs';
import { CustomFieldConfig } from '@pf/shared/util-platform';
import { TypedFormGroup } from 'ngx-sub-form';
import { SafeAny } from '@pf/shared-common';

export interface ManageLocationView {
    maxAssociatedCustomers: number;
}

@Injectable()
export abstract class LocationFacadeConfig
    extends EntityFacadeConfig<LocationDto>
    implements IFormFacadeConfig<LocationDto>
{
    abstract customFields$: Observable<CustomFieldConfig[]>;
    abstract view: WritableSignal<ManageLocationView>;

    onFormChange?(
        currentValue: LocationDto,
        formGroup: TypedFormGroup<SafeAny>
    ): void;
}
