import { IAppMenu, IAppRoutes, IAppSubMenu } from '@pf/shared-ui';

// TODO TS: fix hidden menu item bug in here
import { UserPermissionCheckService } from '@pf/shared-services';
import { firstValueFrom } from 'rxjs';
import { inject, Injectable, Injector } from '@angular/core';
import { RoutePathBuilder } from '@ngspot/route-path-builder';

@Injectable()
export abstract class PlatformRoutes
    extends RoutePathBuilder
    implements IAppRoutes
{
    userPermissionCheckService = inject(UserPermissionCheckService);
    navigation: IAppMenu[] = [];

    protected constructor() {
        super(inject(Injector));
    }

    protected setupNavigation() {
        this.checkAllMenuItemsForPermission(this.getAllMenuItems()).then(
            nav => (this.navigation = nav)
        );
    }

    private async checkAllMenuItemsForPermission(menuItems: IAppMenu[]) {
        return await Promise.all(
            menuItems.map(async item => {
                const checkedMenuItem = (await this.checkPermissionGranted(
                    item,
                    this.userPermissionCheckService
                )) as IAppMenu;
                if (item.subMenu) {
                    checkedMenuItem.subMenu = await Promise.all(
                        item.subMenu.map(
                            async subItem =>
                                (await this.checkPermissionGranted(
                                    subItem,
                                    this.userPermissionCheckService
                                )) as IAppSubMenu
                        )
                    );
                }
                return checkedMenuItem;
            })
        );
    }

    private async checkPermissionGranted(
        item: IAppMenu | IAppSubMenu,
        permissionCheckService: UserPermissionCheckService
    ) {
        if (!item.permissionType || item.permissionType === '') {
            item.hide = false;
            return item;
        }

        const hasPermission = await firstValueFrom(
            permissionCheckService.hasOneOfPermissions(
                typeof item.permissionType === 'string'
                    ? [item.permissionType]
                    : item.permissionType
            )
        );
        if (!hasPermission) {
            item.hide = true;
        }

        return item;
    }

    protected abstract getAllMenuItems(): IAppMenu[];
}
