import { ActivatedRouteSnapshot } from '@angular/router';
import { from, Observable, of, switchMap, tap } from 'rxjs';

import { DirtyCheckService } from '../services/dirty-check.service';
import { FormHostRouteData } from '../../layout';
import { FormService } from '../services/form.service';
import { Injectable } from '@angular/core';
import { SafeAny } from '@pf/shared-common';

@Injectable()
export class DirtyCheckGuard {
    constructor(
        private formService: FormService,
        private dirtyCheckService: DirtyCheckService
    ) {}

    canDeactivate(
        component: SafeAny,
        currentRoute: ActivatedRouteSnapshot
    ): Observable<boolean> {
        const data = currentRoute.data as FormHostRouteData;
        return this.formService.activeState$(data.entityType).pipe(
            switchMap(state => {
                if (state?.isDirty) {
                    return from(this.dirtyCheckService.promptConfirm()).pipe(
                        tap(cancelled => {
                            if (cancelled) {
                                this.formService.cancelled(data.entityType);
                            }
                        })
                    );
                }
                return of(true).pipe(
                    tap(() => {
                        if (state?.action === 'init') {
                            this.formService.cancelled(data.entityType);
                        }
                    })
                );
            })
        );
    }
}
