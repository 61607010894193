import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';
import {
    PermissionTypes,
    PFEntities,
    PlatformModules,
    ProfileProvider,
} from '@pf/shared-common';
import { UserPermissionCheckService } from '@pf/shared-services';

@Directive({
    selector: '[pfRequiredPermissions]',
})
export class RequiredPermissionsDirective implements AfterViewInit {
    @Input() customPermission?: string;
    @Input() permissionPlatform?: PlatformModules;
    @Input() permissionEntity?: PFEntities;
    @Input() permissionType?: PermissionTypes;
    @Input() hideElementIfNoPermission = false;

    constructor(
        private permissionCheckService: UserPermissionCheckService,
        private profileProvider: ProfileProvider,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.profileProvider.permissions$.subscribe(permissions => {
            const permissionExist = !permissions.find(
                p => p.permissionName === this.combinePermissionPath()
            );
            if (!permissionExist) {
                throw new Error('Permission does not exist');
            }
        });
    }

    ngAfterViewInit(): void {
        this.checkPermission();
    }

    private checkPermission() {
        const requiredPermission =
            this.customPermission || this.combinePermissionPath();
        if (!requiredPermission) {
            return;
        }
        this.permissionCheckService
            .checkPermission(requiredPermission)
            .subscribe(hasPermission => {
                if (hasPermission) {
                    return;
                }
                this.renderer.setProperty(
                    this.elRef.nativeElement.children[0],
                    this.hideElementIfNoPermission ? 'hidden' : 'disabled',
                    'true'
                );
                if (!this.hideElementIfNoPermission) {
                    this.renderer.setProperty(
                        this.elRef.nativeElement.children[0],
                        'title',
                        'You do not have permission to perform this action'
                    );
                }
            });
    }

    private combinePermissionPath() {
        return `${this.permissionPlatform}:${this.permissionEntity}:${this.permissionType}`;
    }
}
