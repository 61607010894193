import {
    AfterContentInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    ViewContainerRef,
} from '@angular/core';

import { AbstractPageAnchor } from './AbstractPageAnchor';
import { ContentService } from '../services/content.service';
import { InjectionService } from '../../../services/injection.service';

@Directive({
    selector: '[pfSection]',
})
export class SectionDirective
    extends AbstractPageAnchor
    implements AfterContentInit
{
    constructor(
        private contentService: ContentService,
        elementRef: ElementRef,
        viewContainerRef: ViewContainerRef,
        injectionService: InjectionService,
        cdr: ChangeDetectorRef
    ) {
        super(elementRef, viewContainerRef, injectionService, cdr);
    }

    ngAfterContentInit(): void {
        this.setDivider();
    }

    protected registerPageAnchor() {
        this.contentService.registerSection({
            title: this.title,
            id: this.id,
            articles: [],
        });
    }
}
