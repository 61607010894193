import {
    IEntity,
    IMappingProfile,
    ITypeEntityFragment,
} from '@pf/shared-common';
import { ChargeDto } from './charge.dto';
import {
    AccountType,
    ConversionRate,
    StopCharge,
    StopChargeViewModel,
    StopItemFragment,
} from '@control-tower/platform-loads';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    ConversionRateSymbol,
    ITypeEntityFragmentSymbol,
} from '@pf/shared/util-platform';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';

export interface IStopChargeDto extends IEntity, ChargeDto {}

export class StopChargeDto implements IStopChargeDto {
    constructor(data: Partial<StopChargeDto>) {
        Object.assign(this, data);
    }

    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    currencyTypeCode?: string;
    amount!: number;
    date?: string;
    conversions?: ConversionRate[];
    accountType?: AccountType;
    chargeType?: ITypeEntityFragment;
    quantity!: number;
    description?: string;
    equipmentType?: ITypeEntityFragment;
    stopItem?: StopItemFragment;
}

const symbols = entitySymbols('StopCharge');

withDefaultEntityMetadata<StopChargeDto>(symbols.dto, {
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
    accountType: String,
    chargeType: ITypeEntityFragmentSymbol,
    quantity: Number,
    description: String,
    equipmentType: ITypeEntityFragmentSymbol,
    stopItem: Object,
});

withMetadata<StopCharge>(symbols.entity, {
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
    accountType: String,
    chargeType: ITypeEntityFragmentSymbol,
    quantity: Number,
    description: String,
    equipmentType: ITypeEntityFragmentSymbol,
    stopItem: Object,
});

withMetadata<StopChargeViewModel>(symbols.createBody, {
    chargeTypeId: String,
    equipmentTypeId: String,
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
    accountType: String,
    quantity: Number,
    description: String,
    stopItemId: String,
});

export const StopChargeMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<StopCharge, StopChargeDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<StopChargeDto, StopCharge>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<StopChargeDto, StopChargeViewModel>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                x => x.chargeTypeId,
                mapFrom(source => source.chargeType?.id || null)
            ),
            forMember(
                x => x.equipmentTypeId,
                mapFrom(source => source.equipmentType?.id || null)
            ),
            forMember(
                x => x.stopItemId,
                mapFrom(source => source.stopItem?.id || null)
            )
        );
    },
};
