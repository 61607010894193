import { Injectable } from '@angular/core';
import { PFCommunicationsEntities, PermissionTypes } from '@pf/shared-common';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildCommunicationsPermissionPath } from '@pf/shared/util-platform';

@Injectable()
export class RoutesForOneTimeLink extends AbstractRouteBuilder {
    permissionType = buildCommunicationsPermissionPath(
        PermissionTypes.Modify,
        PFCommunicationsEntities.OneTimeLink
    );
}
