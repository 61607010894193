import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAIN_URL } from '@pf/shared-ui';
import { PlatformAuthService } from '@pf/shared-common';

@UntilDestroy()
@Component({
    template: '',
})
export class LoginCallbackComponent implements OnInit {
    constructor(
        private readonly _authService: PlatformAuthService,
        private readonly _router: Router,
        @Inject(MAIN_URL) private readonly _mainUrl: string
    ) {}

    onAuthLoaded() {
        this._router.navigate(['/']);
    }

    onAuthFailed() {
        this._router.navigate(['/login-failed']);
    }

    ngOnInit(): void {
        this._authService
            .getAccessToken()
            .pipe(untilDestroyed(this))
            .subscribe(token => {
                if (token) {
                    this.onAuthLoaded();
                } else {
                    this.onAuthFailed();
                }
            });

        this._authService.error$.pipe(untilDestroyed(this)).subscribe(() => {
            this.onAuthFailed();
        });
    }
}
