import { Observable } from 'rxjs';

export abstract class PlatformAuthService {
    abstract readonly isLoading$: Observable<boolean>;
    /**
     * Emits boolean values indicating the authentication state of the user. If `true`, it means a user has authenticated.
     * This depends on the value of `isLoading$`, so there is no need to manually check the loading state of the SDK.
     */
    abstract readonly isAuthenticated$: Observable<boolean>;
    /**
     * Emits details about the authenticated user, or null if not authenticated.
     */
    abstract readonly user$: Observable<{ email?: string } | null | undefined>;
    /**
     * Emits errors that occur during login, or when checking for an active session on startup.
     */
    abstract readonly error$: Observable<Error>;
    abstract login(): void;
    abstract logout(returnUrl?: string): void;

    abstract getAccessToken(): Observable<string>;
}
