import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LoadCreate,
    LoadDto,
    LoadRead,
    LoadSearchParams,
} from '../../entities/load.dto';
import { Injectable } from '@angular/core';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { LoadMetadataMap } from './load.mapping-profile';

@Injectable()
export class LoadMapper extends AbstractEntityMapper<
    LoadRead,
    LoadCreate,
    LoadDto,
    LoadSearchParams
> {
    constructor() {
        super(LoadDto, PlatformLoadsMapper, LoadMetadataMap.symbols);
    }
}
