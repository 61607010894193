<div header-content>
    <pf-text header-name type="primary">{{ column.headerName }}</pf-text>
    <pf-checkbox
        id="headerCheckbox"
        *ngIf="column.showCheckbox"
        [control]="checkboxControl"
        [label]="column.checkboxLabel"
        labelPosition="right"
        inline
    ></pf-checkbox>
</div>
