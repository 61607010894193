import { Injectable } from '@angular/core';
import { LogManagerService } from './LogManager.service';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(private logManager: LogManagerService) {}
    trace(message: string) {
        this.logManager.log.trace(message);
    }
    debug(message: string) {
        this.logManager.log.debug(message);
    }
    info(message: string) {
        this.logManager.log.info(message);
    }
    warn(message: string) {
        this.logManager.log.warn(message);
    }
    error(message: string) {
        this.logManager.log.error(message);
    }
    fatal(message: string) {
        this.logManager.log.fatal(message);
    }
}
