import {
    AssociatedAddressMetadataMap,
    ContactMetadataMap,
    CustomFieldMetadataMap,
    IdIsDefaultSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    CustomerCreate,
    CustomerDto,
    CustomerOmniSearchFieldTypes,
    CustomerRead,
    CustomerSearchParams,
} from '../../entities/customer.dto';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultOmniSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';

const symbols = entitySymbols(PFCoreEntities.Customer);
withDefaultEntityMetadata<CustomerDto>(symbols.dto, {
    customerName: String,
    customerNumber: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.dto],
    contacts: [ContactMetadataMap.symbols.dto],
    customFields: [CustomFieldMetadataMap.symbols.dto],
    resourceAuth: ResourceAuthSymbol,
});
withDefaultEntityMetadata<CustomerRead>(symbols.entity, {
    customerName: String,
    customerNumber: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
    contacts: [ContactMetadataMap.symbols.entity],
    customFields: [CustomFieldMetadataMap.symbols.entity],
});
withMetadata(symbols.createBody, {
    customerName: String,
    customerNumber: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.createBody],
    resourceAuth: ResourceAuthSymbol,
    contacts: [IdIsDefaultSymbol],
});
withDefaultSearchParamsMetadata<CustomerSearchParams>(symbols.searchParams, {
    customerName: String,
    customerNumber: String,
});

export const CustomerMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<CustomerRead, CustomerDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<CustomerDto, CustomerRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<CustomerDto, CustomerCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<CustomerDto>, CustomerSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('customerName'),
            withSearchFilter('customerNumber'),
            ...withDefaultOmniSearchParamsMappingConfiguration(
                CustomerSearchFieldMapper
            )
        );
        ContactMetadataMap.profile(mapper);
        AssociatedAddressMetadataMap.profile(mapper);
        CustomFieldMetadataMap.profile(mapper);
    },
};

export const CustomerSearchFieldMapper: Partial<
    Record<keyof CustomerDto, CustomerOmniSearchFieldTypes[]>
> = {
    customerName: ['Name'],
    customerNumber: ['Number'],
};
