import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { StatisticComponent } from './statistic.component';

@NgModule({
    imports: [CommonModule, NzStatisticModule, NzEmptyModule, NzSkeletonModule],
    declarations: [StatisticComponent],
    exports: [StatisticComponent],
})
export class VisualizationModule {}
