import { AbstractEntityMapper } from '@pf/shared-services';
import {
    FiscalPeriodCreate,
    FiscalPeriodDto,
    FiscalPeriodRead,
    FiscalPeriodSearchParams,
} from '../../entities/fiscal-period.dto';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { FiscalPeriodMetadataMap } from './fiscal-period.mapping-profile';

@Injectable()
export class FiscalPeriodMapper extends AbstractEntityMapper<
    FiscalPeriodRead,
    FiscalPeriodCreate,
    FiscalPeriodDto,
    FiscalPeriodSearchParams
> {
    constructor() {
        super(
            FiscalPeriodDto,
            PlatformCoreMapper,
            FiscalPeriodMetadataMap.symbols
        );
    }
}
