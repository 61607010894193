import {
    IAppRouter,
    PermissionTypes,
    PFCoreEntities,
    PFItemCatalogEntities,
    PFLoadEntities,
    PFTransportationEntities,
} from '@pf/shared-common';
import {
    buildCorePermissionPath,
    buildItemCatalogPermissionPath,
    CarrierAppConstants,
    CustomerAppConstants,
    ItemAppConstants,
    VendorAppConstants,
} from '@pf/shared/util-platform';
import { UserPermissionGuard } from '@pf/shared/ui-platform';
import { RoutesForLoads } from '@pf/platform-loads/domain';
import { Route } from '@angular/router';

export function MenuItemFn(displayName: string) {
    return (route: IAppRouter) => ({
        displayName: displayName,
        route: route.main().url,
        permissionType: route.permissionType,
    });
}

export const featureRoutes = {
    [PFCoreEntities.Contact]: {
        menu: MenuItemFn('Contacts'),
        route: () =>
            ({
                path: 'contacts',
                data: {
                    breadcrumb: 'Contacts',
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.Contact
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-contacts').then(
                        m => m.PlatformCoreFeatureContactsModule
                    ),
            } as Route),
    },
    [PFCoreEntities.Carrier]: {
        menu: MenuItemFn(CarrierAppConstants.Carriers),
        route: () =>
            ({
                path: 'carriers',
                data: {
                    breadcrumb: CarrierAppConstants.Carriers,
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.Carrier
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-carriers').then(
                        m => m.PlatformCoreFeatureCarriersModule
                    ),
            } as Route),
    },
    [PFCoreEntities.Vendor]: {
        menu: MenuItemFn(VendorAppConstants.Vendors),
        route: () =>
            ({
                path: 'vendors',
                data: {
                    breadcrumb: VendorAppConstants.Vendors,
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.Vendor
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-vendors').then(
                        m => m.PlatformCoreFeatureVendorModule
                    ),
            } as Route),
    },
    [PFCoreEntities.Customer]: {
        menu: MenuItemFn(CustomerAppConstants.Customers),
        route: () =>
            ({
                path: 'customers',
                data: {
                    breadcrumb: CustomerAppConstants.Customers,
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.Customer
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-customers').then(
                        m => m.PlatformCoreFeatureCustomersModule
                    ),
            } as Route),
    },
    [PFCoreEntities.Location]: {
        menu: MenuItemFn('Locations'),
        route: () =>
            ({
                path: 'locations',
                data: {
                    breadcrumb: 'Locations',
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.Location
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-locations').then(
                        m => m.PlatformCoreFeatureLocationsModule
                    ),
            } as Route),
    },
    [PFCoreEntities.User]: {
        menu: MenuItemFn('Users'),
        route: () =>
            ({
                path: 'users',
                data: {
                    breadcrumb: 'Users',
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.User
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-users').then(
                        m => m.PlatformCoreFeatureUsersModule
                    ),
            } as Route),
    },
    [PFCoreEntities.UserRole]: {
        menu: MenuItemFn('Roles'),
        route: () =>
            ({
                path: 'roles',
                data: {
                    breadcrumb: 'Roles',
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.User
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-user-roles').then(
                        m => m.PlatformCoreFeatureUserRolesModule
                    ),
            } as Route),
    },
    [PFCoreEntities.FiscalPeriod]: {
        menu: MenuItemFn('Fiscal Periods'),
        route: () =>
            ({
                path: 'fiscal-periods',
                data: {
                    breadcrumb: 'Fiscal Periods',
                    expectedPermission: buildCorePermissionPath(
                        PermissionTypes.Read,
                        PFCoreEntities.FiscalPeriod
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-core/feature-fiscal-periods').then(
                        m => m.PlatformCoreFeatureFiscalPeriodsModule
                    ),
            } as Route),
    },
    [PFItemCatalogEntities.Item]: {
        menu: MenuItemFn(ItemAppConstants.Items),
        route: () =>
            ({
                path: 'item-catalog',
                data: {
                    breadcrumb: 'Items',
                    expectedPermission: buildItemCatalogPermissionPath(
                        PermissionTypes.Read,
                        PFItemCatalogEntities.Item
                    ),
                },
                canLoad: [UserPermissionGuard],
                loadChildren: () =>
                    import('@pf/platform-item-catalog/feature-items').then(
                        m => m.PlatformItemCatalogFeatureItemsModule
                    ),
            } as Route),
    },
    [PFLoadEntities.Load]: {
        menu: MenuItemFn('Loads'),
        historyMenu: (route: RoutesForLoads) => ({
            displayName: 'Loads History',
            route: route.history().url,
            permissionType: route.permissionType,
        }),
        route: () =>
            ({
                path: 'loads',
                data: {
                    breadcrumb: 'Loads',
                },
                loadChildren: () =>
                    import('@pf/platform-loads/feature-loads').then(
                        m => m.PlatformLoadsFeatureLoadsModule
                    ),
            } as Route),
    },
    [PFTransportationEntities.Driver]: {
        menu: MenuItemFn('Drivers'),
        route: () =>
            ({
                path: 'drivers',
                data: {
                    breadcrumb: 'Drivers',
                },
                loadChildren: () =>
                    import('@pf/platform-transportation/feature-drivers').then(
                        m => m.PlatformTransportationFeatureDriversModule
                    ),
            } as Route),
    },
    [PFTransportationEntities.Equipment]: {
        menu: MenuItemFn('Equipment'),
        route: () =>
            ({
                path: 'equipment',
                data: {
                    breadcrumb: 'Equipment',
                },
                loadChildren: () =>
                    import(
                        '@pf/platform-transportation/feature-equipments'
                    ).then(
                        m => m.PlatformTransportationFeatureEquipmentsModule
                    ),
            } as Route),
    },
    [PFTransportationEntities.Trailer]: {
        menu: MenuItemFn('Trailers'),
        route: () =>
            ({
                path: 'trailers',
                data: {
                    breadcrumb: 'Trailers',
                },
                loadChildren: () =>
                    import('@pf/platform-transportation/feature-trailers').then(
                        m => m.PlatformTransportationFeatureTrailersModule
                    ),
            } as Route),
    },
    [PFTransportationEntities.Vehicle]: {
        menu: MenuItemFn('Vehicles'),
        route: () =>
            ({
                path: 'vehicles',
                data: {
                    breadcrumb: 'Vehicles',
                },
                loadChildren: () =>
                    import('@pf/platform-transportation/feature-vehicles').then(
                        m => m.PlatformTransportationFeatureVehiclesModule
                    ),
            } as Route),
    },
};
