import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseControlComponent } from './components/base-control/base-control.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { EmailActionDirective } from './directives/email-action.directive';
import { IconModule } from './modules/icon/icon.module';
import { InjectionHostDirective } from './directives/injection-host.directive';
import { InputComponent } from './components/input/input.component';
import { MapActionDirective } from './directives/map-action.directive';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { PhoneActionDirective } from './directives/phone-action.directive';
import { SafeNumberPipe } from './pipes/safe-number.pipe';
import { SelectComponent } from './components/select/select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TextComponent } from './components/text/text.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { CardModule } from './modules/card';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ChipComponent } from './components/chip.component';
import { RequiredPermissionsDirective } from './directives/required-permissions.directive';
import { SegmentedComponent } from './components/segmented.component';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { InfoBoxComponent } from './components/info-box.component';
import { ToggleLabelDirective } from './directives/toggle-label.directive';
import { TimelineDropListDirective } from './directives/timeline-drop-list.directive';
import { SafeStringPipe } from './pipes/safe-string.pipe';
import { DatePipe } from './pipes/date.pipe';
import { FirebendAdminDirective } from './directives/firebend-admin.directive';
import {
    AppConstantPipe,
    AppConstantTemplatePipe,
} from './pipes/app-constants.pipe';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { SafeArrayPipe } from './pipes/safe-array.pipe';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { StatusRendererComponent } from './shared-table/status-renderer.component';
import { ListPreviewRendererComponent } from './shared-table/list-preview-renderer.component';
import { AddressRendererComponent } from './shared-table/address-renderer.component';
import { ActionRendererComponent } from './shared-table/action-renderer.component';
import { MultiValueCellRendererComponent } from './shared-table/multi-value-cell-renderer.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzCheckboxModule,
        NzSwitchModule,
        NzInputModule,
        NzInputNumberModule,
        NzFormModule,
        NzSelectModule,
        NzTreeSelectModule,
        NzTypographyModule,
        NzButtonModule,
        NzDatePickerModule,
        NzDropDownModule,
        NzModalModule,
        NzPopoverModule,
        NzSegmentedModule,
        NzTagModule,
        IconModule,
        CardModule,
        NzDividerModule,
    ],
    declarations: [
        AppConstantPipe,
        AppConstantTemplatePipe,
        BaseControlComponent,
        CheckboxComponent,
        InputComponent,
        SwitchComponent,
        TextComponent,
        SelectComponent,
        ButtonComponent,
        DatePickerComponent,
        InjectionHostDirective,
        EmailActionDirective,
        PhoneActionDirective,
        MapActionDirective,
        RequiredPermissionsDirective,
        FirebendAdminDirective,
        TimelineDropListDirective,
        ToggleLabelDirective,
        DatePipe,
        ChipComponent,
        SegmentedComponent,
        InfoBoxComponent,
        SafeNumberPipe,
        SafeStringPipe,
        SafeArrayPipe,
        StatusRendererComponent,
        ListPreviewRendererComponent,
        AddressRendererComponent,
        ActionRendererComponent,
        MultiValueCellRendererComponent,
    ],
    exports: [
        AppConstantPipe,
        AppConstantTemplatePipe,
        BaseControlComponent,
        CheckboxComponent,
        InputComponent,
        SwitchComponent,
        TextComponent,
        SelectComponent,
        ButtonComponent,
        DatePickerComponent,
        IconModule,
        SafeNumberPipe,
        SafeStringPipe,
        SafeArrayPipe,
        InjectionHostDirective,
        EmailActionDirective,
        PhoneActionDirective,
        MapActionDirective,
        RequiredPermissionsDirective,
        FirebendAdminDirective,
        TimelineDropListDirective,
        ToggleLabelDirective,
        DatePipe,
        CardModule,
        ChipComponent,
        SegmentedComponent,
        InfoBoxComponent,
        StatusRendererComponent,
        ListPreviewRendererComponent,
        AddressRendererComponent,
        ActionRendererComponent,
        MultiValueCellRendererComponent,
    ],
})
export class SharedUiModule {}
