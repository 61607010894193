<pf-table
  [loading]='loading'
  [columns]='columns'
  [data]='tableRows$ | async | safeArray'
  [actions]='actions'
  [title]='title'>
</pf-table>

<pf-document-form
  #form
  [document]='selectedDocument'
  [saving]='saving'
  (pfSubmit)='onSubmitClick($event)'></pf-document-form>
