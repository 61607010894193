import {
    FragmentMappingConfig,
    InteroperableMetadata,
    InteroperableMetadataMap,
} from '../fragment-mapping-config';
import {
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
} from '@pf/shared-common';

import { PlatformTransportationFragments } from './platform-transportation-fragments.enum';

export interface IDriverFragment extends IEntityFragment {
    firstName?: string | null;
    lastName?: string | null;
    driverNumber?: string | null;
}

export interface IDriverFragmentDto
    extends IDriverFragment,
        IEntityFragmentDto {}

export function DriverFragmentMetadataMap<
    TFragmentDto extends IDriverFragmentDto,
    TFragment extends IDriverFragment
>(config: FragmentMappingConfig<TFragmentDto, TFragment>): IMappingProfile {
    const interoperableMetadata = {
        driverNumber: String,
        firstName: String,
        lastName: String,
    } as InteroperableMetadata<TFragmentDto, TFragment>;
    return InteroperableMetadataMap(
        PlatformTransportationFragments.Driver,
        interoperableMetadata,
        config
    );
}

export const DefaultDriverFragmentMetadataMap = DriverFragmentMetadataMap<
    IDriverFragmentDto,
    IDriverFragment
>({});
