import { SearchStrategy } from './IOmniSearchParams';
import { SafeAny } from '../types/SafeTypes';

export type SortField<T> = keyof T | string | null;
export type SortOrder = 'ascend' | 'descend' | null;
export type QueryFilters<T> =
    | Partial<Record<keyof T | 'search', string | string[]>>
    | undefined;

export interface IQueryParams<T = unknown> {
    pageIndex: number;
    pageSize: number;
    sortField?: SortField<T>;
    sortOrder?: SortOrder;
    filters?: QueryFilters<T>;
    searchStrategy?: SearchStrategy;
    searchValue?: string;
    searchFields?: string[];
}

export const IQueryParamsSymbol = 'IQueryParams';
export const IQueryParamsMetadata = {
    pageIndex: Number,
    pageSize: Number,
    sortField: String,
    sortOrder: String,
    filters: Object,
};

export interface FilterOption<T = unknown> {
    label: string;
    value: string | number;
    default?: boolean;
    initial?: boolean;
    filterFn?: (...args: SafeAny[]) => QueryFilters<T>;
}
