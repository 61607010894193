import { IEntity, IManageEntityFacade, ISearchFacade } from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    inject,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import {
    catchError,
    first,
    Observable,
    of,
    race,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { FormService } from '../services/form.service';
import { ActivatedRoute } from '@angular/router';
import { EntityNotificationService } from '../services/entity-notification.service';

@UntilDestroy()
@Component({
    selector: 'pf-entity-base-edit',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class EntityBaseEditComponent<TEntity extends object & IEntity>
    implements OnInit
{
    private readonly activatedRoute = inject(ActivatedRoute);
    protected readonly formService = inject(FormService);
    private readonly entityNotifications = inject(EntityNotificationService);
    protected readonly cdr = inject(ChangeDetectorRef);

    abstract entityType: string;
    entityName?: string;
    drawerRendered = true;
    notifyOnEditCompletion = true;

    @Input() isChild = false;
    @Input() entity?: TEntity;

    // Arguments are optional to make Angular happy; however, they are required by derived classes
    protected constructor(
        @Optional()
        @Inject('ignore')
        protected facade: IManageEntityFacade<TEntity> & ISearchFacade<TEntity>
    ) {}

    ngOnInit(): void {
        this.checkIfDrawerRendered();
        this.listenForEditCompletion();
        this.cdr.markForCheck();
        setTimeout(() => {
            this.loadActiveEntity();
            if (!this.isChild) {
                this.loadEntityFromParams();
            }
        });
    }

    abstract newEntityFn$(): Observable<TEntity>;

    protected saveEntity(
        entity: TEntity,
        afterSaveFn?: (savedEntity: TEntity) => void
    ) {
        // this.formService.notifyState(this.entityType, { entity });
        this.facade
            .save$(entity)
            .pipe(
                first(),
                catchError(err => {
                    this.formService.notifyError(this.entityType);
                    return throwError(err);
                }),
                tap(() => {
                    this.notifyEntitySubmitted(entity);
                }),
                tap(savedEntity => {
                    if (afterSaveFn) {
                        afterSaveFn(savedEntity);
                    }
                }),
                tap(() => {
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }

    protected notifyEntitySubmitted(entity: TEntity) {
        this.formService.submitted(this.entityType, entity);
    }

    // can be overridden in derived class
    protected afterEditFn() {
        this.facade.manage();
    }

    private checkIfDrawerRendered() {
        this.drawerRendered = this.formService.isDrawerRendered(
            this.entityType
        );
    }

    private loadEntityFromParams() {
        const entityId = this.activatedRoute.snapshot.params['id'];
        if (entityId) {
            this.facade.load(entityId);
        } else {
            // fallback lookup
            const urlParts = location.pathname.split('/');
            const editIndex = urlParts.findIndex(part => part === 'edit');
            if (editIndex > 0) {
                console.log(
                    'Using fallback id lookup and found id',
                    urlParts[editIndex - 1]
                );
                this.facade.load(urlParts[editIndex - 1]);
            }
        }
    }

    private listenForEditCompletion() {
        race(
            this.formService.cancelled$(this.entityType),
            this.formService.submitted$(this.entityType)
        )
            .pipe(
                first(),
                tap(() => {
                    if (this.drawerRendered) {
                        this.facade.resetActive();
                    } else {
                        this.formService.resetForm(this.entityType);
                    }
                })
            )
            .subscribe(() => {
                this.afterEditFn();
            });

        if (!this.drawerRendered && this.notifyOnEditCompletion) {
            this.entityNotifications.notifyOnEntityUpdated(
                this.facade,
                this.entityType,
                this.entityName || this.entityType,
                untilDestroyed(this)
            );
        }
    }

    private loadActiveEntity() {
        if (this.entity) {
            return;
        }
        this.facade.active$
            .pipe(
                untilDestroyed(this),
                switchMap(entity => {
                    if (entity) return of(entity);
                    return this.newEntityFn$();
                }),
                tap(entity =>
                    this.formService.notifyState(this.entityType, { entity })
                )
            )
            .subscribe(entity => {
                this.entity = entity;
            });
    }
}
