import { IEntity } from '@pf/shared-common';
import {
    TrailerViewModelRead,
    TrailerViewModelCreateBody,
    TrailerSearchRequestParams,
} from '@control-tower/platform-transportation';
import {
    ICarrierFragmentDto,
    ILocationFragmentDto,
} from '@pf/shared/util-platform';

export type TrailerRead = TrailerViewModelRead & IEntity;
export type TrailerCreate = TrailerViewModelCreateBody;
export type TrailerSearchParams = TrailerSearchRequestParams;

export class TrailerDto implements IEntity {
    constructor(data: Partial<TrailerDto>) {
        Object.assign(this, data);
    }
    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    trailerNumber!: string;
    description?: string;
    carrier!: ICarrierFragmentDto;
    location?: ILocationFragmentDto;
}
