import { Injectable, inject } from '@angular/core';
import { AuthState } from '@auth0/auth0-angular';

@Injectable({
    providedIn: 'root',
})
export class OneTimeLinkService {
    private _token: string | null = null;
    private _oneTimeLinkId: string | null = null;

    private tokenLocalStorageKey = 'token';
    private oneTimeLinkIdLocalStorageKey = 'oneTimeLinkId';

    authState = inject(AuthState);

    get isAuthenticated(): boolean {
        return this.token !== null;
    }

    set token(token: string | null) {
        if (token === null) {
            localStorage.removeItem(this.tokenLocalStorageKey);
        } else {
            localStorage.setItem(this.tokenLocalStorageKey, token);
        }
        this._token = token;
    }

    get token(): string | null {
        if (!localStorage) {
            return this._token;
        }

        const storedToken = localStorage.getItem(this.tokenLocalStorageKey);
        if (storedToken) {
            this._token = storedToken;
        }

        return this._token;
    }

    set oneTimeLinkId(oneTimeLinkId: string | null) {
        if (oneTimeLinkId === null) {
            localStorage.removeItem(this.oneTimeLinkIdLocalStorageKey);
        } else {
            localStorage.setItem(
                this.oneTimeLinkIdLocalStorageKey,
                oneTimeLinkId
            );
        }
        this._oneTimeLinkId = oneTimeLinkId;
    }

    get oneTimeLinkId(): string | null {
        if (!localStorage) {
            return this._oneTimeLinkId;
        }

        const storedOneTimeLinkId = localStorage.getItem(
            this.oneTimeLinkIdLocalStorageKey
        );
        if (storedOneTimeLinkId) {
            this._oneTimeLinkId = storedOneTimeLinkId;
        }

        return this._oneTimeLinkId;
    }

    clear() {
        this.oneTimeLinkId = null;
        this.token = null;
    }
}
