import { ModuleWithProviders, NgModule } from '@angular/core';

import {
    AppHeaderComponent,
    MAIN_URL,
} from './components/app-layout/app-header.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { AppNavMenuComponent } from './components/app-layout/app-nav-menu.component';
import { ArticleDirective } from './directives/article.directive';
import { CommonModule } from '@angular/common';
import { ContentAsideComponent } from './components/content-layout/content-aside.component';
import { ContentComponent } from './components/content-layout/content.component';
import { ContentHeaderComponent } from './components/content-layout/content-header.component';
import { ContentPageAnchorComponent } from './components/content-layout/content-page-anchor.component';
import { IconModule } from '../icon';
import { InjectionService } from '../../services/injection.service';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { PageHeaderComponent } from './components/content-layout/page-header.component';
import { RouterModule } from '@angular/router';
import { SectionDirective } from './directives/section.directive';
import { SharedUiModule } from '../../shared-ui.module';
import { TenantSelectorComponent } from './components/app-layout/tenant-selector.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { PreviousRouteService } from './services/previous-route.service';
import { ContentCollapsibleComponent } from './components/content-layout/content-collapsible.component';

@NgModule({
    imports: [
        CommonModule,
        NzLayoutModule,
        NzBreadCrumbModule,
        NzPageHeaderModule,
        NzMenuModule,
        NzAnchorModule,
        NzDropDownModule,
        NzDrawerModule,
        NzCollapseModule,
        RouterModule,
        IconModule,
        SharedUiModule,
    ],
    declarations: [
        AppLayoutComponent,
        AppHeaderComponent,
        AppNavMenuComponent,
        ContentComponent,
        ContentHeaderComponent,
        PageHeaderComponent,
        ContentAsideComponent,
        ContentPageAnchorComponent,
        SectionDirective,
        ArticleDirective,
        TenantSelectorComponent,
        ContentCollapsibleComponent,
    ],
    exports: [
        AppLayoutComponent,
        AppHeaderComponent,
        AppNavMenuComponent,
        ContentComponent,
        ContentHeaderComponent,
        PageHeaderComponent,
        ContentAsideComponent,
        ContentPageAnchorComponent,
        SectionDirective,
        ArticleDirective,
        TenantSelectorComponent,
        ContentCollapsibleComponent,
    ],
})
export class LayoutModule {
    static forRoot(mainUrl: string): ModuleWithProviders<LayoutModule> {
        return {
            ngModule: LayoutModule,
            providers: [
                InjectionService,
                {
                    provide: MAIN_URL,
                    useValue: mainUrl,
                },
                PreviousRouteService,
            ],
        };
    }
}
