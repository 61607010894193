import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { TextType } from '@pf/shared-common';

@Component({
    selector: 'pf-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent {
    @Input() type!: TextType;
    @Input() ellipsis = false;
    @HostBinding('style.--pf-text-line-clamp') @Input() ellipsisRows = 2;

    get nzType() {
        if (this.type === 'description') {
            return 'secondary';
        }
        const acceptedTypes = ['secondary', 'warning', 'danger', 'success'];
        return acceptedTypes.indexOf(this.type as string) > -1
            ? (this.type as
                  | 'secondary'
                  | 'warning'
                  | 'danger'
                  | 'success'
                  | undefined)
            : undefined;
    }

    constructor(private cdr: ChangeDetectorRef) {}
}
