import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { sharedEnv, TenantProvider } from '@pf/shared-common';
import { APP_TENANT_ID } from '@pf/shared-services';

@Injectable()
export class TenantHeaderInterceptor implements HttpInterceptor {
    constructor(
        private tenantProvider: TenantProvider,
        @Optional() @Inject(APP_TENANT_ID) private appTenantId: string
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (
            !request.url
                .toLowerCase()
                .startsWith(sharedEnv.baseApiUrl.toLowerCase())
        ) {
            return next.handle(request);
        }
        if (this.tenantProvider.tenant) {
            return this.appendHeaders(
                request,
                next,
                this.tenantProvider.tenant.id as string
            );
        }
        if (this.appTenantId) {
            return this.appendHeaders(request, next, this.appTenantId);
        }

        return next.handle(request);
    }

    private appendHeaders(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        tenantId: string
    ) {
        let headers = request.headers || new HttpHeaders();
        headers = headers.append('x-ct-tenant', tenantId);
        return next.handle(request.clone({ headers: headers }));
    }
}
