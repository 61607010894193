import { IEntity, ITypeEntityFragment } from '@pf/shared-common';
import {
    ContactSearchRequestParams,
    ContactViewModelCreateBody,
    ContactViewModelRead,
} from '@control-tower/platform-core';
import { IContact } from '../models/IContact';

export type ContactRead = ContactViewModelRead & IEntity;
export type ContactCreate = ContactViewModelCreateBody;
export type ContactSearchParams = ContactSearchRequestParams;

export class ContactDto implements IContact {
    constructor(data: Partial<IContact>) {
        Object.assign(this, data);
        if (!this.fullName) {
            this.fullName = `${this.firstName || ''} ${
                this.lastName || ''
            }`.trim();
        }
        if (!this.id) {
            this.id = this.fullName;
        }
    }

    id!: string;
    fullName!: string;
    firstName!: string;
    lastName!: string;
    email?: string;
    phone?: string;
    fax?: string;
    description?: string;
    contactType?: ITypeEntityFragment;
    isDefault = false;
}
