import { LoadDto } from '../../entities/load.dto';
import { TypeMatch } from '@pf/shared/util-platform';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IFormFacadeConfig,
    ManageFacadeConfig,
    ManagePageView,
} from '@pf/shared/ui-platform';

export interface LoadNote {
    label: string;
    rows: number;
    note?: string;
}

export interface LoadView extends ManagePageView {
    hideDetailedHistoryExport?: boolean;
    hideAddLoadButton?: boolean;
    manageMileage?: boolean;
    manageDrivers?: boolean;
    manageVehicles?: boolean;
    manageTrailers?: boolean;
    canAddStops?: boolean;
    canReorderStops?: boolean;
}

export interface OneTimeLinkConfig {
    carrierRoleId: string;
    vendorRoleId: string;
}

@Injectable()
export abstract class LoadManageFacadeConfig
    extends ManageFacadeConfig<LoadDto, LoadView>
    implements IFormFacadeConfig<LoadDto>
{
    abstract equipmentTypes$?: Observable<TypeMatch[]>;
    abstract notes$?: Observable<LoadNote[]>;
    abstract showMileageInput: boolean;
    abstract oneTimeLinkConfig$(): Observable<OneTimeLinkConfig>;

    onFormChange?(currentValue: LoadDto): void;
}
