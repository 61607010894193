import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFCoreEntities } from '@pf/shared-common';
import { UserRoleRead } from '../../entities/user-role.dto';

@Injectable()
export class UserRoleStore extends AbstractEntityStore<UserRoleRead> {
    constructor() {
        super(PFCoreEntities.UserRole);
    }
}
