import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCommunicationsEntities,
} from '@pf/shared-common';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    OneTimeLinkCreate,
    OneTimeLinkDto,
    OneTimeLinkRead,
    OneTimeLinkSearchParams,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import { Mapper, createMap } from '@automapper/core';

const symbols = entitySymbols(PFCommunicationsEntities.OneTimeLink);

withDefaultEntityMetadata<OneTimeLinkDto>(symbols.dto, {
    expirationDate: String,
    recipientAddress: String,
    subject: String,
    recipientRoles: [String],
    templateUrl: String,
    templateData: Object,
    sendEmailImmediately: Boolean,
    associatedEntityId: String,
    userId: String,
    recipientResourceAuth: ResourceAuthSymbol,
    isActive: Boolean,
    recipientUserGroupType: String,
});

withDefaultEntityMetadata<OneTimeLinkRead>(symbols.entity, {
    expirationDate: String,
    recipientAddress: String,
    subject: String,
    recipientRoles: [String],
    templateUrl: String,
    templateData: Object,
    sendEmailImmediately: Boolean,
    associatedEntityId: String,
    userId: String,
    resourceAuth: ResourceAuthSymbol,
    recipientResourceAuth: ResourceAuthSymbol,
    recipientUserGroupType: String,
});

withMetadata<OneTimeLinkCreate>(symbols.createBody, {
    expirationDate: String,
    recipientAddress: String,
    subject: String,
    recipientRoles: [String],
    templateUrl: String,
    templateData: Object,
    sendEmailImmediately: Boolean,
    associatedEntityId: String,
    resourceAuth: ResourceAuthSymbol,
    recipientResourceAuth: ResourceAuthSymbol,
    recipientUserGroupType: String,
});

withDefaultSearchParamsMetadata<OneTimeLinkSearchParams>(symbols.searchParams, {
    associatedEntityId: String,
});

export const OneTimeLinkMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<OneTimeLinkRead, OneTimeLinkDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<OneTimeLinkDto, OneTimeLinkRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<OneTimeLinkDto, OneTimeLinkCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<OneTimeLinkDto>, OneTimeLinkSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('associatedEntityId'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
