import {
    CustomerCreate,
    CustomerDto,
    CustomerRead,
    CustomerSearchParams,
} from '../../entities/customer.dto';

import { AbstractEntityMapper } from '@pf/shared-services';
import { CustomerMetadataMap } from './customer.mapping-profile';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';

@Injectable()
export class CustomerMapper extends AbstractEntityMapper<
    CustomerRead,
    CustomerCreate,
    CustomerDto,
    CustomerSearchParams
> {
    constructor() {
        super(CustomerDto, PlatformCoreMapper, CustomerMetadataMap.symbols);
    }
}
