import { ITypeEntity } from '../models/ITypeEntity';
import { IPagedSearchParams } from '../models/IPagedSearchParams';
import { IEntity } from '../models/IEntity';
import { v4 as uuidv4 } from 'uuid';

export enum DocumentRequirementLevelType {
    Unknown = '',
    Recommended = 'Recommended',
    Required = 'Required',
}

export interface IDocumentSearchParams extends IPagedSearchParams {
    fileDescription?: string;
    fileName?: string;
    mimeType?: string;
    documentTypeId?: string;
    includeExpired?: boolean;
    search?: string;
}

export interface IDocument {
    id: string;
    fileId?: string;
    url: string | null;
    isDefault?: boolean;
    description?: string | null;
    notes?: string | null;
    fileName: string | null;
    mimeType: string | null;
    documentType: ITypeEntity;
    expirationDate?: string | null;
    createdDate?: string;
    modifiedDate?: string;
    isDeleted?: boolean;
}

export interface IDocumentDto extends IEntity {
    url?: string;
    file?: File;
    documentTypeId: string;
    documentTypeName: string;
    documentTypeCode?: string;
    requirementLevel: DocumentRequirementLevelType;
    fileId?: string;
    fileName?: string;
    notes?: string;
    description?: string | null;
    expirationDate?: string | Date;
}

export class DocumentDto implements IDocumentDto {
    constructor(data: Partial<IDocumentDto>) {
        Object.assign(this, data);
        if (!this.id) this.id = uuidv4();
    }

    id!: string;
    requirementLevel!: DocumentRequirementLevelType;
    documentTypeId!: string;
    documentTypeName!: string;
    expirationDate?: string | Date;
    file?: File;
    fileId?: string;
    fileName?: string;
    notes?: string;
    url?: string;
    isDeleted?: boolean;
}

export interface IDocumentCreate {
    file?: File;
    description?: string;
    isDefault?: boolean;
    notes?: string;
    fileName?: string;
    expirationDate?: string;
    documentTypeId: string;
    isPublic?: boolean;
}

export interface IDocumentTemplate {
    typeFragment: ITypeEntity;
    requirementLevel: DocumentRequirementLevelType;
    allowedQuantity: number;
}
