<form [formGroup]="form.formGroup" nz-form>
    <section pfSection pfTitle="General Information" [disableAnchor]="true">
        <div class="pf-form-group">
            <pf-input
                label="Email"
                inputType="email"
                [controlName]="form.formControlNames.email"
                placeholder="email@yourdomain.com"></pf-input>
        </div>
    </section>
</form>
