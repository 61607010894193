import {
    UserRoleSearchRequestParams,
    UserRoleViewModelBase,
    UserRoleViewModelRead,
} from '@control-tower/platform-core';

import { IEntity, IPagedSearchParams } from '@pf/shared-common';
import { mapToClassSafe } from '@pf/shared-utility';
import { IUserPermissionDto, UserPermissionDto } from './user-permission.dto';

export type UserRoleRead = UserRoleViewModelRead & IEntity;
export type UserRoleCreate = UserRoleViewModelBase;
export type UserRoleSearchParams = UserRoleSearchRequestParams &
    IPagedSearchParams;

export interface IUserRoleDto extends IEntity {
    roleName?: string;
    description?: string;
    permissions?: IUserPermissionDto[];
}

export class UserRoleDto implements IUserRoleDto {
    constructor(data: Partial<IUserRoleDto>) {
        Object.assign(this, data);
        this.permissions = mapToClassSafe(data.permissions, UserPermissionDto);
    }
    roleName!: string;
    description?: string;
    permissions?: UserPermissionDto[];
    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    readOnly?: boolean;
}
