import { IQueryParams, SortOrder } from '../models/QueryParams';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

import { SafeAny } from './SafeTypes';
import { TextType } from './text.types';
import { Type } from '@angular/core';
import { ValueFormatterFn } from './value-formatter.types';
import { IEntity } from '../models/IEntity';
import { IColumnSearchFacade } from '../interfaces/IColumnSearchFacade';

export interface TableAction<TRowData = unknown> {
    tooltip?: string;
    name: string;
    textType: TextType;
    disable?: boolean;
    hide?: boolean;
    onClick: (row: TRowData, index: number) => void;
    onRowChange?: (row: TRowData, action: TableAction<TRowData>) => void;
}

export type TableValueType =
    | 'string'
    | 'number'
    | 'date'
    | 'time'
    | 'datetime'
    | 'checkbox'
    | 'currency';

export interface ITableCellValueRenderer {
    value?: SafeAny;
    column: FrameworkColumn;
    row: SafeAny;
}

export interface TableOptions<TRowData = SafeAny> {
    rowClassRules?: Record<string, (row: TableRow<TRowData>) => boolean>;
    additionalQueryParams?: (
        params: Readonly<TableQueryParams<TRowData>>
    ) => Readonly<TableQueryParams<TRowData>>;
    canRowExpand?: (row: TRowData) => boolean;
    getChildNodes?: (row: TRowData) => TRowData[];
}

export class TableRow<TRowData = SafeAny> {
    private _classes: string[] = [];
    children?: TableRow<TRowData>[];
    expanded = false;

    constructor(
        public index: number,
        public data: TRowData,
        public expandable: boolean,
        tableOptions: TableOptions<TRowData> | null,
        public parent?: TableRow<TRowData>
    ) {
        if (tableOptions) {
            this.setClasses(tableOptions.rowClassRules);
            this.expandable = tableOptions.canRowExpand
                ? tableOptions.canRowExpand(data)
                : expandable;
            this.setTreeData(tableOptions);
        }
    }

    get classes() {
        return this._classes;
    }

    get level(): number {
        return this.parent ? this.parent.level + 1 : 0;
    }

    get indentSize(): number {
        return this.level * 20;
    }

    private setTreeData(tableOptions: TableOptions<TRowData>) {
        if (this.expandable && tableOptions.getChildNodes) {
            const childNodes = tableOptions.getChildNodes(this.data);
            if (childNodes && childNodes.length > 0) {
                this.children = childNodes.map(
                    (childNode, index) =>
                        new TableRow(
                            index,
                            childNode,
                            this.expandable,
                            tableOptions,
                            this
                        )
                );
            }
        }
    }

    private setClasses(
        rowClassRules?: Record<string, (row: TableRow<TRowData>) => boolean>
    ) {
        if (!rowClassRules) {
            this._classes = [];
            return;
        }
        this._classes = Object.keys(rowClassRules).filter((className: string) =>
            rowClassRules[className](this)
        );
    }
}

export interface TableColumn<TRowData> {
    field: keyof TRowData | [keyof TRowData, string];
    type?: TableValueType;
    headerName: string;
    sortOrder?: SortOrder | null;
    showSort?: boolean;
    search?: boolean;
    showCheckbox?: boolean;
    checkboxLabel?: string;
    checkedChange?: (row: TableRow, checked: boolean) => void;
    width?: number;
    exportValueFormatter?: ValueFormatterFn;
    valueFormatter?: ValueFormatterFn;
    component?: Type<ITableCellValueRenderer>;
    componentProps?: Record<string, SafeAny>;
    listFilterFacade?: IColumnSearchFacade<IEntity>;
    key?: string;
    serverSearch?: boolean;
}

export const getTableRowValue = <TRowData>(
    column: { field: TableColumn<SafeAny>['field'] },
    row: TRowData
) => {
    if (!row) {
        return '';
    }
    if (Array.isArray(column.field)) {
        return (
            column.field.reduce((acc, curr) => {
                return (acc as SafeAny)?.[curr];
            }, row) || ''
        );
    } else {
        return (row as SafeAny)[column.field] || '';
    }
};

export interface TableQueryParams<T = unknown> extends IQueryParams<T> {
    serverRendered?: boolean;
    checkboxes?: Record<keyof T, boolean> | undefined;
}

export interface FrameworkColumn {
    field: string;
    headerName: string;
    sortOrder: NzTableSortOrder;
    sortDirections: NzTableSortOrder[];
    sortFn: NzTableSortFn | true;
    search: boolean;
    searchValue: string;
    width: string | null;
    type: TableValueType;
    showCheckbox: boolean;
    checkboxLabel: string;
    checkedChange: (row: TableRow, checked: boolean) => void;
    checked?: boolean | null;
    valueFormatter: ValueFormatterFn;
    component?: Type<ITableCellValueRenderer>;
    componentProps?: Record<string, SafeAny>;
    listFilterFacade?: IColumnSearchFacade<IEntity>;
    key?: string;
    serverSearch?: boolean;
}
