import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconComponent } from './icon.component';
import { NgModule } from '@angular/core';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
    imports: [CommonModule, FontAwesomeModule, NzPopoverModule],
    declarations: [IconComponent],
    exports: [IconComponent],
})
export class IconModule {}
