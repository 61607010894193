import { Injectable } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    StopItemCreate,
    StopItemDto,
    StopItemRead,
    StopItemSearchParams,
} from '../entities/stop-item.dto';
import { StopItemDataService } from '../infrastructure/stop-items/stop-item.data.service';
import { RoutesForStopItems } from './stop-item.routes';
import { StopItemStore } from '../infrastructure/stop-items/stop-item.store';
import { StopItemMapper } from '../infrastructure/stop-items/stop-item.mapper';
import { map, Observable } from 'rxjs';
import { IQueryParams } from '@pf/shared-common';

@Injectable()
export class StopItemFacade extends AbstractManageEntityFacade<
    StopItemDto,
    StopItemRead,
    StopItemCreate,
    StopItemSearchParams
> {
    nameField: keyof StopItemDto = 'id';

    textSearchFilter(): Partial<Record<keyof StopItemDto, string>> {
        throw new Error('Method not implemented.');
    }

    constructor(
        dataService: StopItemDataService,
        routes: RoutesForStopItems,
        store: StopItemStore,
        mapper: StopItemMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: StopItemCreate, dto: StopItemDto) {
        body.resourceAuth = {
            allAuthorized: true, // TODO
        };
    }

    getManyByStopId(stopId: string): StopItemDto[] {
        return this.store
            .filter((stopItem: StopItemDto) => stopItem.stopId === stopId)
            .map(item => new StopItemDto({ ...(item as StopItemDto) }));
    }

    getManyByStopId$(stopId: string): Observable<StopItemDto[]> {
        return this.search$({
            filters: {
                stopId,
                isDeleted: false.toString(),
            },
            pageIndex: 1,
            pageSize: 50,
        } as IQueryParams<StopItemDto>).pipe(
            map(result => {
                return result.data.map(
                    item => new StopItemDto({ ...(item as StopItemDto) })
                );
            })
        );
    }
}
