import { Component } from '@angular/core';
import { AppRouter } from './app.routes';

@Component({
    selector: 'pf-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'Control Tower Demo';
    constructor(public appRouter: AppRouter) {}
}
