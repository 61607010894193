import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
} from '@angular/core';
import {
    EntityChangeTrackingViewModel,
    IChangeTrackingFacade,
} from '@pf/shared-common';
import {
    computeChangeText,
    computeChangedFields,
} from '@pf/shared/util-platform';

import { TableColumnBuilder } from '@pf/shared-ui';

@Component({
    selector: 'platform-detailed-history-view',
    templateUrl: './detailed-history-view.component.html',
    styleUrls: ['./detailed-history-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedHistoryViewComponent {
    private _cdr = inject(ChangeDetectorRef);
    @Input() changeTrackingFacade!: IChangeTrackingFacade;
    @Input() entityName!: string;

    columns = new TableColumnBuilder<EntityChangeTrackingViewModel<unknown>>()
        .withColumn({
            field: 'changes',
            headerName: 'Changes',
            valueFormatter: (
                _,
                record: EntityChangeTrackingViewModel<unknown>
            ) => {
                return record.changes?.length
                    ? computeChangeText(record.changes) +
                          ' - ' +
                          computeChangedFields(record.changes)
                    : '';
            },
        })
        .withColumn({
            field: 'userEmail',
            headerName: 'User',
        })
        .withColumn({
            field: 'createdDate',
            headerName: 'Time Changed',
            type: 'datetime',
        }).columns;

    onDataChanged() {
        this._cdr.markForCheck();
    }
}
