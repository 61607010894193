import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    IEntity,
    ISearchFacade,
    SafeAny,
    SelectOption,
    TableAction,
    TableColumn,
    TableOptions,
} from '@pf/shared-common';
import { TableActionsBuilder } from '@pf/shared-ui';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { first } from 'rxjs';

export type AssociatedEntitiesForm<TDto extends IEntity> = {
    entities: TDto[];
    selector: string;
};

@Component({
    selector: 'platform-associated-entities-form',
    templateUrl: './associated-entities-form.component.html',
    styleUrls: ['./associated-entities-form.component.scss'],
    providers: [...subformComponentProviders(AssociatedEntitiesFormComponent)],
})
export class AssociatedEntitiesFormComponent<
    TFragment extends IEntity,
    TSearchDto extends IEntity
> {
    private _addedEntities = new Set<string>();
    private _cdr = inject(ChangeDetectorRef);

    @Input() title = '';
    @Input() loading = true;
    @Input() tree = false;
    @Input() columns!: TableColumn<TFragment>[];
    @Input() facade!: ISearchFacade<TSearchDto>;
    @Input() fragmentFn!: (entity: TSearchDto) => SafeAny;
    @Input() maxAssociations = 0;
    @Input() maxAssociationsSelectTitle = '';
    @Input() selectTitle = 'Select an option to add';

    get disableSelector() {
        return (
            this.maxAssociations > 0 &&
            this.value.filter(e => !e.isDeleted).length >= this.maxAssociations
        );
    }

    get value() {
        return this.form.formGroup.value.entities;
    }

    actions: TableAction<TFragment>[] =
        new TableActionsBuilder<TFragment>().withDeleteAction(
            this.deleteEntity.bind(this)
        ).actions;

    tableOptions: TableOptions<TFragment> = {
        rowClassRules: {
            added: row => this._addedEntities.has(row.data.id),
            deleted: row => !!row.data.isDeleted,
        },
    };

    public form = createForm<TFragment[], AssociatedEntitiesForm<TFragment>>(
        this,
        {
            formType: FormType.SUB,
            formControls: {
                entities: new FormControl([]),
                selector: new FormControl(''),
            },
            toFormGroup: (data: TFragment[]) => ({
                entities: data || [],
                selector: '',
            }),
            fromFormGroup: (formValue: AssociatedEntitiesForm<TFragment>) =>
                formValue.entities as TFragment[],
        }
    );

    addEntity(option: SelectOption | undefined) {
        if (!option) {
            return;
        }
        if (
            this.form.formGroup.controls.entities.value.some(
                x => x.id === option.value
            )
        ) {
            return;
        }
        this.facade
            .getById$(option.value)
            .pipe(first())
            .subscribe(entity => {
                if (!entity) {
                    console.error('Entity not found', option.value);
                    return;
                }
                this._addedEntities.add(option.value);
                this.form.formGroup.controls.entities.setValue([
                    this.fragmentFn(entity),
                    ...this.form.formGroup.controls.entities.value,
                ]);
            });
    }

    updateEntity(entity: TFragment, index: number) {
        this.form.formGroup.controls.entities.value[index] = entity;
        this.form.formGroup.controls.entities.setValue([
            ...this.form.formGroup.controls.entities.value,
        ]);
        this.form.formGroup.controls.entities.markAsDirty();
    }

    deleteEntity(entity: TFragment, entityIndex: number) {
        const existingValue = [...this.form.formGroup.controls.entities.value];

        if (this._addedEntities.has(entity.id)) {
            this._addedEntities.delete(entity.id);
            existingValue.splice(entityIndex, 1);
        } else {
            existingValue[entityIndex] = {
                ...existingValue[entityIndex],
                isDeleted: true,
            };
        }
        this.form.formGroup.controls.entities.setValue(existingValue);
        this.form.formGroup.controls.entities.markAsDirty();
        setTimeout(() => this._cdr.detectChanges(), 0);
    }
}
