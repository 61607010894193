import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { ContactRead } from './/contact.dto';
import { PFCoreEntities } from '@pf/shared-common';

@Injectable()
export class ContactStore extends AbstractEntityStore<ContactRead> {
    constructor() {
        super(PFCoreEntities.Contact);
    }
}
