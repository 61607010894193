import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResultMap, ResultProps, ResultType } from './result-types';
import { AnimationItem } from 'lottie-web';
import { Router } from '@angular/router';
import { capitalize } from 'lodash-es';

@Component({
    selector: 'pf-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultComponent {
    @Input() resultProps!: ResultProps;
    @Input() recommendedUrl = '';
    @Input() set resultType(resultType: keyof typeof ResultType) {
        this.setResultProps(ResultType[resultType]);
    }
    @Input() hideHomeButton = false;

    setResultProps(resultType: ResultType): void {
        if (!ResultMap.has(resultType)) {
            throw new Error(`Result not found! ${ResultType[resultType]}`);
        }
        this.resultProps = ResultMap.get(resultType) as ResultProps;
    }

    getRecommendedUrl() {
        const parts = this.recommendedUrl.split('/');
        let breadcrumb = '';
        for (let i = 0; i < parts.length; i++) {
            if (parts[i] !== '') {
                if (i < parts.length - 1) {
                    breadcrumb += capitalize(parts[i]) + ' > ';
                }
                breadcrumb += capitalize(parts[i]);
            }
        }
        return breadcrumb;
    }

    animationCreated(animationItem: AnimationItem): void {
        setTimeout(() => {
            animationItem.play();
        }, 500);
    }

    navigateHome() {
        this._router.navigate(['/']);
    }

    constructor(private readonly _router: Router) {}
}
