import { ITypeEntity, ITypeEntityFragment } from '@pf/shared-common';

import { withMetadata } from '@pf/shared-utility';

export const ITypeEntitySymbol = 'ITypeEntity';
export const ITypeEntityFragmentSymbol = 'ITypeEntityFragment';
export const withTypeEntityMetadata = () => {
    withMetadata<ITypeEntity>(ITypeEntitySymbol, {
        id: String,
        name: String,
        code: String,
        description: String,
        createdDate: String,
        modifiedDate: String,
    });
    withMetadata<ITypeEntityFragment>(ITypeEntityFragmentSymbol, {
        id: String,
        name: String,
        code: String,
        description: String,
    });
};
