export function SafeMerge<T>(obj?: T, obj2?: T) {
    if (!obj2) return obj;
    if (!obj) return obj2;
    return {
        ...obj,
        ...obj2,
    };
}

export function RemoveNulls<T extends object>(obj: T): T {
    return Object.entries(obj).reduce(
        (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
        {} as T
    );
}
