import {
    IEntity,
    IMappingProfile,
    ITypeEntityFragment,
} from '@pf/shared-common';
import { ITypeEntityFragmentSymbol } from '@pf/shared/util-platform';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import { Reference, ReferenceViewModel } from '@control-tower/platform-loads';
import {
    entitySymbols,
    isArrayEmpty,
    isValue,
    normalizeString,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { uniqueId } from 'lodash-es';

export const findReference = <T extends IReferenceDto>(
    references: T[],
    code: string
): T | undefined => {
    if (!isValue(code) || isArrayEmpty(references)) {
        return undefined;
    }
    const normalizedCode = normalizeString(code);
    return references.find(
        (reference: IReferenceDto) =>
            reference.referenceType.code?.toLowerCase() === normalizedCode
    );
};

export interface IReferenceDto extends IEntity, Reference {}

export class ReferenceDto implements IReferenceDto {
    constructor(data: Partial<IReferenceDto>) {
        Object.assign(this, data);
        this.id = uniqueId();
    }
    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    referenceType!: ITypeEntityFragment;
    value!: string;
}

export type ReferenceRead = Reference;
export type ReferenceCreate = ReferenceViewModel;

const symbols = entitySymbols('Reference');

withDefaultEntityMetadata<ReferenceDto>(symbols.dto, {
    referenceType: ITypeEntityFragmentSymbol,
    value: String,
});
withMetadata<ReferenceRead>(symbols.entity, {
    referenceType: ITypeEntityFragmentSymbol,
    value: String,
});
withMetadata<ReferenceCreate>(symbols.createBody, {
    referenceTypeId: String,
    value: String,
});

export const ReferenceMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<ReferenceRead, ReferenceDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<ReferenceDto, ReferenceRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<ReferenceDto, ReferenceCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                x => x.referenceTypeId,
                mapFrom(source => source.referenceType?.id || null)
            ),
            forMember(
                x => x.value,
                mapFrom(source => source.value || null)
            )
        );
    },
};
