import {
    UserCreate,
    UserDto,
    UserRead,
    UserSearchParams,
} from '../../entities/user.dto';

import { AbstractEntityMapper } from '@pf/shared-services';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { UserMetadataMap } from './user.mapping-profile';

@Injectable()
export class UserMapper extends AbstractEntityMapper<
    UserRead,
    UserCreate,
    UserDto,
    UserSearchParams
> {
    constructor() {
        super(UserDto, PlatformCoreMapper, UserMetadataMap.symbols);
    }
}
