import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    UserRoleCreate,
    UserRoleDto,
    UserRoleRead,
    UserRoleSearchParams,
} from '../../entities/user-role.dto';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import { UserPermissionMetadataMap } from '../user-permissions/user-permission.mapping-profile';

const symbols = entitySymbols(PFCoreEntities.UserRole);
const permissionsSymbols = entitySymbols(PFCoreEntities.UserPermission);

withDefaultEntityMetadata<UserRoleDto>(symbols.dto, {
    roleName: String,
    description: String,
    permissions: [permissionsSymbols.dto],
    readOnly: Boolean,
});

withDefaultEntityMetadata<UserRoleRead>(symbols.entity, {
    roleName: String,
    description: String,
    isPublic: Boolean,
    fieldLevelPermissions: [Object],
    permissions: [permissionsSymbols.entity],
    readOnly: Boolean,
});

withMetadata<UserRoleCreate>(symbols.createBody, {
    roleName: String,
    description: String,
    isPublic: Boolean,
    fieldLevelPermissions: [Object],
    permissions: [permissionsSymbols.createBody],
});

withDefaultSearchParamsMetadata<UserRoleSearchParams>(symbols.searchParams, {
    roleName: String,
});

export const UserRoleMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<UserRoleRead, UserRoleDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<UserRoleDto, UserRoleRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<UserRoleDto, UserRoleCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<UserRoleDto>, UserRoleSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('roleName'),
            withSearchFilter('readOnly'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
        UserPermissionMetadataMap.profile(mapper);
    },
};
