import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '@pf/shared-common';

@Pipe({
    name: 'safeArray',
})
export class SafeArrayPipe<T extends SafeAny> implements PipeTransform {
    transform(value: T[], defaultValue: T[] = []): T[] {
        if (!value || !Array.isArray(value)) {
            return defaultValue;
        }
        return value as T[];
    }
}
