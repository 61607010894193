import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    CarrierCreate,
    CarrierRead,
    CarrierSearchParams,
} from '../../entities/carrier.dto';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';

import { CarriersService } from '@control-tower/platform-core';
import { CarrierStore } from './carrier.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { IContact } from '@pf/shared/util-platform';

@Injectable()
export class CarrierDataService extends AbstractDataService<
    CarrierRead,
    CarrierCreate
> {
    protected EntityName = PFCoreEntities.Carrier;

    constructor(
        private carrierService: CarriersService,
        store: CarrierStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: CarrierCreate): Observable<CarrierRead> {
        return this.carrierService.carrierCreate({
            carrierViewModelCreateBody: body,
        }) as Observable<CarrierRead>;
    }

    protected deleteObs$(id: string): Observable<CarrierRead> {
        return this.carrierService.carrierDelete({
            id,
        }) as Observable<CarrierRead>;
    }

    protected undoDeleteObs$(id: string): Observable<CarrierRead> {
        return this.carrierService.carrierUndoDelete({
            id,
        }) as Observable<CarrierRead>;
    }

    protected getObs$(id: string): Observable<CarrierRead> {
        return this.carrierService.carrierReadById({
            id,
        }) as Observable<CarrierRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<CarrierRead> {
        return this.carrierService.carrierUpdatePatch({
            id,
            operation: operations,
        }) as Observable<CarrierRead>;
    }

    protected searchObs$(
        searchParams: CarrierSearchParams
    ): Observable<IPagedResult<CarrierRead>> {
        return this.carrierService.carrierSearch(searchParams) as Observable<
            IPagedResult<CarrierRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<CarrierRead>>> {
        return this.carrierService.carrierGetChanges(
            searchParams
        ) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<CarrierRead>>
        >;
    }

    getContacts$(id: string): Observable<IContact[]> {
        return this.carrierService.v1CarriersIdContactsGet({
            id,
        }) as Observable<IContact[]>;
    }
}
