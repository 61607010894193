import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { phone } from 'phone';

export function PhoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }
        const validationResult = phone(value, { country: 'USA' });
        return !validationResult.isValid ? { phone: true } : null;
    };
}
