import {
    ContactDataService,
    ContactFacade,
    ContactMapper,
    ContactStore,
    ContactTypeFacade,
    CustomFieldConfig,
    DocumentTypeFacade,
    SharedUtilPlatformModule,
} from '@pf/shared/util-platform';
import { CarrierDataService } from './infrastructure/carriers/carrier.data.service';
import { CarrierFacade } from './application/carrier.facade';
import { CarrierMapper } from './infrastructure/carriers/carrier.mapper';
import { CarrierStore } from './infrastructure/carriers/carrier.store';
import { CommonModule } from '@angular/common';
import { CustomerDataService } from './infrastructure/customers/customer.data.service';
import { CustomerFacade } from './application/customer.facade';
import { CustomerMapper } from './infrastructure/customers/customer.mapper';
import { CustomerStore } from './infrastructure/customers/customer.store';
import { LocationDataService } from './infrastructure/locations/location.data.service';
import { LocationFacade } from './application/location.facade';
import { LocationMapper } from './infrastructure/locations/location.mapper';
import { LocationStore } from './infrastructure/locations/location.store';
import { LocationTypeFacade } from './application/type-entities/LocationTypeFacade';
import { InjectionToken, NgModule, Type } from '@angular/core';
import { TimezoneDataService } from './infrastructure/timezone/timezone.data.service';
import { TimezoneFacade } from './application/timezone.facade';
import { TimezoneMapper } from './infrastructure/timezone/timezone.mapper';
import { TimezoneStore } from './infrastructure/timezone/timezone.store';
import { UserDataService } from './infrastructure/users/user.data.service';
import { UserFacade } from './application/user.facade';
import { UserMapper } from './infrastructure/users/user.mapper';
import { UserPermissionDataService } from './infrastructure/user-permissions/user-permission.data.service';
import { UserPermissionFacade } from './application/user-permission.facade';
import { UserPermissionMapper } from './infrastructure/user-permissions/user-permission.mapper';
import { UserPermissionStore } from './infrastructure/user-permissions/user-permission.store';
import { UserRoleDataService } from './infrastructure/user-roles/user-role.data.service';
import { UserRoleFacade } from './application/user-role.facade';
import { UserRoleMapper } from './infrastructure/user-roles/user-role.mapper';
import { UserRoleStore } from './infrastructure/user-roles/user-role.store';
import { UserStore } from './infrastructure/users/user.store';
import { VendorDataService } from './infrastructure/vendors/vendor.data.service';
import { VendorFacade } from './application/vendor.facade';
import { VendorMapper } from './infrastructure/vendors/vendor.mapper';
import { VendorStore } from './infrastructure/vendors/vendor.store';
import { SafeAny, sharedEnv } from '@pf/shared-common';
import {
    ApiModule as CoreApiModule,
    Configuration as CoreApiConfig,
    ConfigurationParameters,
} from '@control-tower/platform-core';
import { FiscalPeriodFacade } from './application/fiscal-period.facade';
import { FiscalPeriodDataService } from './infrastructure/fiscal-periods/fiscal-period.data.service';
import { FiscalPeriodStore } from './infrastructure/fiscal-periods/fiscal-period.store';
import { FiscalPeriodMapper } from './infrastructure/fiscal-periods/fiscal-period.mapper';
import { Observable, of } from 'rxjs';
import { LocationFacadeConfig } from './application/location-facade.config';

export const CUSTOMER_CUSTOM_FIELDS = new InjectionToken<
    Observable<CustomFieldConfig[]>
>('CUSTOMER_CUSTOM_FIELDS');

export type CoreProviderConfig = {
    customerCustomFields?: Observable<CustomFieldConfig[]>;
    locationFacadeConfig?: Type<LocationFacadeConfig>;
};

const coreApiConfigFactory = (basePath: string) => {
    return (): CoreApiConfig => {
        const params: ConfigurationParameters = {
            basePath,
        };
        return new CoreApiConfig(params);
    };
};

@NgModule({
    imports: [CommonModule, SharedUtilPlatformModule],
    providers: [
        UserDataService,
        UserFacade,
        UserStore,
        UserMapper,
        CustomerDataService,
        CustomerFacade,
        CustomerStore,
        CustomerMapper,
        CarrierDataService,
        CarrierFacade,
        CarrierStore,
        CarrierMapper,
        VendorDataService,
        VendorFacade,
        VendorStore,
        VendorMapper,
        LocationDataService,
        LocationFacade,
        LocationStore,
        LocationMapper,
        UserRoleDataService,
        UserRoleFacade,
        UserRoleStore,
        UserRoleMapper,
        UserPermissionDataService,
        UserPermissionFacade,
        UserPermissionStore,
        UserPermissionMapper,
        TimezoneFacade,
        TimezoneDataService,
        TimezoneStore,
        TimezoneMapper,
        ContactTypeFacade,
        LocationTypeFacade,
        ContactFacade,
        ContactDataService,
        ContactStore,
        ContactMapper,
        DocumentTypeFacade,
        FiscalPeriodFacade,
        FiscalPeriodDataService,
        FiscalPeriodStore,
        FiscalPeriodMapper,
    ],
})
export class PlatformCoreDomainModule {
    static ApiModule() {
        return CoreApiModule.forRoot(
            coreApiConfigFactory(sharedEnv.baseApiUrl + '/core')
        );
    }

    static ConfigProviders(providers: CoreProviderConfig) {
        const configuredProviders: SafeAny[] = [
            {
                provide: CUSTOMER_CUSTOM_FIELDS,
                useValue: providers.customerCustomFields || of([]),
            },
        ];
        if (providers.locationFacadeConfig) {
            configuredProviders.push({
                provide: LocationFacadeConfig,
                useClass: providers.locationFacadeConfig,
            });
        }
        return configuredProviders;
    }
}
