import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormService } from '../services/form.service';
import { takeUntilDestroyed } from 'ngx-sub-form';
import { debounceTime, filter } from 'rxjs';

@Component({
    selector: 'pf-form-buttons',
    template: `
        <pf-button
            *ngIf="!hideCancel"
            [disabled]="disableCancel"
            buttonType="cancel"
            (click)="cancel($event)"
            >{{ cancelText }}</pf-button
        >
        <pf-button
            buttonType="submit"
            *ngIf="!hideSubmit"
            (click)="submit($event)"
            [disabled]="disableSubmit"
            [loading]="submitting"
            >{{ submitText }}</pf-button
        >
        <ng-content></ng-content>
    `,
    styles: [
        `
            :host {
                display: flex;
                flex-direction: row;
                column-gap: var(--pf-gap);
                justify-content: flex-end;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonsComponent implements OnInit {
    @HostBinding('attr.pf-data-form-buttons')
    @Input()
    entityType!: string;
    @Input() cancelText = 'Cancel';
    @Input() hideCancel = false;
    @Input() submitText = 'Submit';
    @Input() hideSubmit = false;

    @Input() disableCancel = false;
    @Input() disableSubmit = true;
    @Input() submitting = false;
    @Input() manual = false;
    @Output() cancelClick = new EventEmitter<void>();
    @Output() submitClick = new EventEmitter<void>();

    constructor(
        public formService: FormService,
        private cdr: ChangeDetectorRef
    ) {}

    cancel(event: Event) {
        event.stopImmediatePropagation();
        if (this.manual) {
            this.cancelClick.emit();
            return;
        }

        this.formService.cancel(this.entityType);
        this.markForCheck();
    }

    submit(event?: Event) {
        event?.stopImmediatePropagation();

        if (this.manual) {
            this.submitClick.emit();
            return;
        }

        this.formService.submit(this.entityType);
        this.markForCheck();
    }

    markForCheck() {
        setTimeout(() => {
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        if (this.manual) {
            return;
        }
        this.formService
            .activeState$(this.entityType)
            .pipe(
                takeUntilDestroyed(this),
                filter(
                    state =>
                        !!state?.isDirty ||
                        state?.action === 'init' ||
                        state?.action === 'error'
                ),
                debounceTime(100)
            )
            .subscribe(state => {
                this.submitting = state?.action === 'submit';
                this.disableSubmit = false;
                this.cdr.markForCheck();
            });
    }
}
