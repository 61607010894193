/**
 * The `DatePickerType` enum will be used by the `date-picker` component
 * to utilize different property sets for each enum value.
 */
export enum DatePickerType {
    date,
    dateRange,
}

export interface DatePickerTypeProps {
    type: string;
}

export const DatePickerTypePropMap: Record<
    DatePickerType,
    () => DatePickerTypeProps
> = {
    [DatePickerType.date]: () => ({
        type: 'date',
    }),
    [DatePickerType.dateRange]: () => ({
        type: 'dateRange',
    }),
};
