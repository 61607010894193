import {
    FragmentMappingConfig,
    InteroperableMetadata,
    InteroperableMetadataMap,
} from '../fragment-mapping-config';
import {
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
} from '@pf/shared-common';

import { PlatformCoreFragments } from './platform-core-fragments.enum';

export interface IVendorFragment extends IEntityFragment {
    vendorName?: string | null;
    vendorNumber?: string | null;
}

export interface IVendorFragmentDto
    extends IVendorFragment,
        IEntityFragmentDto {}

export function VendorFragmentMetadataMap<
    TFragmentDto extends IVendorFragmentDto,
    TFragment extends IVendorFragment
>(config: FragmentMappingConfig<TFragmentDto, TFragment>): IMappingProfile {
    const interoperableMetadata = {
        vendorName: String,
        vendorNumber: String,
    } as InteroperableMetadata<TFragmentDto, TFragment>;
    return InteroperableMetadataMap(
        PlatformCoreFragments.Vendor,
        interoperableMetadata,
        config
    );
}

export const DefaultVendorFragmentMetadataMap = VendorFragmentMetadataMap<
    IVendorFragmentDto,
    IVendorFragment
>({});
