import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFItemCatalogEntities,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    CategoryViewModelDto,
    ItemCreate,
    ItemDto,
    ItemRead,
    ItemSearchParams,
} from '../../entities/item.dto';
import {
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    ItemBrandViewModel,
    ItemManufacturerViewModel,
} from '@control-tower/platform-item-catalog';

const symbols = entitySymbols(PFItemCatalogEntities.Item);
const itemBrandSymbol = 'ItemBrandViewModel';
const categoryViewModelSymbol = 'CategoryViewModel';
const itemManufacturerSymbol = 'ItemManufacturerViewModel';

withMetadata<ItemBrandViewModel>(itemBrandSymbol, {
    id: String,
    name: String,
    code: String,
    description: String,
    manufacturer: itemManufacturerSymbol,
    customFields: [CustomFieldMetadataMap.symbols.entity],
});
withDefaultEntityMetadata<CategoryViewModelDto>(categoryViewModelSymbol, {
    name: String,
    code: String,
    parentCategoryId: String,
    topLevelCategoryID: String,
    categoryBreadCrumbs: String,
    isTopLevelCategory: Boolean,
    customFields: [CustomFieldMetadataMap.symbols.entity],
});
withMetadata<ItemManufacturerViewModel>(itemManufacturerSymbol, {
    id: String,
    name: String,
    code: String,
    description: String,
    customFields: [CustomFieldMetadataMap.symbols.entity],
});

withDefaultEntityMetadata<ItemDto>(symbols.dto, {
    name: String,
    code: String,
    description: String,
    brandId: String,
    brand: itemBrandSymbol,
    parentItemId: String,
    sku: String,
    modelNumber: String,
    partNumber: String,
    isBreakPack: Boolean,
    hasInstances: Boolean,
    packSize: Number,
    maxGroupSize: Number,
    assignedCategories: [categoryViewModelSymbol],
    hazardous: Boolean,
    weight: Object,
    dimensions: Object,
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.dto],
});

withDefaultEntityMetadata<ItemRead>(symbols.entity, {
    name: String,
    code: String,
    description: String,
    brandId: String,
    brand: itemBrandSymbol,
    parentItemId: String,
    sku: String,
    modelNumber: String,
    partNumber: String,
    isBreakPack: Boolean,
    hasInstances: Boolean,
    packSize: Number,
    maxGroupSize: Number,
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    assignedCategories: [categoryViewModelSymbol],
    hazardous: Boolean,
    weight: Object,
    dimensions: Object,
});

withMetadata<ItemCreate>(symbols.createBody, {
    name: String,
    code: String,
    description: String,
    brandId: String,
    parentItemId: String,
    sku: String,
    modelNumber: String,
    partNumber: String,
    isBreakPack: Boolean,
    hasInstances: Boolean,
    packSize: Number,
    maxGroupSize: Number,
    resourceAuth: ResourceAuthSymbol,
    hazardous: Boolean,
    weight: Object,
    dimensions: Object,
});

withDefaultSearchParamsMetadata<ItemSearchParams>(symbols.searchParams, {});

export const ItemMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<ItemRead, ItemDto>(mapper, symbols.entity, symbols.dto);
        createMap<ItemDto, ItemRead>(mapper, symbols.dto, symbols.entity);
        createMap<ItemDto, ItemCreate>(mapper, symbols.dto, symbols.createBody);
        createMap<IQueryParams<ItemDto>, ItemSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            forMember(
                d => d.search,
                mapFrom(s => {
                    return s.filters?.name ? `*${s.filters.name}*` : null;
                })
            ),
            forMember(
                d => d.categoryIds,
                mapFrom(s => {
                    if (!s.filters?.assignedCategories) {
                        return null;
                    }
                    return typeof s.filters?.assignedCategories === 'string'
                        ? [s.filters?.assignedCategories]
                        : s.filters?.assignedCategories;
                })
            ),
            ...withDefaultSearchParamsMappingConfiguration()
        );

        CustomFieldMetadataMap.profile(mapper);
    },
};
