import { TimeZoneInformation } from '@control-tower/platform-core';
import { IEntity, IPagedSearchParams } from '@pf/shared-common';

export type TimezoneRead = TimeZoneInformation & IEntity;
export type TimezoneCreate = TimeZoneInformation;
export type TimezoneSearchParams = IPagedSearchParams;

export interface ITimezoneDto extends IEntity {
    abbreviation?: string;
    genericName?: string;
    ianaTimeZoneId?: string;
    utcOffsetInHours?: number;
    windowsTimeZoneId?: string;
    country?: string;
}

export class TimezoneDto implements ITimezoneDto {
    constructor(data: Partial<ITimezoneDto>) {
        Object.assign(this, data);
        if (!this.id) {
            this.id = this.utcOffsetInHours?.toString() ?? '';
        }
    }

    get name() {
        return `(UTC${this.getUtcOffsetString()}) ${this.genericName}`;
    }

    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    abbreviation?: string;
    genericName?: string;
    ianaTimeZoneId?: string;
    utcOffsetInHours?: number;
    windowsTimeZoneId?: string;
    country?: string;

    private getUtcOffsetString(): string {
        const offset = this.utcOffsetInHours as number;
        const sign = offset >= 0 ? '+' : '-';
        const hours = Math.floor(Math.abs(offset));
        const minutes = Math.floor((Math.abs(offset) * 60) % 60);
        return `${sign}${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}`;
    }
}
