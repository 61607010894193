import { Injectable } from '@angular/core';
import { ItemDto, ItemRead } from '../../entities/item.dto';
import { PFItemCatalogEntities } from '@pf/shared-common';
import { sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { AbstractEntityStoreWithUiEntities } from '@pf/shared-services';
import { CategoryStore } from '../categories/category.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { asArraySafe } from '@pf/shared-utility';

export interface ItemUI {
    id: string;
    formId: string;
    name: string;
    isDirty: boolean;
    state: ItemDto;
}

@UntilDestroy()
@Injectable()
export class ItemStore extends AbstractEntityStoreWithUiEntities<
    ItemRead,
    ItemUI
> {
    constructor(private categoryStore: CategoryStore) {
        super(PFItemCatalogEntities.Item);
        this.persistUIState('item-ui-state', sessionStorageStrategy);
        this.observeItemsAndUpdateCategoryCounts();
    }

    private observeItemsAndUpdateCategoryCounts() {
        this.added
            .pipe(
                untilDestroyed(this),
                tap(item => {
                    asArraySafe(item.assignedCategories).forEach(category => {
                        this.categoryStore.addItemToCategoryUi(
                            item.id,
                            category.id
                        );
                    });
                })
            )
            .subscribe();
        this.updated
            .pipe(
                untilDestroyed(this),
                tap(item => {
                    asArraySafe(item.assignedCategories).forEach(category => {
                        this.categoryStore.addItemToCategoryUi(
                            item.id,
                            category.id
                        );
                    });
                })
            )
            .subscribe();
        this.deleted
            .pipe(
                untilDestroyed(this),
                tap(item => {
                    asArraySafe(item.assignedCategories).forEach(category => {
                        this.categoryStore.removeItemFromCategoryUi(
                            item.id,
                            category.id
                        );
                    });
                })
            )
            .subscribe();
    }
}
