import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ISegmentedOption } from '@pf/shared-common';

@Component({
    selector: 'pf-segmented',
    templateUrl: './segmented.component.html',
    styleUrls: ['./segmented.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentedComponent {
    @Output()
    selectedModelChange = new EventEmitter<number>();
    @Input()
    segmentedOptions: ISegmentedOption[] = [];
    @Input()
    model: number | null = null;

    handleModelChange(index: number): void {
        this.selectedModelChange.emit(index);
    }
}
