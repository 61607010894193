import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    DocumentDto,
    IDocumentDto,
    IDocumentsFacade as IDocumentsFacade,
    IDocumentTemplate,
    TableAction,
    TableColumn,
} from '@pf/shared-common';
import { BehaviorSubject, catchError, filter, first, map, of, tap } from 'rxjs';
import {
    DocumentFormComponent,
    IDocumentForm,
} from './document-form.component';
import { ModalService } from '../../../services/modal.service';
import { saveAs } from 'file-saver';
import { TableColumnBuilder } from '../../../shared-table/table-column.builder';

@UntilDestroy()
@Component({
    selector: 'pf-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit {
    private _templates: IDocumentTemplate[] = [];
    private readonly _cdr = inject(ChangeDetectorRef);
    private readonly _modalService = inject(ModalService);

    @ViewChild('form', { static: false }) form!: DocumentFormComponent;
    private _entityId: string | undefined;
    @Output() documentSaved = new EventEmitter<IDocumentDto>();
    saving = false;

    @Input() set entityId(id: string | undefined) {
        this._entityId = id;
        this.loadDocuments();
    }

    get entityId() {
        return this._entityId;
    }

    private _documents = new BehaviorSubject<IDocumentDto[]>(
        [] as IDocumentDto[]
    );

    @Input() set templates(templates: IDocumentTemplate[]) {
        this._templates = templates;
        this._documents.next([...this._documents.value]);
    }

    @Input() title = 'Documents';
    @Input() documentsFacade!: IDocumentsFacade;
    @Input() hasRequirementLevel = false;
    @Input() hasExpiration = false;
    @Input() canManageFn: (document: IDocumentDto) => boolean = () => true;
    @Input() filterFn: (document: IDocumentDto) => boolean = () => true;

    actions: TableAction<IDocumentDto>[];

    tableRows$ = this._documents.asObservable().pipe(
        map(documents => {
            return this.getTableRows(documents);
        }),
        map(rows => rows.filter(this.filterFn)),
        tap(() => setTimeout(() => this._cdr.detectChanges(), 0))
    );
    columns: TableColumn<IDocumentDto>[];

    selectedDocument: IDocumentDto | null = null;

    get editing() {
        return this.selectedDocument?.id;
    }

    /**
     * @internal
     */
    loading = false;

    get documents() {
        return this._documents.value;
    }

    constructor() {
        const columnBuilder = new TableColumnBuilder<IDocumentDto>()
            .withNameColumn('documentTypeName', 'Document Type')
            .withColumn({
                field: 'fileName',
                headerName: 'Document Name',
                type: 'string',
            })
            .withColumn({
                field: 'notes',
                headerName: 'Notes',
                type: 'string',
            });

        if (this.hasRequirementLevel) {
            columnBuilder.withColumn({
                field: 'requirementLevel',
                headerName: 'Requirement Level',
                type: 'string',
            });
        }
        if (this.hasExpiration) {
            columnBuilder.withColumn({
                field: 'expirationDate',
                headerName: 'Expires',
                type: 'date',
            });
        }
        this.columns = columnBuilder.columns;

        this.actions = [
            {
                name: 'Upload',
                textType: 'primary',
                onRowChange: (row, action) => {
                    action.hide = !this.canManageFn(row) || !!row?.id;
                },
                onClick: (row: IDocumentDto): void => {
                    this.open(row);
                },
            },
            {
                name: 'Download',
                textType: 'primary',
                onRowChange: (row, action) => {
                    action.hide = !row?.id || !row?.url;
                },
                onClick: (row: IDocumentDto): void => {
                    this.download(row);
                },
            },
            {
                name: 'Edit',
                textType: 'primary',
                onRowChange: (row, action) => {
                    action.hide = !this.canManageFn(row) || !row?.id;
                },
                onClick: (row: IDocumentDto): void => {
                    this.open(row);
                },
            },
            {
                name: 'Delete',
                textType: 'danger',
                onRowChange: (row, action) => {
                    action.hide = !this.canManageFn(row) || !row?.id;
                },
                onClick: (row: IDocumentDto): void => {
                    this._modalService.confirmAction(
                        `${row.documentTypeName} document`,
                        () =>
                            this.deleteDocument(row).subscribe(() =>
                                this._cdr.markForCheck()
                            )
                    );
                },
            },
        ];
    }

    ngOnInit(): void {
        this.loadDocuments();
    }

    onSubmitClick(formValue: IDocumentForm) {
        if (!this.form.valid) {
            return;
        }
        if (!this.selectedDocument) {
            this.close();
            return;
        }
        this.saving = true;
        const document: IDocumentDto = {
            ...this.selectedDocument,
            file: formValue.file as File,
            fileName: formValue.documentName as string,
            expirationDate: formValue.documentExpirationDate as string,
            notes: formValue.documentNotes as string,
            description: formValue.documentDescription as string,
        };
        const op = this.editing
            ? this.editDocument(document)
            : this.createDocument(document);
        op.pipe(
            first(),
            tap(document => {
                this.documentSaved.emit(document);
                this.saving = false;
            }),
            catchError(() => {
                this.saving = false;
                return of(null);
            })
        ).subscribe(() => this.close());
    }

    open(document: IDocumentDto): void {
        this.selectedDocument = document;
        this.form.open();
        this._cdr.markForCheck();
    }

    close(): void {
        this.selectedDocument = null;
        this.form.close();
        this._cdr.markForCheck();
    }

    private loadDocuments() {
        if (!this.documentsFacade) {
            throw new Error('documentsFacade not provided!');
        }
        if (!this.entityId) {
            return;
        }
        this.documentsFacade
            .all$(this.entityId)
            .pipe(
                untilDestroyed(this),
                filter(documents => !!documents)
            )
            .subscribe(documents => {
                this._documents.next(documents);
                this._cdr.markForCheck();
            });
    }

    private getTableRows(documents: IDocumentDto[] = []) {
        return ([] as IDocumentDto[]).concat(
            ...this._templates.map(template =>
                this.getTableRowsForTemplate(documents, template)
            )
        );
    }

    private getTableRowsForTemplate(
        documents: IDocumentDto[],
        template: IDocumentTemplate
    ) {
        const docs = documents.filter(
            doc => doc.documentTypeId === template.typeFragment.id
        );

        if (docs.length > template.allowedQuantity) {
            console.warn(
                `More documents of type '${template.typeFragment.name}' exist than are allowed!`
            );
        }

        const tableRows: IDocumentDto[] = [];
        for (
            let index = 0;
            index < Math.min(docs.length + 1, template.allowedQuantity);
            index++
        ) {
            const existing = docs[index];
            tableRows.push({
                ...existing,
                documentTypeId: template.typeFragment.id,
                documentTypeName: template.typeFragment.name,
                requirementLevel: template.requirementLevel,
            } as IDocumentDto);
        }
        return tableRows;
    }

    private download(document: IDocumentDto) {
        if (!this.entityId) {
            saveAs(document.url as string, document.fileName as string, {
                autoBom: false,
            });
            return;
        }
        this.documentsFacade
            .download$(
                this.entityId as string,
                document.id,
                document.fileName as string
            )
            .subscribe();
    }

    private createDocument(document: IDocumentDto) {
        if (!this.entityId) {
            const newDoc = new DocumentDto(document);
            this.addToDocuments(newDoc);
            return of(newDoc);
        }
        return this.documentsFacade.create$(this.entityId, document).pipe(
            tap(created => {
                this.addToDocuments(created);
            })
        );
    }

    private addToDocuments(document: DocumentDto) {
        const documents = this._documents.value;
        documents.push(document);
        this._documents.next([...documents]);
    }

    private editDocument(modified: IDocumentDto) {
        if (!this.entityId) {
            const updatedDoc = new DocumentDto(modified);
            this.updateDocument(updatedDoc.id, updatedDoc);
            return of(updatedDoc);
        }
        return this.documentsFacade
            .update$(this.entityId, modified.id, modified)
            .pipe(
                tap(updated => {
                    this.updateDocument(modified.id, updated);
                })
            );
    }

    private updateDocument(id: string, document: IDocumentDto) {
        if (id !== document.id) {
            this.removeFromDocuments(id);
            this.addToDocuments(document);
        } else {
            const documents = this._documents.value;
            const index = documents.findIndex(doc => doc.id === id);

            if (index < 0) {
                throw new Error('Document not found!');
            }
            documents[index] = document;
            this._documents.next([...documents]);
        }
    }

    private deleteDocument(document: IDocumentDto) {
        if (!this.entityId) {
            this.removeFromDocuments(document.id);
            return of(new DocumentDto(document));
        }
        return this.documentsFacade.delete$(this.entityId, document.id).pipe(
            tap(() => {
                this.removeFromDocuments(document.id);
            })
        );
    }

    private removeFromDocuments(documentId: string) {
        const documents = this._documents.value.filter(
            doc => doc.id !== documentId
        );
        this._documents.next(documents);
    }
}
