import {
    CustomFieldCreate,
    CustomFieldDto,
    CustomFieldRead,
} from './custom-fields.dto';
import { createMap, Mapper } from '@automapper/core';
import { entitySymbols, withMetadata } from '@pf/shared-utility';

import { IMappingProfile } from '@pf/shared-common';
import { PlatformSharedEntities } from './shared-entities.enum';

const symbols = entitySymbols(PlatformSharedEntities.CustomField);

withMetadata<CustomFieldDto>(symbols.dto, {
    id: String,
    key: String,
    value: String,
});
withMetadata<CustomFieldRead>(symbols.entity, {
    id: String,
    key: String,
    value: String,
    entityId: String,
});
withMetadata<CustomFieldCreate>(symbols.createBody, {
    key: String,
    value: String,
});

export const CustomFieldMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<CustomFieldRead, CustomFieldDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<CustomFieldDto, CustomFieldRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<CustomFieldDto, CustomFieldCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
    },
};
