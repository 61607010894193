import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';

@Component({
    selector: 'platform-not-found',
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
    recommendedPath = '';
    constructor(private route: ActivatedRoute) {}
    ngOnInit(): void {
        this.route.data.pipe(take(1)).subscribe((data) => {
            this.recommendedPath = data['path'];
        });
    }
}
