import { IEntity } from '@pf/shared-common';
import {
    EquipmentViewModelRead,
    EquipmentViewModelCreateBody,
    EquipmentSearchRequestParams,
    Weight,
    EquipmentDimension,
} from '@control-tower/platform-transportation';

export type EquipmentRead = EquipmentViewModelRead & IEntity;
export type EquipmentCreate = EquipmentViewModelCreateBody;
export type EquipmentSearchParams = EquipmentSearchRequestParams;

export class EquipmentDto implements IEntity {
    constructor(data: Partial<EquipmentDto>) {
        Object.assign(this, data);
    }
    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    number!: string;
    description?: string;
    carrierId?: string;
    locationId?: string;
    equipmentTypeId?: string;
    maximumLoadWeight?: Weight;
    weight?: Weight;
    dimensions?: EquipmentDimension;
}
