import { IconPathData, IconProp } from '@fortawesome/fontawesome-svg-core';

export enum IconType {
    home = 'home',
    manage = 'manage',
    carrier = 'carrier',
    close = 'close',
    plus = 'plus',
    search = 'search',
    question = 'question',
    export = 'export',
    expand = 'expand',
    collapse = 'collapse',
    expandAll = 'expandAll',
    collapseAll = 'collapseAll',
    list = 'list',
    info = 'info',
    warning = 'warning',
    success = 'success',
    error = 'error',
    user = 'user',
    notification = 'notification',
    phone = 'phone',
    email = 'email',
    location = 'location',
    view = 'view',
    copy = 'copy',
    load = 'load',
    transportation = 'transportation',
    drag = 'drag',
    inlineMenu = 'inlineMenu',
    contact = 'contact',
    barcode = 'barcode',
    item = 'item',
    collapseTree = 'collapseTree',
    expandTree = 'expandTree',
    moneyCheck = 'moneyCheck',
    circleX = 'circleX',
    upload = 'upload',
}

export interface IconProps {
    faIcon: IconProp;
    height: number;
    width: number;
    pathData: IconPathData;
    style?: string;
}
