import { FilterOption } from '@pf/shared-common';
import {
    endOfDay,
    endOfToday,
    endOfYesterday,
    startOfDay,
    startOfMonth,
    startOfToday,
    startOfWeek,
    startOfYesterday,
    subMonths,
    subWeeks,
} from 'date-fns';

export type DateRangeOption =
    | 'custom'
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'last3Months'
    | 'last6Months'
    | 'last12Months';

export type DateRange = {
    startDate: string;
    endDate: string;
};

export const DateRanges: Record<
    DateRangeOption,
    (
        startDate?: string,
        endDate?: string
    ) => { from: string; to: string; label: string }
> = {
    custom: (startDate, endDate) => ({
        from: startDate as string,
        to: endDate as string,
        label: 'Custom',
    }),
    today: () => ({
        from: startOfToday().toISOString(),
        to: endOfToday().toISOString(),
        label: 'Today',
    }),
    yesterday: () => ({
        from: startOfYesterday().toISOString(),
        to: endOfYesterday().toISOString(),
        label: 'Yesterday',
    }),
    thisWeek: () => ({
        from: startOfWeek(new Date()).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Current Week',
    }),
    lastWeek: () => ({
        from: startOfDay(subWeeks(new Date(), 1)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last Week',
    }),
    thisMonth: () => ({
        from: startOfMonth(new Date()).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Current Month',
    }),
    lastMonth: () => ({
        from: startOfDay(subMonths(new Date(), 1)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last Month',
    }),
    last3Months: () => ({
        from: startOfDay(subMonths(new Date(), 3)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last 3 Months',
    }),
    last6Months: () => ({
        from: startOfDay(subMonths(new Date(), 6)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last 6 Months',
    }),
    last12Months: () => ({
        from: startOfDay(subMonths(new Date(), 12)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last 12 Months',
    }),
};

export const DateRangeFilterOptions = (
    options: Partial<Record<DateRangeOption, true>> & {
        initialDateRage?: DateRange;
        initialOption?: DateRangeOption;
        defaultOption: DateRangeOption;
    },
    fromField = 'createdStartDate',
    toField = 'createdEndDate'
): FilterOption[] => {
    const {
        defaultOption,
        initialOption,
        initialDateRage,
        ...selectedOptions
    } = options;
    return Object.keys(selectedOptions).map(option => {
        const dateRangeOption = option as DateRangeOption;
        const rangeFn = DateRanges[option as DateRangeOption];
        return {
            label: rangeFn().label,
            value: dateRangeOption,
            default: dateRangeOption === defaultOption,
            initial:
                dateRangeOption === initialOption ||
                (initialDateRage?.startDate && dateRangeOption === 'custom'),
            filterFn: (startDate: string, endDate: string) => {
                const range = rangeFn(
                    startDate || initialDateRage?.startDate,
                    endDate || initialDateRage?.endDate
                );
                return {
                    [fromField]: range.from,
                    [toField]: range.to,
                };
            },
        } as FilterOption;
    });
};

export function dateRangeOverlaps(
    a_start: Date | number,
    a_end: Date | number,
    b_start: Date | number,
    b_end: Date | number
) {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start <= a_start && a_end <= b_end) return true; // a in b
    return false;
}
