import { Injectable } from '@angular/core';
import { LoggerService } from '@pf/shared-services';
import {
    AbstractTypeEntityFacade,
    TypeEntityStore,
} from '@pf/shared/util-platform';
import { VehicleTypesService } from '@control-tower/platform-transportation';
import { TypeEntityTypes } from '@pf/shared-common';

@Injectable()
export class VehicleTypeFacade extends AbstractTypeEntityFacade<VehicleTypesService> {
    constructor(
        typeService: VehicleTypesService,
        store: TypeEntityStore,
        logger: LoggerService
    ) {
        super(
            store,
            logger,
            typeService,
            'vehicleTypeGetAll',
            TypeEntityTypes.VehicleType
        );
    }
}
