import { Message } from 'roarr';
import { ILogger } from './ILogger';
import { LogLevel } from './LogLevel.enum';

export class ConsoleLogger implements ILogger {
    logLevel: LogLevel = LogLevel.All;

    constructor(logLevel?: LogLevel) {
        this.logLevel = logLevel || LogLevel.All;
    }

    trace(message: Message): void {
        console.log(message);
    }
    debug(message: Message): void {
        console.log(message);
    }
    info(message: Message): void {
        console.info(message);
    }
    warn(message: Message): void {
        console.warn(message);
    }
    error(message: Message): void {
        console.error(message);
    }
    fatal(message: Message): void {
        console.error(message);
    }
    shouldLog(message: Message<{ logLevel: number }>): boolean {
        return message.context.logLevel >= this.logLevel
    }
}
