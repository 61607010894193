<ng-content select="[toolbar-title]"></ng-content>
<pf-text table-name type="primary" bold>{{ title }}</pf-text>
<div toolbar-actions>
    <pf-button
        buttonType="clear"
        data-pf-handle="clear-filters"
        (click)="resetClicked()"
        [disabled]="disableClearFilters"
        *ngIf="showClearFilters"
    >
        Clear Filter(s)
    </pf-button>
    <div id="table-toolbar-left">
        <ng-content select="[toolbar-left]"></ng-content>
    </div>
    <pf-input
        *ngIf="showSearch"
        inputType="search"
        data-pf-handle="search"
        [placeholder]="'Search ' + title"
        [control]="searchControl"
        inline
    ></pf-input>
    <ng-content select="[toolbar-right]"></ng-content>
</div>
