import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
} from '@angular/core';

import { IChangeTrackingFacade } from '@pf/shared-common';
import { IEntityHistoryComponent } from './IEntityHistoryComponent';
import { Subject } from 'rxjs';
import { calcDrawerHeight } from '@pf/shared-ui';

@Component({
    selector: 'platform-entity-history-drawer',
    templateUrl: './entity-history-drawer.component.html',
    styleUrls: ['./entity-history-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityHistoryDrawerComponent
    implements IEntityHistoryComponent<unknown>
{
    @Input() facade!: IChangeTrackingFacade<unknown>;
    @Input() entityName!: string;
    @Output() opened$ = new Subject<void>();
    @Output() closed$ = new Subject<void>();
    showDrawer = false;
    drawerHeight = calcDrawerHeight(0);

    constructor(private cdr: ChangeDetectorRef) {}

    open() {
        this.showDrawer = true;
        this.cdr.markForCheck();
        this.opened$.next();
    }

    close() {
        this.showDrawer = false;
        this.cdr.markForCheck();
        this.closed$.next();
    }
}
