import { AbstractRouteBuilder } from '@pf/shared-services';
import { Injectable } from '@angular/core';
import { buildLoadPermissionPath } from '@pf/shared/util-platform';
import { PFLoadEntities, PermissionTypes } from '@pf/shared-common';

@Injectable()
export class RoutesForLoads extends AbstractRouteBuilder {
    permissionType = buildLoadPermissionPath(
        PermissionTypes.Read,
        PFLoadEntities.Load
    );
    history() {
        return this.url('history');
    }
}
