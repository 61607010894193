import { ButtonType } from './button.types';
import { IconType } from './icon.types';
import { SafeAny } from './SafeTypes';
import { Subject } from 'rxjs';

export enum NotificationType {
    ToastMessage,
    AppNotification,
    Email,
}

/**
 * Interface for objects defining a notification message
 *
 * @interface
 */
export interface INotificationMessage {
    /** The type of notification */
    type: NotificationType;
    /** Message for notification */
    message: string;
}

export interface INotificationCreateRef {
    type: NotificationType;
    id: string;
}

/**
 * Interface for service that provides notifications
 *
 * @interface
 */
export interface INotificationService {
    create(notification: INotificationMessage): INotificationCreateRef;
    remove(id: string): void;
}

/**
 * Will display an action button. text and either onActionClick or route must be set for button to display
 *
 * @interface
 */
export type NotificationAction = {
    /** Text to display on action button */
    text: string;
    buttonType: ButtonType;
    /** Will emit when action is clicked. Action must be registered in service */
    onClickAction?: string;
    data?: SafeAny;
};

export type NotificationActionVm = NotificationAction & {
    onClick?: Subject<AppNotificationVm>;
};

export enum AppNotificationType {
    success,
    error,
    info,
    warning,
}

export type AppNotificationCreateRef = INotificationCreateRef & {
    /** optional observable that will be invoked when the notification is closed */
    onClose?: Subject<boolean>;
    /** optional observable that will be invoked when the notification action is clicked */
    onClick?: Subject<MouseEvent>;
};

export type AppNotification = INotificationMessage & {
    type: NotificationType.AppNotification;
    appNotificationType: AppNotificationType;
    /** Title for notification */
    title: string;
    action?: NotificationAction;
    /**
     * true: Will persist using localstorage until manually dismissed.
     * false: Will only be visible for current session or until manually dismissed.
     */
    persist: boolean;
    createdDate?: string;
};

export type AppNotificationVm = AppNotification & {
    iconType: keyof typeof IconType;
    action?: NotificationActionVm;
    messageId?: string;
    id?: string;
};

export enum ToastType {
    success,
    error,
    info,
    warning,
    loading,
}
export type ToastMessage = INotificationMessage & {
    type: NotificationType.ToastMessage;
    persist?: boolean | number;
    /** Will determine how to style the displayed message */
    toastType: ToastType;
};

export type ToastMessageCreateRef = INotificationCreateRef & {
    /** optional observable that will be invoked when the notification is closed */
    onClose?: Subject<boolean>;
};

export type EmailAttachment = {
    /** Name of attachment */
    name: string;
    /** Attachment's content type */
    contentType: string;
    /** Base64 Encoded content */
    contents: string;
};

export type EmailNotification = INotificationMessage & {
    type: NotificationType.Email;
    /** Will be used as subject of email */
    subject: string;
    /** Email addreses to send to */
    to: string[];
    /** Who to carbon copy (CC) the email to. */
    cc?: string[];
    /** Who to blind carbon copy (BCC) the email to. */
    bcc?: string[];
    /** Optional email addreses to send from. Will use default setup in platform tenant if not provided */
    from?: string[];
    /** Optional list of attachment urls */
    attachments?: EmailAttachment[];
};
