<pf-segmented
    class="desktop"
    [segmentedOptions]="segmentedOptions"
    [model]="selectedIndex"
    (selectedModelChange)="handleIndexChange($event)"
></pf-segmented>
<pf-select
    class="laptop"
    [data]="selectOptions"
    [control]="selectControl"
    (selected)="handleIndexChange($event.value)"
></pf-select>
