import { AbstractEntityMapper } from '@pf/shared-services';
import {
    ContactCreate,
    ContactDto,
    ContactRead,
    ContactSearchParams,
} from './/contact.dto';
import { Injectable } from '@angular/core';
import { ContactMetadataMap } from './contact.mapping-profile';
import {
    addProfile,
    CamelCaseNamingConvention,
    createMapper,
} from '@automapper/core';
import { pojos } from '@automapper/pojos';

export const mapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

addProfile(mapper, ContactMetadataMap.profile);

@Injectable()
export class ContactMapper extends AbstractEntityMapper<
    ContactRead,
    ContactCreate,
    ContactDto,
    ContactSearchParams
> {
    constructor() {
        super(ContactDto, mapper, ContactMetadataMap.symbols);
    }
}
