import {
    AddressMetadataMap,
    AssociatedAddressMetadataMap,
    ContactMetadataMap,
    CustomFieldMetadataMap,
    DefaultCarrierFragmentMetadataMap,
    DefaultCustomerFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    DefaultVendorFragmentMetadataMap,
    IdIsDefaultSymbol,
    ITypeEntityFragmentSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    LocationCreate,
    LocationDto,
    LocationOmniSearchFieldTypes,
    LocationRead,
    LocationSearchParams,
} from '../../entities/location.dto';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultOmniSearchParamsMappingConfiguration,
    withDefaultOmniSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';

import { CustomerMetadataMap } from '../customers/customer.mapping-profile';
import { TimezoneMetadataMap } from '../timezone/timezone.mapping.profile';

const symbols = entitySymbols(PFCoreEntities.Location);

withDefaultEntityMetadata<LocationDto>(symbols.dto, {
    locationName: String,
    locationNumber: String,
    locationSubType: String,
    address: AddressMetadataMap.symbols.dto,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.dto],
    timeZone: TimezoneMetadataMap.symbols.dto,
    vendors: [DefaultVendorFragmentMetadataMap.symbols.dto],
    carriers: [DefaultCarrierFragmentMetadataMap.symbols.dto],
    customers: [DefaultCustomerFragmentMetadataMap.symbols.dto],
    contacts: [ContactMetadataMap.symbols.dto],
    relatedLocations: [DefaultLocationFragmentMetadataMap.symbols.dto],
    locationTypeId: String,
    locationTypeName: String,
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.dto],
});

withDefaultEntityMetadata<Omit<LocationRead, 'features'>>(symbols.entity, {
    locationName: String,
    locationNumber: String,
    locationSubType: String,
    address: AddressMetadataMap.symbols.entity,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.entity],
    timeZone: TimezoneMetadataMap.symbols.entity,
    vendors: [DefaultVendorFragmentMetadataMap.symbols.entity],
    carriers: [DefaultCarrierFragmentMetadataMap.symbols.entity],
    customers: [DefaultCustomerFragmentMetadataMap.symbols.entity],
    contacts: [ContactMetadataMap.symbols.entity],
    relatedLocations: [DefaultLocationFragmentMetadataMap.symbols.entity],
    locationType: ITypeEntityFragmentSymbol,
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.entity],
});

withMetadata<LocationCreate>(symbols.createBody, {
    locationName: String,
    locationNumber: String,
    locationSubType: String,
    locationTypeId: String,
    address: AddressMetadataMap.symbols.createBody,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.createBody],
    timeZone: TimezoneMetadataMap.symbols.createBody,
    vendors: [String],
    carriers: [String],
    customers: [String],
    contacts: [IdIsDefaultSymbol],
    resourceAuth: ResourceAuthSymbol,
    relatedLocations: [String],
    features: [String],
});

withDefaultOmniSearchParamsMetadata<LocationSearchParams>(
    symbols.searchParams,
    {
        locationName: String,
    }
);

export const LocationMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LocationRead, LocationDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<LocationDto, LocationRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LocationDto, LocationCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                body => body.carriers,
                mapFrom(dto => dto.carriers?.map(c => c.id))
            ),
            forMember(
                body => body.vendors,
                mapFrom(dto => dto.vendors?.map(v => v.id))
            ),
            forMember(
                body => body.customers,
                mapFrom(dto => dto.customers?.map(c => c.id))
            ),
            forMember(
                body => body.relatedLocations,
                mapFrom(dto => dto.relatedLocations?.map(c => c.id))
            )
        );
        createMap<IQueryParams<LocationDto>, LocationSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('locationName'),
            withSearchFilter('locationNumber'),
            withSearchFilter('locationTypeName'),
            forMember(
                d => d.customerIds,
                mapFrom(s => s.filters?.customers)
            ),
            forMember(
                d => d.vendorIds,
                mapFrom(s => s.filters?.vendors)
            ),
            ...withDefaultOmniSearchParamsMappingConfiguration(
                LocationSearchFieldsMapper
            )
        );
        AddressMetadataMap.profile(mapper);
        AssociatedAddressMetadataMap.profile(mapper);
        TimezoneMetadataMap.profile(mapper);
        DefaultCarrierFragmentMetadataMap.profile(mapper);
        DefaultCustomerFragmentMetadataMap.profile(mapper);
        DefaultVendorFragmentMetadataMap.profile(mapper);
        CustomerMetadataMap.profile(mapper);
        ContactMetadataMap.profile(mapper);
        DefaultLocationFragmentMetadataMap.profile(mapper);
        CustomFieldMetadataMap.profile(mapper);
    },
};

export const LocationSearchFieldsMapper: Partial<
    Record<keyof LocationDto, LocationOmniSearchFieldTypes[]>
> = {
    locationName: ['LocationName'],
    locationNumber: ['LocationNumber'],
    locationTypeName: ['LocationTypeName'],
    address: [
        'AddressCity',
        'AddressGeoZone',
        'AddressPostalCode',
        'AddressName',
        'AddressLine',
    ],
};
