import * as saveAs from 'file-saver';

import {
    BehaviorSubject,
    catchError,
    forkJoin,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';
import {
    EntityChangeTrackingViewModel,
    ExportConfig,
    getTableRowValue,
    IChangesSearchParams,
    IChangeTrackingFacade,
    IEntity,
    IEntityChangeTrackingService,
    IEntityDataService,
    IPagedResult,
    IQueryParams,
    SafeAny,
    SelectOption,
    TableColumn,
} from '@pf/shared-common';
import { sortOrderConverter } from '@pf/shared-utility';

import { LoggerService, ValueFormatterService } from '@pf/shared-services';
import { getValueByPointer } from 'fast-json-patch';
import { inject } from '@angular/core';
import { normalizeChanges } from '../utility/change-tracking.util';
import { unparse } from 'papaparse';

export type ChangeTrackingFacadeFactory<TEntity extends IEntity> = (
    entityId: string
) => IChangeTrackingFacade<TEntity>;

export function BuildChangeTrackingFacadeFactory<TEntity extends IEntity>(
    dataService: IEntityDataService<TEntity, unknown>,
    nameField: IChangeTrackingFacade<TEntity>['nameField']
): ChangeTrackingFacadeFactory<TEntity> {
    const vfs = inject(ValueFormatterService);
    const logger = inject(LoggerService);
    return (entityId: string) =>
        new ChangeTrackingFacade(dataService, entityId, vfs, logger, nameField);
}

export class ChangeTrackingFacade<TEntity extends IEntity>
    implements IChangeTrackingFacade<TEntity>
{
    private readonly _currentPageSubject = new BehaviorSubject<
        IPagedResult<EntityChangeTrackingViewModel<TEntity>>
    >({} as IPagedResult<EntityChangeTrackingViewModel<TEntity>>);

    constructor(
        private dataService: IEntityChangeTrackingService<TEntity>,
        private entityId: string,
        private valueFormatterService: ValueFormatterService,
        private logger: LoggerService,
        nameField: keyof TEntity
    ) {
        this.nameField = nameField;
    }

    searchAll$(): Observable<EntityChangeTrackingViewModel<TEntity>[]> {
        throw new Error('Method not implemented.');
    }

    nameField!: keyof TEntity;

    currentPage$ = this._currentPageSubject.asObservable();

    getEntityName(
        changeTrackingVm: EntityChangeTrackingViewModel<TEntity>
    ): string {
        return changeTrackingVm?.entity
            ? (changeTrackingVm.entity[this.nameField] as unknown as string)
            : '';
    }

    getRecentChanges$(): Observable<EntityChangeTrackingViewModel<TEntity>[]> {
        return this.dataService
            .searchChanges$({
                entityId: this.entityId,
                pageNumber: 1,
                pageSize: 3,
                orderBy: ['createdDate:desc'],
            })
            .pipe(map(pageResult => pageResult.data));
    }

    search$(
        params: IQueryParams<EntityChangeTrackingViewModel<TEntity>>
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<TEntity>>> {
        return this.dataService
            .searchChanges$({
                entityId: this.entityId,
                pageNumber: params.pageIndex,
                pageSize: params.pageSize,
                search: params.filters?.entity as string,
                orderBy: sortOrderConverter.convert(params) || undefined,
            })
            .pipe(tap(result => this._currentPageSubject.next(result)));
    }

    searchByText$(
        text: string
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<TEntity>>> {
        return this.search$({
            pageIndex: 1,
            pageSize: 5,
            sortOrder: 'descend',
            sortField: 'createdDate',
            filters: {
                entity: text,
            },
        });
    }

    getMany$(): Observable<EntityChangeTrackingViewModel<TEntity>[]> {
        throw new Error('Method not implemented.');
    }

    getAll$(): Observable<EntityChangeTrackingViewModel<TEntity>[]> {
        throw new Error('Method not implemented.');
    }

    export$(
        config: ExportConfig<EntityChangeTrackingViewModel<TEntity>>
    ): Observable<boolean> {
        const searchParams = {
            entityId: this.entityId,
        };
        this.logger.debug(`Exporting change tracking`);
        const exportColumns: TableColumn<EntityChangeExportVm>[] = [
            {
                headerName: 'Field',
                field: 'field',
            },
            {
                headerName: 'Previous Value',
                field: 'previousValue',
            },
            {
                headerName: 'New Value',
                field: 'newValue',
            },
            {
                headerName: 'User',
                field: 'userEmail',
            },
            {
                headerName: 'Time Changed',
                field: 'createdDate',
            },
        ];
        return this.fetchAllChanges$(searchParams).pipe(
            map(result => {
                this.logger.debug('Mapping result to export data array');
                return result.map(row => {
                    return exportColumns.map(column => {
                        return getTableRowValue(column, row);
                    });
                });
            }),
            map(data => {
                this.logger.debug('Parse data to csv string');
                const fields = exportColumns.map(c => c.headerName);
                const csvString = unparse({
                    fields,
                    data,
                });
                return csvString;
            }),
            map(csvString => {
                this.logger.debug('Save csv string. ' + csvString);
                saveAs(
                    new Blob([csvString], { type: 'text/csv' }),
                    `${this.valueFormatterService.format(
                        'fileTimestamp',
                        new Date()
                    )}_${config.fileName}`,
                    { autoBom: false }
                );
                return true;
            }),
            catchError(err => {
                this.logger.error('Failed to export. ' + JSON.stringify(err));
                return of(false);
            })
        );
    }

    private fetchAllChanges$(
        searchParams: IChangesSearchParams
    ): Observable<EntityChangeExportVm[]> {
        const pageSize = 100;
        searchParams.pageNumber = 1;
        searchParams.pageSize = pageSize;
        return this.dataService.searchChanges$(searchParams).pipe(
            switchMap(result => {
                const pages = Math.ceil(result.totalRecords / pageSize);

                if (pages === 1) {
                    return of([result]);
                }

                const requests = new Array(pages - 1).fill(0).map((_, i) => {
                    const nextQueryParams = {
                        ...searchParams,
                        pageNumber: i + 2,
                    };
                    return this.dataService.searchChanges$(nextQueryParams);
                });
                return forkJoin([of(result), ...requests]);
            }),
            // Reduce array of results into one array
            map(results => {
                const allResults = results.reduce((acc, result) => {
                    acc.push(...result.data);
                    return acc;
                }, [] as EntityChangeTrackingViewModel<TEntity>[]);
                return allResults;
            }),
            // Reduce change vm into EntityChangeExportVm array
            map(results => {
                const flattenedChanges = results.reduce((pv, cv) => {
                    return pv.concat(
                        normalizeChanges(cv.changes).map(change => {
                            const previousValue =
                                (change.path &&
                                    getValueByPointer(
                                        cv.entity,
                                        change.path
                                    )) ||
                                'None';
                            return {
                                type: change.op,
                                field: change.path
                                    .substring(1)
                                    .replace(/\//g, '.'),
                                previousValue: JSON.stringify(previousValue),
                                newValue: JSON.stringify(
                                    change.value || 'None'
                                ),
                                userEmail: cv.userEmail || '',
                                createdDate: this.valueFormatterService.format(
                                    'datetime',
                                    cv.createdDate
                                ),
                            };
                        })
                    );
                }, [] as EntityChangeExportVm[]);
                return flattenedChanges;
            })
        );
    }

    getById(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _: string
    ): EntityChangeTrackingViewModel<TEntity> {
        throw new Error('Method not implemented.');
    }

    getById$(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _: string
    ): Observable<EntityChangeTrackingViewModel<TEntity>> {
        throw new Error('Method not implemented.');
    }

    mapToSelectOptions(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _: Observable<IPagedResult<EntityChangeTrackingViewModel<TEntity>>>
    ): Observable<SelectOption[]> {
        throw new Error('Method not implemented.');
    }
}

interface EntityChangeExportVm {
    type: string;
    field: string;
    previousValue: SafeAny;
    newValue: SafeAny;
    userEmail: string;
    createdDate: string;
}
