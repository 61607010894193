import {
    Converter,
    convertUsing,
    forMember,
    mapFrom,
    MappingConfiguration,
} from '@automapper/core';
import {
    EntitySymbols,
    IEntity,
    IEntityFragment,
    IOmniFieldSearchParams,
    IPagedSearchParams,
    IQueryParams,
    SafeAny,
    SearchStrategy,
    TableQueryParams,
} from '@pf/shared-common';
import { PojoMetadata, PojosMetadataMap } from '@automapper/pojos';

function SymbolName(entityName: string, type: string) {
    return `${entityName}_${type}`;
}

export const entitySymbols = (entityName: string): EntitySymbols => {
    return {
        entity: SymbolName(entityName, 'entity'),
        createBody: SymbolName(entityName, 'createBody'),
        dto: SymbolName(entityName, 'dto'),
        searchParams: SymbolName(entityName, 'entitySearchParams'),
    };
};

export type ModelMetadata<T> = Record<keyof T, PojoMetadata | [PojoMetadata]>;
export type EntityMetadataExtras<TEntity> = Record<
    keyof Omit<TEntity, 'id' | 'isDeleted' | 'createdDate' | 'modifiedDate'>,
    PojoMetadata | [PojoMetadata]
>;
export type SearchParamsMetadataExtras<TSearchParams> = Record<
    keyof Omit<
        TSearchParams,
        'pageNumber' | 'pageSize' | 'orderBy' | 'doCount' | 'isDeleted'
    >,
    PojoMetadata | [PojoMetadata]
>;
export const withMetadata = <T>(
    symbol: string | symbol,
    metadata: ModelMetadata<T>
) => {
    PojosMetadataMap.create<T>(symbol, metadata);
};
export const withDefaultEntityMetadata = <TEntity extends IEntity>(
    symbol: string | symbol,
    metadataExtras: EntityMetadataExtras<
        Omit<
            TEntity,
            | 'id'
            | 'isDeleted'
            | 'createdDate'
            | 'modifiedDate'
            | 'lastModifiedDate'
        >
    >
): void => {
    withMetadata(symbol, {
        id: String,
        isDeleted: Boolean,
        createdDate: Date,
        modifiedDate: Date,
        lastModifiedDate: Date,
        ...metadataExtras,
    } as ModelMetadata<TEntity>);
};
export const withDefaultFragmentMetadata = <
    TEntityFragment extends IEntityFragment
>(
    symbol: string | symbol,
    metadataExtras: EntityMetadataExtras<
        Omit<TEntityFragment, 'id' | 'lastModifiedDate'>
    >
): void => {
    withMetadata(symbol, {
        id: String,
        lastModifiedDate: Date,
        ...metadataExtras,
    } as ModelMetadata<TEntityFragment>);
};
export const withDefaultSearchParamsMetadata = <
    TSearchParams extends IPagedSearchParams
>(
    symbol: string | symbol,
    metadataExtras: Partial<SearchParamsMetadataExtras<TSearchParams>>
): void => {
    withMetadata(symbol, {
        pageNumber: Number,
        pageSize: Number,
        orderBy: [String],
        doCount: Boolean,
        isDeleted: Boolean,
        createdStartDate: String,
        createdEndDate: String,
        modifiedStartDate: String,
        modifiedEndDate: String,
        ...metadataExtras,
    } as ModelMetadata<TSearchParams>);
};
export const withDefaultOmniSearchParamsMetadata = <
    TSearchParams extends IPagedSearchParams
>(
    symbol: string | symbol,
    metadataExtras: Partial<SearchParamsMetadataExtras<TSearchParams>>
): void => {
    withMetadata(symbol, {
        pageNumber: Number,
        pageSize: Number,
        orderBy: [String],
        doCount: Boolean,
        isDeleted: Boolean,
        searchStartsWithFields: [String],
        searchStartsWith: [String],
        searchContainsFields: [String],
        searchContains: [String],
        searchEqualsFields: [String],
        searchEquals: [String],
        ...metadataExtras,
    } as ModelMetadata<TSearchParams>);
};
export const sortOrderConverter: Converter<
    IQueryParams<IEntity>,
    string[] | null
> = {
    convert(source: IQueryParams<IEntity>) {
        if (!source.sortField) {
            return null;
        }
        const sortOrder = {
            ascend: 'asc',
            descend: 'desc',
        };
        return [
            `${source.sortField?.toString()}:${
                sortOrder[source.sortOrder || 'ascend']
            }`,
        ];
    },
};
export const withDefaultSearchParamsMappingConfiguration = <
    TSearchParams extends IPagedSearchParams
>(): MappingConfiguration<IQueryParams<IEntity>, TSearchParams>[] => {
    return [
        forMember(
            d => d.pageNumber,
            mapFrom(s => s.pageIndex)
        ),
        forMember(
            d => d.pageSize,
            mapFrom(s => s.pageSize)
        ),
        forMember(
            d => d.isDeleted,
            mapFrom(s =>
                (s as TableQueryParams<IEntity>).checkboxes?.isDeleted
                    ? (null as SafeAny)
                    : false
            )
        ),
        forMember(
            d => d.orderBy,
            convertUsing(sortOrderConverter, s => s)
        ),
        forMember(
            d => d.createdStartDate,
            mapFrom(s => (s.filters as SafeAny)?.['createdStartDate'])
        ),
        forMember(
            d => d.createdEndDate,
            mapFrom(s => (s.filters as SafeAny)?.['createdEndDate'])
        ),
        forMember(
            d => d.modifiedStartDate,
            mapFrom(s => (s.filters as SafeAny)?.['modifiedStartDate'])
        ),
        forMember(
            d => d.modifiedEndDate,
            mapFrom(s => (s.filters as SafeAny)?.['modifiedEndDate'])
        ),
    ];
};
export const withDefaultOmniSearchParamsMappingConfiguration = <
    TDto extends IEntity,
    TSearchField,
    TSearchParams extends IOmniFieldSearchParams
>(
    mapper: Partial<Record<keyof TDto, TSearchField[]>>
): SafeAny[] => {
    return [
        ...(withDefaultSearchParamsMappingConfiguration<IPagedSearchParams>() as MappingConfiguration<
            IQueryParams<TDto>,
            SafeAny
        >[]),
        forMember(
            d => d.searchContainsFields,
            mapFrom(s => {
                return assignSearchFields(SearchStrategy.Contains, s, mapper);
            })
        ),
        forMember(
            d => d.searchContains,
            mapFrom(s =>
                s.searchStrategy === SearchStrategy.Contains
                    ? s.searchValue || null
                    : null
            )
        ),
        forMember(
            d => d.searchEqualsFields,
            mapFrom(s => {
                return assignSearchFields(SearchStrategy.Equals, s, mapper);
            })
        ),
        forMember(
            d => d.searchEquals,
            mapFrom(s =>
                s.searchStrategy === SearchStrategy.Equals
                    ? s.searchValue || null
                    : null
            )
        ),
        forMember(
            d => d.searchStartsWithFields,
            mapFrom(s => {
                return assignSearchFields(SearchStrategy.StartsWith, s, mapper);
            })
        ),
        forMember(
            d => d.searchStartsWith,
            mapFrom(s =>
                s.searchStrategy === SearchStrategy.StartsWith
                    ? s.searchValue || null
                    : null
            )
        ),
    ] as MappingConfiguration<IQueryParams<TDto>, TSearchParams>[];
};
const assignSearchFields = <TDto extends IEntity, TSearchField>(
    searchStrategy: SearchStrategy,
    params: IQueryParams<TDto>,
    mapper: Partial<Record<keyof TDto, TSearchField[]>>
): Array<string> | null => {
    if (searchStrategy !== params.searchStrategy || !params.searchValue) {
        return null;
    }

    return Object.values(params.searchFields || {}).reduce((prev, curr) => {
        const searchFields = mapper[curr as keyof TDto] as TSearchField[];
        if (searchFields) {
            return [...prev, ...searchFields];
        }
        return prev;
    }, [] as TSearchField[]) as SafeAny as Array<string>;
};
export const withSearchFilter = <
    TDto,
    TSearchParams extends IPagedSearchParams
>(
    dtoProp: keyof TDto,
    searchParamsProp?: keyof TSearchParams
): MappingConfiguration<IQueryParams<TDto>, TSearchParams> => {
    return forMember(
        d => (d as SafeAny)[searchParamsProp || dtoProp],
        mapFrom(s => {
            if (s.searchStrategy && s.searchStrategy >= 0) {
                return null;
            }
            return s.filters?.[dtoProp];
        })
    );
};
