import { Route } from '@angular/router';
import {
    defaultLoadMenu,
    defaultLoadRoutes, defaultLoadSubMenus, defaultManageMenu,
    defaultManageRoutes, defaultManageSubMenus, defaultRouters,
    defaultRouteTemplate, defaultTransportationMenu,
    defaultTransportationRoutes, defaultTransportationSubMenus,
    PlatformRoutes,
    RootRoutesForLoads,
    RootRoutesForTransportation,
    RoutesForManage
} from '@pf/shared/ui-app-shared';
import { Injectable, Provider } from '@angular/core';
import { IAppMenu } from '@pf/shared-ui';

export const appRoutes: Route[] = defaultRouteTemplate('loads/loads', [
    defaultLoadRoutes(),
    defaultManageRoutes(),
    defaultTransportationRoutes()
]);

@Injectable()
export class AppRouter extends PlatformRoutes {
    loads = this.childRoutes('loads', RootRoutesForLoads);
    manage = this.childRoutes('manage', RoutesForManage);
    transportation = this.childRoutes('transportation', RootRoutesForTransportation);

    constructor() {
        super();
        this.setupNavigation();
    }

    protected getAllMenuItems(): IAppMenu[] {
        return [
            defaultTransportationMenu(defaultTransportationSubMenus(this.transportation)),
            defaultLoadMenu(defaultLoadSubMenus(this.loads)),
            defaultManageMenu(defaultManageSubMenus(this.manage))
        ];
    }
}

export const appRouteProviders = [
    ...defaultRouters,
    AppRouter
] as Provider[];
