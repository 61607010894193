import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContentComponent } from '../components/content-layout/content.component';

export interface IPageAnchor {
    id: string;
    title: string;
    index?: number;
}
export interface ContentSection extends IPageAnchor {
    articles: IPageAnchor[];
}

@Injectable()
export class ContentService {
    private _sections = new BehaviorSubject<ContentSection[]>([]);
    sections$ = this._sections.asObservable();
    private _content?: ContentComponent;

    get sections() {
        return this._sections.value;
    }

    get scrollContainer() {
        return this._content?.scrollContainer;
    }

    registerContent(content: ContentComponent) {
        this._content = content;
    }

    registerSection(section: ContentSection) {
        if (this.sections.some((s) => s.id === section.id)) {
            return;
        }
        this._sections.next([
            ...this.sections,
            {
                ...section,
                articles: [],
            },
        ]);
    }

    registerArticle(sectionId: string, article: IPageAnchor, retries = 0) {
        const sectionsCopy = [...this.sections];
        const sectionIndex = sectionsCopy.findIndex((s) => s.id === sectionId);
        if (sectionIndex === -1) {
            if (retries >= 3) {
                throw new Error(`Section ${sectionId} is not registered!`);
            }
            setTimeout(() => {
                this.registerArticle(sectionId, article, retries + 1);
            });
            return;
        }

        const section = sectionsCopy[sectionIndex];
        sectionsCopy[sectionIndex] = {
            ...section,
            articles: [...section.articles, article].sort(
                (a, b) => (a.index ?? 0) - (b.index ?? 0)
            ),
        };
        this._sections.next(sectionsCopy);
    }
}
