<nz-form-item class="pf-form-item">
    <nz-form-label
        *ngIf="!!label"
        class="pf-form-label"
        [nzRequired]="pfRequired"
        [title]="label"
        [nzNoColon]="true"
        [nzTooltipTitle]="tooltip"
    >{{ label }}</nz-form-label
    >
    <nz-form-control class="pf-form-control" [nzAutoTips]="autoTips">
        <div class="input-container">
            <ng-template #tagPlaceHolder let-selectedList
            >+ {{ selectedList.length }}</ng-template
            >
            <ng-container *ngIf="!tree">
                <nz-select
                    #select
                    class="pf-input"
                    nzAllowClear
                    [nzServerSearch]="!!searchFacade"
                    [(nzOpen)]="pfOpen"
                    [nzMode]="multiple ? 'multiple' : 'default'"
                    [nzMaxTagCount]="tagAmount"
                    [nzMaxTagPlaceholder]="tagPlaceHolder"
                    [nzShowSearch]="searchable"
                    [nzLoading]="loading"
                    [nzDisabled]="pfDisabled"
                    [nzPlaceHolder]="placeholder"
                    [formControl]="formControl"
                    (nzOpenChange)="onOpenChange($event)"
                    (nzOnSearch)="onSearchHandler($event)"
                    [nzDropdownRender]="actionRenderTemplate"
                    (ngModelChange)="onChange($event)">
                    <nz-option *ngIf="loading" nzDisabled nzCustomContent>
                        <span nz-icon nzType="loading" class="loading-icon"></span>
                        Loading Data...
                    </nz-option>
                    <nz-option
                        *ngIf="!pfRequired && !multiple"
                        [nzValue]=""
                        nzLabel=""></nz-option>
                    <ng-container *ngIf="grouped">
                        <nz-option-group *ngFor="let group of groupedData$ | async" [nzLabel]="group.name">
                            <nz-option
                                *ngFor="let option of group.options"
                                [nzValue]="option.value"
                                [nzLabel]="option.label || ''"
                                [nzDisabled]="option.disabled"></nz-option>
                        </nz-option-group>
                    </ng-container>
                    <ng-container *ngIf="!grouped">
                        <nz-option
                            *ngFor="let option of flatData$ | async | safeArray"
                            [nzValue]="option.value"
                            [nzLabel]="option.label || ''"
                            [nzDisabled]="option.disabled"></nz-option>
                    </ng-container>
                </nz-select>
                <ng-template #actionRenderTemplate>
                    <ng-container *ngIf="dropdownAction">
                        <nz-divider></nz-divider>
                        <div class="action-container">
                            <input
                                type="text"
                                nz-input
                                #inputElement
                                [attr.hidden]="
                                    !dropdownAction.input ? true : null
                                " />
                            <pf-button
                                [buttonType]="dropdownAction.buttonType"
                                class="action-btn"
                                (click)="actionHandler(inputElement.value)">
                                {{ dropdownAction.label }}
                            </pf-button>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="tree">
                <nz-tree-select
                    #treeSelect
                    class="pf-input"
                    nzAllowClear
                    [nzMultiple]="multiple"
                    [nzMaxTagCount]="tagAmount"
                    [nzMaxTagPlaceholder]="tagPlaceHolder"
                    [nzShowSearch]="searchable"
                    [nzDisabled]="pfDisabled"
                    [nzPlaceHolder]="placeholder"
                    [formControl]="formControl"
                    [nzDropdownMatchSelectWidth]="true"
                    [nzAsyncData]="!!treeSearchFacade"
                    [nzExpandedKeys]="expandedKeys"
                    [nzNodes]="treeData"
                    [nzShowLine]="true"
                    [nzExpandedIcon]="expandIconTemplate"
                    nzVirtualHeight="300px"
                    nzHideUnMatched="true"
                    (nzExpandChange)="onTreeExpandChange($event)"
                    (nzOpenChange)="onOpenChange($event)"
                    (ngModelChange)="onTreeChange($event)"></nz-tree-select>
                <ng-template #expandIconTemplate let-node let-origin="origin">
                    <pf-icon
                        *ngIf="origin.isLeaf && node.icon"
                        [iconType]="node.icon"></pf-icon>
                    <pf-icon
                        *ngIf="!origin.isLeaf"
                        [iconType]="
                            node.isExpanded ? 'collapseTree' : 'expandTree'
                        "></pf-icon>
                </ng-template>
            </ng-container>
            <ng-content select="[input-suffix]"></ng-content>
        </div>
    </nz-form-control>
</nz-form-item>
