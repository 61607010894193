import { Injectable } from '@angular/core';
import { AbstractEntityMapper } from '@pf/shared-services';
import {
    TimezoneRead,
    TimezoneCreate,
    TimezoneDto,
    TimezoneSearchParams,
} from '../../entities/timezone.dto';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { TimezoneMetadataMap } from './timezone.mapping.profile';

@Injectable()
export class TimezoneMapper extends AbstractEntityMapper<
    TimezoneRead,
    TimezoneCreate,
    TimezoneDto,
    TimezoneSearchParams
> {
    constructor() {
        super(TimezoneDto, PlatformCoreMapper, TimezoneMetadataMap.symbols);
    }
}
