import { Component } from '@angular/core';
import { AnimationItems, ResultProps } from '@pf/shared-ui';

@Component({
    selector: 'platform-one-time-link-completed',
    templateUrl: './one-time-link-completed.component.html',
    styleUrls: ['./one-time-link-completed.component.scss'],
})
export class OneTimeLinkCompletedComponent {
    completedProps = {
        title: '',
        animationOptions: {
            path: AnimationItems.Checkmark,
        },
    } as ResultProps;
}
