import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    DocumentDto,
    IDocument,
    IDocumentCreate,
    IDocumentDto,
    IDocumentSearchParams,
    IMappingProfile,
} from '@pf/shared-common';
import {
    addProfile,
    CamelCaseNamingConvention,
    createMap,
    createMapper,
    forMember,
    mapFrom,
    Mapper,
} from '@automapper/core';
import { pojos } from '@automapper/pojos';
import { AbstractEntityMapper } from '../abstractions/AbstractEntityMapper';

const documentSymbols = entitySymbols('Documents');

withDefaultEntityMetadata<IDocumentDto>(documentSymbols.dto, {
    url: String,
    documentTypeId: String,
    documentTypeName: String,
    documentTypeCode: String,
    requirementLevel: String,
    fileId: String,
    fileName: String,
    notes: String,
    description: String,
    expirationDate: String,
    file: Blob,
});

withDefaultEntityMetadata<IDocument>(documentSymbols.entity, {
    fileId: String,
    url: String,
    isDefault: Boolean,
    description: String,
    notes: String,
    fileName: String,
    mimeType: String,
    documentType: Object,
    expirationDate: String,
});

withMetadata<IDocumentCreate>(documentSymbols.createBody, {
    documentTypeId: String,
    description: String,
    isDefault: Boolean,
    notes: String,
    fileName: String,
    expirationDate: String,
    file: Blob,
    isPublic: Boolean,
});

export const DocumentsMappingProfile: IMappingProfile = {
    symbols: documentSymbols,
    profile: (mapper: Mapper) => {
        createMap<IDocument, IDocumentDto>(
            mapper,
            documentSymbols.entity,
            documentSymbols.dto,
            forMember(
                dest => dest.documentTypeId,
                mapFrom(src => src.documentType?.id)
            ),
            forMember(
                dest => dest.documentTypeName,
                mapFrom(src => src.documentType?.name)
            ),
            forMember(
                dest => dest.documentTypeCode,
                mapFrom(src => src.documentType?.code)
            )
        );
        createMap<IDocumentDto, IDocument>(
            mapper,
            documentSymbols.dto,
            documentSymbols.entity
        );
        createMap<IDocumentDto, IDocumentCreate>(
            mapper,
            documentSymbols.dto,
            documentSymbols.createBody,
            forMember(
                dest => dest.expirationDate,
                mapFrom(src => src.expirationDate || '')
            )
        );
    },
};

const mapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

addProfile(mapper, DocumentsMappingProfile.profile);

class DocumentsMapper extends AbstractEntityMapper<
    IDocument,
    IDocumentCreate,
    DocumentDto,
    IDocumentSearchParams
> {
    constructor() {
        super(DocumentDto, mapper, DocumentsMappingProfile.symbols);
    }
}

export const documentsMapper = new DocumentsMapper();
