import { FiscalPeriodDataService } from '../infrastructure/fiscal-periods/fiscal-period.data.service';
import {
    FiscalPeriodCreate,
    FiscalPeriodDto,
    FiscalPeriodRead,
    FiscalPeriodSearchParams,
} from '../entities/fiscal-period.dto';
import { FiscalPeriodStore } from '../infrastructure/fiscal-periods/fiscal-period.store';
import { inject, Injectable } from '@angular/core';
import { RoutesForFiscalPeriod } from './fiscal-period.routes';
import {
    AbstractManageEntityFacade,
    EntitySaveOptions,
} from '@pf/shared-services';
import { FiscalPeriodMapper } from '../infrastructure/fiscal-periods/fiscal-period.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { mergeMap, Observable, of } from 'rxjs';
import { SafeAny } from '@pf/shared-common';
import { ModalService } from '@pf/shared-ui';
import { FiscalPeriodStatus, ResourceAuth } from '@control-tower/platform-core';

@Injectable()
export class FiscalPeriodFacade extends AbstractManageEntityFacade<
    FiscalPeriodDto,
    FiscalPeriodRead,
    FiscalPeriodCreate,
    FiscalPeriodSearchParams
> {
    private _modalService = inject(ModalService);
    nameField: keyof FiscalPeriodDto = 'name';
    overlappingModalComponent: SafeAny;

    constructor(
        dataService: FiscalPeriodDataService,
        routes: RoutesForFiscalPeriod,
        store: FiscalPeriodStore,
        mapper: FiscalPeriodMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: FiscalPeriodCreate) {
        body.resourceAuth = {
            allAuthorized: true,
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof FiscalPeriodDto, string>> {
        return { name: searchText };
    }

    closeFiscalPeriod$(id: string): Observable<FiscalPeriodRead> {
        return this.dataService.update$(id, [
            {
                op: 'replace',
                path: '/status',
                value: 'Closed',
            },
        ]);
    }

    checkOverlappingFiscalPeriods$(startDate: string, endDate: string) {
        return (
            this.dataService as FiscalPeriodDataService
        ).getOverlappingFiscalPeriods$(startDate, endDate);
    }

    override save$(
        dto: FiscalPeriodDto,
        options?: EntitySaveOptions<FiscalPeriodDto>
    ): Observable<FiscalPeriodDto> {
        return (
            dto.status === FiscalPeriodStatus.Open
                ? this.checkOverlappingFiscalPeriods$(
                      dto.startDate,
                      dto.endDate
                  )
                : of([])
        ).pipe(
            mergeMap(overlappingFiscalPeriods => {
                if (overlappingFiscalPeriods.length > 0) {
                    this._modalService.createComponentModal({
                        title: 'Conflicting Fiscal Periods',
                        content: this.overlappingModalComponent,
                        componentParams: {
                            period: overlappingFiscalPeriods[0].name,
                            id: overlappingFiscalPeriods[0].id,
                        },
                        cancelText: 'Cancel',
                        okText: `View ${overlappingFiscalPeriods[0].name} Period`,
                        onOk: () => {
                            this.edit(
                                overlappingFiscalPeriods[0].id,
                                undefined,
                                true
                            );
                        },
                    });
                    throw new Error(
                        'Fiscal Periods cannot overlap. Please select a different date range.'
                    );
                }
                return super.save$(dto, options);
            })
        );
    }
}
