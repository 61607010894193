import { Component, Input } from '@angular/core';
import {
    AnimationType,
    FrameworkColumn,
    ITableCellValueRenderer,
    SafeAny,
    StatusStyle,
} from '@pf/shared-common';

export type StatusOption = {
    label: string;
    status?: StatusStyle;
    animation?: AnimationType;
};
export type StatusOptions = Record<string | number | symbol, StatusOption> & {
    defaultOption: StatusOption;
};

@Component({
    selector: 'pf-status-renderer',
    template: `
        <span [attr.status-color]="status" [attr.animation]="animation"></span>
        <span>{{ label }}</span>
    `,
    styleUrls: ['status-renderer.component.scss'],
})
export class StatusRendererComponent implements ITableCellValueRenderer {
    @Input() options!: StatusOptions;
    @Input() value!: SafeAny;
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;

    get option() {
        return this.options?.[this.value] || this.options?.defaultOption;
    }
    get label() {
        return this.option?.label;
    }
    get animation() {
        return this.option?.animation;
    }
    get status() {
        return this.option?.status;
    }
}
