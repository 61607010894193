import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Injector,
    Input,
} from '@angular/core';
import { BaseControlComponent } from '../base-control/base-control.component';
import {
    DatePickerType,
    DatePickerTypePropMap,
    DatePickerTypeProps,
} from './date-picker.types';
import { PresetRanges } from 'ng-zorro-antd/date-picker/standard-types';
import { DATE_CONFIG } from '@pf/shared-services';
import { IDateConfig } from '@pf/shared-common';

@Component({
    selector: 'pf-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent extends BaseControlComponent {
    private _datePickerType!: DatePickerType;

    /**
     * The `placeholder` input is used as a placeholder on the input
     * component.
     */
    @Input() placeholder = '';

    @Input() presetRanges?: PresetRanges;

    /**
     * The `datePickerType` input is allowing us to specify the `pf-input` type.
     * [DatePickerType]{@link DatePickerType}
     */
    @Input()
    set datePickerType(value: keyof typeof DatePickerType) {
        this._datePickerType = DatePickerType[value];
        this.setProps();
    }

    @Input() showTime? = false;

    /**
     * This property will be used to set different input components' property values
     */
    typeProps?: DatePickerTypeProps;

    get dateFormat() {
        return this.showTime
            ? this.dateConfig.dateFormat + ' ' + this.dateConfig.timeFormat
            : this.dateConfig.dateFormat;
    }

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @Inject(DATE_CONFIG) private dateConfig: IDateConfig,
        private cdr: ChangeDetectorRef
    ) {
        super(injector, elementRef);
    }

    /**
     * This function sets the [`typeProps`]{@link InputComponent#typeProps} fields regarding
     * the given [`inputType`]{@link inputType}.
     */
    private async setProps() {
        await new Promise(r => setTimeout(r));
        this.typeProps = DatePickerTypePropMap[this._datePickerType]();
        this.cdr.markForCheck();
        if (this.formControl) {
            this.formControlConfig(this.formControl);
        }
    }
}
