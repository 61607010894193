import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppNotificationVm } from '@pf/shared-common';
import { AppNotificationService } from '../services/app-notification.service';
import { DateService } from '@pf/shared-services';

@Component({
    selector: 'pf-app-notification',
    templateUrl: './app-notification.component.html',
    styleUrls: ['./app-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNotificationComponent {
    @Input()
    notification!: AppNotificationVm;

    constructor(
        private appNotificationService: AppNotificationService,
        private dateService: DateService
    ) {}

    get timeElapsed() {
        return this.notification.createdDate
            ? this.dateService.timeElapsed(this.notification.createdDate) ||
                  'less than 1 minute'
            : '';
    }

    actionClickHandler() {
        this.notification.action?.onClick?.next(this.notification);
        this.dismiss();
    }

    dismiss() {
        this.appNotificationService.remove(
            this.notification.messageId as string,
            this.notification.id
        );
    }
}
