import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFCoreEntities } from '@pf/shared-common';
import { TimezoneRead } from '../../entities/timezone.dto';

@Injectable()
export class TimezoneStore extends AbstractEntityStore<TimezoneRead> {
    constructor() {
        super(PFCoreEntities.Timezone);
    }
}
