import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import {
    InjectionHostLocation,
    InjectionService,
} from '../services/injection.service';

@Directive({
    selector: '[pfHost]',
})
export class InjectionHostDirective implements OnInit {
    @Input() pfHost: InjectionHostLocation = 'local';
    constructor(
        public viewContainerRef: ViewContainerRef,
        private injectionService: InjectionService
    ) {}
    ngOnInit(): void {
        if (this.pfHost === 'local') {
            return;
        }
        this.injectionService.setViewContainerRef(
            this.pfHost,
            this.viewContainerRef
        );
    }
}
