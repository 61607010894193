import { Injectable } from '@angular/core';
import {
    TimeZoneInformation,
    TimeZonesService,
} from '@control-tower/platform-core';
import { IPagedResult, PFCoreEntities } from '@pf/shared-common';
import { AbstractReadDataService, LoggerService } from '@pf/shared-services';
import { map, Observable } from 'rxjs';
import { TimezoneRead } from '../../entities/timezone.dto';
import { TimezoneStore } from './timezone.store';

@Injectable()
export class TimezoneDataService extends AbstractReadDataService<TimezoneRead> {
    protected EntityName = PFCoreEntities.Timezone;

    constructor(
        private timezoneService: TimeZonesService,
        store: TimezoneStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected getObs$(): Observable<TimezoneRead> {
        throw new Error('Method not implemented.');
    }

    protected searchObs$(): Observable<IPagedResult<TimezoneRead>> {
        return this.timezoneService.v1TimezonesGet().pipe(
            map(result => {
                const groupedByOffset = result.reduce((acc, curr) => {
                    if (acc[curr.utcOffsetInHours]) {
                        acc[curr.utcOffsetInHours].push(curr);
                    } else {
                        acc[curr.utcOffsetInHours] = [curr];
                    }
                    return acc;
                }, {} as Record<number, TimeZoneInformation[]>);
                const distinctOffsets = Object.keys(groupedByOffset).map(
                    offset => ({
                        ...groupedByOffset[+offset][0],
                        genericName: groupedByOffset[+offset]
                            .map(o => o.genericName)
                            .join(', ')
                            .slice(0, 250),
                    })
                );
                return {
                    data: distinctOffsets.map(timezone => {
                        return {
                            id: timezone.utcOffsetInHours.toString(),
                            ...timezone,
                        };
                    }),
                    totalRecords: result.length,
                    currentPage: 1,
                    currentPageSize: result.length,
                } as IPagedResult<TimezoneRead>;
            })
        );
    }
}
