import { PlatformSharedEntities } from './shared-entities.enum';
import { ResourceAuth } from '@control-tower/platform-core';
import { withMetadata } from '@pf/shared-utility';

export const ResourceAuthSymbol = PlatformSharedEntities.ResourceAuth;

export const withResourceAuthMetadata = () => {
    withMetadata<ResourceAuth>(ResourceAuthSymbol, {
        allAuthorized: Boolean,
        vendors: [String],
        locations: [String],
        carriers: [String],
        customers: [String],
    });
};
