<article pfArticle [pfTitle]="title">
    <form [formGroup]="form.formGroup">
        <pf-table
            [data]="loading ? [] : form.formGroup.controls.entities.value"
            [loading]="loading"
            [columns]="columns"
            [actions]="actions"
            [options]="tableOptions"
            [disableAutoPageSize]="true"
            [pageSize]="5"
            [width]="'100%'"
        >
            <pf-select
                label=""
                id="entitySearch"
                toolbar-title
                [tree]="tree"
                [placeholder]="'Search ' + title"
                [clearAfterSelect]="true"
                [searchFacade]="facade"
                [controlName]="form.formControlNames.selector"
                (selected)="addEntity($event)"
                [pfDisabled]="disableSelector"
                [title]="disableSelector ? maxAssociationsSelectTitle : selectTitle"

            ></pf-select>
            <ng-content ngProjectAs="[toolbar-title]"></ng-content>
        </pf-table>
    </form>
</article>
