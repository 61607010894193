<nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource">
    <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
        <nz-tree-node-toggle nzTreeNodeNoopToggle></nz-tree-node-toggle>
        <nz-tree-node-option>
            <div
                [ngClass]="{
                    added: node.relatedChange?.type === 'add',
                    updated: node.relatedChange?.type === 'replace',
                    removed: node.relatedChange?.type === 'remove'
                }"
            >
                <span
                    ><strong>{{ node.prop }}</strong
                    >: {{ node.value }}</span
                >
                <span *ngIf="node.relatedChange" class="related-change">
                    >
                    <span class="updated-value">{{
                        node.relatedChange.value
                    }}</span></span
                >
            </div>
        </nz-tree-node-option>
    </nz-tree-node>

    <nz-tree-node
        *nzTreeNodeDef="let node; when: hasChild"
        nzTreeNodeIndentLine
    >
        <nz-tree-node-toggle
            *ngIf="node.level === 0"
            title="Expand All"
            [nzTreeNodeToggleRecursive]="true"
        >
            <pf-icon
                [iconType]="
                    treeControl.isExpanded(node) ? 'expandAll' : 'collapseAll'
                "
                [rotate]="treeControl.isExpanded(node) ? undefined : 90"
            ></pf-icon>
        </nz-tree-node-toggle>
        <nz-tree-node-toggle>
            <pf-icon
                [iconType]="
                    treeControl.isExpanded(node) ? 'expand' : 'collapse'
                "
                nzTreeNodeToggleRotateIcon
                [rotate]="treeControl.isExpanded(node) ? undefined : 90"
            ></pf-icon>
            <!-- <span nz-icon nzType="caret-down" ></span> -->
        </nz-tree-node-toggle>
        <nz-tree-node-option [nzDisabled]="node.disabled">
            <span
                [ngClass]="{
                    added: node.relatedChange?.type === 'add',
                    removed: node.relatedChange?.type === 'remove'
                }"
                >{{ node.prop }}</span
            >
        </nz-tree-node-option>
    </nz-tree-node>
</nz-tree-view>
<pf-button buttonType="view" id="view-data-btn" (click)="openChangeData()"
    >View Data</pf-button
>
<nz-modal
    [(nzVisible)]="showChangeData"
    nzTitle="History Data"
    (nzOnCancel)="closeChangeData()"
    [nzFooter]="null"
    [nzWidth]="'90vw'"
    [nzCentered]="true"
    [nzZIndex]="10000"
>
    <ng-container *nzModalContent>
        <platform-change-data-view
            [change]="entityChange"
        ></platform-change-data-view>
    </ng-container>
</nz-modal>
