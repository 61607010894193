<form *ngIf='customFieldsForm' [formGroup]='customFieldsForm' nz-form>
  <div class='pf-form-group' [ngClass]="{ compact: pfStyle === 'block', column: pfColumn }">
    <ng-content></ng-content>
    <ng-container
      *ngFor='
                let customFieldConfig of processedCustomFieldConfigs;
                trackBy: trackByFn
            '
    >
      <platform-custom-field-list *ngIf='customFieldConfig.list && customFieldLists[customFieldConfig.key]?.input'
                                  [customFieldKey]='customFieldConfig.key'
                                  [showSearch]='false'
                                  [listLabel]="customFieldConfig.label || ''"
                                  [customFields]='customFieldLists[customFieldConfig.key].input | safeArray'
                                  (itemsChanged)='listItemsChanged($event, customFieldConfig.key)'
      ></platform-custom-field-list>
      <pf-dynamic-control
        *ngIf='!customFieldConfig.list'
        [config]='customFieldConfig'
        [pfStyle]='pfStyle'
        [formControlName]='customFieldConfig.key'
      ></pf-dynamic-control>
    </ng-container>
    <div *ngIf='processedCustomFieldConfigs.length % 2'></div>
  </div>
</form>
