import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    EntityChangeTrackingViewModel,
    NotificationType,
    SafeAny,
    ToastType,
} from '@pf/shared-common';
import { Operation, applyPatch } from 'fast-json-patch';

import { ToastMessageService } from '@pf/shared-ui';
import { copyToClipboard } from '@pf/shared-utility';
import { normalizeChanges } from '@pf/shared/util-platform';

@Component({
    selector: 'platform-change-data-view',
    templateUrl: './change-data-view.component.html',
    styleUrls: ['./change-data-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeDataViewComponent {
    left?: string;
    right?: string;
    @Input() set change(vm: EntityChangeTrackingViewModel<unknown>) {
        const changes = this.orderChanges(
            normalizeChanges(vm.changes) as Operation[]
        );
        this.left = JSON.stringify(vm.entity, null, '\t');
        this.right = JSON.stringify(
            applyPatch(vm.entity as SafeAny, changes, false, false).newDocument,
            null,
            '\t'
        );
    }

    constructor(private toastService: ToastMessageService) {}

    async copy(text: string) {
        const copied = await copyToClipboard(text);
        if (copied) {
            this.toastService.create({
                message: 'Copied to clipboard!',
                toastType: ToastType.info,
                type: NotificationType.ToastMessage,
                persist: 1000,
            });
        } else {
            this.toastService.create({
                message: 'Unable to copy to clipboard!',
                toastType: ToastType.warning,
                type: NotificationType.ToastMessage,
                persist: 1000,
            });
        }
    }

    private orderChanges(changes: Operation[]) {
        return changes.sort((a, b) => {
            if (a.op === 'remove' && b.op === 'remove') {
                return b.path.localeCompare(a.path);
            }
            if (a.op === 'remove') {
                return 1;
            }
            if (b.op === 'remove') {
                return -1;
            }
            return b.path.localeCompare(a.path);
        });
    }
}
