import { AssociatedAddressDto } from '@pf/shared/util-platform';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { subformComponentProviders } from 'ngx-sub-form';

import { EntityListBaseFormComponent } from '@pf/shared-ui';
import { TableColumn } from '@pf/shared-common';

@Component({
    selector: 'platform-addresses-form',
    templateUrl: './addresses-form.component.html',
    styleUrls: ['./addresses-form.component.scss'],
    providers: [...subformComponentProviders(AddressesFormComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesFormComponent extends EntityListBaseFormComponent<AssociatedAddressDto> {
    subFormEntityType = 'Address';

    entityType = 'Addresses';

    @Input() associatedAddress = false;

    protected createItem(): AssociatedAddressDto {
        return new AssociatedAddressDto({});
    }

    columns: TableColumn<AssociatedAddressDto>[] = [
        {
            field: ['address', 'name'],
            headerName: 'Name',
            search: true,
            showSort: true,
        },
        {
            field: ['address', 'addressLine1'],
            headerName: 'Street Address',
            search: true,
            showSort: true,
        },
        {
            field: ['address', 'city'],
            headerName: 'City/Town',
            search: true,
            showSort: true,
        },
        {
            field: ['address', 'geoZone'],
            headerName: 'State/Province',
            search: true,
            showSort: true,
        },
        {
            field: ['address', 'postalCode'],
            headerName: 'PostalCode',
            search: true,
            showSort: true,
        },
        {
            field: ['address', 'country'],
            headerName: 'Country/Region',
            search: true,
            showSort: true,
        },
        {
            field: 'description',
            headerName: 'Description',
            search: true,
            showSort: true,
        },
        {
            field: 'associatedAddressType',
            headerName: 'Type',
        },
    ];
}
