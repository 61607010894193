import {
    AddressDto,
    AssociatedAddressDto,
    IContact,
} from '@pf/shared/util-platform';

import { ContactSummaryComponent } from '../templates/contact.component';
import { TableColumnBuilder } from '@pf/shared-ui';
import { IEntity, IManageEntityFacade } from '@pf/shared-common';

function getAddress(associatedAddress: AssociatedAddressDto) {
    const address = associatedAddress.address ?? new AddressDto({});
    return (
        address.city +
        address.name +
        address.addressLine1 +
        address.addressLine2 +
        address.country +
        address.postalCode +
        associatedAddress.associatedAddressType?.toLowerCase()
    );
}

export class PlatformCommonTableColumns {
    static associatedAddressesColumn<
        T extends IEntity & { associatedAddresses?: AssociatedAddressDto[] }
    >(builder: TableColumnBuilder<T>, facade: IManageEntityFacade<T>) {
        return builder.withDistinctCountColumn(
            'associatedAddresses',
            'Addresses',
            (associatedAddress: AssociatedAddressDto) =>
                getAddress(associatedAddress),
            facade,
            'associated_addresses'
        );
    }

    static contactsColumn<T extends IEntity & { contacts?: IContact[] }>(
        builder: TableColumnBuilder<T>,
        facade: IManageEntityFacade<T>
    ) {
        return builder.withListPreviewColumn(
            'contacts',
            'Contacts',
            {
                displayFn: (contact: IContact) =>
                    contact.firstName + ' ' + contact.lastName,
                popoverDisplayComponent: ContactSummaryComponent,
            },
            facade,
            'contacts'
        );
    }
}
