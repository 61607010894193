import {
    AppNotification,
    AppNotificationVm,
    NotificationActionVm,
} from '@pf/shared-common';
import {
    ChangeDetectionStrategy,
    Component,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { NzNotificationComponent } from 'ng-zorro-antd/notification';

@Component({
    selector: 'pf-page-header',
    template: `
        <ng-template #notificationTpl let-notification let-data="data">
            <div
                class="ant-notification-notice-content ant-notification-notice-with-icon"
            >
                <pf-icon
                    class="ant-notification-notice-icon"
                    [iconType]="data.iconType"
                ></pf-icon>
                <div class="ant-notification-notice-with-icon">
                    <div>
                        <div class="ant-notification-notice-message">
                            {{ data.title }}
                        </div>
                        <div class="ant-notification-notice-description">
                            {{ data.message }}
                        </div>
                        <span
                            class="ant-notification-notice-btn"
                            *ngIf="data.action"
                        >
                            <pf-button
                                [buttonType]="data.action.buttonType"
                                (click)="actionClicked(data, notification)"
                                >{{ data.action.text }}</pf-button
                            >
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,

    // styles: [``],
})
export class AppNotificationTemplatesComponent {
    @ViewChild('notificationTpl', { static: false })
    notificationTpl!: TemplateRef<AppNotification>;

    actionClicked(
        notification: AppNotificationVm,
        notificationRef?: NzNotificationComponent
    ) {
        if (!notification.action) {
            return;
        }
        (notification.action as NotificationActionVm).onClick?.next(
            notification
        );
        notificationRef?.close();
    }
}
