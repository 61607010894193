import { IEntity } from '@pf/shared-common';
import {
    FiscalPeriodSearchRequestParams,
    FiscalPeriodViewModelCreateBody,
    FiscalPeriodViewModelRead,
} from '@control-tower/platform-core';
import { CalendarType } from '@control-tower/platform-core/model/calendarType';
import { FiscalPeriodStatus } from '@control-tower/platform-core/model/fiscalPeriodStatus';

export type FiscalPeriodRead = FiscalPeriodViewModelRead & IEntity;
export type FiscalPeriodCreate = FiscalPeriodViewModelCreateBody;
export type FiscalPeriodSearchParams = FiscalPeriodSearchRequestParams;

export class FiscalPeriodDto implements IEntity {
    constructor(data: Partial<FiscalPeriodDto>) {
        Object.assign(this, data);
    }

    id!: string;
    startDate!: string;
    endDate!: string;
    calendarType!: CalendarType;
    name!: string;
    description?: string | null;
    year!: number;
    quarter?: number | null;
    month?: number | null;
    week?: number | null;
    status!: FiscalPeriodStatus;
    createdDate?: string;
    modifiedDate?: string;
    isDeleted?: boolean;
}
