import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, tap } from 'rxjs';
import { IAppMenu, INavMenu } from '../../../../interfaces/IAppRoutes';
import { LayoutService } from './../../services/layout.service';
import { NzIconService } from 'ng-zorro-antd/icon';
import { IconMap } from '../../../icon';

@UntilDestroy()
@Component({
    selector: 'pf-app-nav-menu',
    templateUrl: './app-nav-menu.component.html',
    styleUrls: ['./app-nav-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavMenuComponent {
    @Input() navigation: IAppMenu[] = [];

    constructor(
        public layoutService: LayoutService,
        cdr: ChangeDetectorRef,
        private iconService: NzIconService
    ) {
        layoutService.isCollapsed$
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                tap(() => cdr.markForCheck())
            )
            .subscribe();

        IconMap.forEach((value, key) => {
            const iconSvg = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'svg'
            );

            iconSvg.setAttribute('role', 'img');
            iconSvg.setAttribute('aria-hidden', 'true');
            iconSvg.setAttribute('focusable', 'false');
            iconSvg.setAttribute('data-prefix', 'fas');
            iconSvg.setAttribute('fill', 'none');
            iconSvg.setAttribute(
                'viewBox',
                `0 0 ${value.width} ${value.height}`
            );

            const paths =
                typeof value.pathData === 'string'
                    ? [value.pathData]
                    : value.pathData;
            paths.forEach(x => {
                const iconPath = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'path'
                );

                iconPath.setAttribute('fill', 'currentColor');

                iconPath.setAttribute('d', x);
                iconSvg.appendChild(iconPath);
            });

            const iconKey = `fa:${key.toString()}`;
            this.iconService.addIconLiteral(iconKey, iconSvg.outerHTML);
        });
    }

    navigationTrackByFn(_: number, menu: INavMenu) {
        return menu.displayName;
    }

    shouldHideFn(menu: IAppMenu) {
        if (
            !menu ||
            menu.hide ||
            !menu.subMenu ||
            menu.subMenu.filter(m => !m.hide).length === 0
        ) {
            return true;
        }

        return;
    }

    get showMenuOnOpen(): boolean {
        return window.innerWidth < 992 ? false : true;
    }
}
