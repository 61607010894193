import { EquipmentDataService } from '../infrastructure/equipments/equipment.data.service';
import {
    EquipmentCreate,
    EquipmentDto,
    EquipmentRead,
    EquipmentSearchParams,
} from '../entities/equipment.dto';
import { EquipmentStore } from '../infrastructure/equipments/equipment.store';
import { Injectable } from '@angular/core';
import { RoutesForEquipment } from './equipment.routes';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { EquipmentMapper } from '../infrastructure/equipments/equipment.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';

@Injectable()
export class EquipmentFacade extends AbstractManageEntityFacade<
    EquipmentDto,
    EquipmentRead,
    EquipmentCreate,
    EquipmentSearchParams
> {
    nameField: keyof EquipmentDto = 'number';

    constructor(
        dataService: EquipmentDataService,
        routes: RoutesForEquipment,
        store: EquipmentStore,
        mapper: EquipmentMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof EquipmentDto, string>> {
        return { number: searchText };
    }
}
