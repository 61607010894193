import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverFacade } from './application/driver.facade';
import { DriverDataService } from './infrastructure/drivers/driver.data.service';
import { DriverStore } from './infrastructure/drivers/driver.store';
import { DriverMapper } from './infrastructure/drivers/driver.mapper';
import { SharedUtilPlatformModule } from '@pf/shared/util-platform';
import { VehicleFacade } from './application/vehicle.facade';
import { VehicleDataService } from './infrastructure/vehicles/vehicle.data.service';
import { VehicleStore } from './infrastructure/vehicles/vehicle.store';
import { VehicleMapper } from './infrastructure/vehicles/vehicle.mapper';
import { VehicleTypeFacade } from './application/type-entities/VehicleTypeFacade';
import { TrailerFacade } from './application/trailer.facade';
import { TrailerDataService } from './infrastructure/trailers/trailer.data.service';
import { TrailerStore } from './infrastructure/trailers/trailer.store';
import { TrailerMapper } from './infrastructure/trailers/trailer.mapper';
import { EquipmentFacade } from './application/equipment.facade';
import { EquipmentDataService } from './infrastructure/equipments/equipment.data.service';
import { EquipmentStore } from './infrastructure/equipments/equipment.store';
import { EquipmentMapper } from './infrastructure/equipments/equipment.mapper';
import { EquipmentTypeFacade } from './application/type-entities/EquipmentTypeFacade';
import {
    Configuration as TransportationApiConfig,
    ConfigurationParameters,
    ApiModule as TransportationApiModule,
} from '@control-tower/platform-transportation';
import { sharedEnv } from '@pf/shared-common';
import { ServiceFacade } from './application/type-entities/ServiceFacade';

const transportationApiConfigFactory = (basePath: string) => {
    return (): TransportationApiConfig => {
        const params: ConfigurationParameters = {
            basePath,
        };
        return new TransportationApiConfig(params);
    };
};

@NgModule({
    imports: [CommonModule, SharedUtilPlatformModule],
    providers: [
        DriverFacade,
        DriverDataService,
        DriverStore,
        DriverMapper,
        VehicleFacade,
        VehicleDataService,
        VehicleStore,
        VehicleMapper,
        VehicleTypeFacade,
        TrailerFacade,
        TrailerDataService,
        TrailerStore,
        TrailerMapper,
        EquipmentFacade,
        EquipmentDataService,
        EquipmentStore,
        EquipmentMapper,
        EquipmentTypeFacade,
        ServiceFacade,
    ],
})
export class PlatformTransportationDomainModule {
    static ApiModule() {
        return TransportationApiModule.forRoot(
            transportationApiConfigFactory(
                sharedEnv.baseApiUrl + '/transportation'
            )
        );
    }
}
