<pf-table
    [data]="loading ? [] : formArray.value"
    [loading]="loading"
    [columns]="columns"
    [actions]="actions"
    [options]="tableOptions"
    [disableAutoPageSize]="true"
    [pageSize]="5"
>
    <pf-button toolbar-right buttonType="add" (click)="add()"
    >Add {{ tableTitle }}</pf-button
    >
</pf-table>
<pf-drawer-form
    [pfTitle]="formTitle"
    [nested]="true"
    [entityType]="entityType"
>
    <ng-container *ngIf="currentControl">
        <ng-container
            *ngTemplateOutlet="formRef; context: { $implicit: currentControl }"
        >
        </ng-container>
    </ng-container>
</pf-drawer-form>
