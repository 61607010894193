<nz-card
    [nzTitle]="title"
    [nzExtra]="headerActionTemplate ?? informationTemplate"
    nzSize="small"
>
    <p [ngStyle]="{ 'margin-bottom': description !== null ? '0' : '10px' }">
        {{ description }}
    </p>
    <ng-content></ng-content>
    <ng-template #informationTemplate>
        <pf-icon
            *ngIf="extraInformation"
            iconType="info"
            [popover]="extraInformation"
        ></pf-icon>
    </ng-template>
</nz-card>
