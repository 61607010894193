import { Mapper } from '@automapper/core';
import {
    EntitySymbols,
    IEntity,
    IEntityMapper,
    IPagedSearchParams,
    IQueryParams,
    IQueryParamsSymbol,
} from '@pf/shared-common';

export abstract class AbstractEntityMapper<
    TEntity,
    TCreateBody,
    TDto extends IEntity,
    TSearchParams extends IPagedSearchParams
> implements IEntityMapper<TEntity, TCreateBody, TDto, TSearchParams>
{
    private _dtoCtor: new (dtoInterface: TDto) => TDto;

    protected constructor(
        dtoCtor: { new (dtoInterface: TDto): TDto },
        protected mapper: Mapper,
        protected readonly entitySymbols: EntitySymbols
    ) {
        this._dtoCtor = dtoCtor;
    }

    toDTO(entity: TEntity): TDto {
        const obj = this.mapper.map<TEntity, TDto>(
            entity,
            this.entitySymbols.entity,
            this.entitySymbols.dto
        );
        return new this._dtoCtor(obj);
    }

    toEntity(dto: TDto): TEntity {
        return this.mapper.map<TDto, TEntity>(
            dto,
            this.entitySymbols.dto,
            this.entitySymbols.entity
        );
    }

    toCreateBody(body: TDto) {
        return this.mapper.map<TDto, TCreateBody>(
            body,
            this.entitySymbols.dto,
            this.entitySymbols.createBody
        );
    }

    toDTOList(entityList: TEntity[]): TDto[] {
        if (!entityList || entityList.length < 1) {
            return new Array<TDto>();
        }
        return entityList.map(entity => this.toDTO(entity));
    }

    toEntityList(dtoList: TDto[]): TEntity[] {
        if (!dtoList || dtoList.length < 1) {
            return new Array<TEntity>();
        }
        return dtoList.map(dto => this.toEntity(dto));
    }

    toSearchParams(params: IQueryParams<TDto>): TSearchParams {
        return this.mapper.map(
            params,
            IQueryParamsSymbol,
            this.entitySymbols.searchParams
        );
    }
}
