import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    VehicleCreate,
    VehicleDto,
    VehicleRead,
    VehicleSearchParams,
} from '../../entities/vehicle.dto';
import { PFTransportationEntities } from '@pf/shared-common';
import {
    DefaultCarrierFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    ITypeEntityFragmentSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFTransportationEntities.Vehicle);

// TODO add metadata
withDefaultEntityMetadata<VehicleDto>(symbols.dto, {
    vehicleNumber: String,
    location: DefaultLocationFragmentMetadataMap.symbols.dto,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.dto,
    vehicleType: ITypeEntityFragmentSymbol,
});

withDefaultEntityMetadata<VehicleRead>(symbols.entity, {
    vehicleNumber: String,
    location: DefaultLocationFragmentMetadataMap.symbols.entity,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.entity,
    vehicleType: ITypeEntityFragmentSymbol,
    resourceAuth: ResourceAuthSymbol,
});

withMetadata<VehicleCreate>(symbols.createBody, {
    vehicleNumber: String,
    locationId: String,
    carrierId: String,
    vehicleTypeId: String,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<VehicleSearchParams>(symbols.searchParams, {
    vehicleNumber: String,
    vehicleTypeId: String,
});

export const VehicleMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<VehicleRead, VehicleDto>(mapper, symbols.entity, symbols.dto);
        createMap<VehicleDto, VehicleRead>(mapper, symbols.dto, symbols.entity);
        createMap<VehicleDto, VehicleCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (d) => d.vehicleTypeId,
                mapFrom((d) => d.vehicleType?.id)
            )
        );
        createMap<IQueryParams<VehicleDto>, VehicleSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('vehicleNumber'),
            withSearchFilter('vehicleType'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
        DefaultCarrierFragmentMetadataMap.profile(mapper);
        DefaultLocationFragmentMetadataMap.profile(mapper);
    },
};
