import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { FiscalPeriodRead } from '../../entities/fiscal-period.dto';
import { PFCoreEntities } from '@pf/shared-common';

@Injectable()
export class FiscalPeriodStore extends AbstractEntityStore<FiscalPeriodRead> {
    constructor() {
        super(PFCoreEntities.FiscalPeriod);
    }
}
