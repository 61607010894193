import {
    IEntity,
    IEntityStore,
    IPagedResult,
    IPredicate,
    SafeAny,
} from '@pf/shared-common';
import { combineLatest, map, Observable, Subject, tap } from 'rxjs';
import {
    hasPage,
    PaginationData,
    selectCurrentPageEntities,
    selectPaginationData,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination,
} from '@ngneat/elf-pagination';
import { createState, Reducer, Store } from '@ngneat/elf';
import {
    addEntities,
    EntitiesRef,
    getActiveEntity,
    getEntity,
    hasEntity,
    resetActiveId,
    selectActiveEntity,
    selectMany,
    selectManyByPredicate,
    setActiveId,
    setEntities,
    updateEntities,
    upsertEntities,
    withActiveId,
    withEntities,
} from '@ngneat/elf-entities';
import { PropsFactory } from '@ngneat/elf/src/lib/state';

interface PaginationState<IdType extends string | number> {
    pagination: PfPaginationData<IdType>;
}

interface PfPaginationData<IdType extends string | number>
    extends PaginationData<IdType> {
    pages: Record<IdType, IdType[]>;
    pageHashes: Record<IdType, string>;
}

export const storeCache: Map<string, Store<SafeAny>> = new Map<
    string,
    Store<SafeAny>
>();

export abstract class AbstractEntityStore<T extends IEntity>
    implements IEntityStore<T>
{
    /**
     * This will be used by state changer functions such as add, update, delete.
     * - to force HTTP request to fire instead of using records from the store.
     */
    private _skipActive = false;
    protected _store!: Store<
        {
            name: string;
            state: { activeId: SafeAny } & PaginationState<string | number> &
                EntitiesRef<'entities', 'ids', 'idKey'>;
            config: never;
        },
        { activeId: SafeAny } & PaginationState<string | number> & {
                entities: Record<T['id'], T>;
                ids: T['id'][];
            }
    >;

    protected constructor(
        storeName: string,
        additionalProps: PropsFactory<SafeAny, SafeAny>[] = []
    ) {
        this.getOrCreateStore(storeName, additionalProps);
    }

    private getOrCreateStore(
        storeName: string,
        additionalProps: PropsFactory<SafeAny, SafeAny>[]
    ) {
        if (storeCache.has(storeName)) {
            this._store = storeCache.get(storeName) as Store<SafeAny>;
        } else {
            const { state, config } = createState(
                withEntities<T>(),
                withActiveId(),
                withPagination(),
                ...additionalProps
            );

            this._store = new Store({ name: storeName, state, config });
            storeCache.set(storeName, this._store);
        }
    }

    added = new Subject<T>();
    updated = new Subject<T>();
    deleted = new Subject<T>();

    get active(): T {
        return this._store.query(getActiveEntity() as SafeAny);
    }

    active$(): Observable<T> {
        return this._store.pipe(selectActiveEntity() as SafeAny);
    }

    get(entityId: string): T {
        return this._store.query(getEntity(entityId) as SafeAny);
    }

    getMany(entityIds: string[]): T[] {
        return this._store.query(selectMany(entityIds) as SafeAny);
    }

    exists(entityId: string): boolean {
        return this._store.query(hasEntity(entityId) as SafeAny);
    }

    filter(predicate: IPredicate<T>): T[] {
        return Object.values(this._store.value.entities).filter(predicate);
    }

    filter$(predicate: IPredicate<T>): Observable<T[]> {
        return this._store.query(selectManyByPredicate(predicate) as SafeAny);
    }

    clearPageHashes() {
        this._store.state.pagination.pageHashes = {};
    }

    skipWhilePageExists(page: number, pageHash: string) {
        return (source: Observable<IPagedResult<T>>) => {
            if (this._skipActive) {
                this._skipActive = false;
                return source;
            }
            if (
                this._store.query(hasPage(page)) &&
                this._store.state.pagination.pageHashes[page] === pageHash
            ) {
                return this._store.pipe(
                    selectMany(
                        this._store.state.pagination.pages[page] as SafeAny
                    ),
                    map(data => {
                        return {
                            data,
                            currentPage: page,
                            currentPageSize: data.length,
                            totalRecords: this._store.state.pagination.total,
                        } as IPagedResult<T>;
                    }),
                    tap(() => this._store.update(setCurrentPage(page)))
                );
            }

            return source;
        };
    }

    getPage$(): Observable<IPagedResult<T>> {
        return combineLatest([
            this._store.pipe(selectPaginationData()),
            this._store.pipe(selectCurrentPageEntities()),
        ]).pipe(
            map(
                ([paginationData, entities]) =>
                    ({
                        data: entities,
                        currentPage: paginationData.currentPage,
                        currentPageSize: paginationData.perPage,
                        totalRecords: paginationData.total,
                    } as IPagedResult<T>)
            )
        );
    }

    upsertMany(entities: T[]) {
        this._store.update(upsertEntities(entities));
    }

    set(entities: T[]) {
        this._store.update(setEntities(entities));
    }

    setActiveEntity(id: string): void {
        this._store.update(setActiveId(id));
    }

    resetActiveEntity(): void {
        this._store.update(resetActiveId());
    }

    setPage(result: IPagedResult<T>, pageHash: string) {
        const { data, currentPage, currentPageSize, totalRecords } = result;
        const paginationData: Partial<PfPaginationData<number>> = {
            total: totalRecords,
            perPage: currentPageSize,
            currentPage: currentPage,
            lastPage: Math.ceil(totalRecords / currentPageSize),
            pageHashes: {
                ...this._store.state.pagination.pageHashes,
                [currentPage]: pageHash,
            },
        };
        this._store.update(
            upsertEntities(data),
            updatePaginationData(paginationData as SafeAny),
            setPage(
                currentPage,
                data.map(c => c.id)
            )
        );
        data.forEach(entity => this.added.next(entity));
    }

    add(entity: T, addToPage: boolean) {
        if (!entity) {
            console.error('Entity is null or undefined.');
            return;
        }
        if (this.get(entity.id)) {
            this.update(entity);
            return;
        }

        this._skipActive = true;
        const reducers: Reducer<SafeAny>[] = [addEntities(entity)];
        if (addToPage) {
            reducers.push(this.addToPageReducer(entity.id));
        }
        this._store.update(...reducers);
        this.added.next(entity);
    }

    update(entity: T) {
        entity.isDeleted = !!entity.isDeleted;
        const current = this._store.query(getEntity(entity.id));
        if (!current) {
            this.add(entity, true);
            return;
        }
        const reducers: Reducer<SafeAny>[] = [
            updateEntities(entity.id, entity),
        ];
        if (current.isDeleted && !entity.isDeleted) {
            reducers.push(this.addToPageReducer(entity.id));
        }
        this._store.update(...reducers);
        this.updated.next(entity);
    }

    delete(entity: T) {
        if (!this.exists(entity.id)) {
            return;
        }
        this._skipActive = true;
        this._store.update(
            updateEntities(entity.id, { ...entity, isDeleted: true }),
            this.removeFromPageReducer(entity.id)
        );
        this.deleted.next(entity);
    }

    private getCurrentPageData() {
        const currentPage = this._store.state.pagination.currentPage || 1;
        return {
            currentPage,
            currentPageIds: (this._store.state.pagination.pages[currentPage] ||
                []) as string[],
        };
    }

    private addToPageReducer(id: string): Reducer<SafeAny> {
        const { currentPage, currentPageIds } = this.getCurrentPageData();
        const newPageIds = [...currentPageIds];
        if (currentPageIds.indexOf(id) === -1) {
            newPageIds.unshift(id);
        }
        return setPage(currentPage, newPageIds);
    }

    private removeFromPageReducer(id: string): Reducer<SafeAny> {
        const { currentPage, currentPageIds } = this.getCurrentPageData();
        return setPage(currentPage, [
            ...currentPageIds.filter((x: string) => x !== id),
        ]);
    }
}
