import { Injectable } from '@angular/core';
import { ChargeTypesService } from '@control-tower/platform-loads';
import { LoggerService } from '@pf/shared-services';
import {
    AbstractTypeEntityFacade,
    TypeEntityStore,
} from '@pf/shared/util-platform';
import { TypeEntityTypes } from '@pf/shared-common';

@Injectable()
export class ChargeTypeFacade extends AbstractTypeEntityFacade<ChargeTypesService> {
    constructor(
        chargeTypesService: ChargeTypesService,
        store: TypeEntityStore,
        logger: LoggerService
    ) {
        super(
            store,
            logger,
            chargeTypesService,
            'chargeTypeGetAll',
            TypeEntityTypes.ChargeType
        );
    }
}
