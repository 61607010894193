import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withMetadata,
} from '@pf/shared-utility';

import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    ContactCreate,
    ContactDto,
    ContactRead,
    ContactSearchParams,
} from './contact.dto';
import { IdIsDefault } from '@control-tower/platform-core';
import { ITypeEntityFragmentSymbol } from '../models/type-entity.mapping-profile';
import { ResourceAuthSymbol } from '../models/resource-auth.mapping-profile';
import { IdIsDefaultSymbol } from '../models/fragments/id-is-default-metadata';

const symbols = entitySymbols(PFCoreEntities.Contact);

withDefaultEntityMetadata<ContactDto>(symbols.dto, {
    firstName: String,
    lastName: String,
    fullName: String,
    email: String,
    phone: String,
    fax: String,
    description: String,
    contactType: ITypeEntityFragmentSymbol,
    isDefault: Boolean,
});
withDefaultEntityMetadata<ContactRead & { isDefault?: boolean }>(
    symbols.entity,
    {
        firstName: String,
        lastName: String,
        email: String,
        phone: String,
        fax: String,
        description: String,
        contactType: ITypeEntityFragmentSymbol,
        resourceAuth: ResourceAuthSymbol,
        isDefault: Boolean,
    }
);

withMetadata<ContactCreate>(symbols.createBody, {
    firstName: String,
    lastName: String,
    email: String,
    phone: String,
    fax: String,
    description: String,
    contactTypeId: String,
    resourceAuth: ResourceAuthSymbol,
});

export const ContactMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<ContactRead, ContactDto>(
            mapper,
            symbols.entity,
            symbols.dto,
            forMember(
                destination => destination.fullName,
                mapFrom(source => source.firstName + ' ' + source.lastName)
            )
        );
        createMap<ContactDto, ContactRead>(mapper, symbols.dto, symbols.entity);
        createMap<ContactDto, ContactCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                createBody => createBody.contactTypeId,
                mapFrom(dto => dto.contactType?.id)
            )
        );
        createMap<IQueryParams<ContactDto>, ContactSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            ...withDefaultSearchParamsMappingConfiguration(),
            forMember(
                s => s.search,
                mapFrom(s => s.filters?.fullName)
            )
        );
        createMap<ContactDto, IdIsDefault>(
            mapper,
            symbols.dto,
            IdIsDefaultSymbol
        );
    },
};
