import { Injectable } from '@angular/core';
import { IModalContent, IModalService } from '@pf/shared-common';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root',
})
export class ModalService implements IModalService {
    constructor(private modal: NzModalService) {}

    info(content: IModalContent): void {
        this.modal.info(this.mapToNzModalType(content));
    }

    warning(content: IModalContent): void {
        this.modal.warning({
            ...this.mapToNzModalType(content),
            nzOkText: content.okText ?? 'Confirm',
            nzCancelText: content.cancelText ?? 'Cancel',
        });
    }

    error(content: IModalContent): void {
        this.modal.error(this.mapToNzModalType(content));
    }

    success(content: IModalContent): void {
        this.modal.success(this.mapToNzModalType(content));
    }

    createComponentModal(content: IModalContent): NzModalRef {
        return this.modal.create({
            ...this.mapToNzModalType(content),
        });
    }

    confirmAction(
        entityName: string,
        onOk: () => void,
        action: 'Delete' | 'Deactivate' | 'Cancel' = 'Delete',
        onCancel?: () => void
    ): void {
        this.warning({
            title: `Confirm ${action}`,
            content: `Are you sure you want to ${action.toLowerCase()} this ${entityName}?`,
            onOk: () => {
                onOk();
            },
            onCancel: () => {
                onCancel?.();
            },
        });
    }

    private mapToNzModalType(content: IModalContent): ModalOptions {
        return {
            nzTitle: content.title,
            nzContent: content.content,
            nzComponentParams: content.componentParams,
            nzOnOk: content.onOk,
            nzOnCancel: () => {
                this.modal.closeAll();
                content.onCancel?.();
            },
            nzOkDisabled: content.isOkDisabled,
            nzKeyboard: true,
            nzMaskClosable: true,
            nzWidth: content.width ?? '50%',
            nzOkText: content.okText ?? 'Ok',
            nzCancelText:
                content.cancelText === undefined
                    ? 'Cancel'
                    : content.cancelText,
        };
    }
}
