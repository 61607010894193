import { Injectable } from '@angular/core';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildCorePermissionPath } from '@pf/shared/util-platform';
import { PermissionTypes, PFCoreEntities } from '@pf/shared-common';

@Injectable()
export class RoutesForFiscalPeriod extends AbstractRouteBuilder {
    permissionType = buildCorePermissionPath(
        PermissionTypes.Modify,
        PFCoreEntities.FiscalPeriod
    );
}
