import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    ICustomField,
    ICustomFieldsEntity,
    IEntity,
    IEntityDataService,
    IEntityStore,
    IPagedResult,
} from '@pf/shared-common';
import { combineLatest, Observable, of, tap } from 'rxjs';

import { AbstractReadDataService } from './AbstractReadDataService';
import { LoggerService } from '../logging/Logger.service';
import { Operation } from 'fast-json-patch';

export abstract class AbstractDataService<
        TRead extends IEntity | ICustomFieldsEntity,
        TCreate
    >
    extends AbstractReadDataService<TRead>
    implements IEntityDataService<TRead, TCreate>
{
    protected constructor(store: IEntityStore<TRead>, _logger: LoggerService) {
        super(store, _logger);
    }

    protected abstract createObs$(body: TCreate): Observable<TRead>;

    protected abstract deleteObs$(id: string): Observable<TRead>;

    protected abstract undoDeleteObs$(id: string): Observable<TRead>;

    protected abstract updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<TRead>;

    protected abstract searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<TRead>>>;

    protected createCustomFieldObs$?(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }>;

    protected updateCustomFieldObs$?(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }>;

    protected deleteCustomFieldObs$?(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }>;

    create$(body: TCreate): Observable<TRead> {
        this._logger.debug(`Creating ${this.EntityName}`);
        return this.createObs$(body).pipe(
            tap(created => this.store.add(created, true))
        );
    }

    update$(id: string, operations: Operation[]): Observable<TRead> {
        this._logger.debug(`Updating ${this.EntityName}: ${id}`);
        return operations.length > 0
            ? this.updateObs$(id, operations).pipe(
                  tap(this.store.update.bind(this.store))
              )
            : of(this.store.get(id));
    }

    delete$(id: string): Observable<TRead> {
        this._logger.debug(`Deleting ${this.EntityName}: ${id}`);
        return this.deleteObs$(id).pipe(
            tap(this.store.delete.bind(this.store))
        );
    }

    undoDelete$(id: string): Observable<TRead> {
        this._logger.debug(`Undo delete ${this.EntityName}: ${id}`);
        return this.undoDeleteObs$(id).pipe(
            tap(this.store.update.bind(this.store))
        );
    }

    searchChanges$(
        params: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<TRead>>> {
        return this.searchChangesObs$(params);
    }

    createCustomFields$(
        entityId: string,
        customFields: ICustomField[]
    ): Observable<Array<ICustomField & { id: string }>> {
        if (!customFields.length) {
            return of([]);
        }
        if (!this.createCustomFieldObs$) {
            throw new Error('Custom fields create observable not implemented');
        }

        const current = this.store.get(entityId);
        if (!current) {
            throw new Error(`Entity with id ${entityId} does not exist`);
        }

        const currentCustomFields =
            (current as ICustomFieldsEntity).customFields || [];
        const observables = customFields.map(customField =>
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            this.createCustomFieldObs$!(entityId, customField)
        );
        return combineLatest(observables).pipe(
            tap(addedFields =>
                this.store.update({
                    ...current,
                    customFields: [...currentCustomFields, ...addedFields],
                })
            )
        );
    }

    updateCustomFields$(
        entityId: string,
        customFields: ICustomField[]
    ): Observable<Array<ICustomField & { id: string }>> {
        if (!customFields.length) {
            return of([]);
        }
        if (!this.updateCustomFieldObs$) {
            throw new Error('Custom fields update observable not implemented');
        }

        const current = this.store.get(entityId);
        if (!current) {
            throw new Error(`Entity with id ${entityId} does not exist`);
        }

        const currentCustomFields =
            (current as ICustomFieldsEntity).customFields || [];
        const observables = customFields.map(customField =>
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            this.updateCustomFieldObs$!(
                entityId,
                customField.id as string,
                customField
            )
        );
        return combineLatest(observables).pipe(
            tap(updatedFields =>
                this.store.update({
                    ...current,
                    customFields: currentCustomFields.map(customField => {
                        const updated = updatedFields.find(
                            updatedField => updatedField.id === customField.id
                        );
                        return updated || customField;
                    }),
                })
            )
        );
    }

    deleteCustomFields$(
        entityId: string,
        customFieldIdsToDelete: string[]
    ): Observable<Array<ICustomField & { id: string }>> {
        if (!customFieldIdsToDelete.length) {
            return of([]);
        }
        if (!this.deleteCustomFieldObs$) {
            throw new Error('Custom fields delete observable not implemented');
        }

        const current = this.store.get(entityId);
        if (!current) {
            throw new Error(`Entity with id ${entityId} does not exist`);
        }

        const currentCustomFields =
            (current as ICustomFieldsEntity).customFields || [];
        const observables = customFieldIdsToDelete.map(id =>
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            this.deleteCustomFieldObs$!(entityId, id)
        );
        return combineLatest(observables).pipe(
            tap(() =>
                this.store.update({
                    ...current,
                    customFields: currentCustomFields.filter(customField => {
                        return !customFieldIdsToDelete.includes(
                            customField.id as string
                        );
                    }),
                })
            )
        );
    }
}
