import { Address, GeoPoint } from '@control-tower/platform-core';
import {
    AssociatedAddressCreate,
    AssociatedAddressDto,
    AssociatedAddressRead,
} from './associated-address.dto';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { IMappingProfile, SafeAny } from '@pf/shared-common';
import { PlatformSharedEntities } from './shared-entities.enum';

export const GeoPointSymbol = PlatformSharedEntities.GeoPoint;
const AddressSymbol =
    PlatformSharedEntities.AssociatedAddress +
    '.' +
    PlatformSharedEntities.Address;

const symbols = entitySymbols(PlatformSharedEntities.AssociatedAddress);

withMetadata<GeoPoint>(GeoPointSymbol, {
    latitude: Number,
    longitude: Number,
});
withMetadata<Address>(AddressSymbol, {
    name: String,
    addressLine1: String,
    addressLine2: String,
    city: String,
    country: String,
    postalCode: String,
    geoZone: String,
    coordinates: GeoPointSymbol,
});
withDefaultEntityMetadata<AssociatedAddressDto>(symbols.dto, {
    address: AddressSymbol,
    description: String,
    associatedAddressType: String,
});
withMetadata<AssociatedAddressRead>(symbols.entity, {
    address: AddressSymbol,
    description: String,
    associatedAddressType: String,
});
withMetadata<AssociatedAddressCreate>(symbols.createBody, {
    address: AddressSymbol,
    description: String,
    associatedAddressType: String,
});

export const AssociatedAddressMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<AssociatedAddressRead, AssociatedAddressDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<AssociatedAddressDto, AssociatedAddressRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<AssociatedAddressDto, AssociatedAddressCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (body) => body.address,
                mapFrom((dto) => {
                    const { id, ...address } = dto.address as SafeAny;
                    return address as Address;
                })
            )
        );
    },
};
