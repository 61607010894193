import { Injectable } from '@angular/core';
import { AbstractSearchFacade } from '@pf/shared-services';
import {
    UserPermissionDto,
    UserPermissionRead,
    UserPermissionCreate,
    UserPermissionSearchParams,
} from '../entities/user-permission.dto';
import { UserPermissionDataService } from '../infrastructure/user-permissions/user-permission.data.service';
import { UserPermissionMapper } from '../infrastructure/user-permissions/user-permission.mapper';
import { UserPermissionStore } from '../infrastructure/user-permissions/user-permission.store';
import { PermissionsService } from '@control-tower/platform-core';

@Injectable()
export class UserPermissionFacade extends AbstractSearchFacade<
    UserPermissionDto,
    UserPermissionRead,
    UserPermissionCreate,
    UserPermissionSearchParams
> {
    nameField: keyof UserPermissionDto = 'permissionName';
    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof UserPermissionDto, string>> {
        return { permissionName: searchText };
    }

    constructor(
        dataService: UserPermissionDataService,
        store: UserPermissionStore,
        mapper: UserPermissionMapper,
        private permissionsService: PermissionsService
    ) {
        super(dataService, store, mapper);
    }

    getAllPermissions$() {
        return this.permissionsService.v1PermissionsGet();
    }
}
