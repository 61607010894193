import { createMap, forMember, ignore, Mapper } from '@automapper/core';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
} from '@pf/shared-common';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import { PFCoreEntities } from '@pf/shared-common';
import {
    UserPermissionCreate,
    UserPermissionDto,
    UserPermissionRead,
    UserPermissionSearchParams,
} from '../../entities/user-permission.dto';

const symbols = entitySymbols(PFCoreEntities.UserPermission);

withDefaultEntityMetadata<UserPermissionDto>(symbols.dto, {
    permissionName: String,
    description: String,
});
withDefaultEntityMetadata<UserPermissionRead>(symbols.entity, {
    permissionName: String,
    description: String,
});
withMetadata(symbols.createBody, {
    permissionName: String,
    description: String,
});
withDefaultSearchParamsMetadata<UserPermissionSearchParams>(
    symbols.searchParams,
    {}
);

export const UserPermissionMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<UserPermissionRead, UserPermissionDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<UserPermissionDto, UserPermissionRead>(
            mapper,
            symbols.dto,
            symbols.entity,
            forMember((x) => x.id, ignore())
        );
        createMap<UserPermissionDto, UserPermissionCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<UserPermissionDto>, UserPermissionSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('permissionName'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
