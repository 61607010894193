import {
    AppNotification,
    AppNotificationCreateRef,
    AppNotificationType,
    AppNotificationVm,
    INotificationService,
    IconType,
    NotificationType,
    SafeAny,
} from '@pf/shared-common';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    filter,
    first,
    map,
} from 'rxjs';
import {
    ComponentRef,
    Injectable,
    NgModuleRef,
    OnDestroy,
} from '@angular/core';
import {
    deleteAllEntities,
    deleteEntities,
    deleteEntitiesByPredicate,
    selectAllEntities,
    upsertEntities,
    withEntities,
} from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

import { AppNotificationTemplatesComponent } from '../components/app-notification-templates';
import { InjectionService } from '../../../services/injection.service';
import { DateService, LoggerService } from '@pf/shared-services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { createStore } from '@ngneat/elf';
import { v4 as uuidv4 } from 'uuid';

interface PersistantAppNotification extends AppNotification {
    id: string;
    messageId: string;
}

const notificationStore = createStore(
    { name: 'AppNotifications' },
    withEntities<PersistantAppNotification>()
);

export const persist = persistState(notificationStore, {
    key: 'app-notifications',
    storage: localStorageStrategy,
});

@Injectable({
    providedIn: 'root',
})
export class AppNotificationService implements INotificationService, OnDestroy {
    private _templatesRef!: ComponentRef<AppNotificationTemplatesComponent>;
    private _actions = new BehaviorSubject<
        Record<string, Subject<AppNotificationVm>>
    >({});
    private _iconTypes: Record<AppNotificationType, keyof typeof IconType> = {
        [AppNotificationType.error]: 'error',
        [AppNotificationType.warning]: 'warning',
        [AppNotificationType.info]: 'info',
        [AppNotificationType.success]: 'success',
    };

    constructor(
        private notificationService: NzNotificationService,
        injectionService: InjectionService,
        moduleRef: NgModuleRef<SafeAny>,
        private dateService: DateService,
        private logger: LoggerService
    ) {
        injectionService.initialized$
            .pipe(
                filter(init => init),
                first()
            )
            .subscribe(() => {
                this._templatesRef = injectionService.appendComponentHost({
                    component: AppNotificationTemplatesComponent,
                    hostLocation: 'root',
                    templates: true,
                    ngModuleRef: moduleRef,
                });
            });
    }

    get notificationTemplateRef() {
        return this._templatesRef?.instance?.notificationTpl;
    }

    persistantNotifications$ = combineLatest([
        notificationStore.pipe(selectAllEntities()),
        this._actions,
    ]).pipe(
        map(([list]) =>
            list.map(notification => this.createNotificationVm(notification))
        )
    );

    ngOnDestroy(): void {
        this._templatesRef?.destroy();
    }

    registerAction(action: string, subject: Subject<AppNotificationVm>) {
        this._actions.next({
            ...this._actions.value,
            [action]: subject,
        });
    }

    create(notification: AppNotification): AppNotificationCreateRef {
        const vm = this.createNotificationVm(notification);
        const key = uuidv4();
        vm.id = key;
        const ref = this.notificationService.template(
            this.notificationTemplateRef,
            {
                nzPlacement: 'topRight',
                nzPauseOnHover: true,
                nzKey: key,
                nzData: {
                    ...vm,
                },
            }
        );
        if (vm.action?.onClick) {
            vm.action.onClick
                .pipe(first())
                .subscribe(() => this.remove(ref.messageId, key));
        }
        if (vm.persist) {
            this.persistNotification(key, ref.messageId, vm);
        }
        return {
            type: NotificationType.AppNotification,
            id: ref.messageId,
            onClose: ref.onClose,
            onClick: ref.onClick,
        };
    }

    remove(id: string, key?: string): void {
        this.notificationService.remove(id);
        if (key) {
            notificationStore.update(deleteEntities(key));
        } else {
            notificationStore.update(
                deleteEntitiesByPredicate(n => n.messageId === id)
            );
        }
    }

    clearPersistant() {
        notificationStore.update(deleteAllEntities());
    }

    private persistNotification(
        key: string,
        messageId: string,
        notification: AppNotificationVm
    ) {
        const persistant = {
            ...notification,
            action: { ...(notification.action || {}) },
            id: key,
            messageId: messageId,
        } as AppNotificationVm;
        if (persistant.action?.onClick) {
            delete persistant.action.onClick;
        }
        notificationStore.update(upsertEntities(persistant));
    }

    private createNotificationVm(
        notification: AppNotification
    ): AppNotificationVm {
        const vm: AppNotificationVm = {
            ...notification,
            createdDate:
                notification.createdDate ||
                this.dateService.formatDateTime(new Date()),
            iconType: this._iconTypes[notification.appNotificationType],
        };
        if (!vm.action?.onClickAction) {
            return vm;
        }
        if (
            vm.action.onClickAction &&
            !this._actions.value[vm.action.onClickAction]
        ) {
            this.logger.warn(
                `AppNotification Action has not been registered: ${vm.action.onClickAction}`
            );
            return vm;
        }
        vm.action = {
            ...vm.action,
            onClick: this._actions.value[vm.action.onClickAction],
        };
        return vm;
    }
}
