export enum LogLevel {
    All = 0,
    Trace = 10,
    Debug = 20,
    Info = 30,
    Warn = 40,
    Error = 50,
    Fatal = 60,
    Off = 70,
}
