<pf-input *ngIf="showSearch" class="custom-field-list-search" inputType="search" [control]="searchControl"
          [placeholder]="searchPlaceholder"></pf-input>
<div class="custom-field-list">
    <pf-text *ngIf="listLabel" type="primary" bold>{{listLabel}}</pf-text>
    <div class="custom-field-list-item"
         *ngFor="let item of listControls$ | async; let inputIndex=index; let lastItem=last">
        <pf-input
            #inputItem
            (keyup.enter)="focusNextOrAddNewItem(inputIndex)"
            pfStyle="compact"
            [control]="item.formControl"
            (pfInput)="onInput()"
            inputType="text"></pf-input>
        <pf-icon class="custom-field-list-action"
                 iconType="close"
                 (click)="removeItem(item.listId)"></pf-icon>
        <span class="custom-field-list-action">
            <pf-icon *ngIf="lastItem"
                     iconType="plus"
                     (click)="addNewItem()"></pf-icon>
        </span>


    </div>
</div>
