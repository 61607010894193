<ng-lottie
    width="400px"
    [options]="resultProps.animationOptions"
    (animationCreated)="animationCreated($event)"></ng-lottie>
<pf-text type="title">{{ resultProps.title }}</pf-text>
<pf-text type="description">{{ resultProps.subTitle }}</pf-text>
<p *ngIf="recommendedUrl">
    Did you mean
    <a style="color: var(--pf-secondary-color)" href="{{ recommendedUrl }}">{{
        getRecommendedUrl()
    }}</a
    >?
</p>
<nz-content>
    <ng-content></ng-content>
</nz-content>
<pf-button *ngIf="!hideHomeButton" buttonType="submit" (click)="navigateHome()"
    >Back Home</pf-button
>
