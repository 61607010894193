import { createMap, Mapper } from '@automapper/core';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    TimezoneCreate,
    TimezoneDto,
    TimezoneRead,
    TimezoneSearchParams,
} from '../../entities/timezone.dto';

const symbols = entitySymbols(PFCoreEntities.Timezone);

withDefaultEntityMetadata<Omit<TimezoneDto, 'name'>>(symbols.dto, {
    abbreviation: String,
    genericName: String,
    ianaTimeZoneId: String,
    utcOffsetInHours: Number,
    windowsTimeZoneId: String,
    country: String,
});
withDefaultEntityMetadata<TimezoneRead>(symbols.entity, {
    abbreviation: String,
    genericName: String,
    ianaTimeZoneId: String,
    utcOffsetInHours: Number,
    windowsTimeZoneId: String,
    country: String,
});
withMetadata<TimezoneCreate>(symbols.createBody, {
    abbreviation: String,
    genericName: String,
    ianaTimeZoneId: String,
    utcOffsetInHours: Number,
    windowsTimeZoneId: String,
    country: String,
});

export const TimezoneMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<TimezoneRead, TimezoneDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<TimezoneDto, TimezoneRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<TimezoneDto, TimezoneCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<TimezoneDto>, TimezoneSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams
            // ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
