import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {
    IValuePresentationComponent,
    ValuePresentationType,
} from '@pf/shared-ui';
import { IContact } from '@pf/shared/util-platform';
@Component({
    selector: 'platform-contact',
    template: `
        <ng-template #summaryTpl>
            <pf-text
                type="menu"
                *ngIf="value?.email"
                [pfEmailAction]="value.email">
                <pf-icon iconType="email" inline></pf-icon>
                <span>{{ value.email }}</span>
            </pf-text>
            <pf-text
                type="menu"
                *ngIf="value?.phone"
                [pfPhoneAction]="value.phone">
                <pf-icon iconType="phone"></pf-icon>
                {{ value.phone }}
            </pf-text>
        </ng-template>
        <ng-container *ngIf="presentAs">
            <ng-container *ngTemplateOutlet="contentRef"></ng-container>
        </ng-container>
    `,
    styles: [
        `
            :host {
                pf-icon {
                    margin-right: var(--pf-gap);
                }
            }
        `,
    ],
})
export class ContactSummaryComponent
    implements IValuePresentationComponent<IContact>
{
    @ViewChild('summaryTpl', { static: true }) summaryTpl!: TemplateRef<void>;
    @Input() value!: IContact;
    @Input() presentAs?: ValuePresentationType;

    get contentRef() {
        // TODO will have to check displayType when additional types are added. This will get refactored
        return this.summaryTpl;
    }
}
