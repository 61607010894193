import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDocumentDto, ProfileProvider } from '@pf/shared-common';
import { NzUploadFile } from 'ng-zorro-antd/upload';

export interface IDocumentForm {
    documentDescription: string;
    file: File | null;
    documentName: string | null;
    documentExpirationDate: string | null;
    documentNotes: string | null;
}

interface DocumentForm {
    file: FormControl<File | null>;
    documentName: FormControl<string | null>;
    documentExpirationDate: FormControl<string | null>;
    documentNotes: FormControl<string | null>;
}

@Component({
    selector: 'pf-document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnInit {
    // TODO move to injected config
    private readonly MAX_FILE_SIZE_IN_MB = 20;
    private readonly ALLOWED_FILE_TYPES = [
        '.xls',
        '.xlsx',
        '.csv',
        '.pdf',
        '.doc',
        '.docx',
        '.jpg',
        '.jpeg',
        '.png',
        '.tiff',
        '.bmp',
        '.txt',
    ];
    private _document: IDocumentDto | null = null;

    @Input() set document(doc: IDocumentDto | null) {
        this._document = doc;
        this.initDocument();
    }

    get document() {
        return this._document;
    }

    @Input() hasExpiration = false;
    @Input() saving = false;
    @Output() pfSubmit = new EventEmitter<IDocumentForm>();

    formGroup: FormGroup<DocumentForm> | null = null;
    uploadHint = `Accepted file types are ${this.fileInputAccept}`;
    fileList: NzUploadFile[] = [];

    /**
     * @internal
     */
    visible = false;

    get fileInputAccept() {
        return this.ALLOWED_FILE_TYPES.join(',');
    }

    get maxFileSize() {
        return this.MAX_FILE_SIZE_IN_MB * 1000;
    }

    get value(): IDocumentForm | null {
        return (this.formGroup?.value as IDocumentForm) || null;
    }

    get valid() {
        return this.formGroup?.valid;
    }

    constructor(private profileProvider: ProfileProvider) {}

    ngOnInit() {
        this.setupForm();
    }

    reset() {
        this.formGroup?.reset();
    }

    beforeUploadFn = (file: NzUploadFile | File): boolean => {
        this.fileList = [file as NzUploadFile];
        this.formGroup?.controls.file.setValue(file as File);
        this.formGroup?.controls.documentName.setValue(file.name);
        return false;
    };

    onDrawerClose() {
        this.close();
    }

    onCancelClick() {
        this.close();
    }

    onSubmitClick() {
        if (!this.valid) {
            return;
        }
        if (!this.value) {
            this.close();
            return;
        }
        this.value.documentDescription = this.profileProvider.profile.email;
        this.pfSubmit.emit(this.value);
    }

    open(): void {
        this.visible = true;
    }

    close(): void {
        this.visible = false;
    }

    private setupForm() {
        this.formGroup = new FormGroup<DocumentForm>({
            file: new FormControl(null, [Validators.required]),
            documentName: new FormControl('', [Validators.required]),
            documentExpirationDate: new FormControl(null),
            documentNotes: new FormControl(''),
        });
        this.initDocument();
    }

    private initDocument() {
        if (this.formGroup && this.document) {
            const { file, fileName, expirationDate, notes } = this.document;
            this.formGroup?.setValue({
                file: file || null,
                documentName: fileName || '',
                documentExpirationDate: (expirationDate as string) || null,
                documentNotes: notes || '',
            });
        }
    }
}
