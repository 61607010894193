import { NzResultStatusType } from 'ng-zorro-antd/result';
import { AnimationOptions } from 'ngx-lottie';

export enum ResultType {
    NotFound,
    Unauthorized,
    Error,
}

export const AnimationItems = {
    NotFound: 'assets/animations/wrong-file.json',
    Error: 'assets/animations/error.json',
    Checkmark: 'assets/animations/checkmark.json',
};

export type ResultProps = {
    statusCode: NzResultStatusType; //HttpStatusCode
    title: string;
    subTitle?: string;
    animationOptions: AnimationOptions | null;
};

const defaultAnimationOptions: AnimationOptions = {
    loop: false,
    autoplay: false,
};

export const ResultMap: Map<ResultType, ResultProps> = new Map<
    ResultType,
    ResultProps
>([
    [
        ResultType.NotFound,
        {
            statusCode: '404',
            title: 'Not Found',
            subTitle:
                "This page wasn't found. Try again or contact your administrator for help.",
            animationOptions: {
                ...defaultAnimationOptions,
                path: AnimationItems.NotFound,
            },
        },
    ],
    [
        ResultType.Unauthorized,
        {
            statusCode: '403',
            title: 'Unauthorized',
            subTitle:
                'You are not authorized to perform this action. Contact your administrator for access.',
            animationOptions: null,
        },
    ],
    [
        ResultType.Error,
        {
            statusCode: '500',
            title: 'Error',
            subTitle:
                'There was an error processing your request. Try again or contact your administrator for help.',
            animationOptions: {
                ...defaultAnimationOptions,
                path: AnimationItems.Error,
            },
        },
    ],
]);
