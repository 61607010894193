<nz-filter-trigger
    *ngIf="column.search || column.serverSearch"
    [(nzVisible)]="searchVisible"
    [nzActive]="isFilterActive"
    [nzDropdownMenu]="menu"
    (nzVisibleChange)="onVisibleChange()"
>
    <pf-icon iconType="search"></pf-icon>
</nz-filter-trigger>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
        <div class="search-box">
            <pf-input
                class="search-box-input"
                inputType="text"
                [placeholder]="'Search ' + column.headerName"
                [control]="searchControl"
                (keyup.enter)="search()"
                inline
            ></pf-input>
            <pf-button
                buttonSize="small"
                buttonType="cancel"
                (click)="reset($event)"
            >
                Reset
            </pf-button>
            <pf-button
                buttonSize="small"
                buttonType="submit"
                (click)="search($event)"
            >
                Search
            </pf-button>
        </div>
    </div>
</nz-dropdown-menu>
