import {
    AssociatedAddress,
    AssociatedAddressType,
} from '@control-tower/platform-core';
import { IEntity } from '@pf/shared-common';
import { v4 as uuidv4 } from 'uuid';
import { AddressDto } from './address.dto';

export type AssociatedAddressRead = AssociatedAddress;
export type AssociatedAddressCreate = AssociatedAddress;

export interface IAssociatedAddressDto extends IEntity {
    address?: AddressDto;
    description?: string | null;
    associatedAddressType?: AssociatedAddressType;
}

export class AssociatedAddressDto implements IAssociatedAddressDto {
    constructor(data: Partial<IAssociatedAddressDto>) {
        Object.assign(this, data);
        if (!this.id) {
            this.id = uuidv4();
        }
    }
    id!: string;
    address?: AddressDto;
    description?: string;
    associatedAddressType?: AssociatedAddressType;
}
