import {
    LoadDto,
    LoadFacadeConfig,
    LoadProviderConfig,
    LoadStatusTypesConfig,
    LoadStopTypesConfig,
} from '@pf/platform-loads/domain';
import { CustomFieldConfig, TypeConfig } from '@pf/shared/util-platform';
import { Validators } from '@angular/forms';
import { TenantProvider } from '@pf/shared-common';
import { map, of } from 'rxjs';
import { OptionalMapOperator } from '@pf/shared-utility';

const loadStatusTypes: LoadStatusTypesConfig = [
    {
        code: 'new',
        statusStyle: 'success',
        displayStats: true,
    },
    {
        code: 'inprogress',
        statusStyle: 'secondary',
        animation: 'pulse',
        displayStats: true,
    },
    {
        code: 'completed',
        statusStyle: 'primary',
        displayStats: true,
    },
];
const loadHistoryStatusTypes: LoadStatusTypesConfig = [
    {
        code: 'new',
        statusStyle: 'success',
        displayStats: true,
    },
    {
        code: 'inprogress',
        statusStyle: 'secondary',
        animation: 'pulse',
        displayStats: true,
    },
    {
        code: 'completed',
        statusStyle: 'primary',
        displayStats: true,
    },
    {
        code: 'cancelled',
        statusStyle: 'primary',
        displayStats: true,
    },
];
const ldgLoadStatusTypes: LoadStatusTypesConfig = [
    {
        code: 'new',
        statusStyle: 'success',
        displayStats: true,
    },
    {
        code: 'submitted',
        statusStyle: 'info',
        displayStats: true,
    },
    {
        code: 'accepted',
        statusStyle: 'secondary',
        animation: 'pulse',
        displayStats: true,
    },
    {
        code: 'completed',
        statusStyle: 'primary',
        displayStats: true,
    },
];
const stopTypes: LoadStopTypesConfig = [
    {
        code: 'origin',
        stopType: 'pickup',
    },
    {
        code: 'destination',
        stopType: 'delivery',
    },
];
const loadReferences: TypeConfig[] = [
    {
        code: 'hazmatCode',
        type: 'text',
    },
    {
        code: 'shipperOrderNumber',
        type: 'text',
        validators: [Validators.required, Validators.pattern(/^ORD\d{3,6}$/)],
    },
    {
        code: 'paymentType',
        type: 'select',
        options: [
            {
                label: 'Prepaid',
                value: 'prepaid',
            },
            {
                label: 'Collect',
                value: 'collect',
            },
            {
                label: 'Third Party',
                value: 'thirdParty',
            },
            {
                label: 'Other',
                value: 'other',
            },
        ],
    },
];
const loadCustomFields: CustomFieldConfig[] = [
    {
        key: 'External Source Id',
        type: 'text',
        validators: [Validators.pattern(/^\d{6}$/)],
    },
    {
        key: 'External Source System',
        type: 'select',
        options: [
            {
                label: 'BlueJay',
                value: 'BlueJay',
            },
            {
                label: 'SalesForce',
                value: 'SalesForce',
            },
        ],
    },
    {
        key: 'External Integration',
        type: 'select',
        options: [
            {
                label: 'EDI',
                value: 'EDI',
            },
            {
                label: 'API',
                value: 'API',
            },
        ],
    },
];

class DemoLoadFacadeConfig extends LoadFacadeConfig {
    constructor(private tenantProvider: TenantProvider) {
        super();
    }

    references$ = of(loadReferences);
    stopTypes$ = of(stopTypes);
    recentStatusTypes$ = this.tenantProvider.name$.pipe(
        map(name => {
            if (name === 'LDG') {
                return ldgLoadStatusTypes;
            }
            return loadStatusTypes;
        })
    );
    historyStatusTypes$ = this.tenantProvider.name$.pipe(
        map(name => {
            if (name === 'LDG') {
                return ldgLoadStatusTypes;
            }
            return loadHistoryStatusTypes;
        })
    );
    customFields$ = of(loadCustomFields);
    override preSaveMap: OptionalMapOperator<LoadDto> = undefined;
    cancelledStatusType = { code: 'cancelled' };
    newStatusType = { code: 'new' };
}

export default {
    loadFacadeConfig: DemoLoadFacadeConfig,
} as LoadProviderConfig;
