import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    CategoriesService,
    ItemCategoriesService,
} from '@control-tower/platform-item-catalog';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
    PFItemCatalogEntities,
} from '@pf/shared-common';
import { CategoryStore } from './category.store';
import {
    CategoryCreate,
    CategoryRead,
    CategorySearchParams,
} from '../../entities/category.dto';
import { Operation } from 'fast-json-patch';
import { mapToIPagedResult } from '@pf/shared/util-platform';
import { ItemRead } from '../../entities/item.dto';

@Injectable()
export class CategoryDataService extends AbstractDataService<
    CategoryRead,
    CategoryCreate
> {
    protected EntityName = PFItemCatalogEntities.Category;

    constructor(
        private service: CategoriesService,
        private itemCategoryService: ItemCategoriesService,
        store: CategoryStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: CategoryCreate): Observable<CategoryRead> {
        return this.service.categoryCreate({
            categoryViewModelBase: body,
        }) as Observable<CategoryRead>;
    }

    protected deleteObs$(id: string): Observable<CategoryRead> {
        return this.service.categoryDelete({
            id,
        }) as Observable<CategoryRead>;
    }

    protected undoDeleteObs$(id: string): Observable<CategoryRead> {
        return this.service.categoryUndoDelete({
            id,
        }) as Observable<CategoryRead>;
    }

    protected getObs$(id: string): Observable<CategoryRead> {
        return this.service.categoryReadById({
            id,
        }) as Observable<CategoryRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<CategoryRead> {
        return this.service.categoryUpdatePatch({
            id,
            operation: operations,
        }) as Observable<CategoryRead>;
    }

    protected searchObs$(
        _searchParams: CategorySearchParams
    ): Observable<IPagedResult<CategoryRead>> {
        return this.service.categoryGetAll().pipe(mapToIPagedResult);
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<CategoryRead>>> {
        return this.service.categoryGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<CategoryRead>>
        >;
    }

    public getCategoryItems$(id: string): Observable<IPagedResult<ItemRead>> {
        return this.itemCategoryService.itemCategoryItemSearch({
            categoryId: id,
            pageNumber: 1,
            pageSize: 100,
        }) as Observable<IPagedResult<ItemRead>>;
    }
}
