import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppNotificationService } from './services/app-notification.service';
import { ToastMessageService } from './services/toast-message.service';
import { EmailService } from './services/email.service';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { AppNotificationTemplatesComponent } from './components/app-notification-templates';
import { SharedUiModule } from '../../shared-ui.module';
import { IconModule } from '../icon';
import { AppNotificationsComponent } from './components/app-notifications.component';
import { AppNotificationComponent } from './components/app-notification.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        IconModule,
        NzMessageModule,
        NzNotificationModule,
        NzBadgeModule,
        NzDropDownModule,
        NzCardModule,
    ],
    declarations: [
        AppNotificationTemplatesComponent,
        AppNotificationsComponent,
        AppNotificationComponent,
    ],
    exports: [AppNotificationsComponent],
})
export class NotificationsModule {
    static forRoot(): ModuleWithProviders<NotificationsModule> {
        return {
            ngModule: NotificationsModule,
            providers: [
                AppNotificationService,
                ToastMessageService,
                EmailService,
            ],
        };
    }
}
