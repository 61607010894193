import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OneTimeLinkFacade } from '@pf/platform-communications/domain';
import { PFCommunicationsEntities } from '@pf/shared-common';
import { EntityBaseEditComponent, FormService } from '@pf/shared-ui';
import { OneTimeLinkDto } from '@pf/shared/util-platform';
import { Observable, of } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'platform-one-time-link-edit',
    templateUrl: './one-time-link-edit.component.html',
    styleUrls: ['./one-time-link-edit.component.scss'],
    providers: [FormService],
})
export class OneTimeLinkEditComponent
    extends EntityBaseEditComponent<OneTimeLinkDto>
    implements OnInit
{
    entityType = PFCommunicationsEntities.OneTimeLink;

    constructor(oneTimeLinkFacade: OneTimeLinkFacade) {
        super(oneTimeLinkFacade);
    }

    newEntityFn$(): Observable<OneTimeLinkDto> {
        return of(new OneTimeLinkDto({}));
    }
}
