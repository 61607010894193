import {
    IEntity,
    IEntityMapper,
    IPagedResult,
    SelectOption,
    TreeSelectOption,
} from '@pf/shared-common';
import { map, Observable } from 'rxjs';
import { toSelectOptions, toSelectOptionsFlattened, toTreeSelectOptions } from './arrays-util';

export type OptionalMapOperator<T> =
    | ((source: Observable<T>) => Observable<T>)
    | undefined;

export const optionalMap =
    <T>(mapOperator: OptionalMapOperator<T>) =>
    (source: Observable<T>): Observable<T> => {
        return mapOperator ? mapOperator(source) : source;
    };

export const mapToSelectOptions =
    <TDto extends IEntity>(label: keyof TDto, value: keyof TDto = 'id', groupBy: (record: TDto) => string | null | undefined = () => undefined) =>
    (source: Observable<IPagedResult<TDto>>): Observable<SelectOption[]> => {
        return source.pipe(
            map(result => toSelectOptions(result.data, label, value, groupBy))
        );
    };

export const flattenAndMapToSelectOptions =
    <TDto extends IEntity>(label: keyof TDto, value: keyof TDto = 'id', groupBy: (record: TDto) => string[] | null | undefined = () => undefined) =>
    (source: Observable<IPagedResult<TDto>>): Observable<SelectOption[]> => {
        return source.pipe(
            map(result => toSelectOptionsFlattened(result.data, label, value, groupBy))
        );
    };

export const mapToTreeSelectOptions =
    <TDto extends IEntity>(
        title: keyof TDto,
        value: keyof TDto = 'id',
        recursive = false
    ) =>
    (
        source: Observable<IPagedResult<TDto>>
    ): Observable<TreeSelectOption[]> => {
        return source.pipe(
            map(result =>
                toTreeSelectOptions(result.data, title, value, recursive)
            )
        );
    };

export const mapToDtoPageResult =
    <TEntity, TCreateBody, TDto, TSearchParams>(
        mapper: IEntityMapper<TEntity, TCreateBody, TDto, TSearchParams>
    ) =>
    (
        source: Observable<IPagedResult<TEntity>>
    ): Observable<IPagedResult<TDto>> => {
        return source.pipe(
            map(result => ({
                ...result,
                data: mapper.toDTOList(result.data),
            }))
        );
    };
