<nz-page-header class="site-page-header">
    <nz-page-header-title>{{ pageTitle }}</nz-page-header-title>
    <nz-page-header-subtitle>{{ pageSubtitle }}</nz-page-header-subtitle>
    <nz-breadcrumb
        [nzAutoGenerate]="true"
        [nzRouteLabelFn]="routeLabelFn"
        nz-page-header-breadcrumb
        *ngIf="!hideBreadcrumbs"
    >
        <nz-breadcrumb-item>
            <a [routerLink]="['/']">Home</a>
        </nz-breadcrumb-item>
    </nz-breadcrumb>
    <nz-page-header-extra
        ><ng-content select="[page-header-extra]"></ng-content
    ></nz-page-header-extra>
</nz-page-header>
