import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    inject,
    signal,
} from '@angular/core';
import {
    IEntity,
    ISearchFacade,
    PFCommunicationsEntities,
    SafeAny,
    TableAction,
    TableColumn,
    TenantProvider,
} from '@pf/shared-common';
import {
    DrawerFormComponent,
    DrawerService,
    FormService,
    TableActionsBuilder,
    TableColumnBuilder,
} from '@pf/shared-ui';
import { OneTimeLinkDto } from '@pf/shared/util-platform';
import { OneTimeLinkEditComponent } from './one-time-link-edit.component';
import { DateService } from '@pf/shared-services';
import { OneTimeLinkFacade } from '@pf/platform-communications/domain';
import { ResourceAuth } from '@control-tower/platform-core';

@Component({
    selector: 'platform-one-time-links-form',
    templateUrl: './one-time-links-form.component.html',
    styleUrls: ['./one-time-links-form.component.scss'],
})
export class OneTimeLinksFormComponent implements OnInit, OnChanges {
    entityType = PFCommunicationsEntities.OneTimeLink;
    @Input() recipientEntityType!: string;
    @Input() recipientEntityRoleId!: string;
    @Input() associatedEntityId!: string;
    @Input() associatedEntityFacade!: ISearchFacade<SafeAny>;
    @Input() parentEntityType!: string;

    @Input() data: OneTimeLinkDto[] = [];
    @Input() collapsed = true;
    @ViewChild(DrawerFormComponent, { static: false })
    drawerForm!: DrawerFormComponent;

    drawerService = inject(DrawerService);
    formService = inject(FormService);
    dateService = inject(DateService);
    oneTimeLinkFacade = inject(OneTimeLinkFacade);

    associatedEntity = {} as IEntity & { resourceAuth: ResourceAuth };
    columns: TableColumn<OneTimeLinkDto>[] = [];
    actions: TableAction<OneTimeLinkDto>[] = [];

    ngOnInit(): void {
        this.columns = this.setColumns();
        this.actions = this.setActions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['associatedEntityId'] &&
            !changes['associatedEntityId'].firstChange
        ) {
            const newAssociatedEntityId =
                changes['associatedEntityId'].currentValue;
            if (newAssociatedEntityId) {
                this.updateAssociatedEntity(newAssociatedEntityId);
            }
        }
    }

    constructor(private tenantProvider: TenantProvider) {}

    updateAssociatedEntity(associatedEntityId: string) {
        this.associatedEntityFacade
            .getById$(associatedEntityId)
            .subscribe(entity => {
                this.associatedEntity = entity;
            });
    }

    addOneTimeLink() {
        const activeFormState = this.formService.activeState(
            this.parentEntityType
        );

        const expirationDayCount = this.tenantProvider.tenant?.emailing
            ?.defaultOneTimeLinkExpirationInHours
            ? this.tenantProvider.tenant.emailing
                  .defaultOneTimeLinkExpirationInHours / 24
            : 60;

        this.drawerService.createFormDrawer({
            entityType: this.entityType,
            parentFormId: activeFormState?.id,
            title: 'Create One-Time Link',
            component: OneTimeLinkEditComponent,
            componentProps: {
                isChild: true,
                entity: {
                    id: '',
                    associatedEntityId: this.associatedEntityId,
                    expirationDate:
                        this.dateService.getFutureDate(expirationDayCount),
                    subject: 'Load Assigned',
                    sendEmailImmediately: true,
                    recipientAddress: '',
                    templateUrl: 'load-onetimelink.html',
                    templateData: {
                        ...this.associatedEntity,
                    },
                    recipientRoles: [this.recipientEntityRoleId],
                    recipientResourceAuth: this.associatedEntity.resourceAuth,
                    recipientUserGroupType: this.recipientEntityType,
                },
            },
        });
    }

    setColumns(): TableColumn<OneTimeLinkDto>[] {
        return new TableColumnBuilder<OneTimeLinkDto>()
            .withColumn({
                field: 'recipientAddress',
                headerName: 'Email',
                width: 250,
                showSort: false,
                search: false,
            })
            .withStatusColumn(
                'isActive',
                null,
                {
                    false: {
                        status: 'danger',
                        label: 'Inactive',
                    },
                    true: {
                        status: 'success',
                        label: 'Active',
                    },
                    defaultOption: {
                        status: 'success',
                        label: 'Active',
                    },
                },
                {
                    exportValueFormatter: value =>
                        value ? 'Active' : 'Inactive',
                }
            )
            .withColumn({
                field: 'createdDate',
                type: 'date',
                search: false,
                headerName: 'Sent',
                showSort: false,
            })
            .withColumn({
                field: 'expirationDate',
                type: 'date',
                search: false,
                headerName: 'Expires',
                showSort: false,
            }).columns;
    }

    setActions(): TableAction<OneTimeLinkDto>[] {
        return new TableActionsBuilder<SafeAny>().withAction({
            name: 'Inactive',
            textType: 'menu',
            onClick: row => {
                this.oneTimeLinkFacade.delete$(row.id).subscribe();
            },
        }).actions;
    }

    get isCreateDisabled(): boolean {
        return this.data && this.data.length > 0;
    }
}
