import { IPagedSearchParams } from './IPagedSearchParams';

export interface IOmniFieldSearchParams extends IPagedSearchParams {
    searchStartsWithFields?: Array<string>;
    searchStartsWith?: string;
    searchContainsFields?: Array<string>;
    searchContains?: string;
    searchEqualsFields?: Array<string>;
    searchEquals?: string;
}

export enum SearchStrategy {
    Contains,
    StartsWith,
    Equals,
}

export type ISearchFields<TEntity> = Array<keyof TEntity | 'search'>;
