export enum CustomerAppConstants {
    Customer = 'Customer',
    Customers = 'Customers',
    ManageCustomersTitle = 'ManageCustomersTitle',
    ManageCustomersSubTitle = 'ManageCustomersSubTitle',
}
export const CustomerAppConstantDefaults = {
    [CustomerAppConstants.Customer]: 'Customer',
    [CustomerAppConstants.Customers]: 'Customers',
    [CustomerAppConstants.ManageCustomersTitle]: 'Manage Customers',
    [CustomerAppConstants.ManageCustomersSubTitle]:
        'Management screen to add, edit, or deactivate customers in the system.',
};
