import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    inject,
    Input,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import {
    Controls,
    createForm,
    FormType,
    NgxRootForm,
    NgxRootFormOptions,
} from 'ngx-sub-form';
import { BehaviorSubject, delay, filter, map, Subject, tap } from 'rxjs';
import { IEntity, SafeAny } from '@pf/shared-common';
import { DirtyCheckService } from '../services/dirty-check.service';
import { FormService } from '../services/form.service';
import { EntityRootFormComponent } from './form.types';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { markDirtyOnSubmitIfInvalid } from './form-operators';
import { optionalMap, OptionalMapOperator } from '@pf/shared-utility';

@Component({
    selector: 'pf-entity-base-root-form',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class EntityBaseRootFormComponent<
    TEntity extends object & IEntity,
    TFormInterface extends object = TEntity
> implements EntityRootFormComponent<TEntity, TFormInterface>, OnInit
{
    protected readonly formService = inject(FormService);
    private readonly dirtyCheckService = inject(DirtyCheckService);
    private readonly manualSave: boolean;
    protected readonly manualSaveSubject = new Subject<void>();
    protected readonly input$ = new BehaviorSubject<TEntity | undefined>(
        undefined
    );
    protected readonly disabled$ = new Subject<boolean>();
    protected readonly output$ = new Subject<TEntity>();
    protected cdr = inject(ChangeDetectorRef);

    loading = true;
    registerDirtyCheck = true;

    initialValue$ = this.input$.asObservable();
    form: NgxRootForm<TEntity, TFormInterface>;

    abstract entityType: string;
    protected preSaveFn?: OptionalMapOperator<boolean>;

    get initialValue() {
        return this.input$.value;
    }

    @Input() set entity(entity: TEntity | undefined | null) {
        if (entity) {
            this.input$.next(entity);
        }
    }

    get entity() {
        return this.input$.value;
    }

    @Input() set disabled(value: boolean | undefined) {
        this.disabled$.next(!!value);
    }

    @Output()
    entityUpdate = this.output$;

    // Arguments are optional to make Angular happy; however, they are required by derived classes
    protected constructor(@Optional() @Inject('ignore') manualSave = true) {
        this.manualSave = manualSave;
        this.form = createForm<TEntity, TFormInterface>(this, {
            formType: FormType.ROOT,
            disabled$: this.disabled$,
            input$: this.input$,
            output$: this.output$,
            manualSave$: manualSave ? this.manualSaveSubject : undefined,
            formControls: this.formControls(),
            toFormGroup: (entity: TEntity) => {
                return this.toFormGroup(entity);
            },
            fromFormGroup: (formValue: TFormInterface) => {
                return {
                    ...(this.initialValue || {}),
                    ...this.fromFormGroup(formValue),
                };
            },
        } as NgxRootFormOptions<TEntity, TFormInterface>);
    }

    ngOnInit(): void {
        this.setupDirtyCheck();
        this.setupManualSave();
        this.listenForFormCancel();
        setTimeout(() => (this.loading = false), 2000);
    }

    abstract formControls(): Controls<TFormInterface>;

    abstract toFormGroup(item: TEntity): TFormInterface;

    abstract fromFormGroup(formValue: TFormInterface): TEntity;

    private setupDirtyCheck() {
        if (!this.registerDirtyCheck) {
            return;
        }
        // Setup isDirty$ observable
        const isDirty$ = dirtyCheck(
            this.form.formGroup,
            this.input$.pipe(
                filter(i => !!i),
                map(initialValue => this.toFormGroup(initialValue as TEntity))
            ),
            {
                useBeforeunloadEvent: true,
            } as SafeAny
        );
        isDirty$
            .pipe(
                untilDestroyed(this),
                filter(() =>
                    // Only notify dirty state when form is in init or error state
                    ['init', 'error'].includes(
                        this.formService.activeState(this.entityType)?.action ||
                            ''
                    )
                ),
                tap(isDirty => {
                    this.formService.notifyState(this.entityType, {
                        isDirty: isDirty && this.form.formGroup.dirty,
                    });
                })
            )
            .subscribe();
        markDirtyOnSubmitIfInvalid(
            this.formService,
            this.entityType,
            this.form.formGroup,
            untilDestroyed(this)
        );
    }

    private setupManualSave() {
        if (!this.manualSave) {
            return;
        }
        this.formService
            .submit$(this.entityType)
            .pipe(
                untilDestroyed(this),
                // use delays and update value to allow sub forms using push change detection to update their values
                delay(0),
                tap(() =>
                    this.form.formGroup.updateValueAndValidity({
                        onlySelf: false,
                        emitEvent: true,
                    })
                ),
                delay(0),
                map(() => true),
                optionalMap(this.preSaveFn),
                tap((shouldSave: boolean) => {
                    if (!shouldSave) {
                        this.formService.notifyState(this.entityType, {
                            action: 'init',
                        });
                        return;
                    }
                    if (
                        !this.formService.activeState(this.entityType)
                            ?.isDirty &&
                        this.formService.activeState(this.entityType)
                            ?.forceDirty
                    ) {
                        this.output$.next(this.initialValue as TEntity);
                    } else {
                        this.manualSaveSubject.next();
                    }
                })
            )
            .subscribe();
    }

    private listenForFormCancel() {
        this.formService
            .cancel$(this.entityType)
            .pipe(
                untilDestroyed(this),
                this.dirtyCheckService.promptIfDirty(),
                tap(confirmed => {
                    if (!confirmed) {
                        this.formService.notifyState(this.entityType, {
                            action: 'init',
                        });
                    }
                }),
                filter(shouldContinue => shouldContinue),
                tap(() => {
                    this.form.formGroup.reset(this.initialValue);
                    this.form.formGroup.markAsPristine();
                    this.formService.cancelled(this.entityType);
                })
            )
            .subscribe();
    }
}
