import { Observable, from, of, switchMap } from 'rxjs';

import { FormActionState } from './form.service';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SafeAny } from '@pf/shared-common';

@Injectable({ providedIn: 'root' })
export class DirtyCheckService {
    constructor(private modal: NzModalService) {}

    promptIfDirty() {
        return (
            source: Observable<FormActionState<SafeAny>>
        ): Observable<boolean> => {
            return source.pipe(
                switchMap((state) => {
                    if (!state.isDirty) {
                        return of(true);
                    }
                    return from(this.promptConfirm());
                })
            );
        };
    }

    promptConfirm(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modal.confirm({
                nzTitle: 'Discard changes?',
                nzContent:
                    'Are you sure you want to leave? Changes made will not be saved.',
                nzOnOk: () => resolve(true),
                nzOnCancel: () => resolve(false),
            });
        });
    }
}
