import { AbstractEntityStore } from '@pf/shared-services';
import { Injectable } from '@angular/core';
import { PFCoreEntities } from '@pf/shared-common';
import { VendorDto } from '../../entities/vendor.dto';

@Injectable()
export class VendorStore extends AbstractEntityStore<VendorDto> {
    constructor() {
        super(PFCoreEntities.Vendor);
    }
}
