import {
    IPagedResult,
    IQueryParams,
    ISearchFacade,
    ITypeEntity,
    SafeAny,
    SelectOption,
    TypeEntityTypes,
} from '@pf/shared-common';
import { first, map, Observable, of, switchMap, tap } from 'rxjs';
import { LoggerService } from '@pf/shared-services';
import { TypeEntityStore } from '../stores/TypeEntityStore';
import { asArraySafe, mapToSelectOptions } from '@pf/shared-utility';
import { mapToIPagedResult } from '../utility/operators.util';
import { TypeMatch } from '../configuration/type.config';

export abstract class AbstractTypeEntityFacade<TService>
    implements ISearchFacade<ITypeEntity>
{
    protected constructor(
        protected store: TypeEntityStore,
        protected logger: LoggerService,
        protected typeService: SafeAny,
        protected serviceFunctionName: keyof TService,
        protected entityType: TypeEntityTypes
    ) {}

    searchAll$(_params: IQueryParams<ITypeEntity>): Observable<ITypeEntity[]> {
        throw new Error('Method not implemented.');
    }

    currentPage$: Observable<IPagedResult<ITypeEntity>> = this.store
        .getCurrentEntities(this.entityType)
        .pipe(mapToIPagedResult);

    getEntityName(entity: ITypeEntity): string {
        return entity ? entity.name : '';
    }

    mapToSelectOptions(
        source: Observable<IPagedResult<ITypeEntity>>
    ): Observable<SelectOption[]> {
        return mapToSelectOptions<ITypeEntity>('name')(source);
    }

    getById(id: string): ITypeEntity | null {
        return this.store.get(id, this.entityType) || null;
    }

    getById$(id: string) {
        const existing = this.getById(id);
        if (existing) {
            return of(existing);
        }
        return this.getAll(true).pipe(
            first(),
            map(() => this.store.get(id, this.entityType) || null)
        );
    }

    getByCode(code: string) {
        return this.store.getByCode(code, this.entityType) || null;
    }

    getByCode$(code: string) {
        const cachedEntity = this.getByCode(code);
        if (cachedEntity) {
            return of(cachedEntity);
        }
        return this.getAll(true).pipe(
            first(),
            map(() => this.store.getByCode(code, this.entityType))
        );
    }

    getMany$(): Observable<ITypeEntity[]> {
        throw new Error('Not implemented');
    }

    getAll$(): Observable<ITypeEntity[]> {
        return this.getAll();
    }

    searchByText$(text: string): Observable<IPagedResult<ITypeEntity>> {
        return this.searchEntities(entities =>
            asArraySafe(entities).filter(entity => entity.name.includes(text))
        );
    }

    search$(
        params?: IQueryParams<ITypeEntity>
    ): Observable<IPagedResult<ITypeEntity>> {
        return this.searchEntities(entities => {
            if (!entities?.length) {
                return [];
            }
            return params
                ? entities.filter(entity =>
                      params.filters
                          ? Object.keys(params.filters).every(key =>
                                (entity as SafeAny)[key].includes(
                                    (params.filters as SafeAny)[key]
                                )
                            )
                          : entities
                  )
                : entities;
        });
    }

    export$(): Observable<boolean> {
        throw new Error('Not implemented');
    }

    mapToCodes$<T extends string | TypeMatch>(
        typeMatches: T[]
    ): Observable<T[]> {
        return this.getAll().pipe(
            first(),
            map((entities: ITypeEntity[]) => {
                if (!entities?.length) {
                    return [];
                }
                return typeMatches
                    .map(
                        match =>
                            (typeof match === 'string'
                                ? { code: match }
                                : match) as TypeMatch
                    )
                    .map((match: TypeMatch) => {
                        match.typeFragment = entities.find(
                            entity => entity.code === match.code
                        );
                        return match;
                    }) as T[];
            })
        );
    }

    private getAll(forceRefresh = false): Observable<ITypeEntity[]> {
        return this.store.getTypeEntities(this.entityType).pipe(
            first(),
            switchMap(entities => {
                if (!entities?.length || forceRefresh) {
                    const serviceFunction = this.typeService[
                        this.serviceFunctionName
                    ].bind(this.typeService) as () => Observable<ITypeEntity[]>;
                    return serviceFunction().pipe(
                        tap(entities => {
                            this.store.setTypeEntities(
                                entities,
                                this.entityType
                            );
                        })
                    );
                }
                return of(entities || []);
            })
        );
    }

    private searchEntities(
        filterFn: (entities: ITypeEntity[]) => ITypeEntity[]
    ): Observable<IPagedResult<ITypeEntity>> {
        return this.getAll().pipe(
            first(),
            map(filterFn),
            mapToIPagedResult,
            tap(result =>
                this.store.setCurrentEntities(
                    result.data.map(entity => entity.id),
                    this.entityType
                )
            )
        );
    }
}
