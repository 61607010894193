import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
} from '@angular/core';
import {
    CustomFieldConfig,
    findCustomField,
    ICustomFieldDto,
} from '@pf/shared/util-platform';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SafeAny } from '@pf/shared-common';
import { PfStyle } from '@pf/shared-ui';
import { asArraySafe } from '@pf/shared-utility';
import { Subscription } from 'rxjs';

type CustomFieldsForm = Record<string, string>;

@Component({
    selector: 'platform-custom-fields-form',
    templateUrl: './custom-fields-form.component.html',
    styleUrls: ['./custom-fields-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomFieldsFormComponent,
        },
    ],
})
export class CustomFieldsFormComponent
    implements ControlValueAccessor, OnDestroy
{
    private _customFields?: ICustomFieldDto[];
    private _customFieldConfigs: CustomFieldConfig[] = [];
    private _entity: SafeAny;

    private readonly cdr = inject(ChangeDetectorRef);

    touched = false;

    disabled = false;

    /**
     * @internal
     */
    customFieldsForm?: FormGroup;

    @Input() pfStyle: PfStyle = 'default';
    @Input() pfColumn = false;
    customFieldLists: Record<
        string,
        {
            input: ICustomFieldDto[];
            output: ICustomFieldDto[];
        }
    > = {};
    private changesSubscription?: Subscription;

    @Input() set customFieldConfigs(configs: CustomFieldConfig[]) {
        this._customFieldConfigs = configs;
        this.processCustomFields();
        this.createForm();
    }

    @Input() set entity(entity: SafeAny) {
        this._entity = entity;
        this.processCustomFields();
    }

    processedCustomFieldConfigs: CustomFieldConfig[] = [];

    ngOnDestroy() {
        this.changesSubscription?.unsubscribe();
    }

    /**
     * @internal
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange = (customFields: ICustomFieldDto[]) => undefined;

    /**
     * @internal
     */
    onTouched = () => undefined;

    writeValue(customFields: ICustomFieldDto[]) {
        this._customFields = customFields;
        this.toFormGroup(customFields);
    }

    registerOnChange(onChange: SafeAny) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: SafeAny) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    trackByFn(index: number, item: CustomFieldConfig) {
        return item.label;
    }

    private createForm() {
        const controls = this._customFieldConfigs.reduce((acc, config) => {
            if (config.list) {
                return acc;
            }
            acc[config.key] = new FormControl();
            return acc;
        }, {} as SafeAny);
        this.customFieldsForm = new FormGroup(controls);
        this.setupFormNotifier();
    }

    private toFormGroup(customFields: ICustomFieldDto[]) {
        if (!this.customFieldsForm) {
            return;
        }

        const matchedCustomFields = [...asArraySafe(customFields)];
        const customFieldLists: CustomFieldsFormComponent['customFieldLists'] =
            {};
        const formValues = this._customFieldConfigs.reduce((acc, config) => {
            if (config.list) {
                customFieldLists[config.key] = {
                    input: matchedCustomFields.filter(
                        c => c.key === config.key
                    ),
                    output: [],
                };
                return acc;
            }
            const customField = findCustomField(
                matchedCustomFields,
                config.key
            );
            if (!customField) {
                matchedCustomFields.push({
                    id: '',
                    key: config.key,
                    value: '',
                });
                acc[config.key] = '';
            } else {
                acc[config.key] = customField.value || '';
            }
            return acc;
        }, {} as CustomFieldsForm);
        this._customFields = matchedCustomFields;
        this.customFieldLists = customFieldLists;
        this.customFieldsForm.setValue(formValues);
        this.cdr.markForCheck();
    }

    private fromFormGroup(formValue: CustomFieldsForm) {
        const customFields: ICustomFieldDto[] = (this._customFields || []).map(
            cf => {
                return {
                    ...cf,
                    value:
                        formValue[cf.key] === undefined
                            ? cf.value
                            : formValue[cf.key],
                };
            }
        );
        for (const customFieldListsKey in this.customFieldLists) {
            const listFields =
                this.customFieldLists[customFieldListsKey].output;
            for (const listField of listFields) {
                if (!listField.id) {
                    customFields.push(listField);
                    continue;
                }
                const existingCustomField = customFields.find(
                    cf => cf.id === listField.id
                );
                if (!existingCustomField) {
                    customFields.push(listField);
                } else {
                    existingCustomField.value = listField.value;
                }
            }
        }
        return customFields.filter(ref => ref.value);
    }

    private setupFormNotifier() {
        if (!this.customFieldsForm) {
            return;
        }
        this.changesSubscription?.unsubscribe();
        this.changesSubscription = this.customFieldsForm.valueChanges.subscribe(
            value => {
                this.onChange(this.fromFormGroup(value));
            }
        );
    }

    listItemsChanged($event: ICustomFieldDto[], key: string) {
        this.markAsTouched();
        this.customFieldLists[key].output = $event;
        this.onChange(this.fromFormGroup(this.customFieldsForm?.value));
    }

    private processCustomFields() {
        if (!this._customFieldConfigs) {
            return;
        }
        this.processedCustomFieldConfigs = this._customFieldConfigs.map(
            config => {
                const processedConfig = {
                    ...config,
                    label: config.label || config.key,
                };
                if (config.entityChanged) {
                    config.entityChanged(this._entity, processedConfig);
                }
                return processedConfig;
            }
        );
    }
}
