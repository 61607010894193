import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    TrailerCreate,
    TrailerDto,
    TrailerRead,
    TrailerSearchParams,
} from '../../entities/trailer.dto';
import { PFTransportationEntities } from '@pf/shared-common';
import {
    DefaultCarrierFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFTransportationEntities.Trailer);

withDefaultEntityMetadata<TrailerDto>(symbols.dto, {
    trailerNumber: String,
    description: String,
    carrier: [DefaultCarrierFragmentMetadataMap.symbols.dto],
    location: [DefaultLocationFragmentMetadataMap.symbols.dto],
});

withDefaultEntityMetadata<TrailerRead>(symbols.entity, {
    trailerNumber: String,
    description: String,
    carrier: [DefaultCarrierFragmentMetadataMap.symbols.entity],
    location: [DefaultLocationFragmentMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
});

withMetadata<TrailerCreate>(symbols.createBody, {
    trailerNumber: String,
    carrierId: String,
    locationId: String,
    description: String,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<TrailerSearchParams>(symbols.searchParams, {
    trailerNumber: String,
});

export const TrailerMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<TrailerRead, TrailerDto>(mapper, symbols.entity, symbols.dto);
        createMap<TrailerDto, TrailerRead>(mapper, symbols.dto, symbols.entity);
        createMap<TrailerDto, TrailerCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<TrailerDto>, TrailerSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('trailerNumber'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
        DefaultCarrierFragmentMetadataMap.profile(mapper);
        DefaultLocationFragmentMetadataMap.profile(mapper);
    },
};
