import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { FrameworkColumn } from '@pf/shared-common';
import { InputComponent } from '../../../../components/input/input.component';

@Component({
    selector: 'pf-table-header-search',
    templateUrl: './table-header-search.component.html',
    styleUrls: ['./table-header-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderSearchComponent {
    @ViewChild(InputComponent) input!: InputComponent;
    @Input() column!: FrameworkColumn;
    @Output() searchChanged = new EventEmitter<FrameworkColumn>();
    searchControl = new FormControl<string>('');
    searchVisible = false;

    get isFilterActive() {
        return this.column.searchValue.length > 0;
    }

    search(event?: Event) {
        event?.stopImmediatePropagation();
        this.column.searchValue = this.searchControl.value?.trim() || '';
        this.searchChanged.next(this.column);
    }

    reset(event?: Event) {
        event?.stopImmediatePropagation();
        this.searchControl.reset('');
        this.column.searchValue = '';
        this.search();
    }

    onVisibleChange() {
        if (this.searchVisible) {
            setTimeout(() => this.input.focus());
        }
    }
}
