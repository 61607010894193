<pf-button
    buttonType="action"
    centered
    [title]="firstAction.tooltip || ''"
    *ngIf="firstAction"
    (click)="actionClicked(firstAction)"
    [disabled]="!!firstAction.disable">
    <pf-text [type]="firstAction.textType">{{ firstAction.name }}</pf-text>
</pf-button>
<ng-container *ngIf="moreActions">
    <nz-divider nzType="vertical"></nz-divider>
    <pf-icon
        class="table-action-menu-trigger"
        iconType="inlineMenu"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        nzOverlayClassName="table-actions-menu"></pf-icon>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li
                nz-menu-item
                *ngFor="let action of moreActions"
                [title]="action.tooltip || ''"
                (click)="actionClicked(action)"
                [nzDisabled]="!!action.disable">
                <pf-text [type]="action.textType">{{ action.name }}</pf-text>
            </li>
        </ul>
    </nz-dropdown-menu>
</ng-container>
