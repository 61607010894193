import { ChargeDto, ChargeMetadataMap, IChargeDto } from './charge.dto';
import {
    DefaultDriverFragmentMetadataMap,
    IDriverFragmentDto,
} from '@pf/shared/util-platform';
import { IEntity, IMappingProfile } from '@pf/shared-common';
import {
    LoadDriver,
    LoadDriverViewModel,
    TripDriver,
} from '@control-tower/platform-loads';
import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
    entitySymbols,
    mapToClassSafe,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import { uniqueId } from 'lodash-es';

export type LoadDriverRead = LoadDriver;
export type LoadDriverCreate = LoadDriverViewModel;

export interface ILoadDriverDto extends IEntity, LoadDriver, TripDriver {}

export class LoadDriverDto implements ILoadDriverDto {
    constructor(data: Partial<ILoadDriverDto>) {
        Object.assign(this, data);
        this.id = uniqueId();
        this.pays = mapToClassSafe(data.pays, ChargeDto);
    }
    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    driver?: IDriverFragmentDto;
    pays?: IChargeDto[];
}

const symbols = entitySymbols('LoadDriver');

withDefaultEntityMetadata<LoadDriverDto>(symbols.dto, {
    driver: DefaultDriverFragmentMetadataMap.symbols.dto,
    pays: [ChargeMetadataMap.symbols.dto],
});

withMetadata<LoadDriverRead>(symbols.entity, {
    driver: DefaultDriverFragmentMetadataMap.symbols.entity,
    pays: [ChargeMetadataMap.symbols.entity],
});

withMetadata<LoadDriverCreate>(symbols.createBody, {
    driverId: String,
    pays: [ChargeMetadataMap.symbols.createBody],
});

export const LoadDriverMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LoadDriverRead, LoadDriverDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<LoadDriverDto, LoadDriverRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LoadDriverDto, LoadDriverCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                (x) => x.driverId,
                mapFrom((source) => source.driver?.id || null)
            )
        );

        ChargeMetadataMap.profile(mapper);
        DefaultDriverFragmentMetadataMap.profile(mapper);
    },
};
