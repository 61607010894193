import { Injectable } from '@angular/core';
import { CategoryRead } from '../../entities/category.dto';
import { PFItemCatalogEntities } from '@pf/shared-common';
import { localStorageStrategy } from '@ngneat/elf-persist-state';
import { AbstractEntityStoreWithUiEntities } from '@pf/shared-services';
import { union } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    combineLatest,
    first,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { selectManyByPredicate } from '@ngneat/elf-entities';
import { asArraySafe } from '@pf/shared-utility';

export interface CategoryUI {
    id: string;
    itemsWithCategory?: string[];
}

@UntilDestroy()
@Injectable()
export class CategoryStore extends AbstractEntityStoreWithUiEntities<
    CategoryRead,
    CategoryUI
> {
    constructor() {
        super(PFItemCatalogEntities.Category);
        this.persistUIState('category-ui-state', localStorageStrategy);
        this.observeCategoriesAndAddOrRemoveUiEntity();
    }

    addItemToCategoryUi(itemId: string, categoryId: string) {
        const category = this.getUIEntity(categoryId);
        if (category) {
            this.updateUIEntity(categoryId, {
                itemsWithCategory: union(category.itemsWithCategory, [itemId]),
            });
        } else {
            this.addUIEntity({
                id: categoryId,
                itemsWithCategory: [itemId],
            });
        }
    }

    removeItemFromCategoryUi(itemId: string, categoryId: string) {
        const category = this.getUIEntity(categoryId);
        if (category) {
            this.updateUIEntity(categoryId, {
                itemsWithCategory: category.itemsWithCategory?.filter(
                    id => id !== itemId
                ),
            });
        }
    }

    getTopLevelCategories$(): Observable<CategoryRead[]> {
        return this._store.pipe(
            selectManyByPredicate(c => c.isTopLevelCategory === true)
        );
    }

    getChildren$(parentId: string): Observable<CategoryRead[]> {
        return this._store.pipe(
            selectManyByPredicate(c => c.parentCategoryId === parentId)
        );
    }

    getAllChildren$(parentId: string): Observable<CategoryRead[]> {
        return this.getChildren$(parentId).pipe(
            first(),
            switchMap(children => {
                return children.length
                    ? combineLatest(
                          asArraySafe(children).map(child =>
                              this.getAllChildren$(child.id)
                          )
                      ).pipe(
                          map(childrenArray =>
                              children.concat(childrenArray.flat())
                          )
                      )
                    : of([]);
            })
        );
    }

    private observeCategoriesAndAddOrRemoveUiEntity() {
        this.added
            .pipe(
                untilDestroyed(this),
                tap(category => {
                    if (!this.getUIEntity(category.id)) {
                        this.addUIEntity({
                            id: category.id,
                            itemsWithCategory: [],
                        });
                    }
                })
            )
            .subscribe();
        this.deleted
            .pipe(
                untilDestroyed(this),
                tap(category => {
                    if (this.getUIEntity(category.id)) {
                        this.removeUIEntity(category.id);
                    }
                })
            )
            .subscribe();
    }
}
