import {
    ICustomFieldsEntity,
    IEntity,
    IEntityDataService,
    IEntityMapper,
    IEntityStore,
    IManageEntityFacade,
    IManageEntityRoutes,
    IPagedSearchParams,
    PlatformAuthService,
    sharedEnv,
} from '@pf/shared-common';
import {
    combineLatest,
    first,
    lastValueFrom,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import {
    asArraySafe,
    fixEntityOperations,
    RemoveNulls,
} from '@pf/shared-utility';
import { cloneDeep, merge } from 'lodash-es';

import { compare } from 'fast-json-patch';
import { AbstractSearchFacade } from './AbstractSearchFacade';
import { inject } from '@angular/core';
import {
    RealTimeEvent,
    realTimeEventFactory,
} from '@firebend/control-tower-web-socket-client';

export interface EntitySaveOptions<TDto extends IEntity | ICustomFieldsEntity> {
    saveCustomFields?: boolean;
    actionText?: string;
    emitEvent?: boolean;
    postSave?: (entity: TDto) => void;
}

export abstract class AbstractManageEntityFacade<
        TDto extends IEntity | ICustomFieldsEntity,
        TEntity extends IEntity | ICustomFieldsEntity,
        TCreateBody extends object,
        TSearchParams extends IPagedSearchParams
    >
    extends AbstractSearchFacade<TDto, TEntity, TCreateBody, TSearchParams>
    implements IManageEntityFacade<TDto>
{
    private _authService = inject(PlatformAuthService);
    added$ = new Subject<{ entity: TDto; actionText?: string }>();
    updated$ = new Subject<{ entity: TDto; actionText?: string }>();
    deleted$ = new Subject<{ entity: TDto; actionText?: string }>();
    restored$ = new Subject<{ entity: TDto; actionText?: string }>();

    protected constructor(
        protected override dataService: IEntityDataService<
            TEntity,
            TCreateBody
        >,
        public routes: IManageEntityRoutes,
        store: IEntityStore<TEntity>,
        mapper: IEntityMapper<TEntity, TCreateBody, TDto, TSearchParams>
    ) {
        super(dataService, store, mapper);
    }

    get active$(): Observable<TDto | null> {
        return this.store
            .active$()
            .pipe(map(entity => (entity ? this.mapper.toDTO(entity) : null)));
    }

    get active(): TDto | null {
        return this.store.active ? this.mapper.toDTO(this.store.active) : null;
    }

    applyResourceAuth?(body: TCreateBody, dto: TDto): void;

    manage() {
        if (!this.routes) {
            return;
        }
        this.routes.main().navigate();
    }

    // added parameter savecustomfields because trying to update the custom fields after cancelling a load is returning 403 -terry
    save$(dto: TDto, options?: EntitySaveOptions<TDto>): Observable<TDto> {
        console.log('saving', dto);
        const resolvedOptions = {
            saveCustomFields: true,
            emitEvent: true,
            ...(options || {}),
        };
        if (!dto.id) {
            return this.addEntity$(dto, resolvedOptions);
        } else {
            return this.updateEntity$(dto, resolvedOptions);
        }
    }

    private updateEntity$(dto: TDto, resolvedOptions: EntitySaveOptions<TDto>) {
        const existingEntity = this.store.get(dto.id);
        if (!existingEntity) {
            throw new Error(
                `Cannot update entity ${dto.id} because it does not exist in the store`
            );
        }
        const existingEntityCreateBody = this.mapper.toCreateBody(
            this.mapper.toDTO(existingEntity)
        );
        const updatingEntityCreateBody = this.mapper.toCreateBody(dto);

        const mergedEntity = merge(
            cloneDeep(existingEntityCreateBody),
            updatingEntityCreateBody
        );
        this.applyResourceAuth?.(mergedEntity, dto);
        const operations = fixEntityOperations(
            dto,
            compare(existingEntityCreateBody, mergedEntity)
        );

        return this.dataService.update$(dto.id, operations).pipe(
            switchMap(entity =>
                resolvedOptions.saveCustomFields
                    ? this.saveCustomFields$(entity, dto)
                    : of(entity)
            ),
            map(entity => this.mapper.toDTO(entity)),
            tap(savedDto => {
                if (resolvedOptions.postSave) {
                    resolvedOptions.postSave(savedDto);
                }
            }),
            tap(entity => {
                if (resolvedOptions.emitEvent) {
                    this.updated$.next({
                        entity,
                        actionText: resolvedOptions.actionText,
                    });
                }
            })
        );
    }

    private addEntity$(dto: TDto, resolvedOptions: EntitySaveOptions<TDto>) {
        const body = RemoveNulls(this.mapper.toCreateBody(dto));
        this.applyResourceAuth?.(body, dto);
        return this.dataService.create$(body).pipe(
            switchMap(entity =>
                resolvedOptions.saveCustomFields
                    ? this.saveCustomFields$(entity, dto)
                    : of(entity)
            ),
            map(entity => this.mapper.toDTO(entity)),
            tap(savedDto => {
                if (resolvedOptions.postSave) {
                    resolvedOptions.postSave(savedDto);
                }
            }),
            tap(savedDto => {
                if (resolvedOptions.emitEvent) {
                    this.added$.next({
                        entity: savedDto,
                        actionText: resolvedOptions.actionText,
                    });
                }
            })
        );
    }

    saveCustomFields$(entity: TEntity, dto: TDto): Observable<TEntity> {
        const existingCustomFields = asArraySafe(
            (entity as ICustomFieldsEntity).customFields
        );
        const customFields = asArraySafe(
            (dto as ICustomFieldsEntity).customFields
        );

        const updates = [];
        if (customFields.length > 0) {
            const addingCustomFields = customFields.filter(
                customField => !customField.id
            );
            if (addingCustomFields.length > 0) {
                updates.push(
                    this.dataService.createCustomFields$(
                        entity.id,
                        addingCustomFields
                    )
                );
            }

            const updatingCustomFields = customFields.filter(
                customField =>
                    customField.id &&
                    existingCustomFields.find(e => e.id === customField.id)
                        ?.value !== customField.value
            );
            if (updatingCustomFields.length > 0) {
                updates.push(
                    this.dataService.updateCustomFields$(
                        entity.id,
                        updatingCustomFields
                    )
                );
            }
        }

        if (existingCustomFields.length > 0) {
            const customFieldIdsToDelete = existingCustomFields
                .filter(
                    existingCustomField =>
                        !customFields.find(
                            customField =>
                                customField.id === existingCustomField.id
                        )
                )
                .map(customField => customField.id as string);
            if (customFieldIdsToDelete.length > 0) {
                updates.push(
                    this.dataService.deleteCustomFields$(
                        entity.id,
                        customFieldIdsToDelete
                    )
                );
            }
        }
        return updates.length
            ? combineLatest(updates).pipe(map(() => entity))
            : of(entity);
    }

    resetActive(): void {
        this.store.resetActiveEntity();
    }

    setActive(entityId: string): void {
        this.store.setActiveEntity(entityId);
    }

    add() {
        this.resetActive();
        if (!this.routes) {
            return;
        }
        this.routes.add().navigate();
    }

    edit(entityId: string, hash?: string, newWindow = false): void {
        this.store.setActiveEntity(entityId);

        if (!this.routes) {
            return;
        }
        if (newWindow) {
            window.open(this.routes.edit(entityId).url, '_blank');
        } else {
            this.routes.edit(entityId).navigate({
                state: { hash },
            });
        }
    }

    view(entityId: string): void {
        if (!this.routes) {
            return;
        }
        this.routes.view(entityId).navigate();
    }

    delete$(
        entityId: string,
        actionText?: string,
        notify = true
    ): Observable<TDto> {
        return this.dataService.delete$(entityId).pipe(
            map(entity => this.mapper.toDTO(entity)),
            tap(entity => {
                if (notify) {
                    this.deleted$.next({ entity, actionText });
                }
            })
        );
    }

    undoDelete$(
        entityId: string,
        actionText?: string,
        notify = true
    ): Observable<TDto> {
        return this.dataService.undoDelete$(entityId).pipe(
            map(entity => this.mapper.toDTO(entity)),
            tap(entity => {
                if (notify) {
                    this.restored$.next({ entity, actionText });
                }
            })
        );
    }

    load(entityId: string) {
        this.dataService
            .get$(entityId)
            .pipe(
                first(),
                tap(() => this.store.setActiveEntity(entityId))
            )
            .subscribe();
    }

    listenForRealTimeEvents(
        eventName: string,
        opts?: {
            customEntityMapping: (entity: TEntity) => Observable<TEntity>;
        }
    ) {
        realTimeEventFactory(`${sharedEnv.baseRealTimeUrl}/events/signalr`)
            .withAccessToken(lastValueFrom(this._authService.getAccessToken()))
            .startAsync()
            .then(builder => {
                const customEntityMapping =
                    opts?.customEntityMapping || (entity => of(entity));
                builder.onAll<TEntity>(eventName, event => {
                    this.processEvent(event, customEntityMapping);
                });
            });
    }

    private processEvent(
        event: RealTimeEvent<TEntity>,
        customEntityMapping: (entity: TEntity) => Observable<TEntity>
    ) {
        if (!event.entity) {
            return;
        }
        const existingEntity = this.store.get(event.entity.id);
        if (
            existingEntity &&
            event.entity.modifiedDate === existingEntity.modifiedDate
        ) {
            return;
        }
        customEntityMapping(event.entity as TEntity)
            .pipe(
                first(),
                tap(entity => {
                    this.store.update(entity);
                })
            )
            .subscribe();
    }
}
