import {
    PlatformAuthService,
    SafeAny,
    TenantProvider,
} from '@pf/shared-common';
import {
    Tenant,
    TenantBranding,
    TenantLocalization,
    TenantsService,
} from '@control-tower/platform-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createStore, select, withProps } from '@ngneat/elf';
import { catchError, filter, map, of, Subject, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    excludeKeys,
    localStorageStrategy,
    persistState,
} from '@ngneat/elf-persist-state';

interface ITenantStore {
    defaultTenantId: string | null;
    selectedTenantId: string | null;
    tenants: Record<string, Tenant>;
}

const tenantStore = createStore(
    { name: 'tenant' },
    withProps<ITenantStore>({
        selectedTenantId: null,
        defaultTenantId: null,
        tenants: {},
    })
);

export const persist = persistState(tenantStore, {
    storage: localStorageStrategy,
    source: () => tenantStore.pipe(excludeKeys(['tenants', 'defaultTenantId'])),
});

function getProperty$<TReturn>(
    prop: keyof Tenant,
    mapFn: (tenant: Tenant) => TReturn
) {
    return tenantStore.pipe(
        filter(
            p =>
                p.selectedTenantId != null &&
                !!p.tenants[p.selectedTenantId]?.[prop]
        ),
        map(state => state.tenants[state.selectedTenantId as string]),
        select(mapFn)
    );
}

@UntilDestroy()
@Injectable()
export class DefaultTenantProvider extends TenantProvider {
    private _loaded = false;

    constructor(
        private tenantService: TenantsService,
        private authService: PlatformAuthService
    ) {
        super();
    }

    get tenant() {
        const tenantId =
            tenantStore.value.selectedTenantId ||
            tenantStore.value.defaultTenantId;
        if (!tenantId) return null;
        return tenantStore.value.tenants[tenantId];
    }

    id$ = getProperty$('id', tenant => tenant.id as string);
    name$ = getProperty$('name', tenant => tenant.name as string);
    branding$ = getProperty$(
        'branding',
        tenant => (tenant.branding || {}) as TenantBranding
    );
    localization$ = getProperty$(
        'localization',
        tenant => (tenant.localization || {}) as TenantLocalization
    );
    tenants$ = tenantStore.pipe(select(state => state.tenants));

    failure$: Subject<SafeAny> = new Subject();

    load(): void {
        if (this._loaded) {
            return;
        }
        this._loaded = true;
        this.authService.isAuthenticated$
            .pipe(
                untilDestroyed(this),
                filter(isAuthenticated => isAuthenticated),
                switchMap(() => this.tenantService.v1TenantsGet()),
                map(tenants =>
                    (tenants || []).reduce((pv, cv) => {
                        pv[cv.id as string] = cv;
                        return pv;
                    }, {} as ITenantStore['tenants'])
                )
            )
            .pipe(
                catchError(error => {
                    this.failure$.next(error);
                    return of({} as Record<string, Tenant>);
                })
            )
            .subscribe(tenants =>
                tenantStore.update(state => ({
                    ...state,
                    tenants: tenants,
                }))
            );
    }

    setDefaultTenant(tenantId: string): void {
        tenantStore.update(state => {
            return {
                ...state,
                defaultTenantId: tenantId,
                selectedTenantId: state.selectedTenantId || tenantId,
            };
        });
    }

    selectTenant(tenantId: string): void {
        if (tenantStore.value.selectedTenantId === tenantId) {
            return;
        }
        tenantStore.update(state => {
            return {
                ...state,
                selectedTenantId: tenantId,
            };
        });
        // brute force refresh. We could potentially do this without a page refresh;
        // however, we would have to make sure every part of the app uses consistent refresh logic
        (window as SafeAny).location.reload();
    }
}
