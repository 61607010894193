import { NZ_CONFIG } from 'ng-zorro-antd/core/config';

export interface IGlobalAppConfig {
    forms: {
        errorTips: Record<string, string>;
    };
}

export function GlobalAppConfigProvider(config: IGlobalAppConfig) {
    return {
        provide: NZ_CONFIG,
        useValue: {
            form: {
                nzAutoTips: {
                    en: config.forms.errorTips,
                },
            },
        },
    };
}
