import {
    IEntity,
    SafeAny,
    SelectOption,
    TreeSelectOption,
} from '@pf/shared-common';

export const mapToClassSafe = <TClass, TInterface = TClass>(
    list: TInterface[] | undefined | null,
    ctor: new (data: TInterface) => TClass
): TClass[] => {
    return list ? list.map(item => new ctor(item)) : [];
};

export const asArraySafe = <TInterface>(
    list: TInterface[] | undefined | null
): TInterface[] => {
    return list ? [...list] : [];
};

export const shallowCopyArray = (arr: SafeAny[]) => {
    return [...arr];
};

export const isArrayEmpty = (arr: SafeAny[]) => {
    return !arr || arr.length === 0;
};

export const toSelectOptions = <TDto>(
    data: TDto[],
    label: keyof TDto,
    value: keyof (TDto & IEntity) = 'id',
    groupBy: (record: TDto) => string | null | undefined = () => undefined
) => {
    return data.map(record => {
        return {
            label: (record as SafeAny)[label] as SafeAny,
            value: (record as SafeAny)[value] as SafeAny,
            group: groupBy(record) as SafeAny
        } as SelectOption;
    });
};

export const toSelectOptionsFlattened = <TDto>(
    data: TDto[],
    label: keyof TDto,
    value: keyof (TDto & IEntity) = 'id',
    groupBy: (record: TDto) => string[] | null | undefined = () => undefined
) => {
    return data.flatMap(record => {
        const groups = groupBy(record);
        return (groups || [null]).map(group => ({
            label: (record as SafeAny)[label] as SafeAny,
            value: (record as SafeAny)[value] as SafeAny,
            group: group as SafeAny
        } as SelectOption));
    });
};

export const toTreeSelectOptions = <TDto>(
    data: TDto[],
    title: keyof TDto,
    value: keyof (TDto & IEntity) = 'id',
    recursive = false
): TreeSelectOption[] => {
    return data.map(record => {
        const children = (record as SafeAny).children as TDto[];
        return {
            title: record[title] as SafeAny,
            key: (record as SafeAny)[value],
            value: (record as SafeAny)[value],
            children: recursive
                ? toTreeSelectOptions(
                      asArraySafe(children),
                      title,
                      value,
                      recursive
                  )
                : null,
            isLeaf: recursive && !children?.length,
        } as TreeSelectOption;
    });
};

export function flattenTree<T extends { children?: T[] }>(data: T): T[] {
    if (!data.children?.length) {
        return [] as T[];
    }
    return data.children.concat(
        data.children.reduce((pv, cv) => {
            return pv.concat(flattenTree(cv));
        }, [] as T[])
    );
}
