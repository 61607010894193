import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { FormControl } from '@angular/forms';

@UntilDestroy()
@Component({
    selector: 'pf-table-toolbar',
    templateUrl: './table-toolbar.component.html',
    styleUrls: ['./table-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableToolbarComponent implements AfterViewInit {
    @Input() title = '';
    @Input() showSearch = false;
    @Input() showClearFilters = false;
    @Output() search = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter();
    @Input() disableClearFilters = false;
    searchControl = new FormControl<string>('');

    ngAfterViewInit(): void {
        this.searchControl.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe((search) => {
                this.search.next(search ? search.trim() : '');
            });
    }

    resetClicked() {
        this.searchControl.reset();
        // value is irrelevant here
        this.resetTable.next(new Date());
    }
}
