<pf-content>
    <pf-content-collapsible
        title="{{ recipientEntityType | AppConstant }} Links"
        [active]="collapsed">
        <pf-table
            [data]="data"
            [columns]="columns"
            [actions]="actions"
            [disableAutoPageSize]="true"
            [searchField]="null"
            [pageSize]="5">
            <pf-button
                [disabled]="isCreateDisabled"
                nz-popover
                nzPopoverTrigger="hover"
                [nzPopoverContent]="isCreateDisabled ? popoverTemplate : ''"
                [nzPopoverTitle]="
                    isCreateDisabled ? 'Inactivate Current Link' : ''
                "
                toolbar-right
                buttonType="add"
                (click)="addOneTimeLink()">
                Create Link
            </pf-button>
        </pf-table>
    </pf-content-collapsible>
</pf-content>

<ng-template #popoverTemplate>
    Only one link One-Time Link can be active for a
    {{ recipientEntityType | AppConstant }}.<br />
    Inactivate any active link and try again.
</ng-template>
