import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ChangeOperation,
    EntityChangeTrackingViewModel,
} from '@pf/shared-common';
import {
    computeChangeText,
    computeChangedFields,
} from '@pf/shared/util-platform';

@Component({
    selector: 'platform-change-view',
    templateUrl: './change-view.component.html',
    styleUrls: ['./change-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeViewComponent {
    changeText?: string;
    changedFields?: string;

    @Input() entityName!: string;
    @Input() autoExpand = false;
    private _entityChange!: EntityChangeTrackingViewModel<unknown>;

    @Input() set entityChange(
        changeVm: EntityChangeTrackingViewModel<unknown>
    ) {
        this._entityChange = changeVm;
        if (!changeVm) {
            return;
        }
        this.setChangeText(changeVm.changes || []);
        this.setChangedFields(changeVm.changes || []);
    }

    get entityChange() {
        return this._entityChange;
    }

    private setChangeText(changes: ChangeOperation[]) {
        const changeText = computeChangeText(changes);
        this.changeText = changeText;
    }

    private setChangedFields(changes: ChangeOperation[]) {
        const changedFields = computeChangedFields(changes);
        this.changedFields = changedFields;
    }
}
