import {
    CarrierCreate,
    CarrierDto,
    CarrierRead,
    CarrierSearchParams,
} from '../../entities/carrier.dto';

import { AbstractEntityMapper } from '@pf/shared-services';
import { CarrierMetadataMap } from './carrier.mapping-profile';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';

@Injectable()
export class CarrierMapper extends AbstractEntityMapper<
    CarrierRead,
    CarrierCreate,
    CarrierDto,
    CarrierSearchParams
> {
    constructor() {
        super(CarrierDto, PlatformCoreMapper, CarrierMetadataMap.symbols);
    }
}
