import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LayoutService implements OnDestroy {
    private _isCollapsedSubject = new BehaviorSubject<boolean>(false);
    private _onScroll = new Subject<Event>();
    onScroll$ = this._onScroll.asObservable();

    private _scrollContainer!: HTMLElement;
    private _scrollSubscription?: Subscription;
    set scrollContainer(container: HTMLElement) {
        this._scrollContainer = container;
        this.listenForScrollEvents();
    }
    get scrollContainer() {
        return this._scrollContainer;
    }

    get isCollapsed() {
        return this._isCollapsedSubject.value;
    }
    set isCollapsed(value) {
        this._isCollapsedSubject.next(value);
    }
    isCollapsed$ = this._isCollapsedSubject.asObservable();

    ngOnDestroy(): void {
        this._scrollSubscription?.unsubscribe();
    }

    private listenForScrollEvents() {
        this._scrollSubscription?.unsubscribe();
        this._scrollSubscription = fromEvent(
            this.scrollContainer,
            'scroll'
        ).subscribe(event => this._onScroll.next(event));
    }
}
