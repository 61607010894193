import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    signal,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    ColumnSearchType,
    FrameworkColumn,
    getTableRowValue,
    IPagedResult,
    IQueryParams,
    ISearchFacade,
    ISearchFields,
    NotificationType,
    QueryFilters,
    SafeAny,
    SearchStrategy,
    SortOrder,
    TableAction,
    TableColumn,
    TableOptions,
    TableQueryParams,
    TableRow,
    TableValueType,
    ToastMessage,
    ToastType,
} from '@pf/shared-common';
import { NzTableComponent, NzTableQueryParams } from 'ng-zorro-antd/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, combineLatest, of, switchMap, tap } from 'rxjs';

import { NotificationsService } from '../../notifications';
import { TableService } from '../services/table.service';
import { ValueFormatterService } from '@pf/shared-services';

export interface TableState {
    pageIndex: number;
    total: number;
    rows: SafeAny[];
    columns: FrameworkColumn[];
}

function DefaultState(): TableState {
    return {
        rows: [],
        columns: [],
        pageIndex: 1,
        total: 0,
    };
}

const defaultTypeWidths: Partial<Record<TableValueType, string>> = {
    date: '140px',
    time: '140px',
    datetime: '200px',
};

const DefaultSortFn =
    (column: TableColumn<SafeAny>) =>
    (
        a: { data: SafeAny; index: number },
        b: { data: SafeAny; index: number }
    ) => {
        const aValue = getTableRowValue(column, a.data);
        const bValue = getTableRowValue(column, b.data);
        if (aValue === bValue) {
            return 0;
        }
        if (!aValue) {
            return -1;
        }
        if (!bValue) {
            return 1;
        }
        if (typeof aValue === 'string' || typeof bValue === 'string') {
            return (aValue as string).localeCompare(bValue as string);
        }
        return aValue - bValue;
    };

@UntilDestroy()
@Component({
    selector: 'pf-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [TableService],
})
export class TableComponent<T> implements OnInit {
    private _tableData: T[] = [];
    private _columns!: TableColumn<T>[];

    @ViewChild('table', { static: true }) table!: NzTableComponent<T>;
    @Input() searchFacade: ISearchFacade<T> | null = null;
    @Input() pageSize = 10;

    @Input() set data(data: IPagedResult<T> | T[] | undefined) {
        if (!data) {
            return;
        }
        this.setupTableData(data);
    }

    @Input() loading = false;

    @Input() set columns(columns: TableColumn<T>[]) {
        this._columns = columns;
        this.setColumns(columns);
    }

    get columns() {
        return this._columns;
    }

    @Input() options: Partial<TableOptions<T>> | null = {};
    @Input() actions: TableAction<T>[] = [];
    @Input() title = '';
    @Input() searchField: ISearchFields<T> | null = [];
    @Input() searchStrategy: SearchStrategy | undefined = undefined;
    @Input() disableAutoPageSize = false;
    @Input() width?: string;
    @Input() expandable = false;
    @Input() singleExpand = false;
    @Input() expandToContent = false;
    @Input() expandRowRef?: TemplateRef<SafeAny>;
    @Input() estimatedRowHeight = 48;

    @Output() queryParamsChanged = this.tableService.queryParamsChanged$;
    @Input() showExport = true;
    @Output() dataChanged = new EventEmitter<TableRow<T>[]>();
    size: 'middle' | 'small' | 'default' = 'default';
    exporting = false;

    get scrollSettings() {
        return {
            x: this.width || '100%',
        };
    }

    get serverRendered() {
        return !!this.searchFacade?.search$;
    }

    get isFiltered() {
        return (
            !!this.tableService.queryParams.searchValue ||
            Object.values(this.tableService.queryParams.filters || {}).some(
                filter => !!filter
            )
        );
    }

    get showClearFilters(): boolean {
        return (
            !!this.searchField ||
            this.columns?.some(column => {
                return column.showCheckbox || column.search;
            })
        );
    }

    get queryParams(): TableQueryParams {
        return this.tableService.queryParams;
    }

    @HostBinding('class.has-pagination')
    get showPagination(): boolean {
        if (!this.tableState?.rows?.length) {
            return false;
        }
        return this.tableState?.total > this.pageSize;
    }

    tableState: TableState;

    constructor(
        private tableService: TableService,
        private valueFormatterService: ValueFormatterService,
        private notificationsService: NotificationsService
    ) {
        this.tableState = DefaultState();
    }

    ngOnInit(): void {
        this.setupQueryParamsNotifier();
        this.setupTableSizing();

        this.reset();
        this.setupClearSelectedListFilters();
    }

    headerTrackBy(_: number, column: FrameworkColumn) {
        return `${column.field}_${column.sortOrder}`;
    }

    onQueryParamsChange(params: NzTableQueryParams): void {
        const { pageSize, sort } = params;
        let pageIndex = params.pageIndex;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder =
            ((currentSort && currentSort.value) as SortOrder) || null;
        if (
            sortField !== this.tableService.queryParams?.sortField ||
            sortOrder !== this.tableService.queryParams?.sortOrder
        ) {
            pageIndex = 1;
        }

        this.tableService.updateTableQueryParams({
            pageSize,
            pageIndex,
            sortField,
            sortOrder,
            serverRendered: this.serverRendered,
        });
    }

    onTableHeaderChecked(column: FrameworkColumn, checked: boolean) {
        const checkboxes: Record<string, boolean> = {
            ...(this.tableService.queryParams?.checkboxes || {}),
        };
        checkboxes[column.field] = checked;

        this.tableService.updateTableQueryParams({
            pageIndex: 1,
            checkboxes,
        } as TableQueryParams);
    }

    reset() {
        this.tableState = {
            ...this.tableState,
            columns: (this.tableState?.columns || []).map(column => {
                return {
                    ...column,
                    sortOrder: null,
                    searchValue: '',
                    checked: false,
                };
            }),
        } as TableState;
        this.tableService.reset({ pageSize: this.pageSize });
    }

    onSearchChanged(searchQuery: string) {
        if (!this.searchField) {
            return;
        }

        const filters: Record<string, string | string[]> = {
            ...(this.tableService.queryParams?.filters || {}),
        };

        filters[this.searchField.toString()] = searchQuery;
        this.tableService.updateTableQueryParams({
            pageIndex: 1,
            filters,
            searchStrategy: this.searchStrategy,
            searchValue: searchQuery,
            searchFields: this.searchField as string[],
        });
    }

    onColumnSearchChanged(column: FrameworkColumn) {
        if (column.serverSearch) {
            this.tableService.updateFilters({
                [column.key || column.field]: column.searchValue,
            });
        } else {
            this.filterData();
        }
    }

    exportHandler(rawExport = false) {
        this.exporting = true;
        const loadingMessageRef = this.notificationsService.create({
            type: NotificationType.ToastMessage,
            toastType: ToastType.loading,
            message: 'Gathering data. Download will begin shortly.',
        } as ToastMessage);
        this.searchFacade
            ?.export$({
                rawExport,
                columns: this.columns,
                fileName: `${this.title}.csv`,
                params: this.tableService.queryParams as IQueryParams<T>,
            })
            .subscribe(result => {
                this.notificationsService.remove(loadingMessageRef);
                this.notificationsService.create({
                    type: NotificationType.ToastMessage,
                    toastType:
                        result === true ? ToastType.success : ToastType.error,
                    message:
                        result === true
                            ? 'Download complete.'
                            : result === false
                            ? 'Export failed!'
                            : result,
                } as ToastMessage);
                this.exporting = false;
            });
    }

    filterChanged(column: FrameworkColumn, filter: string[]) {
        this.tableService.updateFilters({
            [column.key || column.field]: filter,
        });
    }

    listFilters = signal<Record<string, ColumnSearchType[]>>({});

    private setupListFilters(columns: TableColumn<T>[]) {
        const listFilterFacades = columns
            .filter(c => c.listFilterFacade)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(c => c.listFilterFacade!);
        const listFilterFacadeNames = listFilterFacades.map(
            f => f.columnListFilterName
        );
        listFilterFacadeNames.forEach(name => {
            this.listFilters.mutate(state => (state[name] = []));
        });
        combineLatest(
            listFilterFacades.map(f => f.getColumnListFilters$())
        ).subscribe(listFilters => {
            listFilters.forEach((filters, index) => {
                this.listFilters.mutate(state => {
                    state[listFilterFacadeNames[index]] = filters;
                });
            });
            this.setupFrameworkColumns(columns);
        });
    }

    private setupClearSelectedListFilters() {
        this.tableService.reset$.subscribe(() => {
            this.clearSelectedListFilters();
        });
    }

    private clearSelectedListFilters() {
        this.listFilters.mutate(state => {
            Object.keys(state).forEach(key => {
                const col = this.columns.find(
                    c => c.listFilterFacade?.columnListFilterName === key
                );
                if (col) {
                    col.listFilterFacade
                        ?.getColumnListFilters$()
                        .subscribe(filters => {
                            this.listFilters.mutate(
                                state =>
                                    (state[
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        col.listFilterFacade!.columnListFilterName
                                    ] = filters)
                            );
                        });
                }
            });
        });
    }

    private setupFrameworkColumns(columns: TableColumn<T>[]) {
        const frameworkColumns: FrameworkColumn[] = columns.map(col => {
            return {
                field: col.field as string,
                headerName: col.headerName,
                sortOrder: col.sortOrder || null,
                sortDirections: col.showSort
                    ? ['ascend', 'descend', null]
                    : [null],
                sortFn: this.serverRendered || DefaultSortFn(col),
                search: !!col.search && !col.component,
                searchValue: '',
                listFilterFacade: col.listFilterFacade,
                key: col.key,
                serverSearch: !!col.serverSearch,
                showCheckbox: !!col.showCheckbox,
                checkboxLabel: col.checkboxLabel || '',
                checkedChange: col.checkedChange,
                type: col.type || 'string',
                width: this.calcColumnWidth(col.width, col.type),
                valueFormatter:
                    col.valueFormatter ||
                    this.valueFormatterService.getFormatter(col.type),
                component: col.component,
                componentProps: col.componentProps,
            } as FrameworkColumn;
        });
        this.tableState = {
            ...this.tableState,
            columns: frameworkColumns,
        };
    }

    private setColumns(columns: TableColumn<T>[]) {
        if (columns.some(c => c.listFilterFacade)) {
            this.setupListFilters(columns);
        } else {
            this.setupFrameworkColumns(columns);
        }
    }

    calcColumnWidth(width?: number, type?: TableValueType): string | null {
        if (!width && !type) {
            return null;
        }
        if (width) {
            return width + 'px';
        }
        return defaultTypeWidths[type as TableValueType] || null;
    }

    private setupTableData(data: IPagedResult<T> | T[]) {
        if (!data) {
            this.setData([]);
            return;
        }
        if (this.serverRendered) {
            const tableData = data as IPagedResult<T>;
            this.setData(tableData.data);
            this.tableState = {
                ...this.tableState,
                pageIndex: tableData.currentPage,
                total: tableData.totalRecords,
            };
        } else {
            const tableData = data as T[];
            this.setData(tableData);
            this.tableState = {
                ...this.tableState,
                pageIndex: 1,
                total: tableData.length,
            };
        }
    }

    @HostListener('window:resize', ['$event'])
    private setupTableSizing() {
        this.setTableSize();
        this.setPageSize();
    }

    private setTableSize() {
        if (window.innerWidth < 1200) {
            this.size = 'small';
        }
    }

    private setPageSize() {
        if (this.disableAutoPageSize) {
            return;
        }
        this.pageSize = Math.max(
            Math.floor((window.innerHeight - 100) / this.estimatedRowHeight) -
                1,
            3
        );
    }

    private setupQueryParamsNotifier() {
        this.tableService.queryParamsChanged$
            .pipe(
                untilDestroyed(this),
                tap(() => (this.loading = true)),
                switchMap(params => {
                    let searchParams = params as TableQueryParams<T>;
                    if (this.options?.additionalQueryParams) {
                        const { filters, ...other } =
                            this.options.additionalQueryParams(searchParams);
                        searchParams = {
                            ...searchParams,
                            ...other,
                            filters: {
                                ...(searchParams.filters || {}),
                                ...filters,
                            } as QueryFilters<T>,
                        };
                    }
                    return (this.searchFacade as ISearchFacade<T>).search$(
                        searchParams
                    );
                }),
                tap(() => (this.loading = false)),
                catchError(error => {
                    console.log(error);
                    // api error handled by interceptor
                    return of([]);
                })
            )
            .subscribe();
        this.searchFacade?.currentPage$
            .pipe(untilDestroyed(this))
            .subscribe(result => (this.data = result));
    }

    private setData(data: T[]) {
        this._tableData = data;
        this.filterData();
        this.dataChanged.emit(this.tableState.rows);
    }

    private filterData() {
        const searchColumns = this.tableState.columns.filter(
            c => c.searchValue && !c.serverSearch
        );
        this.tableState = {
            ...this.tableState,
            rows: (this._tableData || []).filter((row: SafeAny) => {
                return searchColumns.every(column => {
                    const columnValue = getTableRowValue(column, row);
                    const formattedValue = column.valueFormatter
                        ? column.valueFormatter(columnValue, row)
                        : columnValue;
                    return (
                        formattedValue &&
                        formattedValue
                            .toLowerCase()
                            .indexOf(column.searchValue.toLowerCase()) > -1
                    );
                });
            }),
        };
    }
}
