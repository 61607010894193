import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUtilPlatformModule } from '@pf/shared/util-platform';
import { LoadDataService } from './infrastructure/loads/load.data.service';
import { LoadFacade } from './application/loads/load.facade';
import { LoadStore } from './infrastructure/loads/load.store';
import { LoadMapper } from './infrastructure/loads/load.mapper';
import { LoadStatusTypeFacade } from './application/type-entities/LoadStatusTypeFacade';
import { StopDataService } from './infrastructure/stops/stop.data.service';
import { StopFacade } from './application/stops/stop.facade';
import { StopMapper } from './infrastructure/stops/stop.mapper';
import { StopStore } from './infrastructure/stops/stop.store';
import { StopTypeFacade } from './application/type-entities/StopTypeFacade';
import { ChargeTypeFacade } from './application/type-entities/ChargeTypeFacade';
import { ReferenceTypeFacade } from './application/type-entities/ReferenceTypeFacade';
import { SafeAny, sharedEnv } from '@pf/shared-common';
import {
    ApiModule as LoadsApiModule,
    Configuration as LoadsApiConfig,
    ConfigurationParameters,
} from '@control-tower/platform-loads';

import { StopItemFacade } from './application/stop-item.facade';
import { StopItemDataService } from './infrastructure/stop-items/stop-item.data.service';
import { StopItemMapper } from './infrastructure/stop-items/stop-item.mapper';
import { StopItemStore } from './infrastructure/stop-items/stop-item.store';
import { RoutesForStopItems } from './application/stop-item.routes';
import { LoadFacadeConfig } from './application/loads/load-facade.config';
import { LoadManageFacadeConfig } from './application/loads/load-manage-facade.config';
import { StopFacadeConfig } from './application/stops/stop-facade.config';
import {
    LoadDocumentsFacade,
    LoadDocumentsFacadeConfig,
} from './application/loads/load-documents.facade';

export type LoadProviderConfig = {
    loadFacadeConfig: Type<LoadFacadeConfig>;
    loadManageFacadeConfig?: Type<LoadManageFacadeConfig>;
    loadDocumentsFacadeConfig?: Type<LoadDocumentsFacadeConfig>;
    stopFacadeConfig?: Type<StopFacadeConfig>;
};

const loadsApiConfigFactory = (basePath: string) => {
    return (): LoadsApiConfig => {
        const params: ConfigurationParameters = {
            basePath,
        };
        return new LoadsApiConfig(params);
    };
};

@NgModule({
    imports: [CommonModule, SharedUtilPlatformModule],
    providers: [
        LoadDataService,
        LoadFacade,
        LoadDocumentsFacade,
        LoadStore,
        LoadMapper,
        LoadStatusTypeFacade,
        StopDataService,
        StopFacade,
        StopStore,
        StopMapper,
        StopItemDataService,
        StopItemFacade,
        StopItemStore,
        StopItemMapper,
        RoutesForStopItems,
        StopTypeFacade,
        ChargeTypeFacade,
        ReferenceTypeFacade,
    ],
})
export class PlatformLoadsDomainModule {
    static ApiModule() {
        return LoadsApiModule.forRoot(
            loadsApiConfigFactory(sharedEnv.baseApiUrl + '/loads')
        );
    }

    static ConfigProviders(providers: LoadProviderConfig) {
        const configuredProviders: SafeAny[] = [
            {
                provide: LoadFacadeConfig,
                useClass: providers.loadFacadeConfig,
            },
            {
                provide: LoadDocumentsFacadeConfig,
                useClass: providers.loadDocumentsFacadeConfig,
            },
        ];
        if (providers.loadManageFacadeConfig) {
            configuredProviders.push({
                provide: LoadManageFacadeConfig,
                useClass: providers.loadManageFacadeConfig,
            });
        }
        if (providers.stopFacadeConfig) {
            configuredProviders.push({
                provide: StopFacadeConfig,
                useClass: providers.stopFacadeConfig,
            });
        }
        return configuredProviders;
    }
}
