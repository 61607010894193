import {
    PFCoreEntities,
    PFItemCatalogEntities,
    PFLoadEntities,
    PFTransportationEntities,
    SafeAny,
} from '@pf/shared-common';
import { Route } from '@angular/router';
import { featureRoutes } from './feature-routes';
import { FailedLoginComponent } from '../pages/failed-login.component';
import { LoginCallbackComponent } from '../pages/login-callback.component';
import { NotFoundComponent } from '../pages/not-found.component';
import { PathResolver } from '../resolvers/path.resolver';
import { ErrorComponent } from '../pages/error.component';
import { PublicAppComponent } from '../components/public-app.component';
import {
    AuthGuard,
    OneTimeLinkCompletedComponent,
    OneTimeLinkErrorComponent,
    OneTimeLoginComponent,
} from '@pf/shared/ui-platform';

function featureRoutesFromConfig(
    config: Partial<Record<keyof typeof featureRoutes, boolean | undefined>>
): Route[] {
    return Object.keys(config).reduce((acc, feature) => {
        if ((config as SafeAny)[feature] === true) {
            acc.push((featureRoutes as SafeAny)[feature].route() as Route);
        }
        return acc;
    }, [] as Route[]);
}

export const defaultRouteTemplate = (
    defaultPath: string,
    appRoutes: Route[]
): Route[] => [
    {
        path: '',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: defaultPath,
                pathMatch: 'full',
            },
            ...appRoutes,
        ],
    },
    {
        path: 'public',
        component: PublicAppComponent,
        children: [
            {
                path: 'login-failed',
                component: FailedLoginComponent,
            },
            {
                path: 'login-callback',
                component: LoginCallbackComponent,
            },
            {
                path: 'one-time-link/complete',
                component: OneTimeLinkCompletedComponent,
            },
            {
                path: 'one-time-link/error',
                component: OneTimeLinkErrorComponent,
            },
            {
                path: 'one-time-login/:id',
                component: OneTimeLoginComponent,
                data: {
                    returnUrl: '/',
                },
            },
        ],
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: '**',
        resolve: {
            path: PathResolver,
        },
        component: NotFoundComponent,
    },
];

export const defaultLoadRoutes = (
    path = 'loads',
    defaultPath = 'loads'
): Route => ({
    path,
    data: {
        breadcrumb: 'Loads',
    },
    children: [
        {
            path: '',
            redirectTo: `${path}/${defaultPath}`,
            pathMatch: 'full',
        },
        featureRoutes[PFLoadEntities.Load].route(),
    ],
});

export const defaultManageRoutes = (
    features: {
        [PFCoreEntities.Contact]?: boolean;
        [PFCoreEntities.Carrier]?: boolean;
        [PFCoreEntities.Vendor]?: boolean;
        [PFCoreEntities.Customer]?: boolean;
        [PFCoreEntities.Location]?: boolean;
        [PFCoreEntities.User]?: boolean;
        [PFCoreEntities.UserRole]?: boolean;
        [PFCoreEntities.FiscalPeriod]?: boolean;
        [PFItemCatalogEntities.Item]?: boolean;
    } = {
        [PFCoreEntities.Contact]: true,
        [PFCoreEntities.Carrier]: true,
        [PFCoreEntities.Vendor]: true,
        [PFCoreEntities.Customer]: true,
        [PFCoreEntities.Location]: true,
        [PFCoreEntities.User]: true,
        [PFCoreEntities.UserRole]: true,
        [PFCoreEntities.FiscalPeriod]: true,
        [PFItemCatalogEntities.Item]: true,
    },
    path = 'manage',
    defaultPath = 'vendors',
    additionalRoutes: Route[] = []
): Route => {
    const manageRoutes = featureRoutesFromConfig(features);

    return {
        path,
        data: {
            breadcrumb: 'Manage',
        },
        children: [
            {
                path: '',
                redirectTo: `${path}/${defaultPath}`,
                pathMatch: 'full',
            },
            ...manageRoutes,
            ...additionalRoutes,
        ],
    };
};

export const defaultTransportationRoutes = (
    path = 'transportation',
    defaultPath = 'drivers'
): Route => ({
    path,
    data: {
        breadcrumb: 'Transportation',
    },
    children: [
        {
            path: '',
            redirectTo: `${path}/${defaultPath}`,
            pathMatch: 'full',
        },
        featureRoutes[PFTransportationEntities.Driver].route(),
        featureRoutes[PFTransportationEntities.Vehicle].route(),
        featureRoutes[PFTransportationEntities.Trailer].route(),
        featureRoutes[PFTransportationEntities.Equipment].route(),
    ],
});
