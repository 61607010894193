import {
    ContactMetadataMap,
    CustomFieldMetadataMap,
    DefaultCustomerFragmentMetadataMap,
    DefaultLocationFragmentMetadataMap,
    DefaultVendorFragmentMetadataMap,
    ICustomerFragmentDto,
    ICustomFieldDto,
    ILocationFragmentDto,
    ITypeEntityFragmentSymbol,
    IVendorFragmentDto,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    IEntityFragment,
    IEntityFragmentDto,
    ITypeEntityFragment,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    LoadStopSequenceViewModel,
    LoadUnifiedStopViewModel,
    StopStatus,
    StopUnifiedViewModelRead,
} from '@control-tower/platform-loads';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultFragmentMetadata,
    withMetadata,
} from '@pf/shared-utility';
import { StopItemDto } from './stop-item.dto';
import { StopItemMetadataMap } from '../infrastructure/stop-items/stop-item.mapping-profile';
import { StopChargeDto, StopChargeMetadataMap } from './stop-charge.dto';
import { EventMetadataMap } from './event.dto';
import { ReferenceMetadataMap } from './reference.dto';
import { ItemDto } from '@pf/platform-item-catalog/domain';
import { NoteMetadataMap } from './notes.dto';
import { StopStatusViewModel } from '@control-tower/platform-loads/model/stopStatusViewModel';

export interface IStopUnifiedFragmentDto extends IEntityFragmentDto {
    stopType: ITypeEntityFragment;
    location: ILocationFragmentDto;
    customer?: ICustomerFragmentDto;
    vendor?: IVendorFragmentDto;
    arrival: string;
    departure: string;
    stopStatus: StopStatus;
    expectedDeparture: string;
    expectedArrival: string;
    sequence: number;
    uniqueItemCount: number;
    totalItemCount: number;
    totalItemValue: number;
    customFields: ICustomFieldDto[];
    stopItems: Array<StopItemDto & { item: ItemDto }>;
    charges: StopChargeDto[];
}

export type StopUnifiedFragmentRead = StopUnifiedViewModelRead &
    IEntityFragment;
export type StopUnifiedFragmentCreate = LoadStopSequenceViewModel;

const stopFragmentSymbols = entitySymbols(
    `${PFLoadEntities.Load}-${PFLoadEntities.Stop}-Unified`
);
const stopStatusSymbol = 'StopStatus';

export const StopUnifiedMetadataMap = {
    symbols: stopFragmentSymbols,
    profile: (mapper: Mapper) => {
        withDefaultEntityMetadata<IStopUnifiedFragmentDto>(
            stopFragmentSymbols.dto,
            {
                stopType: ITypeEntityFragmentSymbol,
                location: DefaultLocationFragmentMetadataMap.symbols.dto,
                customer: DefaultCustomerFragmentMetadataMap.symbols.dto,
                vendor: DefaultVendorFragmentMetadataMap.symbols.dto,
                arrival: String,
                departure: String,
                stopStatus: stopStatusSymbol,
                expectedDeparture: String,
                expectedArrival: String,
                sequence: Number,
                uniqueItemCount: Number,
                totalItemCount: Number,
                totalItemValue: Number,
                customFields: [CustomFieldMetadataMap.symbols.dto],
                stopItems: [StopItemMetadataMap.symbols.dto],
                charges: [StopChargeMetadataMap.symbols.dto],
            }
        );
        withDefaultFragmentMetadata<StopUnifiedFragmentRead>(
            stopFragmentSymbols.entity,
            {
                stopType: ITypeEntityFragmentSymbol,
                location: DefaultLocationFragmentMetadataMap.symbols.entity,
                vendor: DefaultVendorFragmentMetadataMap.symbols.entity,
                customer: DefaultCustomerFragmentMetadataMap.symbols.entity,
                arrival: String,
                departure: String,
                stopStatus: stopStatusSymbol,
                expectedDeparture: String,
                expectedArrival: String,
                sequence: Number,
                customFields: [CustomFieldMetadataMap.symbols.entity],
                stopItems: [StopItemMetadataMap.symbols.entity],
                charges: [StopChargeMetadataMap.symbols.entity],
                contacts: [ContactMetadataMap.symbols.entity],
                events: [EventMetadataMap.symbols.entity],
                references: [ReferenceMetadataMap.symbols.entity],
                resourceAuth: ResourceAuthSymbol,
                specialInstructions: String,
            }
        );
        withMetadata<LoadUnifiedStopViewModel>(stopFragmentSymbols.createBody, {
            id: String,
            notes: [NoteMetadataMap.symbols.createBody],
            stopTypeId: String,
            locationId: String,
            vendorId: String,
            customerId: String,
            arrival: String,
            departure: String,
            stopStatus: stopStatusSymbol,
            expectedDeparture: String,
            expectedArrival: String,
            sequence: Number,
            customFields: [CustomFieldMetadataMap.symbols.createBody],
            stopItems: [StopItemMetadataMap.symbols.createBody],
            charges: [StopChargeMetadataMap.symbols.createBody],
            contacts: [ContactMetadataMap.symbols.createBody],
            events: [EventMetadataMap.symbols.createBody],
            references: [ReferenceMetadataMap.symbols.createBody],
            resourceAuth: ResourceAuthSymbol,
            specialInstructions: String,
        });

        createMap<StopUnifiedFragmentRead, IStopUnifiedFragmentDto>(
            mapper,
            stopFragmentSymbols.entity,
            stopFragmentSymbols.dto
        );
        createMap<IStopUnifiedFragmentDto, StopUnifiedFragmentRead>(
            mapper,
            stopFragmentSymbols.dto,
            stopFragmentSymbols.entity
        );
        createMap<IStopUnifiedFragmentDto, LoadUnifiedStopViewModel>(
            mapper,
            stopFragmentSymbols.dto,
            stopFragmentSymbols.createBody,
            forMember(
                x => x.stopStatus,
                mapFrom(
                    source =>
                        ({
                            stopStatusTypeId:
                                source.stopStatus?.stopStatusType?.id,
                        } as StopStatusViewModel)
                )
            )
        );

        DefaultLocationFragmentMetadataMap.profile(mapper);
        DefaultCustomerFragmentMetadataMap.profile(mapper);
        DefaultVendorFragmentMetadataMap.profile(mapper);
    },
};
