import { Component, Input } from '@angular/core';
import {
    FrameworkColumn,
    ITableCellValueRenderer,
    SafeAny,
} from '@pf/shared-common';

@Component({
    selector: 'platform-barcode-cell-renderer',
    template: `
        <div
            id="barcode-cell-renderer-container"
            nz-popover
            [nzPopoverTitle]="value"
            [nzPopoverContent]="contentTemplate">
            <pf-text type="primary" *ngIf="value"
                >{{ value }} <pf-icon iconType="barcode"></pf-icon
            ></pf-text>
            <ng-template #contentTemplate>
                <platform-barcode
                    [barcodeValue]="value"
                    [displayValue]="true"></platform-barcode>
            </ng-template>
        </div>
    `,
    styles: [
        `
            #barcode-cell-renderer-container {
                width: 100%;
            }
        `,
    ],
})
export class BarcodeCellRendererComponent implements ITableCellValueRenderer {
    @Input() value!: SafeAny;
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;
}
