import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ButtonType,
    IPagedResult,
    ISearchFacade,
    ITreeSearchFacade,
    SafeAny,
    SelectOption,
    TreeSelectOption,
} from '@pf/shared-common';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import {
    BehaviorSubject,
    combineLatest,
    debounce,
    distinctUntilChanged,
    filter,
    isObservable,
    map,
    Observable,
    of,
    Subject,
    Subscription,
    switchMap,
    tap,
    timer,
} from 'rxjs';
import { BaseControlComponent } from '../base-control/base-control.component';
import {
    NzFormatEmitEvent,
    NzTreeNode,
    NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select';
import { asArraySafe } from '@pf/shared-utility';

export interface SelectionDropdownAction {
    label: string;
    input?: boolean;
    buttonType: ButtonType;
    action: (input?: string) => void;
}

export interface SelectOptionGroup {
    name: string;
    options: SelectOption[];
}

function groupData(flatData: SelectOption[]): SelectOptionGroup[] {
    return Array.from(new Set(flatData.map(x => x.group)))
        .map(
            x =>
                ({
                    name: x,
                    options: flatData.filter(y => y.group === x),
                } as SelectOptionGroup)
        )
        .sort((a, b) => (!a.name ? 1 : a.name.localeCompare(b.name)));
}

@UntilDestroy()
@Component({
    selector: 'pf-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends BaseControlComponent implements OnInit {
    private readonly _search = new Subject<string>();
    private _dataSubject = new BehaviorSubject<
        SelectOption[] | TreeSelectOption[]
    >([]);
    private _selectedOption?: SelectOption | null = null;
    private _selectedOptions?: SelectOption[] | null = null;

    flatData$ = this._dataSubject.asObservable().pipe(
        map(data => (this.tree ? [] : data)),
        map(data => {
            this.ensureSelectedOptionsAreInList(data as SelectOption[]);
            return data;
        }),
        tap(() => this.cdr.markForCheck())
    );

    groupedData$ = this._dataSubject.asObservable().pipe(
        map(data => {
            if (!this.grouped) {
                return [];
            }
            this.ensureSelectedOptionsAreInList(data as SelectOption[]);
            return groupData(data as SelectOption[]);
        }),
        tap(() => this.cdr.markForCheck())
    );

    @ViewChild(NzSelectComponent) nzSelect?: NzSelectComponent;
    @ViewChild(NzTreeSelectComponent) nzTreeSelect!: NzTreeSelectComponent;

    @Input() pfOpen = false;
    @Input() placeholder = '';
    @Input() tree = false;
    @Input() searchFacade?: ISearchFacade<SafeAny> | ITreeSearchFacade<SafeAny>;
    @Input() clearAfterSelect = false;
    @Input() multiple = false;
    @Input() lazyLoad = false;
    @Input() tagAmount = 1;
    @Input() dropdownAction?: SelectionDropdownAction;
    @Input() grouped = false;
    private _dataListenerSubscription?: Subscription;

    @Input() set data(
        data:
            | SelectOption[]
            | TreeSelectOption[]
            | Observable<SelectOption[]>
            | null
    ) {
        if (isObservable(data)) {
            this.listenForData(data);
        } else {
            this._dataSubject.next(data as SelectOption[] | TreeSelectOption[]);
        }
    }

    @Input() set selectedOption(option: SelectOption | null | undefined) {
        this._selectedOption = option;
        this.ensureSelectedOptionsAreInList(this.flatData);
    }

    get selectedOption() {
        return this._selectedOption;
    }

    @Input() set selectedOptions(option: SelectOption[] | null | undefined) {
        this._selectedOptions = option;
        this.ensureSelectedOptionsAreInList(this.flatData);
    }

    get selectedOptions() {
        return this._selectedOptions;
    }

    @Input() expandedKeys: string[] = [];

    @Output() selected = new EventEmitter<SelectOption>();
    @Output() selectedMultiple = new EventEmitter<SelectOption[]>();

    get flatData() {
        return this._dataSubject.getValue() as SelectOption[];
    }

    get treeData() {
        return this._dataSubject.getValue() as TreeSelectOption[];
    }

    get flatSearchFacade() {
        return this.searchFacade as ISearchFacade<SafeAny>;
    }

    get treeSearchFacade() {
        return this.searchFacade as ITreeSearchFacade<SafeAny>;
    }

    get searchable() {
        return !!this.searchFacade || !!this._dataSubject.getValue()?.length;
    }

    /**
     * This property is used by form to show below validation messages regarding the
     * input type.
     */
    autoTips = {
        en: {
            required: 'Required',
        },
    };

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        private cdr: ChangeDetectorRef
    ) {
        super(injector, elementRef);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.setupSearchNotifier();
        this.determineInitialLoad();
    }

    actionHandler(input: string): void {
        if (this.dropdownAction) {
            this.dropdownAction.action(input);
        }
    }

    private listenForData(data: Observable<SelectOption[]>) {
        this._dataListenerSubscription?.unsubscribe();
        this._dataListenerSubscription = data
            .pipe(tap(d => console.log('obs data for select', d)))
            .subscribe(d => this._dataSubject.next(asArraySafe(d)));
    }

    private determineInitialLoad() {
        if (this.tree && this.treeSearchFacade) {
            this.loadTreeData();
        }
        if (this.searchFacade) {
            if (!this.lazyLoad) {
                this._search.next('');
            } else {
                this.data = this.data || [];
            }
        }
    }

    onOpenChange(open: boolean): void {
        if (open && this.lazyLoad) {
            this._search.next((this.selectedOption?.label as string) || '');
        }
        if (!open) {
            this.loading = false;
        }
    }

    onTreeExpandChange(event: NzFormatEmitEvent): void {
        console.log('onTreeExpandChange', event);
        const node = event.node;
        const key = node?.key;
        if (node && node.getChildren().length === 0 && node.isExpanded && key) {
            this.loadNodeChildren$(key, node).subscribe();
        }
    }

    private loadNodeChildren$(
        key: string,
        node: NzTreeNode,
        loadRecursively = false
    ): Observable<NzTreeNode> {
        if (!this.treeSearchFacade) {
            return of(node);
        }

        const processChildrenOperator = (
            source: Observable<TreeSelectOption[]>
        ) =>
            source.pipe(
                map((children: TreeSelectOption[]) => {
                    if (children.length === 0) {
                        node.isLeaf = true;
                    } else {
                        node.addChildren(children);
                    }
                    return node;
                }),
                switchMap((node: NzTreeNode) => {
                    if (loadRecursively && node.children.length > 0) {
                        return combineLatest(
                            node.children.map(child =>
                                this.loadNodeChildren$(child.key, child, true)
                            )
                        ).pipe(map(() => node));
                    }
                    return of(node);
                })
            );
        return this.treeSearchFacade
            .loadChildren$(key)
            .pipe(
                this.treeSearchFacade.mapToTreeSelectOptions.bind(
                    this.treeSearchFacade
                ),
                processChildrenOperator
            );
    }

    onSearchHandler(value: string): void {
        this._search.next(value);
    }

    onChange(value: string): void {
        if (!value) {
            return;
        }

        if (this.multiple) {
            const selectedOptions = this.flatData?.filter(
                x => x.value === value
            );
            this.selectedOptions = selectedOptions;
            this.selectedMultiple.emit(selectedOptions);
        } else {
            this.selectedOption = this.flatData?.find(x => x.value === value);
            this.selected.emit(this.selectedOption);
        }
        if (this.clearAfterSelect) {
            this.nzSelect?.clearInput();
            this.nzSelect?.writeValue(null);
        }
    }

    onTreeChange(value: string | string[]): void {
        if (!value) {
            if (this.multiple) {
                this.selectedMultiple.emit([]);
            } else {
                this.selected.emit(undefined);
            }
            return;
        }

        if (this.multiple) {
            const selectedOptions = (value as string[]).map(d => {
                const option = this.nzTreeSelect.getTreeNodeByKey(d)
                    ?.origin as TreeSelectOption;
                return {
                    label: option?.title || '',
                    value: option?.value,
                } as SelectOption;
            });
            this.selectedOptions = selectedOptions;
            this.selectedMultiple.emit(selectedOptions);
        } else {
            const option = this.nzTreeSelect.getTreeNodeByKey(value as string)
                ?.origin as TreeSelectOption;
            this.selectedOption = {
                label: option?.title || '',
                value: option?.value,
            };
            this.selected.emit(this.selectedOption);
        }
        if (this.clearAfterSelect) {
            this.nzTreeSelect.writeValue([]);
        }
    }

    private getMappingOperator(
        source: Observable<IPagedResult<SafeAny>>
    ): Observable<TreeSelectOption[] | SelectOption[]> {
        if (!this.searchFacade) {
            throw new Error('The search facade is not defined');
        }
        if (this.tree) {
            return this.treeSearchFacade.mapToTreeSelectOptions(source);
        } else {
            return this.flatSearchFacade?.mapToSelectOptions(source);
        }
    }

    private setupSearchNotifier() {
        if (this.tree) {
            return;
        }
        this._search
            .asObservable()
            .pipe(
                untilDestroyed(this),
                filter(
                    () =>
                        !!this.searchFacade &&
                        (!this.lazyLoad || !!this.nzSelect?.nzOpen)
                ), // Only search if the select is open if lazy loading
                tap(() => (this.loading = true)),
                debounce(() =>
                    timer(this._dataSubject.getValue()?.length ? 300 : 0)
                ),
                distinctUntilChanged(),
                map(value => (value || '').trim()),
                switchMap(searchText => {
                    const searchFacade = this.searchFacade as ISearchFacade;
                    return searchFacade
                        .searchByText$(searchText)
                        .pipe(source => this.getMappingOperator(source));
                })
            )
            .subscribe((data: SelectOption[] | TreeSelectOption[]) => {
                this.ensureSelectedOptionsAreInList(data as SelectOption[]);
                this.data = data.length ? data : [];
                this.cdr.markForCheck();
                this.loading = false;
            });
    }

    private ensureSelectedOptionsAreInList(data: SelectOption[]) {
        if (!data || this.tree) {
            return;
        }

        if (this.multiple && this.selectedOptions) {
            const optionsToAdd = this.selectedOptions.filter(
                x => !data.find(y => y.value === x.value)
            );
            data.unshift(...optionsToAdd);
        } else if (
            !this.multiple &&
            this.selectedOption &&
            !data.find(x => x.value === this.selectedOption?.value)
        ) {
            data.unshift(this.selectedOption);
        }
    }

    private loadTreeData() {
        this.treeSearchFacade
            .loadTopLevel$()
            .pipe(
                this.treeSearchFacade.mapToTreeSelectOptions.bind(
                    this.treeSearchFacade
                ),
                switchMap((options: TreeSelectOption[]) => {
                    if (this.lazyLoad) {
                        return of(options);
                    }
                    return this.loadAllChildrenRecursively(options);
                })
            )
            .subscribe((data: SafeAny[]) => {
                this.data = data;
                this.cdr.markForCheck();
                this.loading = false;
            });
    }

    private loadAllChildrenRecursively(options: TreeSelectOption[]) {
        return combineLatest(
            options.map(option => {
                const node = new NzTreeNode(option as NzTreeNodeOptions);
                return this.loadNodeChildren$(node.key, node, true);
            })
        );
    }
}
