import { Component, Input, TemplateRef } from '@angular/core';
import {
    FrameworkColumn,
    SafeAny,
    TableAction,
    TableOptions,
    TableRow,
} from '@pf/shared-common';

@Component({
    selector: 'pf-table-rows',
    templateUrl: './table-rows.component.html',
    styleUrls: ['./table-rows.component.scss'],
})
export class TableRowsComponent {
    @Input() expandToContent = false;
    @Input() columns: FrameworkColumn[] = [];
    @Input() actions: TableAction<SafeAny>[] = [];
    @Input() expandRowRef?: TemplateRef<{
        columns?: FrameworkColumn[];
        row: TableRow;
        actions?: TableAction<SafeAny>[];
        index?: number;
        expanded: boolean;
    }>;
    private _data: Readonly<SafeAny[]> = [];

    @Input() set data(data: Readonly<SafeAny[]>) {
        this._data = data;
        this.tableRows = this.toTableRows(data);
    }

    @Input() expandable = false;

    @Input() expand = false;
    @Input() singleExpand = false;

    @Input() options: Partial<TableOptions> | null = {};

    tableRows: TableRow[] = [];

    dataTrackBy(_: number, row: TableRow) {
        return row.data?.id ? row.data.id : row.index;
    }

    headerTrackBy(index: number, column: FrameworkColumn) {
        return `${column.field}_${column.sortOrder}_${
            this.tableRows?.[index]?.expanded || false
        }`;
    }

    shouldDisplayExpandIcon(row: TableRow): boolean {
        return !!this.expandRowRef || !!row.children?.length;
    }

    expandHandler(expanded: SafeAny, index: number) {
        if (this.singleExpand && expanded === true) {
            this.setExpandRecursively(this.tableRows, false);
        }
        this.tableRows[index].expanded = expanded;
        // this.tableRows = [...this.tableRows];
    }

    private toTableRows(data: Readonly<SafeAny[]>) {
        const rows = data.map(
            (row, index) =>
                new TableRow(index, row, this.expandable, this.options)
        );
        this.setExpandRecursively(rows, this.expand);
        return rows;
    }

    private setExpandRecursively(rows: TableRow[], expanded: boolean) {
        rows.forEach(row => {
            row.expanded = expanded;
            if (row.children) {
                this.setExpandRecursively(row.children, expanded);
            }
        });
    }
}
