import { Injectable } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    ContactCreate,
    ContactDto,
    ContactRead,
    ContactSearchParams,
} from './contact.dto';
import { ContactMapper } from './contact.mapper';
import { ContactStore } from './contact.store';
import { ContactDataService } from './contact.data.service';
import { BuildChangeTrackingFacadeFactory } from '../facades/ChangeTrackingFacade';
import { RoutesForContact } from './contact.routes';
import { ResourceAuth } from '@control-tower/platform-core';

@Injectable()
export class ContactFacade extends AbstractManageEntityFacade<
    ContactDto,
    ContactRead,
    ContactCreate,
    ContactSearchParams
> {
    nameField: keyof ContactDto = 'fullName';

    constructor(
        dataService: ContactDataService,
        routes: RoutesForContact,
        store: ContactStore,
        mapper: ContactMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: ContactCreate) {
        body.resourceAuth = {
            allAuthorized: true,
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        'firstName'
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof ContactDto, string>> {
        return { firstName: searchText };
    }
}
