import {
    FragmentMappingConfig,
    InteroperableMetadata,
    InteroperableMetadataMap,
} from '../fragment-mapping-config';
import {
    IEntityFragment,
    IEntityFragmentDto,
    IMappingProfile,
    ITypeEntityFragment,
} from '@pf/shared-common';

import { ITypeEntityFragmentSymbol } from '../../type-entity.mapping-profile';
import { PlatformTransportationFragments } from './platform-transportation-fragments.enum';

export interface IVehicleFragment extends IEntityFragment {
    vehicleType?: ITypeEntityFragment | null;
    vehicleNumber?: string | null;
}

export interface IVehicleFragmentDto
    extends IVehicleFragment,
        IEntityFragmentDto {}

export function VehicleFragmentMetadataMap<
    TFragmentDto extends IVehicleFragmentDto,
    TFragment extends IVehicleFragment
>(config: FragmentMappingConfig<TFragmentDto, TFragment>): IMappingProfile {
    const interoperableMetadata = {
        vehicleType: ITypeEntityFragmentSymbol,
        vehicleNumber: String,
    } as InteroperableMetadata<TFragmentDto, TFragment>;
    return InteroperableMetadataMap(
        PlatformTransportationFragments.Vehicle,
        interoperableMetadata,
        config
    );
}

export const DefaultVehicleFragmentMetadataMap = VehicleFragmentMetadataMap<
    IVehicleFragmentDto,
    IVehicleFragment
>({});
