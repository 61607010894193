import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DocumentFormComponent } from './components/document-form.component';
import { DocumentsComponent } from './components/documents.component';
import { NgModule } from '@angular/core';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { SharedUiModule } from '../../shared-ui.module';
import { TableModule } from '../table/table.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzUploadModule,
        NzDrawerModule,
        TableModule,
        SharedUiModule,
    ],
    declarations: [DocumentsComponent, DocumentFormComponent],
    exports: [DocumentsComponent, DocumentFormComponent],
})
export class DocumentsModule {}
