import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemFacade } from './application/item.facade';
import { ItemDataService } from './infrastructure/items/item.data.service';
import { ItemStore } from './infrastructure/items/item.store';
import { ItemMapper } from './infrastructure/items/item.mapper';
import { sharedEnv } from '@pf/shared-common';
import {
    ApiModule,
    Configuration,
    ConfigurationParameters,
} from '@control-tower/platform-item-catalog';
import {
    CategoryFacade,
    READONLY_CATEGORY_NAMES,
} from './application/category.facade';
import { CategoryDataService } from './infrastructure/categories/category.data.service';
import { CategoryStore } from './infrastructure/categories/category.store';
import { CategoryMapper } from './infrastructure/categories/category.mapper';

export type ItemCatalogProviderConfig = {
    readonlyCategoryNames?: string[];
};

const apiConfigFactory = (basePath: string) => {
    return (): Configuration => {
        const params: ConfigurationParameters = {
            basePath,
        };
        return new Configuration(params);
    };
};

@NgModule({
    imports: [CommonModule],
    providers: [
        ItemFacade,
        ItemDataService,
        ItemStore,
        ItemMapper,
        CategoryFacade,
        CategoryDataService,
        CategoryStore,
        CategoryMapper,
    ],
})
export class PlatformItemCatalogDomainModule {
    static ApiModule() {
        return ApiModule.forRoot(
            apiConfigFactory(sharedEnv.baseApiUrl + '/item-catalog')
        );
    }

    static ConfigProviders(config: ItemCatalogProviderConfig) {
        const providers = [];
        if (config.readonlyCategoryNames?.length) {
            providers.push({
                provide: READONLY_CATEGORY_NAMES,
                useValue: config.readonlyCategoryNames,
            });
        }
        return providers;
    }
}
