import { VehicleDataService } from '../infrastructure/vehicles/vehicle.data.service';
import {
    VehicleCreate,
    VehicleDto,
    VehicleRead,
    VehicleSearchParams,
} from '../entities/vehicle.dto';
import { VehicleStore } from '../infrastructure/vehicles/vehicle.store';
import { Injectable } from '@angular/core';
import { RoutesForVehicle } from './vehicle.routes';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { VehicleMapper } from '../infrastructure/vehicles/vehicle.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';

@Injectable()
export class VehicleFacade extends AbstractManageEntityFacade<
    VehicleDto,
    VehicleRead,
    VehicleCreate,
    VehicleSearchParams
> {
    nameField: keyof VehicleDto = 'vehicleNumber';

    constructor(
        dataService: VehicleDataService,
        routes: RoutesForVehicle,
        store: VehicleStore,
        mapper: VehicleMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof VehicleDto, string>> {
        return { vehicleNumber: searchText };
    }
}
