import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
} from '@angular/core';
import {
    IEntity,
    IManageEntityFacade,
    ISearchFacade,
    ISearchFields,
    PermissionTypes,
    PFEntities,
    PlatformModules,
    SearchStrategy,
    TableAction,
    TableColumn,
} from '@pf/shared-common';
import { EntityNotificationService } from '../services/entity-notification.service';

@UntilDestroy()
@Component({
    selector: 'pf-entity-base-manage',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class EntityBaseManageComponent<
    TEntity extends object & IEntity
> implements OnInit
{
    private readonly entityNotifications = inject(EntityNotificationService);

    abstract title: string;
    abstract tableWidth: string;
    abstract searchFields: ISearchFields<TEntity>;
    abstract searchStrategy?: SearchStrategy;
    abstract entityType: string;
    abstract entityName: string;
    abstract entityFacade: IManageEntityFacade<TEntity> &
        ISearchFacade<TEntity>;
    abstract permissionPlatform: PlatformModules;
    abstract permissionEntity: PFEntities;
    abstract permissionType: PermissionTypes;

    columns: TableColumn<TEntity>[] = [];
    actions: TableAction<TEntity>[] = [];

    ngOnInit(): void {
        this.columns = this.setColumns();
        this.actions = this.setActions();
    }

    abstract setColumns(): TableColumn<TEntity>[];

    abstract setActions(): TableAction<TEntity>[];

    add() {
        this.entityFacade.add();
    }

    edit(entity: TEntity | string) {
        this.entityFacade.edit(typeof entity === 'string' ? entity : entity.id);
    }

    delete(entity: TEntity | string) {
        this.entityFacade
            .delete$(typeof entity === 'string' ? entity : entity.id)
            .subscribe();
    }

    restore(entity: TEntity | string) {
        this.entityFacade
            .undoDelete$(typeof entity === 'string' ? entity : entity.id)
            .subscribe();
    }

    protected registerManageEntityNotifications() {
        this.entityNotifications.notifyOnEntityAdded(
            this.entityFacade,
            this.entityType,
            this.entityName,
            untilDestroyed(this)
        );
        this.entityNotifications.notifyOnEntityUpdated(
            this.entityFacade,
            this.entityType,
            this.entityName,
            untilDestroyed(this)
        );
        this.entityNotifications.notifyOnEntityDeleted(
            this.entityFacade,
            this.entityType,
            this.entityName,
            untilDestroyed(this)
        );
        this.entityNotifications.notifyOnEntityRestored(
            this.entityFacade,
            this.entityType,
            this.entityName,
            untilDestroyed(this)
        );
    }
}
