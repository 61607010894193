import {
    CustomFieldViewModel,
    CustomFieldViewModelCreate,
} from '@control-tower/platform-core';

import { ICustomField } from '@pf/shared-common';
import { isArrayEmpty, isValue, normalizeString } from '@pf/shared-utility';

export type CustomFieldRead = CustomFieldViewModel & ICustomField;
export type CustomFieldCreate = CustomFieldViewModelCreate;

export const findCustomField = <T extends ICustomFieldDto>(
    customFields: T[],
    key: string
): T | undefined => {
    if (!isValue(key) || isArrayEmpty(customFields)) {
        return undefined;
    }
    const normalizedKey = normalizeString(key);
    return customFields.find(
        (customField: ICustomFieldDto) =>
            customField.key?.toLowerCase() === normalizedKey
    );
};

export interface ICustomFieldDto extends ICustomField {
    key: string;
}

export class CustomFieldDto implements ICustomFieldDto {
    constructor(data: Partial<ICustomFieldDto>) {
        Object.assign(this, data);
    }

    id!: string;
    key!: string;
    value?: string | null;
}
