import { BehaviorSubject, first } from 'rxjs';
import {
    ComponentRef,
    Injectable,
    NgModuleRef,
    Type,
    ViewContainerRef,
} from '@angular/core';

import { IDynamicComponent } from '../interfaces/IDynamicComponent';
import { SafeAny } from '@pf/shared-common';

export type InjectionHostLocation = 'root' | 'local' | string;

export interface InjectionProps<T> {
    component: Type<T>;
    hostLocation: InjectionHostLocation | ViewContainerRef;
    templates?: boolean;
    ngModuleRef?: NgModuleRef<SafeAny>;
    props?: Partial<{ [K in keyof T]: T[K] }>;
    index?: number;
}

/**
 * Injection service is a helper to append components
 * dynamically to a known location in the DOM, most
 * noteably for dialogs/tooltips appending to body.
 *
 * @export
 * @class InjectionService
 */
@Injectable({
    providedIn: 'root',
})
export class InjectionService {
    private _hostLocations: Record<InjectionHostLocation, ViewContainerRef> =
        {};
    private _initializedSubject = new BehaviorSubject<boolean>(false);

    initialized$ = this._initializedSubject.asObservable();

    /**
     * Sets the default root view container.
     *
     * @param {any} container
     *
     * @memberOf InjectionService
     */
    setViewContainerRef(
        hostLocation: InjectionHostLocation,
        container: ViewContainerRef
    ): void {
        this._hostLocations[hostLocation] = container;
        if (hostLocation === 'root') {
            this._initializedSubject.next(true);
        }
    }

    /**
     * Projects the inputs onto the component
     *
     * @param {ComponentRef<any>} component
     * @param {*} props
     * @returns {ComponentRef<any>}
     *
     * @memberOf InjectionService
     */
    projectComponentInputs<T>(
        component: ComponentRef<T>,
        props: Partial<{ [K in keyof T]: T[K] }> | undefined
    ): ComponentRef<T> {
        const instance = component.instance as T;
        if (props) {
            const propNames = Object.getOwnPropertyNames(props) as Array<
                keyof T
            >;
            for (const prop of propNames) {
                instance[prop] = (props as T)[prop];
            }
        }

        return component;
    }

    /**
     * Appends a component to the root host
     *
     * @template T
     * @param { InjectionProps<T> } options
     * @returns {ComponentRef<T>}
     *
     * @memberOf InjectionService
     */
    appendComponentHost<T>(options: InjectionProps<T>): ComponentRef<T> {
        const viewContainerRef =
            options.hostLocation instanceof ViewContainerRef
                ? options.hostLocation
                : this._hostLocations[options.hostLocation];
        if (!viewContainerRef) {
            throw new Error(
                `Injection host location ${options.hostLocation} has not been registered!`
            );
        }
        return this.appendComponent(viewContainerRef, options);
    }

    getElementsFromComponentHost(
        hostLocation: InjectionHostLocation,
        elementSelector: string
    ) {
        const viewContainerRef = this._hostLocations[hostLocation];
        if (!viewContainerRef) {
            throw new Error(
                `Injection host location ${hostLocation} has not been registered!`
            );
        }
        const elementRefs =
            viewContainerRef.element.nativeElement.parentNode.querySelectorAll(
                elementSelector
            );
        return Array.from(elementRefs);
    }

    insertIntoComponentHost<T>(
        position: 'start' | 'end',
        options: InjectionProps<T>
    ): ComponentRef<T> {
        const componentRef = this.appendComponentHost(options);
        const element = componentRef.location.nativeElement as HTMLElement;
        const prevSibling = element.previousSibling;
        if (!prevSibling) {
            return componentRef;
        }
        if (!prevSibling.hasChildNodes() || position === 'end') {
            prevSibling.appendChild(componentRef.location.nativeElement);
        } else {
            prevSibling.insertBefore(
                componentRef.location.nativeElement,
                prevSibling.firstChild
            );
        }
        return componentRef;
    }

    private appendComponent<T>(
        viewContainerRef: ViewContainerRef,
        options: InjectionProps<T>
    ): ComponentRef<SafeAny> {
        if (options.hostLocation !== 'root') {
            viewContainerRef.clear();
        }
        const componentRef = viewContainerRef.createComponent(
            options.component,
            {
                index: options.index,
                ngModuleRef: options.ngModuleRef,
            }
        );

        // project the options passed to the component instance
        this.projectComponentInputs(componentRef, options.props);
        this.observeDestroy(componentRef);

        return componentRef;
    }

    private observeDestroy(componentRef: ComponentRef<SafeAny>) {
        if (!(componentRef.instance as IDynamicComponent).destroy$) {
            return;
        }
        componentRef.instance.destroy$.pipe(first()).subscribe(() => {
            componentRef.destroy();
        });
    }
}
