import { Address } from '@control-tower/platform-core';
import { IAddressDto } from '@pf/shared-common';
import { v4 as uuidv4 } from 'uuid';

export type AddressRead = Address;
export type AddressCreate = Address;

export class AddressDto implements IAddressDto {
    constructor(data: Partial<IAddressDto>) {
        Object.assign(this, data);
        if (!this.id) {
            this.id = uuidv4();
        }
        if (!this.country) {
            this.country = 'US';
        }
    }

    id!: string;
    name?: string;
    addressLine1!: string;
    addressLine2?: string;
    city!: string;
    country!: string;
    postalCode!: string;
    geoZone!: string;
    coordinatesLatitude?: number;
    coordinatesLongitude?: number;
}
