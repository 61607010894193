import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    ViewChild,
} from '@angular/core';
import { ContactDto, ContactFacade, IContact } from '@pf/shared/util-platform';
import { PFCoreEntities, TableColumn, TableRow } from '@pf/shared-common';
import {
    DrawerFormComponent,
    FormService,
    TableColumnBuilder,
} from '@pf/shared-ui';
import { FormControl } from '@angular/forms';
import { catchError, tap, throwError } from 'rxjs';
import { AssociatedEntitiesFormComponent } from './associated-entities-form.component';

@Component({
    selector: 'platform-associated-contacts-form',
    templateUrl: './associated-contacts-form.component.html',
    styles: [
        `
            :host {
                [data-pf-handle] {
                    max-width: 200px;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedContactsFormComponent {
    private _cdr = inject(ChangeDetectorRef);
    @ViewChild(DrawerFormComponent, { static: false })
    drawerForm!: DrawerFormComponent;
    @ViewChild(AssociatedEntitiesFormComponent, { static: false })
    associatedContactsForm!: AssociatedEntitiesFormComponent<
        IContact,
        IContact
    >;
    entityType = PFCoreEntities.Contact;
    contactColumns: TableColumn<IContact>[] = new TableColumnBuilder<IContact>()
        .withNameColumn('fullName')
        .withColumn({
            headerName: 'Email',
            field: 'email',
        })
        .withColumn({
            headerName: 'Phone',
            field: 'phone',
        })
        .withColumn({
            headerName: 'Type',
            field: ['contactType', 'name'],
        })
        .withColumn({
            headerName: 'Description',
            field: 'description',
        })
        .withColumn({
            headerName: 'Is Default',
            field: 'isDefault',
            type: 'checkbox',
            checkedChange: (contact: TableRow<IContact>, checked: boolean) => {
                if (!checked) {
                    return;
                }
                for (
                    let i = 0;
                    i < this.associatedContactsForm.value.length;
                    i++
                ) {
                    const row = this.associatedContactsForm.value[i];
                    this.associatedContactsForm.updateEntity(
                        {
                            ...row,
                            isDefault: row.id === contact.data.id,
                        },
                        i
                    );
                }
                this._cdr.detectChanges();
            },
        }).columns;
    @Input() loading = false;
    @Input() control!: FormControl;
    newContact: IContact | null = null;
    private _formService = inject(FormService);

    constructor(public contactFacade: ContactFacade) {
        this._formService.registerForm(this.entityType);
    }

    contactFragmentFn(contact: IContact) {
        return {
            ...contact,
            fullName: `${contact.firstName} ${contact.lastName}`,
            contactTypeName: contact.contactType?.name,
        } as IContact;
    }

    addContact() {
        this.newContact = new ContactDto({ isDeleted: false });
        this.drawerForm.open();
    }

    saveContact(contact: IContact) {
        this.contactFacade
            .save$(contact as ContactDto)
            .pipe(
                catchError(err => {
                    this._formService.notifyError(PFCoreEntities.Contact);
                    return throwError(err);
                }),
                tap(contact => {
                    this.associatedContactsForm.addEntity({
                        value: contact.id,
                        label: contact.fullName,
                    });
                    this.drawerForm.close();
                    this.newContact = null;
                })
            )
            .subscribe();
    }
}
