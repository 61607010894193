import {
    PFCoreEntities,
    PFItemCatalogEntities,
    PFLoadEntities,
    PFTransportationEntities,
} from '@pf/shared-common';
import { IAppMenu, IAppSubMenu } from '@pf/shared-ui';
import {
    RootRoutesForLoads,
    RootRoutesForTransportation,
    RoutesForManage,
} from './default-app-routers';
import { featureRoutes } from './feature-routes';

export const defaultTransportationSubMenus = (
    routes: RootRoutesForTransportation
): IAppSubMenu[] => {
    return [
        featureRoutes[PFTransportationEntities.Driver].menu(routes.drivers),
        featureRoutes[PFTransportationEntities.Equipment].menu(
            routes.equipment
        ),
        featureRoutes[PFTransportationEntities.Trailer].menu(routes.trailers),
        featureRoutes[PFTransportationEntities.Vehicle].menu(routes.vehicles),
    ];
};

export const defaultTransportationMenu = (
    subMenus: IAppSubMenu[]
): IAppMenu => {
    return {
        displayName: 'Transportation',
        icon: 'transportation',
        subMenu: subMenus,
    } as IAppMenu;
};

export const defaultLoadSubMenus = (
    routes: RootRoutesForLoads
): IAppSubMenu[] => {
    return [
        featureRoutes[PFLoadEntities.Load].menu(routes.loads),
        featureRoutes[PFLoadEntities.Load].historyMenu(routes.loads),
    ];
};

export const defaultLoadMenu = (subMenus: IAppSubMenu[]): IAppMenu => {
    return {
        displayName: 'Loads',
        icon: 'load',
        subMenu: subMenus,
    };
};

export const defaultManageSubMenus = (
    routes: RoutesForManage
): IAppSubMenu[] => {
    return [
        featureRoutes[PFCoreEntities.Contact].menu(routes.contacts),
        featureRoutes[PFCoreEntities.Customer].menu(routes.customers),
        featureRoutes[PFCoreEntities.Vendor].menu(routes.vendors),
        featureRoutes[PFCoreEntities.Carrier].menu(routes.carriers),
        featureRoutes[PFCoreEntities.Location].menu(routes.locations),
        featureRoutes[PFCoreEntities.User].menu(routes.users),
        featureRoutes[PFCoreEntities.UserRole].menu(routes.roles),
        featureRoutes[PFItemCatalogEntities.Item].menu(routes.itemCatalog),
        featureRoutes[PFCoreEntities.FiscalPeriod].menu(routes.fiscalPeriods),
    ];
};

export const defaultManageMenu = (subMenus: IAppSubMenu[]): IAppMenu => {
    return {
        displayName: 'Manage',
        icon: 'manage',
        subMenu: subMenus,
    };
};
