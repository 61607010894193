import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TenantProvider } from '@pf/shared-common';

@Component({
    selector: 'pf-tenant-selector',
    template: `
        <pf-button
            id="tenant-menu-btn"
            buttonType="actions-more"
            icon-right
            nz-dropdown
            [nzDropdownMenu]="menu">
            <p>
                {{
                    tenantProvider.name$ | async | safeString : 'Select Tenant'
                }}
            </p>
        </pf-button>
        <nz-dropdown-menu #menu nzPlacement="bottomLeft">
            <ul nz-menu class="tenant-menu">
                <li
                    *ngFor="
                        let tenant of tenantProvider.tenants$ | async | keyvalue
                    "
                    (click)="tenantProvider.selectTenant(tenant.key)">
                    {{ tenant.value.name }}
                </li>
            </ul>
        </nz-dropdown-menu>
    `,
    styles: [
        `
            #tenant-menu-btn ::ng-deep button {
                color: white;
            }

            .tenant-menu {
                padding: var(--pf-gap);
            }

            .tenant-menu > li {
                padding: var(--pf-gap) 0;
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantSelectorComponent {
    constructor(public tenantProvider: TenantProvider) {}
}
