import {
    CamelCaseNamingConvention,
    addProfile,
    createMapper,
} from '@automapper/core';
import { IQueryParamsMetadata, IQueryParamsSymbol } from '@pf/shared-common';
import {
    withResourceAuthMetadata,
    withTypeEntityMetadata,
} from '@pf/shared/util-platform';

import { pojos } from '@automapper/pojos';
import { withMetadata } from '@pf/shared-utility';
import { DriverMetadataMap } from './drivers/driver.mapping-profile';
import { VehicleMetadataMap } from './vehicles/vehicle.mapping-profile';
import { TrailerMetadataMap } from './trailers/trailer.mapping-profile';
import { EquipmentMetadataMap } from './equipments/equipment.mapping-profile';

/** Global Metadata */
withMetadata(IQueryParamsSymbol, IQueryParamsMetadata);
withTypeEntityMetadata();
withResourceAuthMetadata();

/** Create mapper */
export const PlatformTransportationMapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

/** Add mapping profiles */
// addProfile(PlatformTransportationMapper, LoadMetadataMap.profile);
addProfile(PlatformTransportationMapper, DriverMetadataMap.profile);
addProfile(PlatformTransportationMapper, VehicleMetadataMap.profile);
addProfile(PlatformTransportationMapper, TrailerMetadataMap.profile);
addProfile(PlatformTransportationMapper, EquipmentMetadataMap.profile);
