import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import {
    EntityChangeTrackingViewModel,
    IChangeTrackingFacade,
} from '@pf/shared-common';

import { IEntityHistoryComponent } from './IEntityHistoryComponent';
import { Observable } from 'rxjs';
import { EntityHistoryDrawerComponent } from './entity-history-drawer.component';
import { calcDrawerHeight } from '@pf/shared-ui';

@Component({
    selector: 'platform-entity-history',
    templateUrl: './entity-history.component.html',
    styleUrls: ['./entity-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityHistoryComponent
    implements IEntityHistoryComponent<unknown>, OnInit
{
    @Input() facade!: IChangeTrackingFacade<unknown>;
    @Input() entityName!: string;
    recentChanges$!: Observable<EntityChangeTrackingViewModel<unknown>[]>;
    showDetailedHistory = false;
    drawerHeight: string;

    get detailedHistoryTitle() {
        return `${this.entityName} Detailed History`;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        @Optional() @Host() historyDrawer: EntityHistoryDrawerComponent
    ) {
        this.drawerHeight = calcDrawerHeight(historyDrawer ? 1 : 0);
    }

    ngOnInit(): void {
        if (!this.facade) {
            throw new Error('Facade must be set on entity history component');
        }
        this.recentChanges$ = this.facade.getRecentChanges$();
    }

    trackByFn(_: number, value: EntityChangeTrackingViewModel<unknown>) {
        return value.id;
    }

    openDetailedHistory() {
        this.showDetailedHistory = true;
    }

    closeDetailedHistory() {
        this.showDetailedHistory = false;
    }

    drawerOnViewInit() {
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);
    }
}
