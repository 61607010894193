import {
    AssociatedAddressMetadataMap,
    ContactMetadataMap,
    CustomFieldMetadataMap,
    IdIsDefaultSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    CarrierCreate,
    CarrierDto,
    CarrierOmniSearchFieldTypes,
    CarrierRead,
    CarrierSearchParams,
} from '../../entities/carrier.dto';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultOmniSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';

const symbols = entitySymbols(PFCoreEntities.Carrier);
withDefaultEntityMetadata<CarrierDto>(symbols.dto, {
    carrierName: String,
    scac: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.dto],
    contacts: [ContactMetadataMap.symbols.dto],
    resourceAuth: ResourceAuthSymbol,
});
withDefaultEntityMetadata<CarrierRead>(symbols.entity, {
    scac: String,
    carrierName: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
    contacts: [ContactMetadataMap.symbols.entity],
    customFields: [CustomFieldMetadataMap.symbols.entity],
});
withMetadata<CarrierCreate>(symbols.createBody, {
    scac: String,
    carrierName: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.createBody],
    resourceAuth: ResourceAuthSymbol,
    contacts: [IdIsDefaultSymbol],
});
withDefaultSearchParamsMetadata<CarrierSearchParams>(symbols.searchParams, {
    carrierName: String,
    scac: String,
});

export const CarrierMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<CarrierRead, CarrierDto>(mapper, symbols.entity, symbols.dto);
        createMap<CarrierDto, CarrierRead>(mapper, symbols.dto, symbols.entity);
        createMap<CarrierDto, CarrierCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<CarrierDto>, CarrierSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('carrierName'),
            withSearchFilter('scac'),
            ...withDefaultOmniSearchParamsMappingConfiguration(
                CarrierSearchFieldsMapper
            )
        );
        ContactMetadataMap.profile(mapper);
        AssociatedAddressMetadataMap.profile(mapper);
        CustomFieldMetadataMap.profile(mapper);
    },
};

export const CarrierSearchFieldsMapper: Partial<
    Record<keyof CarrierDto, CarrierOmniSearchFieldTypes[]>
> = {
    carrierName: ['CarrierName'],
    scac: ['Scac'],
};
