import { IPagedResult } from '@pf/shared-common';
import { Observable, map } from 'rxjs';

export function mapToIPagedResult<T>(
    source: Observable<T[]>
): Observable<IPagedResult<T>> {
    return source.pipe(
        map(entities => {
            return {
                data: entities,
                currentPage: 1,
                currentPageSize: entities.length,
                totalRecords: entities.length,
            } as IPagedResult<T>;
        })
    );
}
