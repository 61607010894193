import { AbstractEntityMapper } from '@pf/shared-services';
import {
    EquipmentCreate,
    EquipmentDto,
    EquipmentRead,
    EquipmentSearchParams,
} from '../../entities/equipment.dto';
import { Injectable } from '@angular/core';
import { PlatformTransportationMapper } from '../platform-transportation.mapper';
import { EquipmentMetadataMap } from './equipment.mapping-profile';

@Injectable()
export class EquipmentMapper extends AbstractEntityMapper<
    EquipmentRead,
    EquipmentCreate,
    EquipmentDto,
    EquipmentSearchParams
> {
    constructor() {
        super(
            EquipmentDto,
            PlatformTransportationMapper,
            EquipmentMetadataMap.symbols
        );
    }
}
