import {
    IdentityService,
    User,
    UsersService,
    UserTenant,
} from '@control-tower/platform-core';
import {
    catchError,
    combineLatest,
    filter,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';
import {
    PlatformAuthService,
    ProfileProvider,
    SafeAny,
    TenantProvider,
    UserProfile,
} from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createStore, select, withProps } from '@ngneat/elf';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { isValue } from '@pf/shared-utility';

const userStore = createStore(
    { name: 'user' },
    withProps<User>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
    })
);

function getProperty$<TReturn>(
    prop: keyof User,
    mapFn: (user: User) => TReturn
) {
    return userStore.pipe(
        filter(p => !!p[prop]),
        select(mapFn)
    );
}

export const APP_TENANT_ID = new InjectionToken<string>('APP_TENANT_ID');

@UntilDestroy()
@Injectable()
export class DefaultProfileProvider extends ProfileProvider {
    private _loaded = false;

    constructor(
        private identityService: IdentityService,
        private tenantProvider: TenantProvider,
        private authService: PlatformAuthService,
        private userService: UsersService,
        @Optional() @Inject(APP_TENANT_ID) private appTenantId: string
    ) {
        super();
        this.setDataDogUser();
    }

    private tenant$ = combineLatest([
        getProperty$('tenants', user => user.tenants || []),
        this.tenantProvider.id$,
    ]).pipe(
        map(([tenants, tenantId]: [UserTenant[], string]) => {
            return tenants.find(tenant => tenant.tenantId === tenantId);
        })
    );
    id$ = getProperty$('id', user => user.id);

    get id(): string | undefined {
        return userStore.getValue().id;
    }

    tenants$ = getProperty$('tenants', user => user.tenants || []);
    fullName$ = getProperty$('firstName', user =>
        (user.firstName + ' ' + user.lastName).trim()
    );
    lastLogin$ = getProperty$(
        'lastLogin',
        user => new Date(user.lastLogin as string)
    );
    email$ = getProperty$('email', user => user.email);
    permissions$ = this.tenant$.pipe(
        map(tenant => tenant?.assignedPermissions || [])
    );
    vendors$ = this.tenant$.pipe(map(tenant => tenant?.vendors || []));
    carriers$ = this.tenant$.pipe(map(tenant => tenant?.carriers || []));
    locations$ = this.tenant$.pipe(map(tenant => tenant?.locations || []));
    customers$ = this.tenant$.pipe(map(tenant => tenant?.customers || []));
    userGroupType$ = this.tenant$.pipe(
        map(tenant => tenant?.userGroupType || 'None')
    );

    get profile() {
        const user = userStore.getValue();
        const tenant = user.tenants?.find(
            tenant => tenant.tenantId === this.tenantProvider.tenant?.id
        );
        return {
            userId: user.id as string,
            fullName: user.firstName + ' ' + user.lastName,
            lastLogin: new Date(user.lastLogin as string),
            email: user.email,
            permissions: tenant?.assignedPermissions || [],
            vendors: tenant?.vendors || [],
            carriers: tenant?.carriers || [],
            locations: tenant?.locations || [],
            customers: tenant?.customers || [],
            userGroupType: tenant?.userGroupType || 'None',
        } as UserProfile;
    }

    hasPermission$(permissionName: string): Observable<boolean> {
        return this.permissions$.pipe(
            map(permissions =>
                permissions.some(p => p.permissionName === permissionName)
            )
        );
    }

    load(): void {
        if (this._loaded) {
            return;
        }
        this._loaded = true;
        this.authService.isAuthenticated$
            .pipe(
                untilDestroyed(this),
                filter(isAuthenticated => isAuthenticated),
                switchMap(() => this.identityService.v1IdentityGet()),
                tap(identity => {
                    const defaultTenant = (identity.tenants || []).find(
                        t =>
                            t.tenantId === this.appTenantId ||
                            (!this.appTenantId &&
                                (t as SafeAny).default === true)
                    );
                    if (defaultTenant) {
                        this.tenantProvider.setDefaultTenant(
                            defaultTenant.tenantId as string
                        );
                    }
                })
            )
            .subscribe(user =>
                userStore.update(state => ({
                    ...state,
                    ...user,
                }))
            );
    }

    resetPassword(): Observable<boolean> {
        if (!this.id) {
            return of(false); // TODO error console/display
        }
        return this.userService.userPasswordReset({ id: this.id }).pipe(
            catchError(() => of(false)),
            map(() => true)
        );
    }

    private setDataDogUser() {
        combineLatest([this.tenant$, this.fullName$])
            .pipe(
                untilDestroyed(this),
                filter(([tenant, _]) => isValue(tenant)),
                tap(() => {
                    datadogRum.setUser({
                        id: this.profile.userId,
                        name: this.profile?.fullName,
                        email: this.profile?.email,
                        tenantId: this.tenantProvider.tenant?.id,
                        tenantName: this.tenantProvider.tenant?.name,
                    });
                })
            )
            .subscribe();
    }
}
