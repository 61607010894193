import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFTransportationEntities } from '@pf/shared-common';
import { DriverRead } from '../../entities/driver.dto';

@Injectable()
export class DriverStore extends AbstractEntityStore<DriverRead> {
    constructor() {
        super(PFTransportationEntities.Driver);
    }
}
