import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { LoadRead } from '../../entities/load.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class LoadStore extends AbstractEntityStore<LoadRead> {
    constructor() {
        super(PFLoadEntities.Load);
    }
}
