import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { launchCall, launchText } from '@pf/shared-utility';

@Directive({
    selector: '[pfPhoneAction]',
})
export class PhoneActionDirective {
    @Input('pfPhoneAction') phoneNumber?: string;
    @Input() action: 'call' | 'text' = 'call';
    @HostBinding('style.cursor')
    cursor = 'pointer';
    @HostBinding('attr.disabled')
    get disabled() {
        return this.phoneNumber ? null : '';
    }
    @HostListener('click') onClick() {
        if (!this.phoneNumber) {
            return;
        }
        switch (this.action) {
            case 'call':
                launchCall(this.phoneNumber);
                break;
            case 'text':
                launchText(this.phoneNumber);
                break;
            // case 'prompt':
            //     this.presentPhoneOptions();
            //     break;
            default:
                break;
        }
    }

    // async presentPhoneOptions() {
    //     const alert = await this.alertController.create({
    //         header: 'Pick an action',
    //         message: '',
    //         buttons: [
    //             {
    //                 text: 'Call',
    //                 handler: () => {
    //                     launchCall(this.phoneNumber);
    //                 },
    //             },
    //             {
    //                 text: 'Text',
    //                 handler: () => {
    //                     launchText(this.phoneNumber);
    //                 },
    //             },
    //         ],
    //     });

    //     await alert.present();
    // }
}
