import { AbstractEntityStore } from '@pf/shared-services';
import { CustomerRead } from '../../entities/customer.dto';
import { Injectable } from '@angular/core';
import { PFCoreEntities } from '@pf/shared-common';

@Injectable()
export class CustomerStore extends AbstractEntityStore<CustomerRead> {
    constructor() {
        super(PFCoreEntities.Customer);
    }
}
