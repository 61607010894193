import { AbstractEntityMapper } from '@pf/shared-services';
import {
    ItemCreate,
    ItemDto,
    ItemRead,
    ItemSearchParams,
} from '../../entities/item.dto';
import { Injectable } from '@angular/core';
import { PlatformItemCatalogMapper } from '../platform-item-catalog.mapper';
import { ItemMetadataMap } from './item.mapping-profile';

@Injectable()
export class ItemMapper extends AbstractEntityMapper<
    ItemRead,
    ItemCreate,
    ItemDto,
    ItemSearchParams
> {
    constructor() {
        super(ItemDto, PlatformItemCatalogMapper, ItemMetadataMap.symbols);
    }
}
