import { UserFragment } from '@control-tower/platform-loads';
import { withMetadata } from '@pf/shared-utility';

export const UserFragmentSymbol = 'UserFragment';

export function withUserFragmentMetadata() {
    withMetadata<UserFragment>(UserFragmentSymbol, {
        id: String,
        firstName: String,
        lastName: String,
        email: String,
        lastModifiedDate: String,
    });
}
