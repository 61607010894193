import { AddressDto, AssociatedAddressDto } from '@pf/shared/util-platform';
import { Component, Input } from '@angular/core';
import { EntityBaseSubFormComponent } from '@pf/shared-ui';
import { FormControl, Validators } from '@angular/forms';
import { Controls, subformComponentProviders } from 'ngx-sub-form';
import { AssociatedAddressType } from '@control-tower/platform-core';
import { PFCoreEntities, SelectOption } from '@pf/shared-common';
import { getData } from 'country-list';
import { toSelectOptions } from '@pf/shared-utility';

type AddressForm = Pick<
    AssociatedAddressDto,
    'associatedAddressType' | 'description'
> & {
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    country: string;
};

@Component({
    selector: 'platform-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: subformComponentProviders(AddressFormComponent),
})
export class AddressFormComponent extends EntityBaseSubFormComponent<
    AssociatedAddressDto,
    AddressForm
> {
    @Input() set embeddedForm(val: boolean) {
        this.listenForSubmit = false;
        this.listenForCancel = false;
        this.registerDirtyCheck = false;
    }

    @Input() associatedAddress = false;

    countries = toSelectOptions(
        getData().sort(a => {
            if (['US', 'CA', 'MX'].includes(a.code)) {
                return -1;
            }
            return 0;
        }),
        'name',
        'code'
    );

    addressTypeOptions = Object.keys(AssociatedAddressType).map(
        type => ({ label: type, value: type } as SelectOption)
    );

    entityType = 'Address';
    rootEntityType = PFCoreEntities.Location;

    constructor() {
        super();
    }

    setValidators() {
        if (this.associatedAddress) {
            this.form.formGroup.controls.associatedAddressType.setValidators([
                Validators.required,
            ]);
        } else {
            this.form.formGroup.controls.associatedAddressType.clearValidators();
        }
    }

    formControls(): Controls<AddressForm> {
        return {
            name: new FormControl(null, null),
            streetAddress: new FormControl(null, [Validators.required]),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null, [Validators.required]),
            zip: new FormControl(null, [Validators.required]),
            country: new FormControl('US', [Validators.required]),
            description: new FormControl(null),
            associatedAddressType: new FormControl(null, [Validators.required]),
        };
    }

    fromFormGroup(formValue: AddressForm): AssociatedAddressDto {
        if (this.associatedAddress) {
            const associatedAddress = this.initialValue as AssociatedAddressDto;
            return new AssociatedAddressDto({
                ...this.initialValue,
                address: {
                    id: associatedAddress.address?.id || '',
                    name: formValue.name,
                    addressLine1: formValue.streetAddress,
                    addressLine2: '',
                    city: formValue.city,
                    geoZone: formValue.state,
                    postalCode: formValue.zip,
                    country: formValue.country || 'US',
                },
                description: formValue.description,
                associatedAddressType: formValue.associatedAddressType,
            });
        }

        return new AddressDto({
            ...(this.initialValue as AddressDto),
            addressLine1: formValue.streetAddress,
            addressLine2: '',
            city: formValue.city,
            geoZone: formValue.state,
            postalCode: formValue.zip,
            country: formValue.country || 'US',
        });
    }

    toFormGroup(address: AssociatedAddressDto): AddressForm {
        this.initialValue = address;

        this.setValidators();

        const addressDto = address.address || (address as AddressDto);

        const associatedAddress = address as AssociatedAddressDto;
        return {
            name: addressDto.name || '',
            streetAddress: addressDto.addressLine1 || '',
            city: addressDto.city || '',
            state: addressDto.geoZone || '',
            zip: addressDto.postalCode || '',
            country: addressDto.country || 'US',
            description: associatedAddress.description || '',
            associatedAddressType: associatedAddress.associatedAddressType,
        };
    }
}
