import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    VendorCreate,
    VendorRead,
    VendorSearchParams,
} from '../../entities/vendor.dto';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { VendorsService } from '@control-tower/platform-core';
import { VendorStore } from './vendor.store';
import { IContact } from '@pf/shared/util-platform';

@Injectable()
export class VendorDataService extends AbstractDataService<
    VendorRead,
    VendorCreate
> {
    protected EntityName = PFCoreEntities.Vendor;

    constructor(
        private vendorService: VendorsService,
        store: VendorStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: VendorCreate): Observable<VendorRead> {
        return this.vendorService.vendorsCreate({
            vendorViewModelCreateBody: body,
        }) as Observable<VendorRead>;
    }

    protected deleteObs$(id: string): Observable<VendorRead> {
        return this.vendorService.vendorsDelete({
            id,
        }) as Observable<VendorRead>;
    }

    protected undoDeleteObs$(id: string): Observable<VendorRead> {
        return this.vendorService.vendorsUndoDelete({
            id,
        }) as Observable<VendorRead>;
    }

    protected getObs$(id: string): Observable<VendorRead> {
        return this.vendorService.vendorsReadById({
            id,
        }) as Observable<VendorRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<VendorRead> {
        return this.vendorService.vendorsUpdatePatch({
            id,
            operation: operations,
        }) as Observable<VendorRead>;
    }

    protected searchObs$(
        searchParams: VendorSearchParams
    ): Observable<IPagedResult<VendorRead>> {
        return this.vendorService.vendorsSearch(searchParams) as Observable<
            IPagedResult<VendorRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<VendorRead>>> {
        return this.vendorService.vendorsGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<VendorRead>>
        >;
    }

    getContacts$(id: string): Observable<IContact[]> {
        return this.vendorService.v1VendorsIdContactsGet({
            id,
        }) as Observable<IContact[]>;
    }
}
