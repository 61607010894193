import { Injectable } from '@angular/core';
import {
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    Router,
    Routes,
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
    providedIn: 'root',
})
export class PathResolver {
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): string | null {
        let result = null;
        this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
            if (!isAuthenticated) {
                return null;
            }
            const typoPath = state.url;
            const threshold = this.getThreshold(typoPath);

            const paths = this.getPaths(this.router.config);

            const dictionary = Object.values(paths).filter(
                (path) => Math.abs(path.length - typoPath.length) < threshold
            );

            if (!dictionary.length) {
                return null;
            }

            this.sortByDistances(typoPath, dictionary);

            return (result = `${dictionary[0]}`);
        });
        return result;
    }

    constructor(private router: Router, private auth: AuthService) {}

    private getPaths = (routes: Routes, baseRoute = ''): string[] => {
        const paths = [];

        for (const route of routes) {
            if (route.path) {
                paths.push(`${baseRoute ? `/${baseRoute}` : ''}/${route.path}`);
            }

            if (route.children) {
                paths.push(...this.getPaths(route.children, route.path));
            }
        }

        return paths;
    };

    private getThreshold(typoPath: string): number {
        return Math.ceil(typoPath.length / 3);
    }

    private sortByDistances(typoPath: string, dictionary: string[]): void {
        const pathsDistance = {} as { [name: string]: number };

        dictionary.sort((a, b) => {
            if (!(a in pathsDistance)) {
                pathsDistance[a] = this.levenshtein(a, typoPath);
            }
            if (!(b in pathsDistance)) {
                pathsDistance[b] = this.levenshtein(b, typoPath);
            }

            return pathsDistance[a] - pathsDistance[b];
        });
    }

    private levenshtein(a: string, b: string): number {
        if (a.length == 0) {
            return b.length;
        }
        if (b.length == 0) {
            return a.length;
        }

        const matrix = [];

        // increment along the first column of each row
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        // increment each column in the first row
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        // Fill in the rest of the matrix
        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) == a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1, // substitution
                        matrix[i][j - 1] + 1, // insertion
                        matrix[i - 1][j] + 1 // deletion
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }
}
