import { IEntity, IMappingProfile } from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withMetadata,
} from '@pf/shared-utility';

import {
    DistanceUnitOfMeasurement,
    LoadDistance,
    LoadDistanceViewModel,
} from '@control-tower/platform-loads';
import { uniqueId } from 'lodash-es';

export interface IDistanceDto extends IEntity, LoadDistance {}

export class DistanceDto implements IDistanceDto {
    constructor(data: Partial<IDistanceDto>) {
        Object.assign(this, data);
        this.id = uniqueId();
    }

    id: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    miles?: number;
    kilometers?: number;
    unitOfMeasure?: DistanceUnitOfMeasurement;
}

export type DistanceRead = LoadDistance;
export type DistanceCreate = LoadDistanceViewModel;

const symbols = entitySymbols('Distance');

withDefaultEntityMetadata<DistanceDto>(symbols.dto, {
    miles: Number,
    kilometers: Number,
    unitOfMeasure: String,
});

withMetadata<DistanceRead>(symbols.entity, {
    miles: Number,
    kilometers: Number,
    unitOfMeasure: String,
    description: String,
});

withMetadata<DistanceCreate>(symbols.entity, {
    value: Number,
    type: String,
    description: String,
});

export const MileMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<DistanceRead, DistanceDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<DistanceDto, DistanceRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<DistanceDto, DistanceCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                d => d.type,
                mapFrom(s =>
                    s.miles
                        ? DistanceUnitOfMeasurement.Miles
                        : s.kilometers
                        ? DistanceUnitOfMeasurement.Kilometers
                        : null
                )
            ),
            forMember(
                d => d.value,
                mapFrom(s => s.miles || s.kilometers)
            )
        );
    },
};
