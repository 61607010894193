import { inject } from '@angular/core';
import {
    ExportConfig,
    getTableRowValue,
    IEntity,
    IEntityMapper,
    IEntityReadDataService,
    IEntityStore,
    IPagedResult,
    IPagedSearchParams,
    IQueryParams,
    ISearchFacade,
    QueryFilters,
    SafeAny,
    SelectOption,
    TreeSelectOption,
} from '@pf/shared-common';
import {
    mapToDtoPageResult,
    mapToSelectOptions,
    mapToTreeSelectOptions,
} from '@pf/shared-utility';
import * as saveAs from 'file-saver';
import { unparse } from 'papaparse';
import {
    catchError,
    combineLatest,
    map,
    Observable,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { LoggerService } from '../logging/Logger.service';
import { ValueFormatterService } from '../value-formatter.service';

export abstract class AbstractSearchFacade<
    TDto extends IEntity,
    TEntity extends IEntity,
    TCreateBody,
    TSearchParams extends IPagedSearchParams
> implements ISearchFacade<TDto>
{
    protected abstract nameField: keyof TDto;
    protected lastQueryParams?: IQueryParams<TDto>;

    getEntityName(entity: TDto): string {
        return entity ? (entity[this.nameField] as unknown as string) : '';
    }

    abstract textSearchFilter(searchText: string): QueryFilters<TDto>;

    private readonly valueFormatterService = inject(ValueFormatterService);
    private readonly logger = inject(LoggerService);

    protected constructor(
        protected dataService: IEntityReadDataService<TEntity>,
        protected store: IEntityStore<TEntity>,
        protected mapper: IEntityMapper<
            TEntity,
            TCreateBody,
            TDto,
            TSearchParams
        >
    ) {}

    currentPage$ = this.store.getPage$().pipe(mapToDtoPageResult(this.mapper));

    getById(id: string) {
        const entity = this.store.get(id);
        if (entity) {
            return this.mapper.toDTO(entity);
        }
        return null;
    }

    getById$(id: string) {
        const existing = this.getById(id);
        if (existing) {
            return of(existing);
        }
        return this.dataService.get$(id).pipe(
            tap(entity => this.store.add(entity, false)),
            map(entity => this.mapper.toDTO(entity))
        );
    }

    getMany$(ids: string[]) {
        return this.dataService
            .getMany$(ids)
            .pipe(map(entities => this.mapper.toDTOList(entities)));
    }

    reload$() {
        if (this.lastQueryParams) {
            this.store.clearPageHashes();
            return this.search$(this.lastQueryParams).pipe(map(() => true));
        }
        return of(false);
    }

    search$(params: IQueryParams<TDto>): Observable<IPagedResult<TDto>> {
        this.lastQueryParams = params;
        const searchParams = this.mapper.toSearchParams(params);
        return this.dataService
            .search$(searchParams)
            .pipe(mapToDtoPageResult(this.mapper));
    }

    oneTimeSearch$(params: IQueryParams<TDto>): Observable<IPagedResult<TDto>> {
        const searchParams = this.mapper.toSearchParams(params);
        return this.dataService
            .oneTimeSearch$(searchParams)
            .pipe(mapToDtoPageResult(this.mapper));
    }

    searchAll$(
        params: IQueryParams<TDto> = { pageIndex: 1, pageSize: 50 }
    ): Observable<TDto[]> {
        this.lastQueryParams = params;
        const searchParams = this.mapper.toSearchParams(params);
        const pageSize = (searchParams.pageSize = 50);

        return this.dataService.search$(searchParams).pipe(
            switchMap(result => {
                const pages = Math.ceil(result.totalRecords / pageSize);

                if (pages <= 1) {
                    return of([result]);
                }

                const requests = new Array(pages - 1).fill(0).map((_, i) => {
                    const nextQueryParams = {
                        ...params,
                        pageNumber: i + 2,
                    };
                    return this.dataService.search$(nextQueryParams);
                });
                return combineLatest([of(result), ...requests]);
            }),
            map(result =>
                result.flatMap(result =>
                    result.data.map(d => this.mapper.toDTO(d))
                )
            )
        );
    }

    searchByText$(text: string): Observable<IPagedResult<TDto>> {
        const params: IQueryParams<TDto> = {
            pageIndex: 1,
            pageSize: 20,
            sortOrder: 'ascend',
            sortField: this.nameField,
            filters: this.textSearchFilter(text),
        };
        return this.search$(params);
    }

    export$<TExport = TDto>(
        config: ExportConfig<TDto, TExport>
    ): Observable<boolean | 'Cancelled'> {
        const searchParams = this.mapper.toSearchParams(config.params);
        const filename = `${this.valueFormatterService.format(
            'fileTimestamp',
            new Date()
        )}_${config.fileName}`;
        const exportBlobObs$ = config.rawExport
            ? this.dataService.exportRaw$(searchParams, filename)
            : this.exportFromConfig(config, searchParams);
        return exportBlobObs$.pipe(
            map(blob => {
                saveAs(blob, filename, { autoBom: false });
                return true;
            }),
            catchError(err => {
                if (err?.message?.indexOf('STATUS:') > -1) {
                    return of(err.message.replace('STATUS:', ''));
                }
                this.logger.error('Failed to export. ' + JSON.stringify(err));
                return of(false);
            })
        );
    }

    private exportFromConfig(
        config: ExportConfig<TDto, SafeAny>,
        searchParams: IPagedSearchParams
    ): Observable<Blob> {
        return this.dataService.searchAll$(searchParams).pipe(
            map(entities => this.mapper.toDTOList(entities)),
            map(dtos =>
                config.typeConverter ? dtos.map(config.typeConverter) : dtos
            ),
            switchMap(exportDtos => {
                return config.reducer$
                    ? config.reducer$(exportDtos)
                    : of(exportDtos);
            }),
            switchMap(exportDtos =>
                exportDtos.length > 0
                    ? of(exportDtos)
                    : throwError(() => new Error('STATUS:Cancelled'))
            ),
            map(exportDtos => {
                this.logger.debug('Mapping dto list to export data array');
                return exportDtos.map(row => {
                    return config.columns.map(column => {
                        const value = getTableRowValue(column, row);
                        if (column.exportValueFormatter) {
                            return column.exportValueFormatter(value, row);
                        }
                        if (column.valueFormatter) {
                            return column.valueFormatter(value, row);
                        }
                        return this.valueFormatterService.format(
                            column.type,
                            value,
                            row
                        );
                    });
                });
            }),
            map(data => {
                this.logger.debug('Parse data to csv string');
                const fields = config.columns.map(c => c.headerName);
                return unparse({
                    fields,
                    data,
                });
            }),
            map(csvString => {
                return new Blob([csvString], { type: 'text/csv' });
            })
        );
    }

    mapToSelectOptions(
        source: Observable<IPagedResult<TDto>>
    ): Observable<SelectOption[]> {
        return mapToSelectOptions<TDto>(this.nameField)(source);
    }

    mapToTreeSelectOptions(
        source: Observable<IPagedResult<TDto>>
    ): Observable<TreeSelectOption[]> {
        return mapToTreeSelectOptions<TDto>(this.nameField)(source);
    }

    getCustomFieldsByKey$(key: string) {
        return this.dataService.searchCustomFields$({
            key,
        });
    }
}
