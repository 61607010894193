import { Injectable } from '@angular/core';
import { EmailService } from './email.service';
import { AppNotificationService } from './app-notification.service';
import { ToastMessageService } from './toast-message.service';
import {
    INotificationCreateRef,
    INotificationMessage,
    INotificationService,
    NotificationType,
} from '@pf/shared-common';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private _notificationServices!: Record<
        NotificationType,
        INotificationService
    >;
    constructor(
        toastMessageService: ToastMessageService,
        appNotificationService: AppNotificationService,
        emailService: EmailService
    ) {
        this._notificationServices = {
            [NotificationType.ToastMessage]: toastMessageService,
            [NotificationType.AppNotification]: appNotificationService,
            [NotificationType.Email]: emailService,
        };
    }

    create(notification: INotificationMessage) {
        return this._notificationServices[notification.type].create(
            notification
        );
    }

    remove(createRef: INotificationCreateRef) {
        this._notificationServices[createRef.type].remove(createRef.id);
    }
}
