import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { TrailersService } from '@control-tower/platform-transportation';
import {
    EntityChangeTrackingViewModel,
    IChangesSearchParams,
    IPagedResult,
} from '@pf/shared-common';
import { TrailerStore } from './trailer.store';
import {
    TrailerCreate,
    TrailerRead,
    TrailerSearchParams,
} from '../../entities/trailer.dto';
import { Operation } from 'fast-json-patch';
import { PFTransportationEntities } from '@pf/shared-common';

@Injectable()
export class TrailerDataService extends AbstractDataService<
    TrailerRead,
    TrailerCreate
> {
    protected EntityName = PFTransportationEntities.Trailer;
    constructor(
        private service: TrailersService,
        store: TrailerStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }
    protected createObs$(body: TrailerCreate): Observable<TrailerRead> {
        return this.service.trailerCreate({
            trailerViewModelCreateBody: body,
        }) as Observable<TrailerRead>;
    }
    protected deleteObs$(id: string): Observable<TrailerRead> {
        return this.service.trailerDelete({
            id,
        }) as Observable<TrailerRead>;
    }
    protected undoDeleteObs$(id: string): Observable<TrailerRead> {
        // return this.service.trailerUndoDelete({
        //     id,
        // }) as Observable<TrailerRead>;
        throw new Error('Method not implemented.');
    }
    protected getObs$(id: string): Observable<TrailerRead> {
        return this.service.trailerReadById({
            id,
        }) as Observable<TrailerRead>;
    }
    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<TrailerRead> {
        return this.service.trailerUpdatePatch({
            id,
            operation: operations,
        }) as Observable<TrailerRead>;
    }
    protected searchObs$(
        searchParams: TrailerSearchParams
    ): Observable<IPagedResult<TrailerRead>> {
        return this.service.trailerSearch(searchParams) as Observable<
            IPagedResult<TrailerRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingViewModel<TrailerRead>>> {
        return this.service.trailerGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingViewModel<TrailerRead>>
        >;
    }
}
