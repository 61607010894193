import { Injectable } from '@angular/core';
import { AbstractEntityMapper } from '@pf/shared-services';
import {
    StopItemCreate,
    StopItemDto,
    StopItemRead,
    StopItemSearchParams,
} from '../../entities/stop-item.dto';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { StopItemMetadataMap } from './stop-item.mapping-profile';

@Injectable()
export class StopItemMapper extends AbstractEntityMapper<
    StopItemRead,
    StopItemCreate,
    StopItemDto,
    StopItemSearchParams
> {
    constructor() {
        super(StopItemDto, PlatformLoadsMapper, StopItemMetadataMap.symbols);
    }
}
