import { Injectable } from '@angular/core';
import { AbstractEntityMapper } from '@pf/shared-services';
import {
    StopRead,
    StopCreate,
    StopDto,
    StopSearchParams,
} from '../../entities/stop.dto';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { StopMetadataMap } from './stop.mapping-profile';

@Injectable()
export class StopMapper extends AbstractEntityMapper<
    StopRead,
    StopCreate,
    StopDto,
    StopSearchParams
> {
    constructor() {
        super(StopDto, PlatformLoadsMapper, StopMetadataMap.symbols);
    }
}
