import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OneTimeLinkFacade } from '@pf/platform-communications/domain';
import { Auth0AuthService } from '@pf/shared-services';

@Component({
    selector: 'platform-one-time-login',
    template: ``,
})
export class OneTimeLoginComponent implements OnInit {
    oneTimeLinkFacade = inject(OneTimeLinkFacade);
    authService = inject(Auth0AuthService);

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'];
        this.route.paramMap.subscribe(params => {
            const id = params.get('id');

            if (!returnUrl || !id) {
                this.router.navigate(['/public/one-time-link/error']);
                return;
            }

            this.oneTimeLinkFacade.getOneTimeToken(id).subscribe(token => {
                if (token) {
                    window.location.href = returnUrl;
                } else {
                    this.router.navigateByUrl('/public/one-time-link/error');
                }
            });
        });
    }
}
