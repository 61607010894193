import { Component, Input } from '@angular/core';

@Component({
    selector: 'pf-content-collapsible',
    templateUrl: './content-collapsible.component.html',
    styleUrls: ['./content-collapsible.component.scss'],
})
export class ContentCollapsibleComponent {
    @Input() title?: string;
    @Input() active = false;
}
