import { AbstractEntityMapper } from '@pf/shared-services';
import {
    UserRoleCreate,
    UserRoleDto,
    UserRoleRead,
    UserRoleSearchParams,
} from '../../entities/user-role.dto';
import { Injectable } from '@angular/core';
import { PlatformCoreMapper } from '../platform-core.mapper';
import { UserRoleMetadataMap } from './user-role.mapping-profile';

@Injectable()
export class UserRoleMapper extends AbstractEntityMapper<
    UserRoleRead,
    UserRoleCreate,
    UserRoleDto,
    UserRoleSearchParams
> {
    constructor() {
        super(UserRoleDto, PlatformCoreMapper, UserRoleMetadataMap.symbols);
    }
}
