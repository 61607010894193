<ng-container *ngIf="iconProps !== null">
    <fa-icon
        [icon]="iconProps.faIcon"
        [style]="iconProps.style"
        [rotate]="rotate"
        nz-popover
        [ngClass]="iconClass"
        [nzPopoverTitle]="popover?.title"
        [nzPopoverContent]="popover?.content"
        nzPopoverPlacement="right"
    ></fa-icon>
</ng-container>
