import { Component } from '@angular/core';
import { AnimationItems, ResultProps } from '@pf/shared-ui';

@Component({
    selector: 'platform-one-time-link-error',
    templateUrl: './one-time-link-error.component.html',
    styleUrls: ['./one-time-link-error.component.scss'],
})
export class OneTimeLinkErrorComponent {
    errorProps = {
        title: 'There was an error processing your link.',
        animationOptions: {
            path: AnimationItems.NotFound,
        },
    } as ResultProps;
}
