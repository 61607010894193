import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FailedLoginComponent } from './pages/failed-login.component';
import { LoginCallbackComponent } from './pages/login-callback.component';
import { ErrorComponent } from './pages/error.component';
import { NotFoundComponent } from './pages/not-found.component';
import { LayoutModule, ResultModule, SharedUiModule } from '@pf/shared-ui';
import { PublicAppComponent } from './components/public-app.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        ResultModule,
        SharedUiModule,
        LayoutModule,
        RouterModule,
    ],
    declarations: [
        FailedLoginComponent,
        LoginCallbackComponent,
        ErrorComponent,
        NotFoundComponent,
        PublicAppComponent,
    ],
    exports: [
        FailedLoginComponent,
        LoginCallbackComponent,
        ErrorComponent,
        NotFoundComponent,
    ],
})
export class SharedUiAppSharedModule {}
