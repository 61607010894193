import {
    CustomerCreate,
    CustomerDto,
    CustomerRead,
    CustomerSearchParams,
} from '../entities/customer.dto';

import {
    AbstractManageEntityFacade,
    EntitySaveOptions,
} from '@pf/shared-services';
import {
    BuildChangeTrackingFacadeFactory,
    IContact,
    IEntityWithContactsFacade,
} from '@pf/shared/util-platform';
import { CustomerDataService } from '../infrastructure/customers/customer.data.service';
import { CustomerMapper } from '../infrastructure/customers/customer.mapper';
import { CustomerStore } from '../infrastructure/customers/customer.store';
import { inject, Injectable } from '@angular/core';
import { RoutesForCustomers } from './customer.routes';
import { map, Observable, of, switchMap } from 'rxjs';
import { ColumnSearchType, IColumnSearchFacade } from '@pf/shared-common';
import { CUSTOMER_CUSTOM_FIELDS } from '../platform-core-domain.module';
import { ResourceAuth } from '@control-tower/platform-loads';

@Injectable()
export class CustomerFacade
    extends AbstractManageEntityFacade<
        CustomerDto,
        CustomerRead,
        CustomerCreate,
        CustomerSearchParams
    >
    implements
        IEntityWithContactsFacade<CustomerDto>,
        IColumnSearchFacade<CustomerDto>
{
    nameField: keyof CustomerDto = 'customerName';
    customFieldsConfig$ = inject(CUSTOMER_CUSTOM_FIELDS, { optional: true });

    constructor(
        protected customerDataService: CustomerDataService,
        routes: RoutesForCustomers,
        customerStore: CustomerStore,
        customerMapper: CustomerMapper
    ) {
        super(customerDataService, routes, customerStore, customerMapper);
    }

    override applyResourceAuth(body: CustomerCreate, dto: CustomerDto) {
        body.resourceAuth = {
            customers: dto.id ? [dto.id] : [],
        } as ResourceAuth;
    }

    override save$(
        dto: CustomerDto,
        options?: EntitySaveOptions<CustomerDto>
    ): Observable<CustomerDto> {
        return super.save$(dto, options).pipe(
            switchMap(result => {
                if (
                    !result.resourceAuth?.customers?.find(x => x === result.id)
                ) {
                    // save again to update resourceAuth with new location id
                    return super.save$(result, {
                        emitEvent: false,
                        saveCustomFields: false,
                    });
                }
                return of(result);
            })
        );
    }

    get columnListFilterName(): string {
        return 'customer';
    }

    getColumnListFilters$(): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageNumber: 1,
            pageSize: 50,
        };

        return this.dataService.searchAll$(queryParams).pipe(
            map(results =>
                results.sort((a, b) =>
                    a.customerName.localeCompare(b.customerName)
                )
            ),
            map(result =>
                result.map(customer => ({
                    value: customer.id,
                    text: customer.customerName,
                }))
            )
        );
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    getContacts$(entity: CustomerDto): Observable<IContact[]> {
        return this.customerDataService.getContacts$(entity.id);
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof CustomerDto, string>> {
        return { customerName: searchText };
    }
}
